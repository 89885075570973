import { Check } from '@mui/icons-material';
import { Chip } from '@mui/material';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { theme } from '../../theme';

function OverdueChip(props) {
    const overdueValue = <NumericFormat value={props.overdueValue / 100} displayType='text' thousandSeparator={true} prefix={props.numOverdue + ' ($'} suffix={')'} decimalScale={0} fixedDecimalScale />;
    const percentageOverdueValue = <NumericFormat value={props.overdueValue / 100} displayType='text' thousandSeparator={true} prefix={'$'} suffix={` (${props.overduePercentage}%)`} decimalScale={0} fixedDecimalScale />;

    if (props.numOverdue === 0) {
        return (
            <Chip 
                avatar={<Check fontSize='small' style={{ color: `${theme.palette.campaignMonitoring.green.text}`, width: '18px', height: '18px' }} />} 
                label="None" 
                style={{ 
                    backgroundColor: `${theme.palette.campaignMonitoring.green.background}`,
                    color: `${theme.palette.campaignMonitoring.green.text}`
                }} 
                size='small'
            />
        );
    } else if (props.format === 'no_percentage') {
        return (
            <Chip 
                label={overdueValue} 
                style={{ 
                    backgroundColor: `${theme.palette.campaignMonitoring.red.background}`,
                    color: `${theme.palette.campaignMonitoring.red.text}`
                }} 
                size='small'
            />
        );
    } else if (props.format === 'currency') {
        return (
            <Chip 
                label={percentageOverdueValue} 
                style={{ 
                    backgroundColor: `${theme.palette.campaignMonitoring.red.background}`,
                    color: `${theme.palette.campaignMonitoring.red.text}`
                }} 
                size='small'
            />
        )
    } else if (props.format === 'count') {
        return (
            <Chip 
                label={`${props.numOverdue} Optimizations (${props.numOverduePercentage}%)`} 
                style={{ 
                    backgroundColor: `${theme.palette.campaignMonitoring.red.background}`,
                    color: `${theme.palette.campaignMonitoring.red.text}`
                }} 
                size='small'
            />
        );
    }

    return <></>;
}

export default OverdueChip;
