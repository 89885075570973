import React, { useState } from 'react';
import { Stack } from '@mui/material';
import ItemSwapCard from './ItemSwapCard';
import ItemSwapNavigation from './ItemSwapNavigation';
import { motion, AnimatePresence } from 'framer-motion';
import LogOnEnter from '../../CustomComponents/LogOnEnter';


function ItemSwapsFeed(props) {
    const [isGoingBack, setIsGoingBack] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const swaps = props.swaps;

    function navigateBack() {
        setIsAnimating(true);
        setIsGoingBack(true);
        if (Object.values(swaps)[props.activeSwapIndex - 1].recommendations.some((rec) => rec.status === 'open')) {
            props.setChecked(Object.values(swaps)[props.activeSwapIndex - 1].recommendations.map((rec) => rec.draft.id));
        } else if (Object.values(swaps)[props.activeSwapIndex - 1].recommendations.some((rec) => rec.status === 'rejected')) {
            props.setChecked([]);
        } else {
            props.setChecked(Object.values(swaps)[props.activeSwapIndex - 1].recommendations.filter((rec) => rec.status === 'accepted').map((rec) => rec.draft.id));
        }
        props.setActiveSwapIndex(props.activeSwapIndex - 1);
    }

    function navigateForward() {
        setIsAnimating(true);
        setIsGoingBack(false);
        if (Object.values(swaps)[props.activeSwapIndex + 1].recommendations.some((rec) => rec.status === 'open')) {
            props.setChecked(Object.values(swaps)[props.activeSwapIndex + 1].recommendations.map((rec) => rec.draft.id));
        } else if (Object.values(swaps)[props.activeSwapIndex + 1].recommendations.some((rec) => rec.status === 'rejected')) {
            props.setChecked([]);
        } else {
            props.setChecked(Object.values(swaps)[props.activeSwapIndex + 1].recommendations.filter((rec) => rec.status === 'accepted').map((rec) => rec.draft.id));
        }
        props.setActiveSwapIndex(props.activeSwapIndex + 1);
    }

    const variants = {
        enter: (isGoingBack) => { 
            return {
                x: isGoingBack ? -500 : 500,
                opacity: 0
            }
        },
        center: { x: 0, y: 0, zIndex: 1, opacity: 1 },
        exit: (isGoingBack) => {
            return {
                x: isGoingBack ? 500 : -500, 
                zIndex: 0, 
                opacity: 0
            } 
        }
    }

    const isAccepted = props.currentSwapStatus === 'accepted';
    const isRejected = props.currentSwapStatus === 'rejected';
    const isOpen = props.currentSwapStatus === 'open';

    return (
        <>
            <Stack spacing={2} px={2} pt={2} pb={14} sx={{ maxWidth: screen.width }}>
                <AnimatePresence onExitComplete={() => setIsAnimating(false)} exitBeforeEnter custom={isGoingBack} >
                    <motion.div
                        key={props.currentSwap.recommendations[0].item.id}
                        custom={isGoingBack}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{ 
                            x: { type: 'spring', stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 },
                            duration: 0.3
                        }}
                    >
                        <LogOnEnter action='viewed_swap' data={{ swap: props.currentSwap }}>
                            <ItemSwapCard 
                                currentSwap={props.currentSwap}
                                currentIndex={props.activeSwapIndex}
                                isAccepted={isAccepted}
                                isRejected={isRejected}
                                isOpen={isOpen}
                                checked={props.checked}
                                drafts={props.drafts}
                                setIsDrawerOpen={props.setIsDrawerOpen}
                                showItemCost={props.showItemCost}
                            />
                        </LogOnEnter>
                    </motion.div>
                </AnimatePresence>
            </Stack>
            <ItemSwapNavigation 
                swaps={Object.values(swaps)} 
                navigateForward={navigateForward}
                navigateBack={navigateBack}
                handleCompletion={props.handleCompletion}
                currentIndex={props.activeSwapIndex}
                currentStatus={props.currentSwapStatus}
                cannotSwap={props.checked.length === 0}
                swapItems={props.swapItems}
                declineSwap={props.declineSwap}
                undoSwap={props.undoSwap}
                isAnimating={isAnimating}
                setIsShowingFeedbackDrawer={props.setIsShowingFeedbackDrawer}
            />
        </>
    );
}

export default ItemSwapsFeed;
