import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSaveCampaignTemplateMutation, useUpdateCampaignMutation, useUpdateCampaignTemplateMutation } from '../../../api/campaignSlice';

function SaveSettingsTemplateDialog(props) {
    const [templateName, setTemplateName] = useState(props.campaign.template?.name ?? '');
    const [error, setError] = useState('');
    const [saveTemplate] = useSaveCampaignTemplateMutation();
    const [updateTemplate] = useUpdateCampaignTemplateMutation();
    const [updateCampaign] = useUpdateCampaignMutation(props.campaign.id);

    const handleClose = () => {
        props.setOpen(false);
    };

    const handleSaveTemplate = async() => {
        setError('');
        var template_data = props.settings;
        delete template_data.base_template;

        await saveTemplate({
            hospitalId: props.campaign.hospital.id,
            requestBody: {
                name: templateName,
                template_data: template_data
            }
        }).unwrap().then((response) => {
            updateCampaign({
                campaignId: props.campaign.id,
                requestBody: {
                    settings: props.settings,
                    base_template_id: response.id
                }
            });
            handleClose();
        }).catch(() => {
            setError('A template with this name already exists. Please choose another name.');
        });
    };

    const handleUpdateTemplate = async() => {
        var template_data = props.settings;
        delete template_data.base_template;

        await updateTemplate({
            templateId: props.campaign.template.id,
            requestBody: {
                name: templateName,
                template_data: template_data
            }
        }).unwrap().then(() => {
            updateCampaign({
                campaignId: props.campaign.id,
                requestBody: {
                    settings: props.settings,
                }
            });
        });
        handleClose();
    };

    if (props.campaign.template) {
        return (
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle>Update Settings Template</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Update the currently selected campaign settings template.
                    </DialogContentText>
                </DialogContent>
                <DialogContent>
                    <TextField disabled fullWidth label='Template Name' value={templateName} onChange={e => setTemplateName(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleUpdateTemplate} disabled={!templateName}>Update Template</Button>
                </DialogActions>
            </Dialog>
        )
    } else {
        return (
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle>Save Settings Template</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Save the currently selected campaign settings as a template to easily start with the same settings for future campaigns.
                    </DialogContentText>
                </DialogContent>
                <DialogContent>
                    <TextField fullWidth label='Enter Template Name' value={templateName} onChange={e => setTemplateName(e.target.value)} />
                    { error && 
                        <DialogContentText>
                            <Typography variant='normal' color='error'>{error}</Typography>
                        </DialogContentText> 
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSaveTemplate} disabled={!templateName}>Save Template</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default SaveSettingsTemplateDialog;
