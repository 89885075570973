import { InfoOutlined } from '@mui/icons-material';
import { Paper, Stack, Switch, Tooltip, Typography } from '@mui/material';
import React from 'react';

function AdditionalSettings(props) {
    return (
        <Paper elevation={2}>
            <Stack p={3} spacing={2}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='light' sx={{ fontSize: '18px' }}>Additional Settings</Typography>
                </Stack>
                <Stack spacing={2} direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>Automated Texting: </Typography>
                    <Stack direction='row' spacing={1} alignItems='center'>
                        <Switch disabled={true} checked={props.settings.automated_texting} onChange={(e) => props.setSettings({ ...props.settings, automated_texting: e.target.checked })} />
                        <Tooltip title='When enabled, the system will automatically send text messages containing dashboard links to physicians when you start this campaign.'>
                            <InfoOutlined style={{ opacity: 0.5 }} />
                        </Tooltip>
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    )
}


export default AdditionalSettings;
