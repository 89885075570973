import { Skeleton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import logo from '../../../../assets/docsi_logo.svg';

function DashboardHeader(props) {
    return (
        <Stack direction="row" justifyContent="space-between" px={2} py={1} sx={{ backgroundColor: '#FFFFFF' }}>
            <Stack>
                <Box sx={{ fontSize: '20px'}} alignItems='center'>
                    { props.physician ? <Typography variant='normal'>Dr. {props.physician.name}</Typography> : <Skeleton variant='text' width={175} /> }
                </Box>
                <Box sx={{ fontSize: '14px' }}>
                    { props.hospital ? <Typography variant='normal'>{props.hospital.name}</Typography> : <Skeleton variant='text' width={175} /> }
                </Box>
            </Stack>
            <Box display='flex' alignItems='center' maxWidth='40%'>
                { props.hospital ? <img src={props.hospital.logo ? props.hospital.logo : logo} alt='Site Logo' style={{ objectFit: 'contain' }} width='100%' height={25}/> : <Skeleton variant='rectangle' width={150} height={20} /> }
            </Box>
        </Stack>
    )
}

export default DashboardHeader;