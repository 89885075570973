import React from 'react';
import { Box, useTheme } from '@mui/system';
import { Stack, Typography } from '@mui/material';
import TaskHeader from './TaskHeader';
import ItemSwapProgress from './ItemSwapProgress';
import { ChevronRightRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';


function ReviewComplianceSwapsTask(props) {
    const theme = useTheme();
    const navigate = useNavigate();

    const navigateToItemSwaps = () => {
        navigate(`/physician/${props.cardOwner.id}/compliance/${props.campaign.id}`, { state: {
            physician: props.cardOwner
        }});
    }

    const reviewedRecs = props.totalRecs - props.activeRecs;

    return (
        <Stack className='pendo-compliance-task' spacing={2}>
            <TaskHeader title='Review Compliance Recommendations' taskCount={props.activeRecs} shouldShowCount={props.activeRecs > 0} />
            <Box p={2} onClick={navigateToItemSwaps} sx={{ boxShadow: 2, borderRadius: '4px', background: `${theme.palette.background.white}` }}>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <ItemSwapProgress value={props.totalRecs > 0 ? Math.round((reviewedRecs / props.totalRecs) * 100) : 0} />
                        <Typography variant='light' sx={{ fontSize: '12px' }}>
                            {reviewedRecs} of {props.totalRecs} swaps reviewed
                        </Typography>
                    </Stack>
                    <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ width: '100%' }}>
                        <Typography variant='normal' sx={{ fontSize: '12px' }}>
                            You can reduce  <strong><NumericFormat value={props.complianceDebt / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} /></strong> in non-contract spend by swapping <strong>{props.activeRecs} items</strong> for in-contract items.
                        </Typography>
                        <ChevronRightRounded sx={{ opacity: '0.6' }}/>
                    </Stack>
                </Stack>
            </Box>
        </Stack>
    );

}


export default ReviewComplianceSwapsTask;

