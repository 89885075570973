import React from 'react';
import { Stack } from '@mui/material';
import ItemSwapChip from './ItemSwapChip';
import { useMemo } from 'react';

function ItemSwapChipSection(props) {
    const formattedSavings = useMemo(() => {
        if (props.potentialSavings / 100 >= 1000) {
            return Math.round(props.potentialSavings / 100 / 1000) + 'k';
        } else {
            return Math.round(props.potentialSavings / 100);
        }
    }, [props.potentialSavings]);

    return (
        <Stack direction='row' spacing={1}>
            { props.variant === 'current' && 
                <>
                    { props.recommendation.context.contract_compliance && <ItemSwapChip text='Not Compliant' /> }
                    { props.recommendation.item.ecri_assessment && <ItemSwapChip variant='positive' text='ECRI Assessment Available' /> }
                </>
            }
            { props.variant === 'recommended' && (
                <>
                    { props.recommendation.context.contract_compliance && <ItemSwapChip variant='positive' text='Contract Compliant' /> }
                    { props.recommendation.context.peer_preference && <ItemSwapChip variant='positive' text='Peer Preferred' /> }
                    { props.potentialSavings > 0 && <ItemSwapChip variant='positive' text={'Save $' + formattedSavings + ' /yr'} /> }
                </>
            )}
        </Stack>
    );
}

export default ItemSwapChipSection;