import {useState} from 'react';

export default function useDashboardToken() {
    const getToken = () => {
        return localStorage.getItem('dashboardToken');
    }

    const [token, setToken] = useState(getToken());

    const saveToken = dashboardToken => {
        if (dashboardToken === null) {
            localStorage.removeItem('dashboardToken');
        } else {
            localStorage.setItem('dashboardToken', dashboardToken);
        }
        setToken(dashboardToken)
    }

    return {
        setPhysicianDashboardToken: saveToken,
        physicianDashboardToken: token
    }
}
