import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../Hooks/useAuth';

function RequireSuperAccess({ children }) {
    const { authed, isSuperUser } = useAuth();
    const location = useLocation();

    return (authed === true && isSuperUser ? children : <Navigate to='/cards' replace state={{ path: location.pathname }} />);
}

export default RequireSuperAccess;