import React from 'react';
import {Box} from '@mui/system';
import {Link, Stack, Grid} from '@mui/material';
import whiteLogo from '../../../assets/white_logo.svg';
import smallLogo from '../../../assets/single_letter_logo.svg'

function LockedAccount() {
    return (
        <Grid container sx={{ display: 'flex', height: '100%', width: '100%', backgroundColor: '#F7F9FC' }}>
            <Grid item xs={0} sm={2} md={3} />
            <Grid item xs={12} sm={8} md={6} height='100%' display='grid'>
                <Stack direction='row' sx={{ alignSelf: 'center', boxShadow: 4, borderRadius: '13px' }}>
                    <Stack px={4} py={6} spacing={6} sx={{ minWidth: '60%', borderTopLeftRadius: '13px', borderBottomLeftRadius: '13px' }}>
                        <Box>
                            <img src={smallLogo} alt="DOCSI Mini Logo" width='40' height='30' />
                        </Box>
                        <Stack sx={{ justifySelf: 'center', alignSelf: 'center', width: '343'}}>
                            <Box sx={{font: 'normal normal normal 24px/22px Roboto' }}>Account Locked</Box>
                            <Box sx={{font: 'normal normal normal 16px/24px Roboto', color: '#00000099'}} my={4}>{'Sorry, you\'ve had too many login attempts. Contact your administrator or our support team for assistance.'}</Box>
                        </Stack>
                        <Box ml={4} mb={2} sx={{font: 'normal normal normal 16px/24px Roboto'}}>Need Help? <Link  color = '#2C2CFF' target="_blank"  href = "https://docs.google.com/forms/d/e/1FAIpQLSdZk2yXi-G7yU_dhUurqcd_id2ffgXYXh46m0tNwW2EJJmD-Q/viewform">Reach Out</Link></Box>
                    </Stack>
                    <Box sx={{ display: { xs: 'none', lg: 'flex' }, width: '100%', backgroundColor: '#2C2CFF', alignItems: 'center', justifyContent: 'center', borderTopRightRadius: '13px', borderBottomRightRadius: '13px' }}>
                        <img src={whiteLogo} alt="DOCSI Logo" width='75%' style={{ objectFit: 'contain' }} />
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={0} sm={2} md={3} />
        </Grid>
    );
}

export default LockedAccount;