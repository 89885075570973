import React from 'react';
import { TableRow, TableCell, Stack, TableContainer, Table, TableHead, TableBody, TablePagination } from '@mui/material';
import GenericTableRow from './GenericTableRow';

function GenericTable(props) {
    const handleChangePage = (event, newPage) => {
        props.setPage(newPage + 1);
    }

    return (
        <Stack sx={{ overflow: 'hidden', height: '100%', width: '100%', backgroundColor: 'white', borderRadius: '4px', boxShadow: 6 }}>
            <TableContainer sx={{ height: '93%' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow sx={{ '&:hover': { cursor: 'default' } }}>
                            {props.headers.map((header, index) => (
                                <TableCell key={index} sx={{ fontWeight: '700', flex: 1 }}>{header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {props.data &&
                        <TableBody>
                            {props.data.map((dataRow) => (
                                <GenericTableRow key={dataRow.id} data={dataRow} dataType={props.dataType} />
                            ))}
                        </TableBody>
                    }
                </Table>
            </TableContainer>
            <TablePagination
                component='div'
                rowsPerPageOptions={[15]}
                count={props.total ?? 0}
                rowsPerPage={15}
                page={props.page}
                onPageChange={handleChangePage}
                style={{ 'overflowY': 'hidden' }}
            />
        </Stack>
    );
}

export default GenericTable;
