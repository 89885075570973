import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/system';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { Typography, Toolbar, Button } from '@mui/material';
import { theme } from '../../theme';
import { Box } from '@mui/system';
import logo from '../../../assets/docsi_logo.png';
import { useUnsubscribeUserFromEmailsMutation } from '../../api/userSlice';


function EmailUnsubscribe() {
    const { userId } = useParams();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [unsubscribeUser] = useUnsubscribeUserFromEmailsMutation();

    useEffect(() => {
        unsubscribeUser({ userId }).unwrap().then(() => {
            setIsSuccess(true);
        }).catch(() => {
            setIsError(true);
        });
    }, [userId]);

    return (
        <Stack sx={{ backgroundColor: `${theme.palette.background.main}`, height: '100%' }}>
            <Toolbar sx={{ justifyContent: 'end', width: '100%' }}>
                <Box display='flex' alignItems='center' mr={{ xs: 2,  md: 6 }}>
                    <img src={logo} alt="DOCSI Logo" style={{ objectFit: 'contain' }} height={25}/>
                </Box>
            </Toolbar>
            {isSuccess &&
                <Stack justifyContent='center' alignItems='center' width='100%' height='100%' spacing={3}>
                    <Typography variant='heavy' fontSize='20px'>You have been successfully unsubscribed from receiving automated DOCSI emails.</Typography>
                    <CheckCircle color='success' style={{ height: '50px', width: '50px' }} />
                    <Button variant='contained' color='primary' onClick={() => window.location.href = '/'}>Return to DOCSI</Button>
                </Stack>
            }
            {isError &&
                <Stack justifyContent='center' alignItems='center' width='100%' height='100%' spacing={3}>
                    <Typography variant='heavy' fontSize='20px'>Something went wrong in trying to unsubscribe your account from emails. Please try again or reach out to DOCSI support.</Typography>
                    <Cancel color='error' style={{ height: '50px', width: '50px' }} />
                    <Button variant='contained' color='primary' onClick={() => window.location.href = '/'}>Return to DOCSI</Button>
                </Stack>
            }
        </Stack>
    );
}

export default EmailUnsubscribe;
