import React from 'react';
import PickList from '../PickList/PickList';
import Comments from './Comments';
import Instruments from './Instruments';

function CardContent(props) {
    if (props.selectedIndex === 0) {
        return <PickList pickList={props.card.pick_list} card={props.card} />;
    } else if (props.selectedIndex === 1) {
        return <Instruments card={props.card} />;
    } else {
        return <Comments card={props.card} />;
    }
}

export default CardContent;