import { Box } from '@mui/system';
import { Typography, Stack, InputLabel, TextField, Paper, Button,  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ToggleButton} from '@mui/material';
import { useGetCardOwnerQuery, useUpdateCardOwnerMutation, useDeleteCardOwnerMutation} from '../../api/cardSlice'
import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useMemo } from 'react';
import { useGetCardOwnerSpecialtiesQuery } from '../../api/userSlice';
import CreateAutocomplete from '../CustomComponents/CreateAutocomplete';

function EditPhysician(){
    let params = useParams();
    const navigate = useNavigate();
    const { data: physician, isSuccess } = useGetCardOwnerQuery(params.physicianId);
    const [submitUpdateCardOwner] = useUpdateCardOwnerMutation();
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const updateCardOwner = async(data) => {
        var requestSpecialty = data.specialty;
        if (typeof(data.specialty) !== 'string') {
            requestSpecialty = data.specialty.name;
        }

        await submitUpdateCardOwner({
            cardOwner : physician,
            firstName: data.firstName,
            lastName: data.lastName,
            specialty : requestSpecialty,
            externalId :data.externalId,
            phoneNumber : data.phoneNumber,
            doNotText : data.doNotText
        });
        navigate('/physicians');
    }

    return (
        isSuccess &&
        <Stack spacing={2} px={{ xs: 2, md: 4 }}>
            <Typography variant='heavy' sx={{ fontSize: '20px' }}>Edit Physician</Typography>
            <PhysicianDetail isConfirmationOpen={isConfirmationOpen} setIsConfirmationOpen={setIsConfirmationOpen} updateCardOwner={updateCardOwner} physician={physician} />
        </Stack>
    )
}

export default EditPhysician;

function PhysicianDetail(props){
    const [deleteCardOwner] = useDeleteCardOwnerMutation();
    const navigate = useNavigate();

    const handleDelete = async () => {
        await deleteCardOwner({ 
            cardOwner : props.physician
        });
        navigate('/physicians');
    }

    const [externalId, setExternalId] = useState(props.physician.external_id ?  props.physician.external_id : '');
    const [firstName, setFirstName] = useState(props.physician.first_name);
    const [lastName, setLastName] = useState(props.physician.last_name);
    const [specialty, setSpecialty] = useState(props.physician.specialty ? props.physician.specialty : '');
    const [phoneNumber, setPhoneNumber] = useState(props.physician.phone_number);
    const [doNotText, setDoNotText] = useState(Boolean(props.physician.do_not_text));

    var originalSpecialty = props.physician.specialty ? props.physician.specialty : '';
    var originalExternalId = props.physician.external_id ? props.physician.external_id : '';

    const canUpdate = useMemo(() => {
        return (firstName !== props.physician.first_name || lastName !== props.physician.last_name || specialty !== originalSpecialty || externalId !== originalExternalId || phoneNumber !== props.physician.phone_number || doNotText !== Boolean(props.physician.do_not_text));
    }, [firstName, lastName, specialty, externalId, phoneNumber, doNotText]);

    return(
        <Paper elevation={2} sx={{ maxWidth: { xs: '100%', md: '50%', lg: '40%' } }}>
            <Stack spacing={4} px={4} pt={4}> 
                <Box>
                    <InputLabel htmlFor="cardOwner-first-name">
                        First Name
                    </InputLabel>
                    <TextField 
                        id="cardOwner-first-name"
                        variant='outlined' 
                        fullWidth={true}
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                    />
                </Box>
                <Box>
                    <InputLabel htmlFor="cardOwner-last-name">
                        Last Name
                    </InputLabel>
                    <TextField 
                        id="cardOwner-last-name"
                        variant='outlined' 
                        fullWidth={true}
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                    />
                </Box>
                <Box>
                    <InputLabel htmlFor="cardOwner-specialty">
                        Specialty
                    </InputLabel>
                    <CreateAutocomplete
                        label="Specialty"
                        value={specialty}
                        setValue={setSpecialty}
                        loadOptions={() => useGetCardOwnerSpecialtiesQuery(props.physician.hospital.id)}
                        fullWidth={true}
                    />
                </Box>
                <Box>
                    <InputLabel htmlFor="cardOwner-external-id">
                        External ID
                    </InputLabel>
                    <TextField 
                        id="cardOwner-external-id"
                        variant='outlined' 
                        fullWidth={true}
                        value={externalId}
                        onChange={(event) => setExternalId(event.target.value)}
                    />
                </Box>
                <Box>
                    <InputLabel htmlFor="cardOwner-phone-number">
                        Phone Number
                    </InputLabel>
                    <TextField 
                        id="cardOwner-phone-number"
                        variant='outlined' 
                        fullWidth={true}
                        value={phoneNumber}
                        onChange={(event) => setPhoneNumber(event.target.value)}
                    />
                </Box>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography variant='heavy' sx={{ fontSize: '16px' }}>Texting Status:</Typography>
                    <ToggleButton
                        value="check"
                        selected={doNotText}
                        onChange={() => setDoNotText(!doNotText)}
                    >
                        {doNotText ? 'Disabled' : 'Enabled'}
                    </ToggleButton>
                </Stack>
                <Stack alignItems='flex-start'>
                    <Button 
                        variant='text' 
                        color='error'
                        onClick={() =>{props.setIsConfirmationOpen(true)}}
                    >Disable Account</Button>
                    <DeletePhysicianConfirmation 
                        open = {props.isConfirmationOpen} 
                        setOpen = {props.setIsConfirmationOpen} 
                        confirmDisable= {handleDelete}
                    />
                </Stack>
                <Stack direction='row' spacing={2} justifyContent='flex-end' alignItems='center' pb={2} pr={2}>
                    <Button 
                        variant='contained' 
                        onClick={() => props.updateCardOwner({firstName: firstName, lastName: lastName, specialty : specialty, externalId: externalId, phoneNumber: phoneNumber, doNotText: doNotText})}
                        disabled = {!canUpdate}>Update Physician
                    </Button>
                </Stack>
            </Stack>
        </Paper>
    )
}



function DeletePhysicianConfirmation(props) {
    function handleClose() {
        props.setOpen(false);
    }

    function handleConfirm() {
        props.confirmDisable();
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>
            Confirm Card Owner Deletetion
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this card owner?
                </DialogContentText>
                <DialogContentText>
                    Deleting this physician will also delete their preference cards.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color = 'error'>Cancel</Button>
                <Button onClick={handleConfirm } color = 'primary'>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}