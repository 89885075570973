import React, { useMemo, useState } from 'react';
import DraftTable from '../DraftTable';
import { Button, Stack, Typography } from '@mui/material';
import PreviewStats from './PreviewStats';
import { useUpdateCampaignMutation } from '../../../api/campaignSlice';

function CampaignPreview(props) {
    const [checkedIncludedPhysicians, setCheckedIncludedPhysicians] = useState([]);
    const [checkedExcludedPhysicians, setCheckedExcludedPhysicians] = useState([]);
    const [updateCampaign] = useUpdateCampaignMutation();

    const allIncludedPhysicians = useMemo(() => {
        var physicians = [];
        Object.keys(props.physicians).forEach((physicianId) => {
            if (!props.campaign.settings['excluded_physicians'].includes(parseInt(physicianId))) {
                physicians.push(props.physicians[physicianId]);
            }
        });
        return physicians;
    }, [props.physicians, props.campaign]);

    const allExcludedPhysicians = useMemo(() => {
        var physicians = [];
        Object.keys(props.physicians).forEach((physicianId) => {
            if (props.campaign.settings['excluded_physicians'].includes(parseInt(physicianId))) {
                physicians.push(props.physicians[physicianId]);
            }
        });
        return physicians;
    }, [props.physicians, props.campaign]);

    const handleIncludeClick = async() => {
        await updateCampaign({
            campaignId: props.campaign.id,
            requestBody: {
                settings: {
                    ...props.campaign.settings,
                    excluded_physicians: props.campaign.settings['excluded_physicians'].filter((physicianId) => !checkedExcludedPhysicians.includes(physicianId))
                }
            }
        });
        setCheckedExcludedPhysicians([]);
    }

    const handleExcludeClick = async() => {
        await updateCampaign({
            campaignId: props.campaign.id,
            requestBody: {
                settings: {
                    ...props.campaign.settings,
                    excluded_physicians: checkedIncludedPhysicians
                }
            }
        });
        setCheckedIncludedPhysicians([]);
    }
    
    return (
        <Stack spacing={3}>
            <PreviewStats physicians={allIncludedPhysicians} drafts={props.drafts} isLoadingDrafts={props.isLoadingDrafts} />
            <Stack spacing={1}>
                { props.campaign.status === 'open' &&
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <Typography variant="h6">Included Physicians</Typography>
                        { checkedIncludedPhysicians.length > 0 &&
                            <Button variant='contained' size='small' onClick={handleExcludeClick}>Exclude Checked Physicians</Button>
                        }
                    </Stack>
                }
                <DraftTable 
                    physicians={allIncludedPhysicians} 
                    checkedPhysicians={checkedIncludedPhysicians} 
                    setCheckedPhysicians={setCheckedIncludedPhysicians}
                    drafts={props.tableData} 
                    campaign={props.campaign} 
                    isLoadingDrafts={props.isLoadingDrafts} 
                />
            </Stack>
            { props.campaign.status === 'open' && 
                <Stack spacing={1}>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <Typography variant="h6">Excluded Physicians</Typography>
                        { checkedExcludedPhysicians.length > 0 &&
                            <Button variant='contained' size='small' onClick={handleIncludeClick}>Include Checked Physicians</Button>
                        }
                    </Stack>
                    <DraftTable 
                        physicians={allExcludedPhysicians}
                        checkedPhysicians={checkedExcludedPhysicians} 
                        setCheckedPhysicians={setCheckedExcludedPhysicians}
                        drafts={props.tableData} 
                        campaign={props.campaign} 
                        isLoadingDrafts={props.isLoadingDrafts} 
                    />
                </Stack>
            }
        </Stack>
    )
}

export default CampaignPreview;