import { Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import StartNewConversation from './StartNewConversation';

function MissingConversationView(props) {
    const [showNewConversation, setShowNewConversation] = useState(false);

    return (
        <Stack spacing={1} justifyContent='center' alignItems='center'>
            <Typography variant='heavy'>It appears there is no active conversation with this physician.</Typography>
            <Button size='small' variant='contained' color='primary' onClick={() => setShowNewConversation(true)}>Start a new conversation</Button>
            <StartNewConversation showNewConversation={showNewConversation} setShowNewConversation={setShowNewConversation} sendMessage={props.sendMessage} />
        </Stack>
    )
}


export default MissingConversationView;
