import { ArrowBackIos } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { theme } from '../theme';

function HeaderBreadcrumbs() {
    const params = useParams();
    let { state } = useLocation();
    const navigate = useNavigate();

    // These are inntended to be used as back buttons so the breadcrumb for each path is the name of the previos page in the path
    // This is different from how breadcrumbs would actually be used so be aware when adding new routes
    // The way this is set up, paths that need a back button must have a breadcrumb and a redirectTo, everything else needn't be mentioned!
    const routes = [
        // Cards
        { path: '/cards/:cardId', breadcrumb: 'All Cards', props: { redirectTo: '/cards' } },
        { path: '/cards/create', breadcrumb: 'All Cards', props: { redirectTo: '/cards' } },
        // Users
        { path: '/users/:userId', breadcrumb: 'All Users', props: { redirectTo: '/users' } },
        { path: '/users/create', breadcrumb: 'All Users', props: { redirectTo: '/users' } },
        // Inventory
        { path: '/inventory/:inventoryId/details', breadcrumb: 'Inventory', props: { redirectTo: '/inventory' } },
        { path: '/inventory/create', breadcrumb: 'Inventory', props: { redirectTo: '/inventory' } },
        // Physicians
        { path: '/physicians/:physicianId', breadcrumb: 'All Physicians', props: { redirectTo: '/physicians' } },
        { path: '/physicians/create', breadcrumb: 'All Physicians', props: { redirectTo: '/physicians' } },
        // Campaigns
        { path: '/campaigns/:campaignId', breadcrumb: 'All Campaigns', props: { redirectTo: '/campaigns' } },
        { path: '/campaigns/create', breadcrumb: 'All Campaigns', props: { redirectTo: '/campaigns' } },
        { path: '/campaigns/:campaignId/previews/:draftId', breadcrumb: 'Back to Campaign', props: { redirectTo: '/campaigns/' + params.campaignId } },
        // PhysicianTexting
        { path: '/physicians/:physicianId/physicianTexting', breadcrumb: 'All Physicians', props: { redirectTo: '/physicianTexting' } },

        // SuperSettings
        { path: '/superSettings/hospitals/:hospitalId/manageHospital', breadcrumb: 'Back to Hospital Settings', props: { redirectTo: '/superSettings/hospitals/' + params.hospitalId } },
        { path: '/superSettings/hospitals/:hospitalId/passwordResets', breadcrumb: 'Back to Hospital Settings', props: { redirectTo: '/superSettings/hospitals/' + params.hospitalId } },
        { path: '/superSettings/hospitals/:hospitalId/manageCategories', breadcrumb: 'Back to Hospital Settings', props: { redirectTo: '/superSettings/hospitals/' + params.hospitalId } },
        { path: '/superSettings/hospitals/:hospitalId/imports', breadcrumb: 'Back to Hospital Settings', props: { redirectTo: '/superSettings/hospitals/' + params.hospitalId } },
        { path: '/superSettings/hospitals/:hospitalId/imports/:importId', breadcrumb: 'Back to Imports', props: { redirectTo: '/superSettings/hospitals/' + params.hospitalId + '/imports' } },
        { path: '/superSettings/hospitals/:hospitalId/reviewerManagement', breadcrumb: 'Back to Hospital Settings', props: { redirectTo: '/superSettings/hospitals/' + params.hospitalId } },

        // Create Hospital - outlier
        { path: '/superSettings/hospitals/create', 
            breadcrumb: ('Back to ' + (state?.organizationName ? state?.organizationName : 'Organization')), 
            props: { redirectTo: '/superSettings/organizations/' + state?.organizationId } },
    ];
    const breadcrumbs = useBreadcrumbs(routes);

    // The is so that the breadcrumb for the last path in the array (which would be the longest match) is the one that will be displayed
    const longestMatchingBreadcrumb = breadcrumbs[breadcrumbs.length - 1];

    return (
        longestMatchingBreadcrumb.redirectTo ? 
            <Button variant='text' onClick={() => navigate(longestMatchingBreadcrumb.redirectTo)} startIcon={<ArrowBackIos />} style={{ color: `${theme.palette.subtext.main}` }}>
                {longestMatchingBreadcrumb.breadcrumb}
            </Button> : <Box></Box>
    );
}

export default HeaderBreadcrumbs;