import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { CircularProgress, circularProgressClasses, Typography } from '@mui/material';

function ItemSwapProgress(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <BackgroundCircularProgress variant='determinate' value={100} />
            <CustomCicularProgress variant='determinate' {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant='heavy' component='div' color='black' sx={{ fontSize: '12px' }}>
                    {`${props.value}%`}
                </Typography>
            </Box>
        </Box>
    )
}

const CustomCicularProgress = styled(CircularProgress)(({ theme }) => ({
    zIndex: 1,
    [`& .${circularProgressClasses.circleDeterminate}`]: {
        color: `${theme.palette.green.main}`
    }
}));

const BackgroundCircularProgress = styled(CircularProgress)(() => ({
    position: 'absolute',
    [`& .${circularProgressClasses.circleDeterminate}`]: {
        color: '#eaeaea'
    }
}));

export default ItemSwapProgress;