import { useState } from 'react';

export default function usePhysicianDashboardUser() {
    const getUser = () => {
        return JSON.parse(localStorage.getItem('dashboardUser'));
    };

    const [user, setUser] = useState(getUser());

    const saveUser = (newUser) => {
        if (newUser === null) {
            localStorage.removeItem('dashboardUser');
        } else {
            localStorage.setItem('dashboardUser', JSON.stringify(newUser));
        }
        setUser(newUser);
    };

    return {
        setDashboardUser: saveUser,
        dashboardUser: user,
    };
}
