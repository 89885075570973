import { useDispatch } from 'react-redux'
import { NotificationActions } from '../../api/notificationSlice';

export const useNotification = () => {
    const dispatch = useDispatch();

    const displayNotification = (notification) => {
        dispatch(NotificationActions.addNotification(notification));
    }

    const displaySuccessNotification = (notification) => {
        dispatch(NotificationActions.addSuccessNotification(notification));
    }

    const displayWarningNotification = (notification) => {
        dispatch(NotificationActions.addWarningNotification(notification));
    }

    const displayErrorNotification = (notification) => {
        dispatch(NotificationActions.addErrorNotification(notification));
    }

    const clearNotification = () => {
        dispatch(NotificationActions.clearNotification());
    }

    return { displayNotification, displaySuccessNotification, displayWarningNotification, displayErrorNotification, clearNotification };
};
