import { PeopleRounded, TrendingDownRounded, TrendingUpRounded, ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import { Stack, styled, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import React from 'react';
import { NumericFormat } from 'react-number-format';

function ItemDetail(props) {
    const theme = useTheme();

    if (props.type == 'low_utilization') {
        return (
            <ItemDetailChip variant='negative' direction='row' spacing={0.5}>
                <TrendingDownRounded fontSize='small' />
                <Typography variant='heavy'>{props.data !== null ? Math.trunc(props.data * 100) : '--'}% Use</Typography>
            </ItemDetailChip>
        );
    } else if (props.type === 'quantity') {
        if (props.quantityType === 'open') {
            return ( 
                <Box px={0.8} py={0.25} sx={{ borderRadius: '4px', backgroundColor: `${theme.palette.open.main}`, color: 'white', fontSize: '14px' }}>
                    <Typography variant='heavy'>Open: {props.data}</Typography>
                </Box>
            );
        } else if (props.quantityType === 'hold') {
            return (
                <Box px={0.8} py={0.25} sx={{ borderRadius: '4px', backgroundColor: `${theme.palette.hold.main}`, color: 'white', fontSize: '14px' }}>
                    <Typography variant='heavy'>Hold: {props.data}</Typography>
                </Box>
            );
        }
    } else if (props.type === 'cost') {
        return (
            <ItemDetailChip variant='neutral'>
                <Typography variant='heavy'>
                    <NumericFormat
                        value={props.data}
                        thousandSeparator={true}
                        displayType={'text'}
                        prefix={'$'}
                        decimalScale={0}
                        fixedDecimalScale={true}
                    /> /year
                </Typography>
            </ItemDetailChip>
        )
    } else if (props.type === 'high_utilization') {
        return (
            <ItemDetailChip variant='positive' direction='row' spacing={0.5}>
                <TrendingUpRounded fontSize='small' />
                <Typography variant='heavy'>{props.data !== null ? Math.trunc(props.data * 100) : '--'}% Use</Typography>
            </ItemDetailChip>
        );
    } else if (props.type === 'utilization') {
        return (
            <ItemDetailChip variant='neutral' direction='row' spacing={0.5}>
                <Typography variant='heavy'>{props.data !== null ? Math.trunc(props.data * 100) : '--'}% Use</Typography>
            </ItemDetailChip>
        );
    } else if (props.type === 'outlier') {
        return (
            <ItemDetailChip variant='negative' direction='row' spacing={0.5}>
                <PeopleRounded fontSize='small' />
                <Typography variant='heavy'>{props.data !== null ? Math.trunc(props.data * 100) : '--'}%</Typography>
            </ItemDetailChip>
        );
    } else if (props.type === 'out_of_compliance') {
        return (
            <ItemDetailChip variant='negative' direction='row' spacing={0.5}>
                <Typography variant='heavy'>Not Compliant</Typography>
            </ItemDetailChip>
        );
    } else if (props.type === 'in_compliance') {
        return (
            <ItemDetailChip variant='positive' direction='row' spacing={0.5}>
                <Typography variant='heavy'>Contract Compliant</Typography>
            </ItemDetailChip>
        );
    } else if (props.type === 'swap_already_on_card') {
        return (
            <ItemDetailChip variant='positive' direction='row' spacing={0.5}>
                <Typography variant='heavy'>Substitute Present</Typography>
            </ItemDetailChip>
        );
    } else if (props.type === 'high_prevalence') {
        return (
            <ItemDetailChip variant='positive' direction='row' spacing={0.5}>
                <Typography variant='heavy'>{props.data !== null ? Math.trunc(props.data * 100) : '--'}% Prevalence</Typography>
            </ItemDetailChip>
        );
    } else if (props.type === 'low_prevalence') {
        return (
            <ItemDetailChip variant='negative' direction='row' spacing={0.5}>
                <Typography variant='heavy'>{props.data !== null ? Math.trunc(props.data * 100) : '--'}% Prevalence</Typography>
            </ItemDetailChip>
        );
    } else if (props.type === 'medium_prevalence') {
        return (
            <ItemDetailChip variant='neutral' direction='row' spacing={0.5}>
                <Typography variant='heavy'>{props.data !== null ? Math.trunc(props.data * 100) : '--'}% Prevalence</Typography>
            </ItemDetailChip>
        );
    } else if (props.type === 'cheaper') {
        return (
            <ItemDetailChip variant='positive' direction='row' spacing={0.5}>
                <ArrowDownwardRounded style={{ fontSize: '16px' }} />
                <Typography variant='heavy'>{props.data !== null ? props.data : '--'}% Cheaper</Typography>
            </ItemDetailChip>
        );
    } else if (props.type === 'more_expensive') {
        return (
            <ItemDetailChip variant='negative' direction='row' spacing={0.5}>
                <ArrowUpwardRounded style={{ fontSize: '16px' }} />
                <Typography variant='heavy'>{props.data !== null ? props.data : '--'}% More Expensive</Typography>
            </ItemDetailChip>
        );
    } else if (props.type === 'even_cost') {
        return (
            <ItemDetailChip variant='neutral' direction='row' spacing={0.5}>
                <Typography variant='heavy'>Same Cost</Typography>
            </ItemDetailChip>
        );
    } else if (props.type === 'percentage_above_average') {
        return (
            <ItemDetailChip variant='negative' direction='row' spacing={0.5}>
                <Typography variant='heavy'>High Item Spend</Typography>
            </ItemDetailChip>
        );
    } else if (props.type === 'percentage_above_category_average') {
        return (
            <ItemDetailChip variant='negative' direction='row' spacing={0.5}>
                <Typography variant='heavy'>High Category Spend</Typography>
            </ItemDetailChip>
        );
    }
    return <></>;
}

const ItemDetailChip = styled(Stack)(({ variant, theme }) => ({
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '4px',
    fontSize: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    
    ...( variant === 'neutral' && {
        backgroundColor: theme.palette.background.neutral,
        color: theme.palette.subtext.main
    }),
    
    ...( variant === 'positive' && {
        backgroundColor: theme.palette.background.positive,
        color: theme.palette.blue.main
    }),

    ...( variant === 'negative' && {
        backgroundColor: theme.palette.background.negative,
        color: theme.palette.red.main
    })
}));

export default ItemDetail;
