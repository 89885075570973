import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useTheme } from '@mui/system';

function ActivateItemDialog(props) {
    const theme = useTheme();

    function handleClose() {
        props.setOpen(false);
    }

    const handleActivate = async() => {
        await props.activateItem();
        handleClose();
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>Activate this item?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Activated items can be added to preference cards.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='text' style={{ color: `${theme.palette.subtext.main}` }} onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant='text' color='primary' onClick={handleActivate}>
                    Activate Item
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ActivateItemDialog;