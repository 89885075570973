import { Button, Checkbox, FormControlLabel, List, ListItem, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

function Rationale(props) {
    const handleToggle = (value) => () => {
        props.checked[value] = !props.checked[value];
        const newChecked = {...props.checked};
        props.setChecked(newChecked);
    }

    function canAdvance() {
        if (props.checked['inferior-clinical'] || props.checked['inferior-quality'] || props.checked['challenging']) {
            return true;
        } else if (props.customAnswer !== '') {
            return true;
        } else {
            return false;
        }
    }

    function advanceStep() {
        props.setStep(1);
        window.scrollTo(0, 0);
    }

    function setCustomAnswer(e) {
        props.setCustomAnswer(e.target.value);
        props.setChecked({...props.checked, 'custom-answer': e.target.value});
    }

    return (
        <Stack spacing={2}>
            <Stack spacing={2}>
                <Typography variant='heavy' sx={{ fontSize: '20px' }}>Why did you decline this swap?</Typography>
                <Typography variant='normal' sx={{ fontSize: '14px', color: '#0000008A', letterSpacing: '0.25px' }}>
                    This information helps optimize surgical supply chain for you and your colleagues. Thank you!
                </Typography>
                <List>
                    <ListItem>
                        <FormControlLabel 
                            control={<Checkbox checked={props.checked['inferior-clinical']} onChange={handleToggle('inferior-clinical')} />} 
                            label={<Typography variant='normal' fontSize='14px'>Item produces inferior clinical results</Typography>}
                            name='inferior-clinical'
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel 
                            control={<Checkbox checked={props.checked['inferior-quality']} onChange={handleToggle('inferior-quality')} />} 
                            label={<Typography variant='normal' fontSize='14px'>Item is inferior in quality to what I currently use</Typography>}
                            name='inferior-quality' 
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel 
                            control={<Checkbox checked={props.checked['challenging']} onChange={handleToggle('challenging')} />} 
                            label={<Typography variant='normal' fontSize='14px'>Item is more technically challenging to use</Typography>}
                            name='challenging'
                        />
                    </ListItem>
                    <ListItem>
                        <TextField 
                            label='Enter custom answer' 
                            multiline 
                            fullWidth
                            rows={3}
                            variant='outlined'
                            value={props.customAnswer}
                            onChange={(e) => setCustomAnswer(e)}
                        />
                    </ListItem>
                </List>
            </Stack>
            <Stack spacing={2} alignItems='center'>
                <Button variant='blueContained' disabled={!canAdvance()} onClick={advanceStep}>Submit Feedback</Button>
                <Button variant='text' onClick={props.closeView}>Back to Swap</Button>
            </Stack>
        </Stack>
    );
}

export default Rationale;
