import { InfoOutlined } from '@mui/icons-material';
import { Chip, MenuItem, Paper, Select, Stack, Tooltip, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useGetCardOwnerSpecialtiesQuery } from '../../../api/userSlice';

function Specialties(props) {
    const { data: specialties = [] } = useGetCardOwnerSpecialtiesQuery(props.campaign.hospital.id);

    const [includedSpecialties, setIncludedSpecialties] = useState(props.campaign.settings.specialties);

    const notIncludedSpecialties = useMemo(() => {
        var specialtiesToReturn = [];
        specialties.forEach(specialty => {
            if(!includedSpecialties.find(includedSpecialty => specialty.name === includedSpecialty.name)) {
                specialtiesToReturn.push(specialty);
            }
        });
        return specialtiesToReturn.sort((a, b) => a.name.localeCompare(b.name));
    }, [specialties, includedSpecialties]);

    const handleRemoveSpecialty = (specialty) => {
        setIncludedSpecialties(includedSpecialties.filter(s => s !== specialty));
        props.setSettings({ ...props.settings, specialties: includedSpecialties.filter(s => s !== specialty) });
    };

    const handleAddSpecialty = (specialty) => {
        setIncludedSpecialties([...includedSpecialties, specialty]);
        props.setSettings({ ...props.settings, specialties: [...includedSpecialties, specialty]});
    };

    return (
        <Paper elevation={2} sx={{ width: '23%' }}>
            <Stack p={3} spacing={2} height='100%' justifyContent='space-between'>
                <Stack spacing={2}>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <Typography variant='light' sx={{ fontSize: '18px' }}>Specialties</Typography>
                        <Tooltip title='Specialties are used to determine which groups of physicians are included in the campaign.'>
                            <InfoOutlined style={{ opacity: 0.5 }} />
                        </Tooltip>
                    </Stack>
                    <Stack direction='row' alignItems='center' spacing={1} rowGap={0.5} flexWrap='wrap'>
                        <Typography variant='normal' sx={{ fontSize: '16px' }}>Included: </Typography>
                        { includedSpecialties.length === 0 && <Typography variant='normal' sx={{ fontSize: '16px' }}>None</Typography> }
                        { includedSpecialties.map((specialty, index) => (
                            <Chip disabled={props.disabled} key={index} label={specialty.name} onDelete={() => handleRemoveSpecialty(specialty)} />
                        ))}
                    </Stack>
                </Stack>
                <Select
                    value={''}
                    onChange={event => handleAddSpecialty(event.target.value)}
                    displayEmpty
                    disabled={props.disabled}
                    sx={{ width: '100%' }}
                >
                    <MenuItem value={''}>Add a specialty</MenuItem>
                    { notIncludedSpecialties.map((specialty, index) => (
                        <MenuItem key={index} value={specialty}>{specialty.name}</MenuItem>
                        
                    ))}
                </Select>
            </Stack>
        </Paper>
    )
}

export default Specialties;
