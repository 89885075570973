import { Box, Stack, styled } from '@mui/system';
import React from 'react';
import { theme } from '../../../../theme';
import { Tooltip, tooltipClasses, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.white,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 12,
        padding: '16px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
}));

function ProgressTooltip(props) {
    return (
        <LightTooltip title={<TooltipContent acceptedCount={props.acceptedCount} acceptedValue={props.acceptedValue} deniedCount={props.deniedCount} deniedValue={props.deniedValue} unreviewedCount={props.unreviewedCount} unreviewedValue={props.unreviewedValue} />}>
            {props.children}
        </LightTooltip>
    );
}

function TooltipContent(props) {
    return (
        <Stack spacing={1}>
            <Stack direction='row' spacing={1} alignItems='center'>
                <Box sx={{ width: '16px', height: '16px', backgroundColor: theme.palette.blue.main, borderRadius: '4px' }} />
                <Typography variant='normal' fontSize='12px'><strong>Approved: </strong> {props.acceptedCount} Optimizations, <NumericFormat value={props.acceptedValue} displayType='text' prefix='$' thousandSeparator={','} fixedDecimalScale decimalScale={0} /></Typography>
            </Stack>
            <Stack direction='row' spacing={1} alignItems='center'>
                <Box sx={{ width: '16px', height: '16px', backgroundColor: theme.palette.background.deniedProgress, borderRadius: '4px' }} />
                <Typography variant='normal' fontSize='12px'><strong>Denied: </strong> {props.deniedCount} Optimizations, <NumericFormat value={props.deniedValue} displayType='text' prefix='$' thousandSeparator={','} fixedDecimalScale decimalScale={0} /></Typography>
            </Stack>
            <Stack direction='row' spacing={1} alignItems='center'>
                <Box sx={{ width: '16px', height: '16px', backgroundColor: theme.palette.background.unreviewedProgress, borderRadius: '4px' }} />
                <Typography variant='normal' fontSize='12px'><strong>Unreviewed: </strong> {props.unreviewedCount} Optimizations, <NumericFormat value={props.unreviewedValue} displayType='text' prefix='$' thousandSeparator={','} fixedDecimalScale decimalScale={0} /></Typography>
            </Stack>
        </Stack>
    );
}

export default ProgressTooltip;
