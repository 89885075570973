import { Box, Stack } from '@mui/system';
import React from 'react';
import { Typography } from '@mui/material';
import { theme } from '../../theme';


function MultipleProgressBar(props) {
    const segmentsToDisplay = props.segments.filter(segment => segment.value > 0);

    return (
        <Stack direction='row' spacing={1} alignItems='center'>
            <Stack direction='row' spacing={0.25} alignItems='center' sx={{ backgroundColor: 'white', width: '65%', height: '10px', borderRadius: '8px' }}>
                { segmentsToDisplay.map((segment, index) => (
                    <Box key={index} sx={{ 
                        backgroundColor: segment.color, 
                        width: `${segment.value}%`, 
                        height: '100%',
                        borderTopLeftRadius: (index === 0 || segmentsToDisplay.length === 1) ? '8px' : '0px',
                        borderBottomLeftRadius: (index === 0 || segmentsToDisplay.length === 1) ? '8px' : '0px',
                        borderTopRightRadius: index === segmentsToDisplay.length - 1 ? '8px' : '0px',
                        borderBottomRightRadius: index === segmentsToDisplay.length - 1 ? '8px' : '0px',
                    }} />
                ))}
                { segmentsToDisplay.length === 0 && (
                    <Box sx={{ 
                        backgroundColor: theme.palette.background.unreviewedProgress, 
                        width: '100%', 
                        height: '100%',
                        borderRadius: '8px'
                    }} />
                )}
            </Stack>
            { props.label !== undefined && (
                <Stack width='auto'>
                    <Typography variant='normal' fontSize='12px'>{props.label}</Typography>
                </Stack>
            )}
        </Stack>
    );
}

export default MultipleProgressBar;

