import React, { useEffect } from 'react';
import {useSearchParams, useNavigate} from 'react-router-dom';
import useAuth from '../Hooks/useAuth';
import { Stack, Typography, CircularProgress } from '@mui/material';
import useDashboardToken from '../Hooks/useDashboardToken';
import usePhysicianDashboardUser from '../Hooks/usePhysicianDashboardUser';
import { physicianDashboardSlice } from '../../api/physicianDashboardSlice';
import useAnalytics from '../Hooks/useAnalytics';

function PhysicianDashboardAuthRedirect() {
    const [searchParams, ] = useSearchParams();
    const analytics = useAnalytics();
    const { physicianDashboardToken, setPhysicianDashboardToken } = useDashboardToken();
    const { dashboardUser, setDashboardUser } = usePhysicianDashboardUser();
    const { setPhysicianDashboardAuthed } = useAuth();
    const [getCurrentUser] = physicianDashboardSlice.endpoints.getCurrentDashboardUser.useLazyQuery();
    const navigate = useNavigate();

    useEffect(() => {
        if (searchParams.get('token')) {
            setPhysicianDashboardToken(searchParams.get('token'));
            getCurrentUser().unwrap().then((user) => {
                setDashboardUser(user);
                setPhysicianDashboardAuthed(true);
                analytics.identify('owner-' + user.id, {
                    'name': user.name,
                    'specialty': user.specialty,
                });
                analytics.group(user.organization.id);
                navigate('/physician/dashboard/landing');
            }).catch(() => {
                setPhysicianDashboardToken(null);
                setDashboardUser(null);
                navigate('/login');
            });
        } else if (!physicianDashboardToken || !dashboardUser) {
            navigate('/login');
        } else {
            navigate('/physician/dashboard/landing');
        }
    }, [searchParams]);

    return(
        <Stack width="100%" height="100%">
            <Stack width="100%" height="100%" justifyContent="center" alignItems="center" spacing={2}>
                <Typography variant="h4">Validating your token. Hang tight...</Typography>
                <CircularProgress />
            </Stack>
        </Stack>
    );
}

export default PhysicianDashboardAuthRedirect;
