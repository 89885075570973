import React, { useState } from 'react';
import {Box} from '@mui/system';
import {Button, Link, Stack, TextField, Grid} from '@mui/material';
import whiteLogo from '../../../assets/white_logo.svg';
import smallLogo from '../../../assets/single_letter_logo.svg'
import { useResetPasswordMutation } from './passwordSlice';

function PasswordRecovery() {
    const [email, setEmail] = useState();
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isLinkSent, setIsLinkSent] = useState(false);
    const [resetPassword] = useResetPasswordMutation();

    const handleRecoveryLink = async () => {
        setIsLinkSent(true);
        await resetPassword({
            email: email
        });
    }

    function handleEmailChange(value) {
        setEmail(value);
        if (isValidEmail(value)) {
            setIsInvalidEmail(false);
            setIsButtonDisabled(false);
        } else {
            setIsInvalidEmail(true);
            setIsButtonDisabled(true);
        }
    }

    function isValidEmail(email) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    return (
        <Grid container sx={{ display: 'flex', height: '100%', width: '100%', backgroundColor: '#F7F9FC' }}>
            <Grid item xs={0} sm={2} md={3} />
            <Grid item xs={12} sm={8} md={6} height='100%' display='grid'>
                <Stack direction='row' sx={{ minHeight: '60%', alignSelf: 'center', boxShadow: 4, borderRadius: '13px' }}>
                    <Stack p={4} spacing={4} sx={{ minWidth: '60%', borderTopLeftRadius: '13px', borderBottomLeftRadius: '13px' }}>
                        <Box>
                            <img src={smallLogo} alt="DOCSI Mini Logo" width='40' height='30' />
                        </Box>
                        <Stack sx={{ justifySelf: 'center', alignSelf: 'center', width: '343'}}>
                            <Box sx={{font: 'normal normal normal 24px/22px Roboto' }}>Recover Password</Box>
                            { isLinkSent ? 
                                <Box sx={{font: 'normal normal normal 16px/24px Roboto', color: '#00000099'}} my={4}>If an account exists, an email will be sent with further instructions.</Box>
                                :
                                <>
                                    <Box sx={{font: 'normal normal normal 16px/24px Roboto', color: '#00000099'}} mb={4}>{'Don\'t worry, happens to the best of us.'}</Box>
                                    <Box mb={4}>
                                        <TextField 
                                            fullWidth 
                                            error={isInvalidEmail} 
                                            helperText={isInvalidEmail ? 'Please enter a valid email' : ''}
                                            variant='outlined' 
                                            label='Email' 
                                            value={email} 
                                            onChange={(e) => handleEmailChange(e.target.value)} 
                                        />
                                    </Box>
                                    <Box mb={2}>
                                        <Button variant='contained' disabled={isButtonDisabled} onClick={handleRecoveryLink} sx = {{backgroundColor : '#2C2CFF', 
                                            '&:hover': {bgcolor:'#2C2CFF'}, color: 'white' }}>Send me a recovery link</Button>
                                    </Box>
                                </>
                            }
                            <Box sx={{font: 'normal normal normal 16px/24px Roboto'}}>Need Help? <Link color = '#2C2CFF' target="_blank"  href = "https://docs.google.com/forms/d/e/1FAIpQLSdZk2yXi-G7yU_dhUurqcd_id2ffgXYXh46m0tNwW2EJJmD-Q/viewform">Reach Out</Link></Box>
                        </Stack>
                    </Stack>
                    <Box sx={{ display: { xs: 'none', lg: 'flex' }, width: '100%', backgroundColor: '#2C2CFF', alignItems: 'center', justifyContent: 'center', borderTopRightRadius: '13px', borderBottomRightRadius: '13px' }}>
                        <img src={whiteLogo} alt="DOCSI Logo" width='75%' style={{ objectFit: 'contain' }} />
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={0} sm={2} md={3} />
        </Grid>
    );
}

export default PasswordRecovery;