import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import StatisticTile from '../StatisticTiles/StatisticTile';

function PreviewStats(props) {
    const calculatedStats = useMemo(() => {
        var maxSavings = 0;
        var fewerItemsHandled = 0;

        props.drafts.forEach((draft) => {
            if (draft.is_excluded) { return; }
            Object.keys(draft.recommendations).forEach((category) => {
                draft.recommendations[category].forEach((recommendation) => {
                    if (recommendation.is_excluded) { return; }
                    maxSavings += recommendation.recommendation_value;
                    if (recommendation.type === 'removal') {
                        if (draft.items[category] === undefined) { return; }
                        const item = draft.items[category].find((item) => item.id === recommendation.item.id);
                        if (item) {
                            fewerItemsHandled += (item.open_quantity + item.hold_quantity) * (draft.card.yearly_stats?.usage_count ?? 1);
                        }
                    }
                });
            });
        });

        return {
            maxSavings: maxSavings,
            fewerItemsHandled: fewerItemsHandled
        }
    }, [props.drafts]);

    return (
        <Stack direction='row' spacing={2} alignItems='center'>
            <StatisticTile 
                title='Predicted Maximum Savings' 
                value={calculatedStats.maxSavings / 100} 
                formatAsDollar 
                includeTrendUp
                isLoading={props.isLoadingDrafts}
            />
            <StatisticTile
                title='Fewer Items Handled per Year'
                value={calculatedStats.fewerItemsHandled}
                includeTrendDown
                isLoading={props.isLoadingDrafts}
            />
            <StatisticTile
                title='Number of Physicians'
                value={Object.keys(props.physicians).length}
                isLoading={props.isLoadingDrafts}
            />
        </Stack>
    );
}

export default PreviewStats;
