import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { CustomLinearProgress } from '../../../CustomComponents/CustomLinearProgress';

function UserEfficiencyBar(props) {
    if (props.peerAverage) {
        return (
            <Stack>
                <Stack direction='row'>
                    <Box width={(props.peerAverage * 100 - 8) + '%'} />
                    <Typography variant='normal' sx={{ fontSize: '12px', color: '#000000DE', letterSpacing: '0.18px' }}>
                        {Math.round(props.peerAverage * 100)}% Peer Avg
                    </Typography>
                </Stack>
                <CustomLinearProgress
                    variant='determinate' 
                    value={props.efficiency * 100}
                    average={props.peerAverage * 100}
                />
            </Stack>
        )
    } else {
        return (<></>);
    }
}

export default UserEfficiencyBar;
