import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

function PasswordResetConfirmation(props) {
    function handleClose() {
        props.setOpen(false);
    }

    function handleConfirm() {
        props.confirmReset();
        props.setOpen(false);
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>
                Confirm User Password Reset
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to force this user to reset their password?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                <Button onClick={handleConfirm}>
                    Yes, reset
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PasswordResetConfirmation;
