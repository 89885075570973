import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, TextField } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { useMemo } from 'react';
import { useState } from 'react';

function AddSectionDialog(props) {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const theme = useTheme();

    function handleClose() {
        props.setOpen(false);
        setTitle('');
        setBody('');
    }

    const handleSave = async() => {
        await props.saveNewSection(title, body);
        handleClose();
    }

    const canSave = useMemo(() => {
        return title !== '' && body !== '';
    }, [title, body]);

    return (
        <Dialog open={props.open} onClose={handleClose} maxWidth={'sm'} fullWidth sx={{
            '& .MuiDialog-container': {
                alignItems: 'flex-start',
                paddingTop : '100px'
            }
        }}>
            <DialogTitle>Add New Section</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Section Title
                </DialogContentText>
                <Select
                    id='title'
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    fullWidth
                >
                    { props.titleOptions.map((option) => (
                        <MenuItem key={option.id} value={option}>{option.name}</MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogContent>
                <DialogContentText>
                    Section Body
                </DialogContentText>
                <TextField
                    id='body'
                    placeholder='Add section body'
                    multiline
                    rows={5}
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} style={{ color: `${theme.palette.subtext.main}` }}>Cancel</Button>
                <Button 
                    disabled={!canSave} 
                    onClick={handleSave} 
                    style={{ color: canSave ? `${theme.palette.blue.heavy}` : `${theme.palette.background.disabled}` }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddSectionDialog;