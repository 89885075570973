import { Stack, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Rationale from './Rationale';
import Halfway from './Halfway';
import DeclineItemCards from './DeclineItemCards';


function ComplianceDeclineFlow(props) {
    const [step, setStep] = useState(0);
    const [checked, setChecked] = useState({
        'inferior-clinical': props.currentSwap?.recommendations[0].rejection_context['inferior-clincal'] ?? false,
        'inferior-quality': props.currentSwap?.recommendations[0].rejection_context['inferior-quality'] ?? false,
        'challenging': props.currentSwap?.recommendations[0].rejection_context['challenging'] ?? false,
        'custom-answer': props.currentSwap?.recommendations[0].rejection_context['custom-answer'] ?? '',
        'trial': props.currentSwap?.recommendations[0].rejection_context['trial'] ?? false,
        'wet-lab': props.currentSwap?.recommendations[0].rejection_context['wet-lab'] ?? false,
        'peer-discussion': props.currentSwap?.recommendations[0].rejection_context['peer-discussion'] ?? false,
        'vendor-discussion': props.currentSwap?.recommendations[0].rejection_context['vendor-discussion'] ?? false,
    });
    const [customAnswer, setCustomAnswer] = useState(checked['custom-answer']);

    function closeView() {
        props.setIsDeclining(false);
    }

    useEffect(() => {
        setChecked({
            'not-equivalent': props.currentSwap?.recommendations[0].rejection_context['inferior-clinical'] ?? false,
            'more-info': props.currentSwap?.recommendations[0].rejection_context['inferior-quality'] ?? false,
            'other': props.currentSwap?.recommendations[0].rejection_context['challenging'] ?? false,
            'custom-answer': props.currentSwap?.recommendations[0].rejection_context['custom-answer'] ?? '',
            'trial': props.currentSwap?.recommendations[0].rejection_context['trial'] ?? false,
            'wet-lab': props.currentSwap?.recommendations[0].rejection_context['wet-lab'] ?? false,
            'peer-discussion': props.currentSwap?.recommendations[0].rejection_context['peer-discussion'] ?? false,
            'vendor-discussion': props.currentSwap?.recommendations[0].rejection_context['vendor-discussion'] ?? false,
        });
        setCustomAnswer(checked['custom-answer']);
    }, [props.currentSwap]);

    return (
        <Stack px={2} py={2} spacing={2} sx={{ backgroundColor: 'white', height: '100%' }}>
            <Typography variant='heavy' sx={{ fontSize: '20px' }}>Swap Declined</Typography>
            <DeclineItemCards 
                item={props.currentSwap?.recommendations[0].item} 
                recommendedItem={props.currentSwap?.recommendations[0].recommended_item}
            />
            { step === 0 && 
                <Rationale 
                    checked={checked} 
                    setChecked={setChecked}  
                    customAnswer={customAnswer}
                    setCustomAnswer={setCustomAnswer}
                    setStep={setStep}
                    closeView={closeView}
                />
            }
            { step === 1 &&
                <Halfway 
                    checked={checked} 
                    setChecked={setChecked}  
                    customAnswer={customAnswer}
                    setCustomAnswer={setCustomAnswer}
                    setStep={setStep}
                    closeView={closeView}
                    declineSwap={props.declineSwap}
                />
            }
        </Stack>
    );
}


export default ComplianceDeclineFlow;
