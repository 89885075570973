import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Typography } from '@mui/material';

function CardEfficiencyDialog(props) {

    function handleClose() {
        props.setIsShowingInfo(false);
    }

    return (
        <Dialog onClose={handleClose} open={props.isShowingInfo} >
            <DialogTitle>
                What is Cost Efficiency?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <strong>Cost Efficiency</strong> of a card is the percentage of the supply cost
                    (including open and hold items) that is used efficiently. Inefficient usage of items will count against card efficiency. There a few different ways to adjust your preference cards to increase efficiency:
                </DialogContentText>
                <ul>
                    <li>
                        <Typography variant='normal' fontSize='14px'>
                            {'Add items that aren\'t on your cards but are frequently used'}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='normal' fontSize='14px'>
                            Remove unnecessary supplies that are rarely used
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='normal' fontSize='14px'>
                            Adjust item open/hold quantities to better align with frequency of use
                        </Typography>
                    </li>
                </ul>
                <DialogContentText>
                    <strong>Overall Cost Efficiency</strong> is the average Cost Efficiency of your cards,
                    weighted by case volume.
                </DialogContentText>
                <DialogContentText>
                    {'Don\'t worry if your stats are a little behind. Unless you are in the top three of your leaderboard, your peers won\'t see your ranking. You got this!'}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

export default CardEfficiencyDialog;
