import { Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import AdditionalSettings from './AdditionalSettings';
import ComplianceSettings from './ComplianceSettings';
import ItemCategories from './ItemCategories';
import MinimumCaseCount from './MinimumCaseCount';
import OptimizationTypes from './OptimizationTypes';
import SaveSettingsTemplateDialog from './SaveSettingsTemplateDialog';
import Specialties from './Specialties';
import UsageThreshold from './UsageThreshold';

function CampaignSettings(props) {
    const [isShowingTemplateDialog, setIsShowingTemplateDialog] = useState(false);
    const isCampaignComplete = props.campaign.status === 'complete';

    return (
        <Stack spacing={3}>
            <Stack direction='row' px={3} alignItems='center' justifyContent='space-between'>
                <Typography variant='bold' sx={{ fontSize: '20px' }}>Campaign Settings</Typography>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <Button variant='contained' onClick={() => setIsShowingTemplateDialog(true)}>Save Settings to Template</Button>
                </Stack>
            </Stack>
            <Stack direction='row' justifyContent='space-between' flexWrap='wrap' rowGap={2}>
                <OptimizationTypes disabled={isCampaignComplete} campaign={props.campaign} settings={props.settings} setSettings={props.setSettings} />
                <Specialties disabled={isCampaignComplete} campaign={props.campaign} settings={props.settings} setSettings={props.setSettings} />
                <ItemCategories disabled={isCampaignComplete} campaign={props.campaign} settings={props.settings} setSettings={props.setSettings} />
                <Stack spacing={2} width='23%'>
                    <MinimumCaseCount disabled={isCampaignComplete} campaign={props.campaign} settings={props.settings} setSettings={props.setSettings} />
                    <UsageThreshold disabled={isCampaignComplete} campaign={props.campaign} settings={props.settings} setSettings={props.setSettings} />
                </Stack>
            </Stack>
            <Stack direction='row' spacing={2}>
                { props.settings.compliance && 
                    <ComplianceSettings disabled={isCampaignComplete} campaign={props.campaign} settings={props.settings} setSettings={props.setSettings} />
                }
                <AdditionalSettings disabled={isCampaignComplete} campaign={props.campaign} settings={props.settings} setSettings={props.setSettings} />
            </Stack>
            <SaveSettingsTemplateDialog open={isShowingTemplateDialog} setOpen={setIsShowingTemplateDialog} campaign={props.campaign} settings={props.settings} />
        </Stack>
    )
}

export default CampaignSettings;
