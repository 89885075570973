import { Button, Dialog, DialogContent, DialogTitle, MenuItem, Select, Stack, TextField } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useCreateCategoryMutation } from '../../../api/categorySlice';

function AddCategoryDialog(props) {
    const [name, setName] = useState('');
    const [order, setOrder] = useState('');
    const [type, setType] = useState('');

    const [createCard] = useCreateCategoryMutation();

    function handleClose() {
        props.setOpen(false);
        setName('');
        setOrder('');
        setType('');
    }

    const handleSubmit = async () => {
        await createCard({
            hospitalId: props.hospitalId,
            requestData: {
                name: name,
                order: order,
                type: type
            }
        });
        handleClose();
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>  
            <DialogTitle>Create New Category</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <TextField
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder='Enter Category Name'
                    />
                    <TextField
                        value={order}
                        onChange={(e) => setOrder(e.target.value)}
                        placeholder='Enter Category Order'
                    />
                    <Select
                        fullWidth
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                    >
                        <MenuItem value='App\Models\Comment'>Comment Category</MenuItem>
                        <MenuItem value='App\Models\AuxiliaryItem'>Auxiliary Item Category</MenuItem>
                    </Select>                    
                    <Stack direction='row' spacing={2} justifyContent='end'>
                        <Button variant='outlined' onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant='contained' onClick={handleSubmit}>
                            Create
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default AddCategoryDialog;