import { ContentCopy } from '@mui/icons-material';
import { Button, ClickAwayListener, IconButton, InputAdornment, ListItemText, Stack, TextField, Tooltip } from '@mui/material';
import { useTheme } from '@mui/system';
import React, { useState } from 'react';
import { useGenerateLinkMutation } from '../../../api/apiSlice';

function PhysicianLink(props) {
    const theme = useTheme();
    const [linkCopied, setLinkCopied] = useState(false);
    const [generatedLink, setGeneratedLink] = useState();

    function handleCopyLink() {
        navigator.clipboard.writeText(generatedLink);
        setLinkCopied(true);
    }

    const [generateLink] = useGenerateLinkMutation();

    async function handleGenerateLink() {
        await generateLink({
            cardOwner: [props.physician.id],
            campaign: props.campaignId

        }).unwrap().then((payload) => {
            setGeneratedLink(payload);
        });
    }

    return (
        <Stack p={1} spacing={3} direction='row' alignItems='center' sx={{ maxWidth: '60%', backgroundColor: `${theme.palette.background.main}`, borderRadius: '8px' }}>
            <ListItemText primary={`Dr. ${props.physician.last_name}, ${props.physician.first_name}`} style={{ width: '25%' }} />
            <TextField 
                variant='standard'
                value={generatedLink}
                size='small'
                InputProps={{
                    readOnly: true,
                    startAdornment: (
                        <InputAdornment position='start'>
                            <ClickAwayListener onClickAway={() => setLinkCopied(false)}>
                                <Tooltip open={linkCopied} title='Copied!' placement='top'>
                                    <IconButton onClick={handleCopyLink}>
                                        <ContentCopy />
                                    </IconButton>
                                </Tooltip>
                            </ClickAwayListener>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position='end'>
                            <Button variant='text' onClick={handleGenerateLink}>
                                Generate Link
                            </Button>
                        </InputAdornment>
                    )
                }}
            />
        </Stack>
    )
}

export default PhysicianLink;
