import {configureStore} from '@reduxjs/toolkit';
import monitorReducersEnhancer from './enhancers/monitorReducer';
import {apiSlice} from './api/apiSlice';
import { physicianDashboardSlice } from './api/physicianDashboardSlice';
import { ingestionSlice } from './api/ingestionSlice';
import { staffReviewSlice } from './api/staffReviewSlice';
import { NotificationReducer } from './api/notificationSlice';

export default configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        [physicianDashboardSlice.reducerPath]: physicianDashboardSlice.reducer,
        [ingestionSlice.reducerPath]: ingestionSlice.reducer,
        [staffReviewSlice.reducerPath]: staffReviewSlice.reducer,
        notification: NotificationReducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware).concat(physicianDashboardSlice.middleware).concat(ingestionSlice.middleware).concat(staffReviewSlice.middleware),
    enhancers: [monitorReducersEnhancer]
})
