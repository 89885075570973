import { InfoOutlined } from '@mui/icons-material';
import { Checkbox, Stack, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';

function UserPermissionToggle(props) {
    const [checked, setChecked] = useState(props.selectedIds.includes(props.permission.id));
    useEffect(() => setChecked(props.selectedIds.includes(props.permission.id)), )
    
    const handleChange = (event) => {
        if (event.target.checked) {
            setChecked(true);
            props.handleCheck(props.permission);
        } else {
            setChecked(false);
            props.handleUncheck(props.permission);
        }
    }

    return (
        <Stack direction='row' justifyContent='space-between'>
            <Stack direction='row' spacing={1} alignItems='center'>
                <Box sx={{ font: 'normal normal normal 16px/24px Roboto' }}>
                    {props.permission.name}
                </Box>
                <Tooltip title={props.permission.description}>
                    <InfoOutlined />
                </Tooltip>
            </Stack>
            <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </Stack>
    )
}

export default UserPermissionToggle;