import { ErrorOutlineRounded, ExpandLess, ExpandMore, FileDownloadOutlined, FlagRounded } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { IconButton, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import React, { useMemo, useState } from 'react';
import FlaggedReviewInfo from './FlaggedReviewInfo';

function DraftReviewTable(props) {
    const [isGeneratingFile, setIsGeneratingFile] = useState(false);
    const drafts = props.drafts ?? {};
    const sortedPhysicians = useMemo(() => {
        return Object.values(props.physicians).sort((a, b) => {
            return a.last_name.localeCompare(b.last_name);
        });
    }, [props.physicians]);

    function handleGenerateCSV() {
        setIsGeneratingFile(true);
        return new Promise((res) => {
            axios({
                url: `/api/campaigns/${props.campaignId}/staffReviewLinkFile`,
                method: 'POST',
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
                window.open(url, '_blank');
                setIsGeneratingFile(false);
                res();
            }).catch(() => {
                setIsGeneratingFile(false);
            });
        });
    }

    return (
        <Stack spacing={2} display='grid'>
            <Stack direction='row' spacing={2} alignItems='center' justifySelf='end'>
                <LoadingButton variant='contained' onClick={handleGenerateCSV} loading={isGeneratingFile} startIcon={<FileDownloadOutlined />}>Generate Reviewer Links</LoadingButton>
            </Stack>
            <Paper elevation={2}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width='3%' />
                            <TableCell width='10%'>Physician</TableCell>
                            <TableCell width='27%'>Cards</TableCell>
                            <TableCell width='20%'>Assigned Reviewer</TableCell>
                            <TableCell width='15%'>Status</TableCell>
                            <TableCell width='10%'>Flagged?</TableCell>
                            <TableCell width='15%' />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { Object.values(sortedPhysicians).map((physician) => (
                            <Row 
                                key={physician.id} 
                                physician={physician} 
                                drafts={drafts[physician.id]} 
                            />
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Stack>
    );
}

function Row(props) {
    const [open, setOpen] = useState(false);
    const [isShowingFlag, setIsShowingFlag] = useState(false);
    const physician = props.physician;
    const drafts = props.drafts;

    const hasFlaggedDraft = drafts?.some((draft) => draft.review_flags.length > 0);
    const hasDraftWithoutReviewer = drafts?.some((draft) => draft.card.reviewers.length === 0);

    return (
        <>
            <TableRow key={physician.id} onClick={() => setOpen(!open)} hover sx={{ cursor: 'pointer' }}>
                <TableCell>{ open ? <ExpandLess /> : <ExpandMore /> }</TableCell>
                <TableCell>{physician.name}</TableCell>
                <TableCell>{drafts?.length}</TableCell>
                <TableCell>
                    { hasDraftWithoutReviewer && 
                        <ErrorOutlineRounded color='error' />
                    }
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                    { hasFlaggedDraft &&
                        <FlagRounded color='error' />
                    }
                </TableCell>
            </TableRow>
            {open && drafts?.map((draft) => (
                <TableRow key={draft.id}>
                    <TableCell />
                    <TableCell />
                    <TableCell>{draft.card.name}</TableCell>
                    <TableCell>
                        { draft.card.reviewers.length === 0 && 'None' }
                        { draft.card.reviewers.length > 0 && draft.card.reviewers[0].first_name + ' ' + draft.card.reviewers[0].last_name } { draft.card.reviewers.length > 1 && ' + ' + (draft.card.reviewers.length - 1) + ' more' }
                    </TableCell>
                    <TableCell>{draft.status.charAt(0).toUpperCase() + draft.status.slice(1)}</TableCell>
                    <TableCell>
                        { draft.review_flags.length > 0 ? 
                            <>
                                <IconButton onClick={() => setIsShowingFlag(true)}>
                                    <FlagRounded /> 
                                </IconButton>
                                <FlaggedReviewInfo flag={draft.review_flags[0]} open={isShowingFlag} handleClose={() => setIsShowingFlag(false)} />
                            </>
                            :
                            <></>
                        }
                    </TableCell>
                    <TableCell />
                </TableRow>
            ))}
        </>
    );
}

export default DraftReviewTable;
