import { InfoOutlined } from '@mui/icons-material';
import { Button, ButtonGroup, Paper, Stack, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

function MinimumCaseCount(props) {
    const [caseCount, setCaseCount] = useState(props.campaign.settings.minimum_required_case_count);

    const handleCaseCountChange = (value) => {
        setCaseCount(value);
        props.setSettings({ ...props.settings, minimum_required_case_count: value });
    };

    return (
        <Paper elevation={2}>
            <Stack p={3} spacing={2}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='light' sx={{ fontSize: '18px' }}>Minimum Case Count</Typography>
                    <Tooltip title='Any cards with a case count lower than the specified value in the past year will be excluded from the campaign.'>
                        <InfoOutlined style={{ opacity: 0.5 }} />
                    </Tooltip>
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>Minimum Case Count: </Typography>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>{caseCount}</Typography>
                    <ButtonGroup disabled={props.disabled} variant='outlined'>
                        <Button disabled={caseCount === 0} onClick={() => handleCaseCountChange(caseCount - 1)}>-</Button>
                        <Button onClick={() => handleCaseCountChange(caseCount + 1)}>+</Button>
                    </ButtonGroup>
                </Stack>
            </Stack>
        </Paper>
    )
}

export default MinimumCaseCount;
