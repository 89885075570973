import React, { useState, useContext } from 'react';
import PickListItem from './PickListItem';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import AddItemDialog from '../Card/AddItem/AddItemDialog';
import { UserContext } from '../Hooks/useUser';
import { AnimatePresence, motion } from 'framer-motion';

function PickList(props) {
    const { hasCardManagement } = useContext(UserContext);
    const [isAddItemDialogOpen, setIsAddItemDialogOpen] = useState(false);
    let itemCategories = Object.keys(props.pickList).sort();

    return (
        <Stack>
            { hasCardManagement() && 
                <Stack direction='row' sx={{ justifyContent: 'end' }}>
                    <Button 
                        variant='contained' 
                        startIcon={<Add />} 
                        onClick={() => setIsAddItemDialogOpen(true)}
                        size='small'
                    >
                        Add Item
                    </Button>
                </Stack>
            }
            <Stack spacing={2}>
                {itemCategories.length === 0 && 
                    <Stack spacing={1} alignItems='center' textAlign='center'>
                        <Typography variant='bold' sx={{ fontSize: '20px' }}>Empty List!</Typography>
                        <Typography variant='normal' sx={{ fontSize: '16px' }}>You have no items on the pick list.</Typography>
                    </Stack>
                }
                {itemCategories.map((itemCategoryName) => (
                    <Stack key={itemCategoryName} sx={{ textAlign: 'left' }}>
                        <Typography variant='heavy' sx={{ fontSize: '16px' , fontWeight : '700'}}>
                            {itemCategoryName.charAt(0).toUpperCase() + itemCategoryName.slice(1)}
                        </Typography>
                        <Stack divider={<Divider orientation='horizontal' sx={{ width: '99%', alignSelf: 'center' }} flexItem />}
                            mt={0.5}>
                            {props.pickList[itemCategoryName].map((item) => (
                                <AnimatePresence exitBeforeEnter>
                                    <motion.div key={item.id} 
                                        exit={{
                                            rotateX: [0, -180, 0],
                                            scale: [1, 1.1, 1],
                                            zIndex: 10
                                        }}
                                        transition={{
                                            duration: 0.5
                                        }}
                                    >
                                        <PickListItem
                                            key={item.id}
                                            item={item}
                                            card={props.card}
                                            canEdit={hasCardManagement()}
                                        />
                                    </motion.div>
                                </AnimatePresence>
                            ))}
                        </Stack>
                    </Stack>
                ))}
                <AddItemDialog open={isAddItemDialogOpen} setOpen={setIsAddItemDialogOpen} card={props.card} />
            </Stack>
        </Stack>
    );
}

export default PickList;
