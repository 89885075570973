import { MarkChatReadRounded, SendRounded } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCardOwnerQuery } from '../../../api/cardSlice';
import { useGetConversationQuery, useSendMessageMutation, useUpdateConversationMutation } from '../../../api/textingSlice';
import MessagesView from './MessagesView';
import MissingConversationView from './MissingConversationView';

function Conversation() {
    const params = useParams();
    const [messageText, setMessageText] = useState('');
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const { data: physician = {} } = useGetCardOwnerQuery(params.physicianId);
    const { data: conversation = {}, error} = useGetConversationQuery(physician.id, { skip: !physician.id, pollingInterval: 10000 });
    const [sendMessage] = useSendMessageMutation();
    const [updateConversation] = useUpdateConversationMutation();
    const isActive = conversation?.status === 'active';

    const handleSendMessage = async(messageBody) => {
        setIsSendingMessage(true);
        await sendMessage({ 
            requestBody: {
                body: messageBody
            },
            physicianId: physician.id
        }).unwrap().then(() => {
            setMessageText('');
            setIsSendingMessage(false);
        });
    }

    const handleMarkAsRead = async() => {
        await updateConversation({ 
            conversation: conversation,
            requestBody: {
                status: 'inactive'
            },
        })
    }

    return (
        <Stack mx={8} border='1px solid rgb(0, 0, 0, 0.12)' borderRadius='6px' maxHeight='98%' justifyContent='space-between' backgroundColor='white'>
            <Stack py={1} px={2} justifyContent='space-between' direction='row' width='100%' borderBottom='1px solid rgb(0, 0, 0, 0.12)' >
                <Stack width='40%'>
                    <Typography variant='h6' fontWeight='bold'>
                        Texting with Dr. {physician.first_name} {physician.last_name}
                    </Typography>
                </Stack>
                <Stack>
                    <Button disabled={!isActive} onClick={handleMarkAsRead} size='small' variant='contained' color='primary' endIcon={<MarkChatReadRounded />}>
                        Mark as Read
                    </Button>
                </Stack>
            </Stack>
            <Stack py={2} borderBottom='1px solid rgb(0, 0, 0, 0.12)' maxHeight='90%' overflow='auto'>
                <MessagesView messages={conversation?.messages ?? []} /> 
                { (error && !physician.do_not_text) && 
                    <MissingConversationView error={error} sendMessage={handleSendMessage} />
                }
            </Stack>
            { (!error && !physician.do_not_text) && 
                <Stack spacing={3} py={2} px={1} direction='row' width='100%' alignItems='center' justifyContent='center'>
                    <Stack width='80%'>
                        <TextField value={messageText} multiline onChange={(e) => setMessageText(e.target.value)} label='Message Text' variant='outlined' fullWidth />    
                    </Stack>
                    <Stack>
                        <LoadingButton 
                            onClick={() => handleSendMessage(messageText)}
                            color='primary'
                            endIcon={<SendRounded />}
                            loadingPosition="end"
                            loading={isSendingMessage}
                            variant='contained'
                        >
                            Send
                        </LoadingButton>
                    </Stack>
                </Stack>
            }
            
        </Stack>
    );
}

export default Conversation;
