import { Button, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import axios from 'axios';
import React, { useState } from 'react';
import CSVGenerationDialog from './CSVGenerationDialog';
import PhysicianLink from './PhysicianLink';

function PhysicianManagement(props) {
    const theme = useTheme();
    const [isShowingDialog, setIsShowingDialog] = useState(false);

    function handleGenerateCSV() {
        return new Promise((res) => {
            axios({
                url: `/api/campaigns/${props.campaignId}/physicianLinkFile`,
                method: 'POST',
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
                window.open(url, '_blank');
                res();
            });
        });
    }
        

    const sortedPhysicians = Object.values(props.physicians).sort((a, b) => {
        if (a.last_name < b.last_name) {
            return -1;
        }
        if (a.last_name > b.last_name) {
            return 1;
        }
        return 0;
    });

    return (
        <Stack spacing={1}>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant="h5">Manage Physician Communications</Typography>   
                <Button variant='contained' size='small' onClick={() => setIsShowingDialog(true)}>Generate CSV</Button>
                <CSVGenerationDialog open={isShowingDialog} handleClose={() => setIsShowingDialog(false)} handleGenerateCSV={handleGenerateCSV} />
            </Stack>
            <Stack p={2} sx={{ backgroundColor: `${theme.palette.background.white}`, borderRadius: '12px' }}>
                <Stack spacing={2}>
                    <Typography variant="h6">Physicians in this campaign: </Typography>
                    { sortedPhysicians.map((physician) => (
                        <PhysicianLink key={physician.id} physician={physician} campaignId={props.campaignId}/>
                    ))}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default PhysicianManagement;
