export default function useAnalytics() {
    const track = (eventName, data) => {
        window.analytics.track(eventName, {
            ...data,
            userAgent: window.navigator.userAgent
        });
    }

    const identify = (userId, data) => {
        window.analytics.identify(userId, {
            ...data,
            userAgent: window.navigator.userAgent
        })
    }

    const group = (hospitalId) => {
        window.analytics.group(hospitalId);
    }

    return {
        track,
        identify,
        group,
    }
}
