import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    IconButton,
    Typography,
    Divider,
    Stack,
    Menu,
    MenuItem
} from '@mui/material';
import { useTheme } from '@mui/system';
import { MoreVert } from '@mui/icons-material';
import EditPickListItem from './EditPickListItem';
import { NumericFormat } from 'react-number-format';
import ItemNameDisplay from '../CustomComponents/ItemNameDisplay';
import SwapItemDialog from '../Card/SwapItem/SwapItemDialog';

function PickListItem(props) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isSwappingItem, setIsSwappingItem] = useState(false);

    function handleMoreClick(event) {
        setMenuOpen(true);
        setAnchorEl(event.currentTarget);
    }

    function handleEditClick() {
        setIsEditing(true);
        setMenuOpen(false);
    }

    function handleReplaceClick() {
        setIsSwappingItem(true);
        setMenuOpen(false);
    }

    function handleStopEditing() {
        setIsEditing(false);
    }

    return (
        <Stack direction='row' spacing={1} sx={{ '&:last-child': { borderRadius: '0 0 8px 8px' }, '&:first-of-type': { borderRadius: '8px 8px 0 0' }, '&:only-child': { borderRadius: '8px' } }}>
            <Stack px={2} py={1} direction='row' justifyContent='space-between' alignItems='center' sx={{ boxShadow: 3, backgroundColor: '#ffffff', width: '100%', borderRadius: 'inherit' }}>
                <Stack width='55%'>
                    <ItemNameDisplay item={props.item} fontSize="14px" />
                    <Stack direction="row" spacing={1} alignItems='center' sx={{ fontSize: '12px', color: '#00000099' }}>
                        <Typography variant='normal' sx={{ fontSize: '14px', color: `${theme.palette.subtext.main}`, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {props.item.vendor.name + ' - ' + props.item.part_number}
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <NumericFormat
                            value={props.item.cost / 100}
                            thousandSeparator={true}
                            displayType={'text'}
                            prefix={'$'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                        />
                    </Stack>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0.5, md: 1 }} alignItems='center' justifyItems={{ xs: 'end', md: 'center' }}>
                        <Typography variant='bold' sx={{ fontSize: '16px', color: `${theme.palette.green.main}` }}>
                            Open: <Typography display='inline' sx={{ color: 'black' }}>{props.item.open_quantity}</Typography>
                        </Typography>
                        <Typography variant='bold' sx={{ fontSize: '14px', color: `${theme.palette.hold.main}` }}>
                            Hold: <Typography display='inline' sx={{ color: 'black' }}>{props.item.hold_quantity}</Typography>
                        </Typography>
                    </Stack>
                    {props.canEdit &&
                        <IconButton onClick={handleMoreClick}> <MoreVert /></IconButton>
                    }
                </Stack>
            </Stack>
            <EditPickListItem open={isEditing} handleClose={handleStopEditing} card={props.card} item={props.item} />
            <SwapItemDialog open={isSwappingItem} handleClose={() => setIsSwappingItem(false)} card={props.card} item={props.item} />
            <Menu anchorEl={anchorEl} open={menuOpen} onClose={() => { setMenuOpen(false) }}>
                <MenuItem onClick={handleEditClick}>Edit Item</MenuItem>
                <MenuItem onClick={handleReplaceClick}>Replace Item</MenuItem>
            </Menu>
        </Stack>
    );
}

PickListItem.propTypes = {
    item: PropTypes.object.isRequired
}


export default PickListItem;
