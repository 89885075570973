import React from 'react';
import useAuth from '../Hooks/useAuth';
import InvalidToken from '../PhysicianDashboard/InvalidToken';

function RequirePhysicianDashboardAuth({ children }) {
    const { physicianDashboardAuthed } = useAuth();

    return (physicianDashboardAuthed === true ? children : <InvalidToken resendButton={true} /> );
}

export default RequirePhysicianDashboardAuth;
