import React, { useContext } from 'react';
import { Autocomplete, Button, Grid, Paper, Stack, TextField, InputLabel, Typography } from '@mui/material';
import { useGetCardOwnersQuery } from '../../../api/cardSlice';
import { useState } from 'react';
import { useMemo } from 'react';
import CardDataImport from './CardDataImport';
import CopyExistingCard from './CopyExistingCard';
import { UserContext } from '../../Hooks/useUser';

function CreateCard() {
    const { user } = useContext(UserContext);
    const {
        data: physicians = [],
        isLoading,
    } = useGetCardOwnersQuery(user.hospital.id);
    
    
    const [name, setName] = useState('');
    const [selectedPhysician, setSelectedPhysician] = useState();
    const [isChoosingDataMethod, setIsChoosingDataMethod] = useState(false);
    const [isCopyingExistingCard, setIsCopyingExistingCard] = useState(false);
    
    const canCreate = useMemo(() => {
        return name && selectedPhysician;
    }, [name, selectedPhysician]);

    const hasSubmittedCardData = useMemo(() => {
        return isChoosingDataMethod || isCopyingExistingCard;
    }, [isChoosingDataMethod, isCopyingExistingCard]);

    function handleSubmitName() {
        setIsChoosingDataMethod(true);
    }

    function handleCopyExistingCard() {
        setIsChoosingDataMethod(false);
        setIsCopyingExistingCard(true);
    }

    const sortedPhysicianNames = [...physicians].sort((a, b) => {
        return a.last_name.localeCompare(b.last_name);
    });

    return (
        <Stack height='100%' overflow='auto' pb={5}>
            <Typography variant='heavy' pb={2} px={{ xs: 2, md: 4 }} sx={{ fontSize: '20px' }}>New Card Creation</Typography>
            <Grid container rowSpacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <Stack px={{ xs: 1, md: 4 }} spacing={2}>
                        <Paper elevation={2}>
                            <Stack p={2} spacing={4}>
                                <Stack>
                                    <InputLabel htmlFor="card-name">
                                        Enter Card Name
                                    </InputLabel>
                                    <TextField 
                                        id="card-name"
                                        variant='outlined' 
                                        placeholder='Card Name'
                                        disabled={hasSubmittedCardData}
                                        value={name}
                                        onChange={(event) => setName(event.target.value)}
                                    />
                                </Stack>
                                <Stack>
                                    <InputLabel htmlFor='physician-select'>
                                        Select Physician
                                    </InputLabel>
                                    <Autocomplete 
                                        id='physician-select'
                                        options={sortedPhysicianNames} 
                                        getOptionLabel={(physician) => `${physician.last_name}, ${physician.first_name}`}
                                        renderInput={(physicians) => <TextField {...physicians} placeholder='Physician'/>}
                                        loading={isLoading}
                                        disabled={hasSubmittedCardData}
                                        value={selectedPhysician}
                                        onChange={(event, newValue) => setSelectedPhysician(newValue)}
                                    />
                                </Stack>
                            </Stack>
                            <Stack direction='row' spacing={2} justifyContent='flex-end' alignItems='center' pb={2} pr={2}>
                                { !isChoosingDataMethod && !isCopyingExistingCard && 
                                    <Button variant='contained' onClick={handleSubmitName} disabled={!canCreate}>Create New Card</Button>
                                }
                            </Stack>
                        </Paper>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={6} px={{ xs: 1, md: 4 }}>
                    { isChoosingDataMethod && 
                        <CardDataImport 
                            user={user}
                            cardName={name} 
                            selectedPhysician={selectedPhysician} 
                            handleCopyExistingCard={handleCopyExistingCard} 
                        />
                    }
                    { isCopyingExistingCard && 
                        <CopyExistingCard 
                            user={user}
                            cardName={name}
                            selectedPhysician={selectedPhysician}
                            setIsChoosingDataMethod={setIsChoosingDataMethod}
                            setIsCopyingExistingCard={setIsCopyingExistingCard}
                        />  
                    }
                </Grid>
            </Grid>
        </Stack>
    );
}

export default CreateCard;