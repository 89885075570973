import React from 'react';
import useAuth from '../Hooks/useAuth';
import {Navigate, useLocation} from 'react-router-dom';

function RequireStaffReviewAuth({ children }) {
    const { staffReviewAuthed } = useAuth();
    const location = useLocation();

    return (staffReviewAuthed === true ? children : <Navigate to='/login' replace state={{ path: location.pathname }} />);
}

export default RequireStaffReviewAuth;
