import React, { useEffect } from 'react';
import {useSearchParams, useNavigate} from 'react-router-dom';
import useStaffReviewToken from '../Hooks/useStaffReviewToken';
import useAuth from '../Hooks/useAuth';
import useStaffReviewUser from '../Hooks/useStaffReviewUser';
import {staffReviewSlice} from '../../api/staffReviewSlice';
import { Stack, Typography, CircularProgress } from '@mui/material';

function StaffReviewAuthRedirect() {
    const [searchParams, ] = useSearchParams();
    const { staffReviewToken, setStaffReviewToken } = useStaffReviewToken();
    const { staffReviewUser, setStaffReviewUser } = useStaffReviewUser();
    const { setStaffReviewAuthed } = useAuth();
    const [getCurrentUser] = staffReviewSlice.endpoints.getCurrentUser.useLazyQuery();
    const navigate = useNavigate();

    useEffect(() => {
        if (searchParams.get('token')) {
            setStaffReviewToken(searchParams.get('token'));
            getCurrentUser().unwrap().then((user) => {
                setStaffReviewUser(user);
                setStaffReviewAuthed(true);
                navigate('/staffReview/activeReviews');
            }).catch(() => {
                setStaffReviewToken(null);
                setStaffReviewUser(null);
                navigate('/login');
            });
        } else if (!staffReviewToken || !staffReviewUser) {
            navigate('/login');
        }
    }, [searchParams]);

    return(
        <Stack width="100%" height="100%">
            <Stack width="100%" height="100%" justifyContent="center" alignItems="center" spacing={2}>
                <Typography variant="h4">Validating your token. Hang tight...</Typography>
                <CircularProgress />
            </Stack>
        </Stack>
    );
}

export default StaffReviewAuthRedirect;
