import { Typography, Stack, DialogContentText } from '@mui/material';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { useTheme } from '@mui/system';
import { East } from '@mui/icons-material';

function SummaryImpact(props) {
    const theme = useTheme();

    if (props.variant === 'swaps') {
        return (
            <Stack sx={{justifyContent: 'center', textAlign: 'center'}}>
                <Typography variant='bold' sx={{fontSize: '18px', letterSpacing: '1.2px' }}>Your Swaps save</Typography>
                <DialogContentText>
                    <Typography variant='bold' sx={{fontSize: '14px', color: `${theme.palette.blue.heavy}`, letterSpacing: '1px' }}>
                        <NumericFormat
                            value={props.savingsSummary?.swap_savings / 100}
                            thousandSeparator={true}
                            decimalScale={0}
                            displayType={'text'}
                            prefix={'$'}
                            suffix={' '}
                        />
                    </Typography>
                    every year
                </DialogContentText>
            </Stack>
        )
    } else if (props.variant === 'compliance') {
        return (
            <Stack sx={{display: 'row', justifyContent: 'center', textAlign: 'center'}}>
                <Typography variant='bold' sx={{fontSize: '18px', letterSpacing: '1.2px' }}>Your Compliance</Typography>
                <Typography variant='bold' p={2} sx={{fontSize: '20px', color: `${theme.palette.blue.heavy}`, letterSpacing: '1.2px', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                    { props.savingsSummary?.current_compliance_percentage - props.savingsSummary?.initial_compliance_percentage > 0.01 &&
                    <>
                        <Stack p={1} height={25} direction='row' sx={{justifyContent: 'center', alignItems: 'center', backgroundColor: `${theme.palette.background.neutral}`, borderRadius: '4px', color: `${theme.palette.subtext.main}`, letterSpacing: '1.08px', maxWidth: 'fit-content' }}>  
                            <NumericFormat 
                                value={props.savingsSummary?.initial_compliance_percentage * 100}
                                displayType={'text'}
                                suffix={'%'}
                            /></Stack><East sx={{padding: '0 10px'}}/>
                    </>
                    }
                    <Stack p={1} height={25} direction='row' sx={{justifyContent: 'center', alignItems: 'center', backgroundColor: `${theme.palette.itemSwapChip.background.positive}`, borderRadius: '4px', color: `${theme.palette.itemSwapChip.text.positive}`, letterSpacing: '1.08px', maxWidth: 'fit-content' }}>
                        <NumericFormat
                            value={props.savingsSummary?.current_compliance_percentage * 100}
                            displayType={'text'}
                            suffix={'%'}    
                        /></Stack>
                </Typography>
            </Stack>
        )
    } else {
        return (
            <Stack sx={{justifyContent: 'center', textAlign: 'center'}}>
                <Typography variant='bold' sx={{fontSize: '18px', letterSpacing: '1.2px', }}>Your Impact</Typography>
                <DialogContentText>
                    <Typography variant='bold' sx={{fontSize: '14px', color: `${theme.palette.blue.heavy}` , letterSpacing: '1px' }}>
                        <NumericFormat
                            value={props.savingsSummary?.fewer_items}
                            displayType={'text'}
                            thousandSeparator={true}
                            suffix={' '}
                        />
                    </Typography>
                    fewer items picked and restocked
                </DialogContentText>
            </Stack>
        )
    }
}

export default SummaryImpact;