import { apiSlice } from './apiSlice';
import { NotificationActions } from './notificationSlice';

export const itemDetailSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getItem: builder.query({
            query: (itemId) => `/items/${itemId}`,
            providesTags: ['Item']
        }),
        addItemImage: builder.mutation({
            query: ({ itemId, formData }) => ({
                url: `/items/${itemId}/images`,
                method: 'POST',
                body: formData,
            }),
            async onQueryStarted({ itemId, formData }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedItem } = await queryFulfilled;
                    dispatch (
                        apiSlice.util.updateQueryData('getItem', itemId.toString(), existingItem => {
                            Object.assign(existingItem, updatedItem);
                        })
                    );
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'Item image added successfully'
                    }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Could not add item image at this time. Please try again later.'
                    }));
                }
            }
        }),
        deleteItemImage: builder.mutation({
            query: ({ image }) => ({
                url: `/itemImages/${image.id}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ image }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch (
                        apiSlice.util.updateQueryData('getItem', image.item_id.toString(), existingItem => {
                            let existingImage = existingItem.images.find((imageData) => imageData.id = image.id);
                            if (existingImage) {
                                existingItem.images.splice(existingItem.images.indexOf(existingImage), 1);
                            }
                        })
                    );
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'Item image deleted successfully'
                    }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Could not remove item image at this time. Please try again later.'
                    }));
                }
            }
        }),
        updateItemDetails: builder.mutation({
            query: ({ item, details }) => ({
                url: `/items/${item.id}`,
                method: 'PATCH',
                body: {
                    details: details
                }
            }),
            async onQueryStarted({ item, details }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedItem } = await queryFulfilled;
                    dispatch(
                        apiSlice.util.updateQueryData('getItem', item.id.toString(), existingItem => {
                            Object.assign(existingItem, updatedItem);
                        })
                    );
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'Item details updated successfully'
                    }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Could not update item details at this time. Please try again later.'
                    }));
                }
            }
        }),
        updateItem: builder.mutation({
            query: ({ item }) => ({
                url: `/items/${item.id}`,
                method: 'PATCH',
                body: item
            }),
            async onQueryStarted({ item }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        apiSlice.util.updateQueryData('getItem', item.id.toString(), existingItem => {
                            Object.assign(existingItem, item);
                        })
                    );
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'Item updated successfully'
                    }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Could not update item at this time. Please try again later.'
                    }));
                }
            },
        })
    }),
});

export const {
    useGetItemQuery,
    useAddItemImageMutation,
    useDeleteItemImageMutation,
    useUpdateItemDetailsMutation,
    useUpdateItemMutation,
} = itemDetailSlice;