import { Button, Paper, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetHospitalQuery, useUpdateHospitalMutation } from '../../../api/apiSlice';
import HospitalSiteDetails from './HospitalSiteDetails';
import HospitalSiteFeatures from './HospitalSiteFeatures';
import HospitalSiteSettings from './HospitalSiteSettings';

function UpdateHospitalSetup() {
    const params = useParams();
    const { data: hospitalData, isLoading } = useGetHospitalQuery(params.hospitalId);
    return (
        !isLoading && <HospitalConfiguration hospitalData={hospitalData} />
    );
}

export default UpdateHospitalSetup;

function HospitalConfiguration(props) {
    const [files, setFiles] = useState([]);
    const [hospitalData, setHospitalData] = useState(props.hospitalData);
    const [updateHospital] = useUpdateHospitalMutation();
    const navigate = useNavigate();

    const handleSave = async() => {
        var requestData;
        if (files.length > 0) {   
            // Handle Form Data Request for Logo File
            requestData = new FormData();
            requestData.append('name', hospitalData.name);          
            requestData.append('high_impact_threshold', hospitalData.high_impact_threshold);
            requestData.append('configurations', JSON.stringify(hospitalData.configurations));
            requestData.append('logo', files[0]);
        } else {
            // Remove logo string from request since there is no change.
            delete hospitalData.logo;
            requestData = hospitalData;
        }

        await updateHospital({
            hospitalId: hospitalData.id, 
            formData: requestData
        });
        navigate('/superSettings/hospitals/' + hospitalData.id);
    }

    return (
        <Stack p={4} spacing={4} maxWidth='75%'>
            <Stack >
                <Paper elevation={2}>
                    <HospitalSiteDetails 
                        hospitalData={hospitalData}
                        setHospitalData={setHospitalData}
                        isCreating={false}
                        files={files}
                        setFiles={setFiles}
                    />
                    <HospitalSiteSettings
                        hospitalData={hospitalData}
                        setHospitalData={setHospitalData}
                    />
                    <HospitalSiteFeatures
                        hospitalData={hospitalData}
                        setHospitalData={setHospitalData}
                    />
                </Paper>
            </Stack>
            <Stack direction='row'>
                <Button variant='contained' disabled={hospitalData == props.hospitalData} onClick={handleSave}>
                    Save
                </Button>
            </Stack>
        </Stack>
    );
}