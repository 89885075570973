import React from 'react';
import { Stack, Typography, Button, Paper } from '@mui/material';
import format from 'date-fns/format/index.js';
import parseISO from 'date-fns/parseISO/index.js';
import { Print } from '@mui/icons-material';
import axios from 'axios';
import CardTitle from './CardTitle';
import { NumericFormat } from 'react-number-format';

function CardDetails(props) {    
    function getPrintedCard() {
        return new Promise((res) => {
            axios({
                url: `/api/cards/${props.cardTemplateData.id}/printedCard`,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                var pdf = window.open(url, '_blank');
                pdf.print();
                res();
            });
        });
    }

    return (
        <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between" alignItems='center'>
                <Stack width='75%'>
                    <CardTitle card={props.cardTemplateData} />
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>
                        Dr. {props.cardTemplateData.owner.name}
                    </Typography>
                    <Typography variant='caption'>
                        <NumericFormat
                            value={props.cardTemplateData.open_cost / 100}
                            thousandSeparator={true}
                            displayType='text'
                            prefix='$'
                            decimalScale={0}
                        /> Open / <NumericFormat
                            value={props.cardTemplateData.total_cost / 100}
                            thousandSeparator={true}
                            displayType='text'
                            prefix='$'
                            decimalScale={0}
                        /> Total
                    </Typography>
                </Stack>
                
                <Stack direction='row' spacing={2}>
                    <Stack direction='column' display={{ xs: 'none', lg: 'grid' }} justifyItems='end'>
                        <Typography variant="caption" display="block">
                            Card Created on {format(parseISO(props.cardTemplateData.created_at) , 'MM/dd/yy')}
                        </Typography>
                        <Typography variant="caption" display="block">
                            Card Updated on {format(parseISO(props.cardTemplateData.updated_at) , 'MM/dd/yy')}
                        </Typography>
                    </Stack>
                    <Paper elevation={2} sx={{ alignSelf: 'center', display: 'grid' }}>
                        <Button variant='contained' startIcon={<Print />} onClick={getPrintedCard} size='small'>
                            Print
                        </Button>
                    </Paper>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default CardDetails;
