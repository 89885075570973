import { KeyboardArrowDown, KeyboardArrowUp, TextsmsRounded } from '@mui/icons-material';
import { Checkbox, IconButton, LinearProgress, Paper, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';

function DraftTable(props){
    const drafts = props.drafts ?? {};
    const sortedPhysicians = useMemo(() => {
        return Object.values(props.physicians).sort((a, b) => {
            return a.last_name.localeCompare(b.last_name);
        });
    }, [props.physicians]);

    return(
        <Paper elevation={2}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width='3%' />
                        <TableCell width='10%'>Physician</TableCell>
                        <TableCell width='27%'>Cards</TableCell>
                        <TableCell width='10%'># of Optimizations</TableCell>
                        <TableCell width='10%'># of Swaps</TableCell>
                        <TableCell width='10%'># of Compliance</TableCell>
                        <TableCell width='10%'>Case Count</TableCell>
                        { props.campaign.status === 'open' && 
                            <>
                                <TableCell width='10%'>Optimization Opportunity</TableCell>
                                <TableCell width='10%'>Swap Opportunity</TableCell>
                                <TableCell width='10%'>Compliance Opportunity</TableCell>
                            </>
                        }
                        { props.campaign.status === 'active' && 
                            <>
                                <TableCell width='10%'>Total Savings Opportunity</TableCell>
                                <TableCell width='10%'>Captured Savings</TableCell>
                            </>
                        }
                        <TableCell width='10%'>Efficiency</TableCell>
                    </TableRow>
                </TableHead>
                { props.isLoadingDrafts ? 
                    <LoadingTable campaign={props.campaign} /> :
                    <TableBody>
                        { Object.values(sortedPhysicians).map((physician) => (
                            <Row 
                                key={physician.id} 
                                physician={physician} 
                                drafts={drafts[physician.id]} 
                                campaign={props.campaign}
                                isChecked={props.checkedPhysicians?.includes(physician.id) ?? false}
                                checkedPhysicians={props.checkedPhysicians}
                                setCheckedPhysicians={props.setCheckedPhysicians}
                            />
                        ))}
                    </TableBody>
                }
            </Table>
        </Paper>
    )
}

function LoadingTable(props) {
    const rows = [];
    for (var i = 0; i < 10; i++) {
        rows.push(
            <TableRow key={i}>
                <TableCell width='3%'/>
                <TableCell width='10%'>
                    <Skeleton variant='text' />
                </TableCell>
                <TableCell width='27%'>
                    <Skeleton variant='text' />
                </TableCell>
                <TableCell width='10%'>
                    <Skeleton variant='text' />
                </TableCell>
                <TableCell width='10%'>
                    <Skeleton variant='text' />
                </TableCell>
                <TableCell width='10%'>
                    <Skeleton variant='text' />
                </TableCell>
                <TableCell width='10%'>
                    <Skeleton variant='text' />
                </TableCell>
                { props.campaign.status === 'open' &&
                    <>
                        <TableCell width='10%'>
                            <Skeleton variant='text' />
                        </TableCell>
                        <TableCell width='10%'>
                            <Skeleton variant='text' />
                        </TableCell>
                    </>
                }
                { props.campaign.status === 'active' &&
                    <>
                        <TableCell width='10%'>
                            <Skeleton variant='text' /> 
                        </TableCell>    
                        <TableCell width='10%'>
                            <Skeleton variant='text' />
                        </TableCell>
                    </>
                }
                <TableCell width='10%'>
                    <Skeleton variant='rectangular' width={100} height={8} />
                </TableCell>
            </TableRow>
        )
    }
    return (
        <TableBody>
            { rows }
        </TableBody>
    )
}


function Row(props) {
    const [open, setOpen] = useState(false);
    const physician = props.physician;
    const drafts = props.drafts;
    const reviewableDrafts = drafts.filter((draft) => (draft.cost_reduction_opportunity + draft.swap_opportunity > 0) && !draft.is_excluded);
    const isCampaignPreview = props.campaign.status === 'open';
    const navigate = useNavigate();

    const reviewedDrafts = reviewableDrafts.filter((draft) => draft.status === 'submitted');
    const caseCount = drafts.reduce((total, draft) => {
        return draft.card.yearly_stats ? total + draft.card.yearly_stats.usage_count : total + 1;
    }, 0);

    const physicianStats = useMemo(() => {
        var numOptimizations = 0;
        var numSwaps = 0;
        var numCompliance = 0;
        var optimizationOpportunity = 0;
        var swapOpportunity = 0;
        var complianceOpportunity = 0;
        var caseCount = 0;

        drafts.forEach((draft) => {
            if (draft.is_excluded) { return; }
            caseCount += draft.card.yearly_stats?.usage_count ?? 1;
            Object.keys(draft.recommendations).forEach((category) => {
                draft.recommendations[category].forEach((rec) => {
                    if (rec.is_excluded) { return; }
                    if (rec.type === 'swap') {
                        numSwaps += 1;
                        swapOpportunity += rec.recommendation_value;
                    } else if (rec.type === 'compliance') {
                        numCompliance += 1;
                        complianceOpportunity += rec.recommendation_value;
                    } else {
                        numOptimizations += 1;
                        optimizationOpportunity += rec.recommendation_value;
                    }
                });
            });
        });

        return {
            numOptimizations: numOptimizations,
            numSwaps: numSwaps,
            numCompliance: numCompliance,
            optimizationOpportunity: optimizationOpportunity,
            swapOpportunity: swapOpportunity,
            complianceOpportunity: complianceOpportunity,
            caseCount: caseCount,
        }
    }, [drafts])

    const sortedDrafts = useMemo(() => {
        return Object.values(drafts).sort((a, b) => {
            return a.card.name.localeCompare(b.card.name);
        });
    }, [drafts]);

    useEffect(() => {
        // Retrieve the expanded state from localStorage
        const expandedState = localStorage.getItem('expandedState');
        if (expandedState) {
            const parsedState = JSON.parse(expandedState);
            setOpen(parsedState[physician.id] || false);
        }
    }, [physician.id]);

    const handleCheck = (event) => {
        if (event.target.checked) {
            props.setCheckedPhysicians([...props.checkedPhysicians, physician.id]);
        } else {
            props.setCheckedPhysicians(props.checkedPhysicians.filter((id) => id !== physician.id));
        }
    }
    
    const handleRowClick = (event) => {
        if (event.target.type === 'checkbox') {
            return;
        }
        // Update the expanded state of the row
        setOpen(!open);

        // Store the expanded state in localStorage
        const expandedState = JSON.parse(localStorage.getItem('expandedState')) || {};
        localStorage.setItem('expandedState', JSON.stringify({
            ...expandedState,
            [physician.id]: !open,
        }));
    };

    function handleTextClick(event) {
        event.stopPropagation();
        navigate(`/physicians/${physician.id}/physicianTexting`);
    }

    return (
        <>
            <TableRow key={physician.id} onClick={(event) => handleRowClick(event)} hover sx={{ cursor: 'pointer' }}>
                <TableCell width='3%'>
                    { isCampaignPreview ? 
                        <Checkbox
                            checked={props.isChecked}
                            onChange={(event) => handleCheck(event)}
                        /> :
                        <IconButton size='small'>
                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    }
                </TableCell>
                <TableCell width='10%'>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <>
                            {physician.last_name + ', ' + physician.first_name}
                        </>
                        <IconButton size='small' onClick={(e) => handleTextClick(e)} disabled={physician.do_not_text}>
                            <TextsmsRounded />
                        </IconButton>
                    </Stack>
                </TableCell>
                <TableCell width='27%'>{reviewedDrafts.length} / {reviewableDrafts.length} Reviewed</TableCell>
                <TableCell width='10%'>{physicianStats.numOptimizations}</TableCell>
                <TableCell width='10%'>{physicianStats.numSwaps}</TableCell>
                <TableCell width='10%'>{physicianStats.numCompliance}</TableCell>
                <TableCell width='10%'>{caseCount}</TableCell>
                { props.campaign.status === 'open' &&
                    <>
                        <TableCell width='10%'>
                            <NumericFormat
                                value={physicianStats.optimizationOpportunity / 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={0}
                                fixedDecimalScale={true}
                            />
                        </TableCell>
                        <TableCell width='10%'>
                            <NumericFormat
                                value={physicianStats.swapOpportunity / 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={0}
                                fixedDecimalScale={true}
                            />
                        </TableCell>
                        <TableCell width='10%'>
                            <NumericFormat
                                value={physicianStats.complianceOpportunity / 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={0}
                                fixedDecimalScale={true}
                            />
                        </TableCell>
                    </>
                }
                { props.campaign.status === 'active' &&
                    <>
                        <TableCell width='10%'>
                            <NumericFormat
                                value={drafts.reduce((total, draft) => total + draft.cost_reduction_opportunity + draft.swap_opportunity + draft.compliance_opportunity, 0) / 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={0}
                                fixedDecimalScale={true}
                            />
                        </TableCell>
                        <TableCell width='10%'>
                            <NumericFormat
                                value={drafts.reduce((total, draft) => total + draft.optimization_savings + draft.swap_savings + draft.compliance_savings, 0) / 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={0}
                                fixedDecimalScale={true}
                            />
                        </TableCell>
                    </>
                }
                <TableCell width='10%'>
                    <EfficiencyBar efficiency={physician.efficiency} />
                </TableCell>
            </TableRow>
            {open && sortedDrafts.map((draft) => (
                <DraftRow key={draft.card.id} draft={draft} campaign={props.campaign}/>
            ))}
        </>
    )
}

function DraftRow(props) {
    const theme = useTheme();
    const draft = props.draft;
    const navigate = useNavigate();
    const isExcluded = props.draft.is_excluded ?? false;

    const draftStats = useMemo(() => {
        var numOptimizations = 0;
        var numSwaps = 0;
        var numCompliance = 0;
        var optimizationOpportunity = 0;
        var swapOpportunity = 0;
        var complianceOpportunity = 0;

        Object.keys(draft.recommendations).forEach((category) => {
            draft.recommendations[category].forEach((rec) => {
                if (rec.is_excluded) { return; }
                
                if (rec.type === 'swap') {
                    numSwaps += 1;
                    swapOpportunity += rec.recommendation_value;
                } else if (rec.type === 'compliance') {
                    numCompliance += 1;
                    complianceOpportunity += rec.recommendation_value;
                } else {
                    numOptimizations += 1;
                    optimizationOpportunity += rec.recommendation_value;
                }
            });
        })

        return {
            numOptimizations: numOptimizations,
            numSwaps: numSwaps,
            numCompliance: numCompliance,
            optimizationOpportunity: optimizationOpportunity,
            swapOpportunity: swapOpportunity,
            complianceOpportunity: complianceOpportunity,
        }
    }, [draft]);


    const goToCardView = () => {
        navigate(`/campaigns/${props.campaign.id}/previews/${draft.id}`);
    }

    return (
        <TableRow onClick={goToCardView} style={{ color: isExcluded ? `${theme.palette.red.main}` : '' }} hover sx={{ cursor: 'pointer' }}>
            <TableCell width='3%'/>
            <TableCell width='10%'/>
            <TableCell width='27%' style={{ color: 'inherit' }}>{draft.card.name}</TableCell>
            <TableCell width='10%' style={{ color: 'inherit' }}>{isExcluded ? 'Excluded' : draftStats.numOptimizations}</TableCell>
            <TableCell width='10%' style={{ color: 'inherit' }}>{isExcluded ? 'Excluded' : draftStats.numSwaps}</TableCell>
            <TableCell width='10%' style={{ color: 'inherit' }}>{isExcluded ? 'Excluded' : draftStats.numCompliance}</TableCell>
            <TableCell width='10%' style={{ color: 'inherit' }}>{draft.card.yearly_stats?.usage_count ?? 1}</TableCell>
            
            { props.campaign.status === 'open' &&
                <>
                    <TableCell width='10%' style={{ color: 'inherit' }}>
                        { isExcluded ? 'Excluded' : 
                            <NumericFormat
                                value={draftStats.optimizationOpportunity / 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={0}
                                fixedDecimalScale={true}
                            />
                        }
                    </TableCell>
                    <TableCell width='10%' style={{ color: 'inherit' }}>
                        { isExcluded ? 'Excluded' :
                            <NumericFormat
                                value={draftStats.swapOpportunity / 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={0}
                                fixedDecimalScale={true}
                            />
                        }
                    </TableCell>
                    <TableCell width='10%' style={{ color: 'inherit' }}>
                        { isExcluded ? 'Excluded' :
                            <NumericFormat
                                value={draftStats.complianceOpportunity / 100}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={0}
                                fixedDecimalScale={true}
                            />
                        }
                    </TableCell>
                </>
            }
            { props.campaign.status === 'active' &&
                <>
                    <TableCell width='10%'>
                        <NumericFormat
                            value={(draft.cost_reduction_opportunity + draft.swap_opportunity + draft.compliance_opportunity) / 100}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            decimalScale={0}
                            fixedDecimalScale={true}
                        />
                    </TableCell>
                    <TableCell width='10%'>
                        <NumericFormat
                            value={(draft.optimization_savings + draft.swap_savings + draft.compliance_savings) / 100}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            decimalScale={0}
                            fixedDecimalScale={true}
                        />
                    </TableCell>
                </>
            }
            <TableCell width='10%'>
                <EfficiencyBar efficiency={draft.efficiency ?? 0} isExcluded={isExcluded} />
            </TableCell>
        </TableRow>
    )
}

function EfficiencyBar(props) {
    return (
        <LinearProgress 
            variant='determinate' 
            value={props.efficiency * 100} 
            style={{ 
                height: 8,
            }} 
        />
    )
}

export default DraftTable