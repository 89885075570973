import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, Box } from '@mui/system';
import { Stack, Typography } from '@mui/material';
import { ChevronRightRounded, CheckCircle } from '@mui/icons-material';
import { NumericFormat } from 'react-number-format';
import format from 'date-fns/format/index.js';
import parseISO from 'date-fns/parseISO/index.js';

function SubmittedCardEntry(props) {
    const navigate = useNavigate();
    const theme = useTheme();

    function handleClick() {
        sessionStorage.setItem('cardListScrollPosition', window.scrollY);
        navigate(`/physician/drafts/${props.draft.id}`, { state: {
            physician: props.physician
        }});
    }

    return (
        <Stack direction='row' justifyContent='space-between' onClick={handleClick} p={2} sx={{ backgroundColor: '#FFFFFF', borderRadius: '4px' }} alignItems='center'>
            <Stack direction='row' spacing={2} alignItems='center'>
                <Box sx={{ color: `${theme.palette.green.main}` }}>
                    <CheckCircle />
                </Box>
                <Stack spacing={0.5} sx={{ width: '70%' }}>
                    <Box sx={{ fontSize: '14px', color: '#000000DE', overflowWrap: 'anywhere' }}>
                        <Typography variant='normal'>{props.draft.card.name}</Typography>
                    </Box>
                    <Box sx={{ fontSize: '12px', color: '#00000099' }}>
                        <Typography variant='normal'>Reviewed on {format(parseISO(props.draft.updated_at), 'MM/dd/yy')}</Typography>
                    </Box>
                </Stack>
            </Stack>
            <Stack direction='row' alignItems='center'>
                <Stack justifyContent='center' alignItems='center'>
                    <Typography variant='normal' sx={{ fontSize: '14px' }}>
                        <NumericFormat
                            value={Math.round(props.draft.optimization_savings / 100)}
                            thousandSeparator={true}
                            displayType={'text'}
                            prefix={'$'}
                        />
                    </Typography>
                    <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}`, textAlign: 'center' }}>
                        Captured Cost Reduction /yr
                    </Typography>
                </Stack>
                <ChevronRightRounded sx = {{opacity : '0.6'}} />
            </Stack>
        </Stack>
    );
}

export default SubmittedCardEntry;