import React from 'react';
import { useGetCardsForReviewQuery } from '../../api/staffReviewSlice';
import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
import ReviewEntry from './ReviewEntry';
import useStaffReviewUser from '../Hooks/useStaffReviewUser';
import LoadingIndicator from '../LoadingIndicator';

function StaffReviewCardList() {
    const { staffReviewUser: user } = useStaffReviewUser();
    const { data: draftsToReview = [], isLoading } = useGetCardsForReviewQuery(user?.id, { skip: !user });

    if (isLoading) {
        return (
            <Stack px={{ xs: 2, md: 6 }} pb={3} sx={{ height: '100%', overflow: 'auto' }}>
                <Typography variant='heavy' sx={{ fontSize: '20px' }}>Card Changes to Review</Typography>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>Reviewing As: </strong>{user?.name}</Typography>
                <Stack width="100%" height="100%">
                    <LoadingIndicator variant='reviews'/>
                </Stack>
            </Stack>
        )
    }
    
    return (
        <Stack spacing={2} px={{ xs: 2, md: 6 }} pb={3} sx={{ height: '100%', overflow: 'auto' }}>
            <Stack>
                <Typography variant='heavy' sx={{ fontSize: '20px' }}>Card Changes to Review</Typography>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>Reviewing As: </strong>{user?.name}</Typography>
            </Stack>
            { draftsToReview.length === 0 && 
                <Typography variant='normal' sx={{ fontSize: '16px' }}>No cards to review at this time. Check back again later!</Typography>
            }
            {draftsToReview.map((draft, index) => (
                <ReviewEntry variant='active' key={index} draft={draft} reviewer={user}/>
            ))}
        </Stack>
    );
}


export default StaffReviewCardList;
