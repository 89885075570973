import {createApi} from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';

var urlPrefix = '';

switch(process.env.ENVIRONMENT_SETTINGS) {
case 'local':
    urlPrefix = 'http://localhost:8000';
    break;
case 'staging':
    urlPrefix = 'https://staging.docsihealth.com';
    break;
case 'demo':
    urlPrefix = 'https://demo.docsihealth.com';
    break;
case 'production':
    urlPrefix = 'https://api.docsihealth.com';
    break;
}

export const physicianDashboardSlice = createApi({
    reducerPath: 'dashboardApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${urlPrefix}/api`,
        prepareHeaders: (headers) => {
            headers.set('content-type', 'application/json');
            headers.set('accept', 'application/json');
            const token = localStorage.getItem('dashboardToken');
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        },
        credentials: 'include'
    }),
    tagTypes: ['Draft', 'UserDrafts', 'PhysicianCampaignDrafts', 'CampaignSummary', 'DashboardUser', 'CardOwner', 'ItemRecommendation', 'PhysicianCompliance', 'ComplianceRecommendation', 'Headline', 'Campaign'],
    endpoints: builder => ({
        getDraft: builder.query({
            query: (draftId) => `/drafts/${draftId}`,
            providesTags: (result, error, id) => [{ type: 'Draft', id: id }]
        }),
        updateDraft: builder.mutation({
            query: ({ draftId, status, isExcluded }) => ({
                url: `/drafts/${draftId}`,
                method: 'PATCH',
                body: {
                    status: status,
                    is_excluded: isExcluded
                }
            }),
            async onQueryStarted({ draftId, status, isExcluded }, { dispatch, queryFulfilled }) {
                const result = dispatch (
                    physicianDashboardSlice.util.updateQueryData('getDraft', draftId.toString(), draft => {
                        if (status) {
                            draft.status = status;
                        }
                        if (isExcluded !== undefined) {
                            draft.is_excluded = isExcluded;
                        }
                    })
                )
                try {
                    await queryFulfilled;
                } catch {
                    result.undo();
                }
            },
            invalidatesTags: ['UserDrafts', 'PhysicianCampaignDrafts', 'DashboardUser', 'Headline', 'CampaignSummary']
        }),
        updateDraftComment: builder.mutation({
            query: ({ draftId, card, comment, commentText }) => ({
                url: `/drafts/${draftId}/comments/${comment.id}`,
                method: 'PATCH',
                body: {
                    body: commentText
                }
            }),
            async onQueryStarted({ draftId, card, comment, commentText }, {dispatch, queryFulfilled }) {
                const result = dispatch (
                    physicianDashboardSlice.util.updateQueryData('getDraft', draftId.toString(), draft => {
                        let existingComment = draft.comments.find(commentData => commentData.id === comment.id);
                        if (existingComment) {
                            existingComment.body = commentText
                        }
                    })
                )
                try {
                    await queryFulfilled;
                } catch {
                    result.undo();
                }
            },
        }),
        updateComplianceRecommendation: builder.mutation({
            query: ({ recommendation, status, rejectionContext }) => ({
                url: `drafts/${recommendation.draft.id}/recommendations/${recommendation.id}`,
                method: 'PATCH',
                body: {
                    status: status,
                    rejection_context: rejectionContext
                }
            }),
            async onQueryStarted({ recommendation, status, rejectionContext }, { dispatch, queryFulfilled }) {
                const result = dispatch (
                    physicianDashboardSlice.util.updateQueryData('getComplianceRecommendations', {'physicianId':recommendation.draft.card.owner.id.toString(), 'campaignId': recommendation.draft.campaign_id.toString()}, recommendations => {
                        let existingRec = recommendations.find(rec => rec.id === recommendation.id);
                        if (existingRec) { 
                            existingRec.status = status;
                            existingRec.rejection_context = rejectionContext;
                        }
                    })
                )
                try {
                    await queryFulfilled;
                } catch {
                    result.undo();
                }
            },
            invalidatesTags: ['DashboardUser', 'PhysicianCompliance', 'Headline', 'CampaignSummary'],
        }),
        updateItemSwapRecommendation: builder.mutation({
            query: ({ recId, status, physicianId, campaignId, rejectionContext }) => ({
                url: `itemRecommendations/${recId}`,
                method: 'PATCH',
                body: {
                    status: status,
                    rejection_context: rejectionContext
                }
            }),
            async onQueryStarted({ recId, status, physicianId, campaignId, rejectionContext }, {dispatch, queryFulfilled }) {
                const result = dispatch (
                    physicianDashboardSlice.util.updateQueryData('getPhysicianItemRecommendations', {'physicianId': physicianId, 'campaignId': campaignId}, recGroup => {
                        let existingRec = recGroup.find(rec => rec.id === recId);
                        if (existingRec) { 
                            existingRec.status = status;
                            existingRec.rejection_context = rejectionContext;
                        }
                    })
                )
                try {
                    await queryFulfilled;
                } catch {
                    result.undo();
                }
            },
            invalidatesTags: ['DashboardUser', 'Headline', 'CampaignSummary'],
        }),
        getCurrentDashboardUser: builder.query({
            query: () => '/user',
            providesTags: ['DashboardUser']
        }),
        getCardOwner: builder.query({
            query: (cardOwnerId) => `/cardOwners/${cardOwnerId}`,
            providesTags: ['CardOwner']
        }),
        getTotalSavingsSummary: builder.query({
            query: ({ physicianId, campaignId }) => `/cardOwners/${physicianId}/savings?campaign=${campaignId}`,
        }),
        getPhysicianCampaignDrafts: builder.query({
            query: ({physicianId, campaignId}) => `/campaigns/${campaignId}/cardOwners/${physicianId}/drafts`,
            providesTags: ['PhysicianCampaignDrafts']
        }),
        getPhysicianItemRecommendations: builder.query({
            query: ({ physicianId, campaignId }) => `/cardOwners/${physicianId}/itemRecommendations?type=swap${campaignId ? `&campaign=${campaignId}` : ''}`,
            providesTags: (result = []) => [
                ...result.map(({ id }) => ({ type: 'ItemRecommendation', id }))
            ],
        }),
        getComplianceRecommendations: builder.query({
            query: ({ physicianId, campaignId }) => `/cardOwners/${physicianId}/itemRecommendations?type=compliance${campaignId ? `&campaign=${campaignId}` : ''}`,
            providesTags: (result = []) => [
                ...result.map(({ id }) => ({ type: 'ComplianceRecommendation', id }))
            ],
        }),
        getHeadlineData: builder.query({
            query: ({ physicianId, campaignId }) => `/cardOwners/${physicianId}/headline${campaignId ? `?campaign=${campaignId}` : ''}`,
            providesTags: ['Headline']
        }),
        getPhysicianCompliance: builder.query({
            query: (physicianId) => `/cardOwners/${physicianId}/compliance`,
            providesTags: ['PhysicianCompliance']
        }),
        logUserAction: builder.mutation({
            query: ({ action, data }) => ({
                url: '/userActions',
                method: 'POST',
                body: {
                    action: action,
                    data: data
                }
            }),
        }),
        getComparableItems: builder.query({
            query: ({ hospitalId, cardId, category, procedureId }) => ({
                url: `/hospitals/${hospitalId}/comparableItems?card=${cardId}&category=${category}&procedure=${procedureId}`,
                method: 'GET'
            }),
        }),
        resetPhysicianDashboardToken: builder.mutation({
            query: ({ token }) => ({
                url: '/resetPhysicianDashboardToken',
                method: 'POST',
                body: {
                    expired_token: token,
                }
            }),
        }),
        getCampaignSummaries: builder.query({
            query: ({physicianId, status, maxDaysInPast}) => `/cardOwners/${physicianId}/campaigns${status ? `?status[]=${status}` : ''}${maxDaysInPast ? `&maxDaysInPast=${maxDaysInPast}` : ''}`,
            providesTags: ['CampaignSummary'],
        }),
        getCampaign: builder.query({
            query: (campaignId) => `/campaigns/${campaignId}`,
            providesTags: (result, error, arg) => [{ type: 'Campaign', id: arg }]
        }),
    })
});

export const {
    useGetDraftQuery,
    useUpdateDraftMutation,
    useUpdateDraftCommentMutation,
    useUpdateItemSwapRecommendationMutation,
    useGetCurrentDashboardUserQuery,
    useGetCardOwnerQuery,
    useGetTotalSavingsSummaryQuery,
    useGetPhysicianCampaignDraftsQuery,
    useGetPhysicianItemRecommendationsQuery,
    useGetComplianceRecommendationsQuery,
    useUpdateComplianceRecommendationMutation,
    useGetHeadlineDataQuery,
    useGetPhysicianComplianceQuery,
    useLogUserActionMutation,
    useGetComparableItemsQuery,
    useResetPhysicianDashboardTokenMutation,
    useGetCampaignSummariesQuery,
    useGetCampaignQuery,
} = physicianDashboardSlice;
