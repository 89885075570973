import { Box, Stack } from '@mui/system';
import { Typography } from '@mui/material';
import React from 'react';
import { ArrowDropDownRounded, ArrowDropUpRounded } from '@mui/icons-material';

function AssessmentRating(props) {
    function getEvidenceText() {
        if (props.assessment.rating === 5) {
            return 'Favorable';
        } else if (props.assessment.rating === 4) {
            return 'Somewhat Favorable';
        } else if (props.assessment.rating === 3) {
            return 'Inconclusive';
        } else if (props.assessment.rating === 2) {
            return 'Somewhat Unfavorable';
        } else {
            return 'Unfavorable';
        }
    }


    return (
        <Stack spacing={1}>
            <Typography variant='heavy' fontSize='14px'><strong>Evidence Assessment: </strong>{getEvidenceText()}</Typography>
            <RatingBar assessment={props.assessment} />
        </Stack>
    );
}


function RatingBar(props) {
    return (
        <Stack direction='row' spacing={0.25}>
            <Box sx={{ display: 'grid', width: '20%', height: '10px', backgroundColor: '#00000033', borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px' }} style={{ background: 'linear-gradient(to right, rgb(191, 45, 71), rgb(205, 104, 130)' }}>
                { props.assessment.rating === 1 && <RatingBarArrows /> }
            </Box>
            <Box sx={{ display: 'grid', width: '20%', height: '10px', backgroundColor: '#00000033' }} style={{ background: 'linear-gradient(to right, rgb(205, 104, 130), rgb(226, 192, 202)' }}>
                { props.assessment.rating === 2 && <RatingBarArrows /> }
            </Box>
            <Box sx={{ display: 'grid', width: '20%', height: '10px', backgroundColor: '#00000033' }} style={{ background: 'linear-gradient(to right, rgb(226, 192, 202), rgb(240, 240, 240), rgb(205, 219, 217)' }}>
                { props.assessment.rating === 3 && <RatingBarArrows /> }
            </Box>
            <Box sx={{ display: 'grid', width: '20%', height: '10px', backgroundColor: '#00000033' }} style={{ background: 'linear-gradient(to right, rgb(205, 219, 217), rgb(132, 176, 169)' }}>
                { props.assessment.rating === 4 && <RatingBarArrows /> }
            </Box>
            <Box sx={{ display: 'grid', width: '20%', height: '10px', backgroundColor: '#00000033', borderTopRightRadius: '6px', borderBottomRightRadius: '6px' }} style={{ background: 'linear-gradient(to right, rgb(132, 176, 169), rgb(64, 132, 122)' }}>
                { props.assessment.rating === 5 && <RatingBarArrows /> }
            </Box>
        </Stack>
    );
}

function RatingBarArrows() {
    return (
        <Stack justifySelf='center' position='relative' top='-15px'>
            <ArrowDropDownRounded />
            <ArrowDropUpRounded style={{ top: '-8px', position: 'relative' }} />
        </Stack>
    );
}

export default AssessmentRating;
