import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import Message from './Message';


function MessagesView(props) {
    const messagesEndRef = React.createRef();

    useEffect(() => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [props.messages]);

    return (
        <Stack height='100%' spacing={1} px={4}>
            {props.messages.map((message) => {
                return (
                    <Message message={message} key={message.index} />
                )
            })}
            <Stack ref={messagesEndRef} />
        </Stack>
    )
}

export default MessagesView;
