import React, { useMemo } from 'react';
import { Stack, Typography, Divider, Button } from '@mui/material';
import StatisticTile from '../../StatisticTiles/StatisticTile';
import { useParams } from 'react-router-dom';
import { useGetCampaignPreviewDraftQuery, useGetCampaignQuery, useUpdatePreviewDraftMutation } from '../../../../api/campaignSlice';
import PreviewOptimization from './PreviewOptimization';
import ItemEntry from './ItemEntry';

function DraftPreview() {
    const {campaignId, draftId}  = useParams();

    const { data: campaign = {} } = useGetCampaignQuery({ campaignId: campaignId });
    const { data: draftData } = useGetCampaignPreviewDraftQuery(draftId);
    const [updateDraft] = useUpdatePreviewDraftMutation();

    const pickList = useMemo(() => {
        if (!draftData) { return {} }
        return draftData?.items;
    }, [draftData]);

    const itemRecommendations = useMemo(() => {
        if (!draftData) { return {} }
        var recommendationsToReturn = [];
        Object.keys(draftData?.recommendations).forEach((cat) => {
            recommendationsToReturn = recommendationsToReturn.concat(draftData?.recommendations[cat]);
        });
        return recommendationsToReturn;
    }, [draftData]);

    const toggleExcludeDraft = async() => {
        if (draftData.is_excluded) {
            await updateDraft({
                draft: draftData,
                isExcluded: false,
            });
        } else {
            await updateDraft({
                draft: draftData,
                isExcluded: true,
            });
        }
    };

    const getAssociatedRecommendations = (item) => {
        return itemRecommendations.filter((rec) => rec.item.id === item.id);
    };

    const nonExcludedRecommendations = useMemo(() => {
        if (!draftData || draftData?.is_excluded) { return [] }

        var recsToReturn = [];
        recsToReturn = recsToReturn.concat(itemRecommendations.filter((rec) => {
            return !rec.is_excluded;
        }));
        return recsToReturn;
    }, [draftData, campaign]);

    const maxSavings = useMemo(() => {
        if (draftData?.is_excluded) { return 0; }
        return nonExcludedRecommendations.reduce((total, rec) => {
            return total + rec.recommendation_value;
        }, 0);
    }, [draftData]);

    return (
        <Stack spacing={2} px={{ xs: 3, md: 5 }} display='flex' height='100%' overflow='auto' >
            <Stack direction="row" justifyContent="space-between" alignItems='center'>
                <Stack width='70%'>
                    <Typography variant='heavy' sx={{ fontSize: '20px', letterSpacing: '0.18px' , fontWeight:'700'}}>
                        Preview of Optimizations for {draftData?.card.name}
                    </Typography>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>
                        Dr. {draftData?.card.doctor.first_name} {draftData?.card.doctor.last_name}
                    </Typography>
                </Stack>
                { draftData?.status === 'preview' &&
                    <Button variant={draftData?.is_excluded ? 'outlined' : 'contained'} onClick={toggleExcludeDraft}>
                        {draftData?.is_excluded ? 'Include Card in Campaign' : 'Exclude Card from Campaign'}
                    </Button>
                }
            </Stack>
            <Stack direction='row' spacing={2}>
                <StatisticTile title='Estimated Max. Savings' value={maxSavings / 100} formatAsDollar />
                <StatisticTile title='Current Card Efficiency' value={draftData?.efficiency * 100} formatAsPercent />
                <StatisticTile title='Number of Optimizations' value={nonExcludedRecommendations?.length} />
            </Stack>
            <Stack spacing={4} pb={4}>
                {Object.keys(pickList).map((category) => (
                    <Stack key={category} spacing={2}>
                        <Typography variant='heavy' sx={{ fontSize: '20px', letterSpacing: '0.18px' , fontWeight:'700'}}>
                            {category}
                        </Typography>
                        <Stack spacing={2} mt={1}>
                            {pickList[category].map((item) => (
                                <Stack>
                                    <ItemEntry key={item.id} item={item} card={draftData?.card} />
                                    <Stack mx={1} divider={<Divider orientation='horizontal' flexItem style={{ borderColor: 'rgba(0, 0, 0, 0.35)' }} />}>
                                        { getAssociatedRecommendations(item).map((rec) => (
                                            <PreviewOptimization 
                                                key={rec.id}
                                                recommendation={rec}
                                                draft={draftData}
                                            />
                                        ))}
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
}

export default DraftPreview;
