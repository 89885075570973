import { Button, Dialog, DialogContentText, Stack, Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { useGetTotalSavingsSummaryQuery } from '../../api/physicianDashboardSlice';
import Confetti from 'react-dom-confetti';
import CongratsModalBG from '../../../assets/CongratsModalBG.png';
import SummaryImpact from './SummaryImpact';

function CompleteModal(props) {
    const navigate = useNavigate();
    const theme = useTheme();
    const [confettiActive, setConfettiActive] = useState(false);

    const confettiConfig = {
        angle: 90,
        spread: 360,
        startVelocity: '30',
        elementCount: '500',
        dragFriction: 0.12,
        duration: '3000',
        stagger: '0',
        width: '10px',
        height: '10px',
        perspective: '500px',
        colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']
    };

    const { 
        data: savingsSummary, 
        isLoading 
    } = useGetTotalSavingsSummaryQuery({ 
        physicianId: props.physician.id,
        campaignId: props.campaignId
    }, { 
        skip: !props.open,
        refetchOnMountOrArgChange: true
    });

    function handleReturnToHeadline() {
        navigate(`/physicianDashboard/headline/${props.campaignId}`);
    }

    function handleCloseModal() {
        props.setOpen(false);
    }

    // Everytime the modal is opened, this activates the confetti.
    useEffect(() => {setConfettiActive(true)}, []);

    return (
        <Dialog open={props.open} fullScreen>
            <Stack p={7} sx={{overflow: 'hidden', height: '100%', textAlign: 'center', justifyContent: 'space-between', alignItems: 'center', backgroundImage: `url(${CongratsModalBG})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat'}}>

                {/* Title - Confetti and Name */}
                <Stack sx={{alignItems: 'center'}}>
                    <Confetti active={confettiActive} config={confettiConfig} />
                    <Typography variant='bold' sx={{fontSize: '18px', letterSpacing: '1.2px' }}>Congratulations,<br></br> Dr. {props.physician.last_name}!</Typography>
           
                    {/* Section 1 - Cost Reduction or Compliance Savings*/}
                    { props.variant === 'compliance' && 
                <>
                    <DialogContentText>Your changes have eliminated</DialogContentText>
                    <Typography variant='bold' sx={{fontSize: '20px', color: `${theme.palette.blue.heavy}`, letterSpacing: '1.2px' }}>
                        { isLoading 
                            ? <Skeleton variant='rectangle'  width={70} height={30} /> 
                            : <NumericFormat 
                                value={savingsSummary?.compliance_savings / 100}
                                thousandSeparator={true}
                                decimalScale={0}
                                displayType={'text'}
                                prefix={'$'}
                            />
                        }</Typography>
                    <DialogContentText>of your annual out-of-contract spend</DialogContentText>
                </>
                    }
                    { props.variant != 'compliance' && 
                <>
                    <DialogContentText>Your changes have saved an estimated</DialogContentText>
                    <Typography variant='bold' sx={{fontSize: '20px', color: `${theme.palette.blue.heavy}`, letterSpacing: '1.2px' }}>
                        { isLoading 
                            ? <Skeleton variant='rectangle'  width={70} height={30} /> 
                            : <NumericFormat 
                                value={savingsSummary?.savings / 100}
                                thousandSeparator={true}
                                decimalScale={0}
                                displayType={'text'}
                                prefix={'$'}
                            />
                        }</Typography>
                    <DialogContentText>per year</DialogContentText>
                </>
                    }
                </Stack>

                {/* Section 2 - Fewer Items or Swaps or Compliance */}
                <SummaryImpact variant={props.variant} savingsSummary={savingsSummary} />

                {/* Ending Text and Buttons */}
                <Stack spacing={2} alignItems='center' width={{xs: '100%', sm: '70%', md: '50%'}}>
                    <DialogContentText>
                    Thank you for reducing waste, saving time, and increasing efficiency in your practice.
                    </DialogContentText>
                    <Button variant='blueContained' fullWidth onClick={handleReturnToHeadline} >
                    Main Dashboard
                    </Button>
                    <Button onClick={handleCloseModal} fullWidth variant='text'>
                        { (props.variant === 'swaps' || props.variant === 'compliance') && 
                        <DialogContentText>
                            Return to swaps
                        </DialogContentText>
                        }
                        { props.variant === 'standard' && 
                        <DialogContentText>
                            Return to card
                        </DialogContentText>
                        }
                    </Button>
                </Stack>
            
            </Stack>
        </Dialog>
    );
}

export default CompleteModal;