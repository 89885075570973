import React, {useState} from 'react';
import { useUpdateItemDetailsMutation } from '../../api/itemDetailSlice';
import { Stack, TextField, Typography, Button, IconButton } from '@mui/material';
import { EditableDetailBox, StyledDetailBox } from '../Card/DetailBox';
import { Edit } from '@mui/icons-material';
import { useTheme } from '@mui/system';
import Linkify from 'linkify-react';

function ItemDetailBox(props) {
    const theme = useTheme();
    const [isEditing, setIsEditing] = useState(false);
    const [title, setTitle] = useState(props.title);
    const [content, setContent] = useState(props.content);
    const [updateItemDetails] = useUpdateItemDetailsMutation();

    function handleCancelClick() {
        setIsEditing(false);
    }

    const handleDeleteClick = async() => {
        var details = Object.assign({}, props.item.details);
        delete details[props.title];

        await updateItemDetails({
            item: props.item,
            details: details
        });
    }

    const handleSave = async() => {
        var details = Object.assign({}, props.item.details);
        if (!(title in details)) {
            delete details[props.title];
        }
        
        details[title] = content;
        
        await updateItemDetails({
            item: props.item,
            details: details
        });
        setIsEditing(false);
    }

    return (
        <Stack>
            { props.canEdit && !isEditing && 
                <EditableDetailBox p={2}>
                    <IconButton onClick={() => setIsEditing(true)} aria-label='edit' size='small' className='editIcon' sx={{ display: { xs: 'initial', lg: 'none' }  }}>
                        <Edit fontSize='small'/>
                    </IconButton>
                    <Stack spacing={1}>
                        <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>{title}</Typography>
                        <Typography variant='heavy' sx={{ fontSize: '14px' }}>
                            <pre style ={{ fontFamily: 'inherit', whiteSpace: 'pre-line' }}>
                                <Linkify options={{ target: 'blank' }}>
                                    {content}
                                </Linkify>
                            </pre>
                        </Typography>
                    </Stack>
                </EditableDetailBox>
            }
            { props.canEdit && isEditing && 
                <EditableDetailBox p={2}>
                    <Stack spacing={1}>
                        <TextField
                            sx={{ width: '100%', whiteSpace: 'pre-line', backgroundColor: `${theme.palette.background.white}` }} 
                            value={title} 
                            onChange={(event) => setTitle(event.target.value)} 
                        />
                        <TextField 
                            multiline 
                            sx={{ width: '100%', whiteSpace: 'pre-line', backgroundColor: `${theme.palette.background.white}` }} 
                            value={content} 
                            onChange={(event) => setContent(event.target.value)} 
                        />
                        <Stack direction='row' spacing={2} justifyContent='end'>
                            <Button variant='text' onClick={handleCancelClick} style={{ color: `${theme.palette.subtext.main}` }}>Cancel</Button>
                            <Button variant='text' onClick={handleDeleteClick} color='error'>Delete</Button>
                            <Button variant='text' onClick={handleSave} style={{ color: `${theme.palette.blue.heavy}` }}>Save</Button>
                        </Stack>
                    </Stack>
                </EditableDetailBox>
            }
            { !props.canEdit && 
                <StyledDetailBox p={2}>
                    <Stack spacing={1}>
                        <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>{title}</Typography>
                        <Typography variant='heavy' sx={{ fontSize: '14px' }}>
                            <pre style={{ fontFamily: 'inherit', whiteSpace: 'pre-line' }}>
                                <Linkify options={{ target: 'blank' }}>
                                    {content}
                                </Linkify>
                            </pre>
                        </Typography>
                    </Stack>
                </StyledDetailBox>
            }
        </Stack>
    );
}

export default ItemDetailBox;
