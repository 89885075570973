import { Stack, Typography, Switch } from '@mui/material';
import React from 'react';
import { useGetPartnerConfigsQuery } from '../../../api/apiSlice';

function HospitalSiteFeatures(props) {
    const {
        data: possibleConfigs = []
    } = useGetPartnerConfigsQuery();

    function handleToggleConfig(config) {
        const configs = props.hospitalData.configurations.slice();

        if (isConfigActive(config)) {
            configs.splice(configs.findIndex((c) => c.id === config.id), 1);
        } else {
            configs.push(config);
        }

        props.setHospitalData({
            ...props.hospitalData,
            configurations: configs
        });
    }

    function isConfigActive(config) {
        return props.hospitalData.configurations.find((c) => c.id === config.id) !== undefined;
    }

    return (
        <Stack p={2} spacing={2}>
            { possibleConfigs.map((config) => (
                <Stack direction='row' justifyContent='space-between' alignItems='center' key={config.id}>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>{config.name}: <strong>{isConfigActive(config) ? 'Enabled' : 'Disabled'}</strong></Typography>
                    <Switch checked={isConfigActive(config)} onChange={() => handleToggleConfig(config)} />
                </Stack>
            ))}
        </Stack>
    );
}

export default HospitalSiteFeatures;
