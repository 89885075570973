import { apiSlice } from './apiSlice';
import { NotificationActions } from './notificationSlice';

export const userSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUsers: builder.query({
            query: ({hospitalId, page}) => `/hospitals/${hospitalId}/users${page ? `?page=${page}` : ''}`,
            providesTags: (result = [], error, arg) =>
                result.data ?
                    [
                        ...result.data.map(({ id }) => ({ type: 'User', id })),
                        { type: 'User', id: 'PARTIAL-LIST' }
                    ] :
                    [{ type: 'User', id: 'PARTIAL-LIST' }],
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ 
                        message: 'Could not load users at this time. Please try again later.' 
                    }));
                }  
            },
        }),
        getUser: builder.query({
            query: (userId) => `/users/${userId}`,
            providesTags: (result, error, arg) => [{ type: 'User', id: arg }]
        }),
        getCurrentUser: builder.query({
            query: () => '/user'
        }),
        createUser: builder.mutation({
            query: ({ hospitalId, firstName, lastName, email, password, externalId , roles}) => ({
                url: `/hospitals/${hospitalId}/users`,
                method: 'POST',
                body: {
                    first_name: firstName,
                    last_name: lastName,
                    name: firstName + ' ' + lastName,
                    email: email,
                    password: password,
                    external_id : externalId,
                    roles : roles
                }
            }),
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    const { data: newUser } = await queryFulfilled;
                    dispatch(NotificationActions.addSuccessNotification({ message: `New user ${newUser.name} successfully created.` }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not create user at this time. Please try again later.' }));
                }
            },
            invalidatesTags: ['User']
        }),
        updateUser: builder.mutation({
            query: ({ user, firstName, lastName, email, emailUnsubscribed, password, roles, hospitals }) => ({
                url: `/users/${user.id}`,
                method: 'PATCH',
                body: {
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    email_unsubscribed: emailUnsubscribed,
                    password: password,
                    roles: roles,
                    hospitals: hospitals,
                }
            }),
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedUser } = await queryFulfilled;
                    dispatch(
                        apiSlice.util.updateQueryData('getUsers', updatedUser.hospital.id, existingUsers => {
                            let matchingUser = existingUsers.find(u => u.id === updatedUser.id);
                            if (matchingUser) {
                                Object.assign(matchingUser, updatedUser);
                            }
                        })
                    );
                    dispatch(
                        apiSlice.util.updateQueryData('getUser', updatedUser.id.toString(), existingUser => {
                            Object.assign(existingUser, updatedUser);
                        })
                    )
                    dispatch(NotificationActions.addSuccessNotification({ message: `User ${updatedUser.name} updated successfully.` }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not update user at this time. Please try again later.' }));
                }
            },
            invalidatesTags: ['User']
        }),
        deleteUser: builder.mutation({
            query: ({ user }) => ({
                url: `/users/${user.id}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ user }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(NotificationActions.addSuccessNotification({ message: `User ${user.name} disabled successfully.` }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not delete user at this time. Please try again later.' }));
                }
            },
            invalidatesTags: ['User']
        }),
        forceUserPasswordReset: builder.mutation({
            query: ({ user }) => ({
                url: '/requestReset',
                method: 'POST',
                body: {
                    email: user.email
                }
            }),
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'User password reset requested successfully.'
                    }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification());
                }
            }
        }),
        generatePasswordResetLink: builder.mutation({
            query: ({ user }) => ({
                url: '/generateResetLink',
                method: 'POST',
                body: {
                    email: user.email
                }
            }),
        }),
        getCardOwnerSpecialties: builder.query({
            query: (hospitalId) => `/hospitals/${hospitalId}/cardOwnerSpecialties`,
        }),
        unsubscribeUserFromEmails: builder.mutation({
            query: ({ userId }) => ({
                url: `/users/${userId}`,
                method: 'PATCH',
                body: {
                    email_unsubscribed: true
                }
            }),
        })
    })
})

export const {
    useGetUsersQuery,
    useGetUserQuery,
    useGetCurrentUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useForceUserPasswordResetMutation,
    useGeneratePasswordResetLinkMutation,
    useGetCardOwnerSpecialtiesQuery,
    useUnsubscribeUserFromEmailsMutation,
} = userSlice;