import React from 'react';
import { Stack } from '@mui/material';
import { Typography } from '@mui/material';
import PreviousCampaignBox from './PreviousCampaignBox';
import { theme } from '../../../theme';

function PastCampaignTab(props) {

    if (props.campaigns) {
        return (
            <Stack px={2} spacing={2} pb={5}>
                { props.campaigns.map((campaign) => (
                    <PreviousCampaignBox key={campaign.id} campaign={campaign} />
                ))}
                <Stack px={5} py={1} sx={{ textAlign: 'center' }}>
                    <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}> Previous Opportunities </Typography>
                    <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}> are limited to the last 90 days</Typography>
                </Stack>
            </Stack>
        )
    }
    else {
        return (
            <Stack px={5} sx={{ textAlign: 'center'}}>
                <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}> Previous Opportunities from the last 90 days appear here after you complete your review and the clinical team implements your requested changes</Typography>
            </Stack>
        )
    }
}

export default PastCampaignTab;

                        