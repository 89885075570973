import { Cancel, Check, Edit } from '@mui/icons-material';
import { IconButton, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import { useUpdateCategoryMutation } from '../../../api/categorySlice';

function Category(props) {
    const theme = useTheme();
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState(props.category.name);
    const [order, setOrder] = useState(props.category.order ?? '');
    const [updateCategory] = useUpdateCategoryMutation();

    const handleConfirmChange = async() => {
        await updateCategory({
            category: props.category,
            requestData: {
                name: name,
                order: order
            }
        });
        setIsEditing(false);
    }

    function handleCancel() {
        setName(props.category.name);
        setOrder(props.category.order ?? '');
        setIsEditing(false);
    }

    if (isEditing) {
        return (
            <Stack direction='row' alignItems='center' justifyContent='space-between' p={2} sx={{ backgroundColor: `${theme.palette.background.white}`, borderRadius: '4px' }}>
                <TextField 
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <Stack spacing={2} direction='row' alignItems='center'>
                    <TextField
                        value={order}
                        onChange={(e) => setOrder(e.target.value)}
                        sx={{ width: '50px' }}
                        inputProps={{ style: { textAlign: 'center'} }}
                    />
                    <Stack spacing={1}>
                        <IconButton onClick={handleConfirmChange}>
                            <Check />
                        </IconButton>
                        <IconButton onClick={handleCancel}>
                            <Cancel />
                        </IconButton>
                    </Stack>
                </Stack>
            </Stack>
        );
    } else {
        return (
            <Stack direction='row' alignItems='center' justifyContent='space-between' p={2} sx={{ backgroundColor: `${theme.palette.background.white}`, borderRadius: '4px' }}>
                <Typography variant='normal' sx={{ fontSize: '14px' }}>
                    {name}
                </Typography>
                <Stack spacing={2} direction='row' alignItems='center'>
                    <Typography variant='normal' sx={{ fontSize: '14px' }}>
                        {order}
                    </Typography>
                    <IconButton onClick={() => setIsEditing(true)}>
                        <Edit fontSize="small"/>
                    </IconButton>
                </Stack>
            </Stack>
        )
    }
}

export default Category;
