import { Stack } from '@mui/material';
import React from 'react';
import ItemDetail from './ItemDetail';

function ItemChipSection(props) {
    const itemSpend = props.item.cost * (props.item.open_quantity + (props.item.hold_quantity * 0.3)) * (props.yearlyUsage ?? 1);

    return (
        <Stack direction='row' spacing={1}>
            { Object.keys(props.recommendation.context).map((context, index) => (
                <ItemDetail 
                    key={index} 
                    type={context} 
                    data={props.recommendation.context[context]}
                />
            ))}
            { props.recommendation.type !== 'addition' && 
                <ItemDetail key='cost' type='cost' data={itemSpend / 100} />
            }
        </Stack>
    )
}

export default ItemChipSection;
