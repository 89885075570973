import React from 'react';
import { useTheme } from '@mui/system';
import { Stack, Typography } from '@mui/material';

function ItemEntry(props) {
    const theme = useTheme();
    
    return (
        <Stack direction='row' spacing={2} sx={{ borderRadius: '8px' }}>
            <Stack px={2} py={1} direction='row' justifyContent='space-between' alignItems='center' sx={{boxShadow: 3, backgroundColor: '#ffffff', width: '100%', borderRadius : 'inherit'  }}>
                <Stack width='55%'>
                    <Typography noWrap variant='normal' sx={{ fontSize: '16px' }}>
                        { props.item.is_active === false && 
                            <>
                                <Typography variant='normal' color='error' sx={{ fontSize: '16px', opacity: 1.0 }}>Inactive</Typography> 
                                <Typography variant='normal' sx={{ fontSize: '16px' }}> - </Typography> 
                            </>
                        }
                        {props.item.name ? props.item.name : props.item.external_name}
                    </Typography>
                    <Typography variant='normal' sx={{ fontSize: '14px', color: `${theme.palette.subtext.main}` }}>
                        {props.item.vendor.name + ' - ' + props.item.part_number}
                    </Typography>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <Stack pr={2} direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0.5, md: 2 }} alignItems='center' justifyItems={{ xs: 'end', md: 'center' }}>
                        <Typography variant='bold' sx={{ fontSize: '16px', color: `${theme.palette.green.main}` }}>
                            Open: <Typography display='inline' sx={{ color: 'black' }}>{props.item.open_quantity}</Typography>
                        </Typography>
                        <Typography variant='bold' sx={{ fontSize: '16px', color: `${theme.palette.hold.main}`}}>
                            Hold: <Typography display='inline' sx={{ color: 'black' }}>{props.item.hold_quantity}</Typography>
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default ItemEntry;
