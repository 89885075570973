import { Dialog, DialogTitle } from '@mui/material';
import { DialogContent } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import ItemSearchDialog from '../ItemSearch/ItemSearchDialog';
import { useSwapItemsMutation } from '../../../api/cardSlice';
import SwapItemReview from './SwapItemReview';
import ItemNameDisplay from '../../CustomComponents/ItemNameDisplay';
import { NumericFormat } from 'react-number-format';
import { Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import { SwapVertRounded } from '@mui/icons-material';

function SwapItemDialog(props) {
    const theme = useTheme();
    const [selectedItem, setSelectedItem] = useState();
    const [swapItems] = useSwapItemsMutation();

    function handleSelect(item) {
        setSelectedItem(item);
    }

    const handleSwapItems = async (openQuantity, holdQuantity) => {
        await swapItems({
            card: props.card,
            item1: props.item,
            item2: selectedItem, 
            open_quantity: openQuantity,
            hold_quantity: holdQuantity
        });
        props.handleClose();
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} maxWidth='md' fullWidth sx={{
            '& .MuiDialog-container': {
                alignItems: 'flex-start',
            },
            mt: 2,
        }}>
            <DialogTitle>
                <Stack>
                    <Typography variant='heavy'>Replace Item on Card</Typography>
                    <Typography variant='normal' sx={{ fontSize: '14px', color: `${theme.palette.subtext.main}` }}>
                        Replace the selected item on this card with another item from inventory.
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Stack spacing={0.5}>
                        <Typography variant='heavy' sx={{ fontSize: '16px', color: `${theme.palette.subtext.main}` }}>
                            Current Item
                        </Typography>
                        <Stack p={1} sx={{ border: '1px solid #0000001F', borderRadius: '8px' }}>
                            <ItemNameDisplay item={props.item} />
                            <Typography variant='normal' sx={{ fontSize: '14px', color: `${theme.palette.subtext.main}` }}>
                                {props.item.vendor.name} - {props.item.part_number} | <NumericFormat value={props.item.cost / 100}
                                    thousandSeparator={true}
                                    displayType={'text'}
                                    prefix={'$'}
                                    decimalScale={2}
                                    fixedDecimalScale={true} />
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack>
                        <SwapVertRounded style={{alignSelf: 'center', color: `${theme.palette.subtext.main}`}} fontSize='medium' />
                        <Stack spacing={0.5}>
                            <Typography variant='heavy' sx={{ fontSize: '16px', color: `${theme.palette.subtext.main}` }}>
                                Replacement Item
                            </Typography>
                            { selectedItem ? 
                                <SwapItemReview 
                                    currentItem={props.item}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                    handleSwapItems={handleSwapItems}
                                /> :
                                <ItemSearchDialog
                                    card={props.card}
                                    handleSelect={handleSelect}
                                />
                            }
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default SwapItemDialog;
