import { Add, Remove } from '@mui/icons-material';
import { Button, ButtonGroup } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';

function QuantityButtonGroup(props) {
    const theme = useTheme();

    function handleIncrease() {
        props.setQuantity(props.quantity + 1);
    }

    function handleDecrease() {
        props.setQuantity(props.quantity - 1);
    }

    return (
        <ButtonGroup variant='outlined' disableElevation>
            <Button disabled={props.quantity === 0} onClick={handleDecrease} style={{ backgroundColor: `${theme.palette.background.white}`, color: `${theme.palette.subtext.main}`, borderColor: '#0000001F' }}>
                <Remove />
            </Button>
            <Button onClick={handleIncrease} style={{ backgroundColor: `${theme.palette.background.white}`, color: `${theme.palette.subtext.main}`, borderColor: '#0000001F' }}>
                <Add />
            </Button>
        </ButtonGroup>
    )
}

export default QuantityButtonGroup;
