import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { NotificationActions } from './notificationSlice';

var urlPrefix = '';

switch(process.env.ENVIRONMENT_SETTINGS) {
case 'local':
    urlPrefix = 'http://localhost:8000';
    break;
case 'staging':
    urlPrefix = 'https://staging.docsihealth.com';
    break;
case 'demo':
    urlPrefix = 'https://demo.docsihealth.com';
    break;
case 'production':
    urlPrefix = 'https://api.docsihealth.com';
    break;
}

const buildBaseQuery = () => {
    const query = fetchBaseQuery({
        baseUrl: `${urlPrefix}/api`,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('reviewToken');
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            headers.set('accept', 'application/json');
            return headers;
        },
        credentials: 'include'
    });
    return async (args, api, extraOptions) => {
        const { error, data } = await query(args, api, extraOptions);
        if (error) {
            if (error.status === 401) {
                localStorage.removeItem('reviewToken');
                window.location.href = '/login';
            }
            return { error: { status: error.status, data: error.data } };
        }
        return { data };
    }
}

export const staffReviewSlice = createApi({
    reducerPath: 'staffReviewApi',
    baseQuery: buildBaseQuery(),
    tagTypes: ['CardsForReview', 'CompletedReviews'],
    endpoints: (builder) => ({
        getCardsForReview: builder.query({
            query: (userId) => `/users/${userId}/staffReviews`,
            providesTags: (result = [], error, arg) => [
                ...result.map(({ id }) => ({ type: 'CardsForReview', id }))
            ]
        }),
        getReview: builder.query({
            query: (draftId) => `/drafts/${draftId}/staffReview`,
            providesTags: (result = [], error, arg) => [
                { type: 'CardsForReview', id: arg },
            ],
            keepUnusedDataFor: 0,
        }),
        updateReview: builder.mutation({
            query: ({ review, draftId, recommendation, newStatus, notes }) => ({
                url: `/staffReviews/${review.id}`,
                method: 'PATCH',
                body: {
                    status: newStatus,
                    notes: notes,
                }
            }),
            async onQueryStarted({ review, draftId, recommendation, newStatus, notes }, { dispatch, queryFulfilled, queryRejected }) {
                try {
                    const { data: updatedReview } = await queryFulfilled;
                    dispatch(staffReviewSlice.util.updateQueryData('getReview', draftId, draft => {
                        let existingRecIndex = draft.recommendations[recommendation.item.category].find((rec) => rec.id === recommendation.id);
                        if (existingRecIndex) {
                            let reviewIndex = existingRecIndex.review.findIndex((rev) => rev.id === review.id);
                            Object.assign(existingRecIndex.review[reviewIndex], updatedReview);
                        }
                    }));
                } catch (error) {
                    if (error.status === 403) {
                        dispatch(NotificationActions.addErrorNotification({
                            message: 'You do not currently have permission to update this review.'
                        }));
                    } else {
                        dispatch(NotificationActions.addErrorNotification({
                            message: 'Could not submit review at this time. Please try again later.'
                        }));
                    }
                }
            }
        }),
        updateDraft: builder.mutation({
            query: ({ draftId, status }) => ({
                url: `/drafts/${draftId}`,
                method: 'PATCH',
                body: {
                    status: status,
                    staff_review_completion_time: new Date().toISOString()
                }
            }),
            async onQueryStarted({ draftId, status }, { dispatch, queryFulfilled, queryRejected }) {
                try {
                    const { data: updatedDraft } = await queryFulfilled;
                    dispatch(staffReviewSlice.util.updateQueryData('getReview', draftId.toString(), draft => {
                        Object.assign(draft, updatedDraft);
                    }));
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'Review changes submitted successfully.'
                    }));
                } catch (error) {
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Could not submit changes at this time. Please try again later.'
                    }));
                }
            },
            invalidatesTags: (result, error, { draftId }) => [{ type: 'CardsForReview', id: draftId }]
        }),
        createReviewFlag: builder.mutation({
            query: ({ draftId, body }) => ({
                url: `/drafts/${draftId}/reviewFlags`,
                method: 'POST',
                body: body
            }),
            async onQueryStarted({ draftId, flag }, { dispatch, queryFulfilled, queryRejected }) {
                try {
                    const { data: newFlag } = await queryFulfilled;
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'Flag submitted successfully.'
                    }));
                } catch (error) {
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Could not add flag at this time. Please try again later.'
                    }));
                }
            }
        }),
        getCompletedReviews: builder.query({
            query: (userId) => `/users/${userId}/completedStaffReviews`,
            providesTags: (result = [], error, arg) => [
                ...result.map(({ id }) => ({ type: 'CompletedReviews', id }))
            ]
        }),
        getCurrentUser: builder.query({
            query: () => '/user'
        }),
        createDraftCheckoutLog: builder.mutation({
            query: ({ draftId, action, reason }) => ({
                url: `/drafts/${draftId}/checkoutLogs`,
                method: 'POST',
                body: {
                    action: action,
                    reason: reason,
                }
            })
        }),
        logUserAction: builder.mutation({
            query: ({ action, data }) => ({
                url: '/userActions',
                method: 'POST',
                body: {
                    action: action,
                    data: data
                }
            }),
        }),
    })
});

export const {
    useGetCardsForReviewQuery,
    useGetReviewQuery,
    useUpdateReviewMutation,
    useUpdateDraftMutation,
    useCreateReviewFlagMutation,
    useGetCompletedReviewsQuery,
    useGetCurrentUserQuery,
    useCreateDraftCheckoutLogMutation,
    useLogUserActionMutation,
} = staffReviewSlice;
