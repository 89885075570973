import { Stack, Typography } from '@mui/material';
import { DoneRounded, NavigationRounded } from '@mui/icons-material';
import React from 'react';
import { Box, useTheme } from '@mui/system';

function TaskHeader(props) {
    const theme = useTheme();

    return (
        <Stack direction='row' spacing={1} alignItems='center'>
            <NavigationRounded sx={{ transform: 'rotate(90deg)', fontSize: '12px' }} />
            <Typography variant='heavy' sx={{ fontSize: '14px' }}>{props.title}</Typography>
            <Box display='grid' borderRadius={1} width={20} height={20} textAlign='center' alignContent='center' justifyContent='center' sx={{ background: `${theme.palette.green.taskBackground}`, color: `${theme.palette.green.taskText}` }}>
                { props.taskCount > 0 ?
                    <Typography variant='heavy' sx={{ fontSize: '16px' }}>
                        {props.taskCount}
                    </Typography> :   
                    <DoneRounded fontSize='small'/>
                }
            </Box>
        </Stack>
    );
}

export default TaskHeader;