import { Upload } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Paper, Snackbar, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { usePostIngestionUploadMutation } from '../../../api/ingestionSlice';

function IngestionBox(props) {
    const [files, setFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [uploadFile] = usePostIngestionUploadMutation();

    const submitFileUpload = async() => {
        setIsUploading(true);
        setIsSuccess(false);
        setIsError(false);
        var requestData = new FormData();
        requestData.append('hospital_id', props.hospital.id);
        requestData.append('organization_id', props.hospital.organization_id)
        requestData.append('data', files[0]);

        await uploadFile({
            data: requestData,
            token: props.token,
            url: props.uploadUrl
        }).unwrap().then(() => {
            setIsSuccess(true);
        }).catch(() => {
            setIsError(true);
        });

        setFiles([]);
        setIsUploading(false);
    }

    function handleAlertClose() {
        setIsSuccess(false);
    }

    function handleErrorClose() {
        setIsError(false);
    }

    return (
        <Paper elevation={2} square sx={{ borderRadius: '4px', width: '18%' }}>
            <Stack p={4} spacing={2} alignItems='center' textAlign='center'>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}>{props.title}</Typography>
                { files.length > 0 ? 
                    <Typography variant='normal' sx={{ fontSize: '14px' }}>{files[0].name}</Typography> :
                    <Button variant='outlined' startIcon={<Upload />} component='label'>
                        Choose File
                        <input hidden accept='.csv' type='file' onChange={(e) => setFiles(e.target.files)} />
                    </Button>
                }
                { files.length > 0 && 
                    <Stack spacing={1}>
                        <Stack direction='row' alignItems='center' spacing={2}>
                            <Button variant='outlined' onClick={() => setFiles([])}>
                                Cancel
                            </Button>
                            <LoadingButton 
                                loading={isUploading} 
                                variant='contained' 
                                onClick={submitFileUpload}
                            >
                                Upload
                            </LoadingButton>
                        </Stack>
                        { props.uploadUrl === 'cardItemUpload' && 
                            <Typography variant='light' sx={{ fontSize: '14px' }}>
                                Note: Card items not included in upload will be removed if presently on a card included in upload file.
                            </Typography>
                        }
                    </Stack>
                }
                <Snackbar
                    open={isSuccess}
                    autoHideDuration={4000}
                    onClose={handleAlertClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity='success'>File Upload Successful!</Alert>
                </Snackbar>
                <Snackbar
                    open={isError}
                    autoHideDuration={4000}
                    onClose={handleErrorClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity='error'>File Upload Failed. Try Again or Fix File.</Alert>
                </Snackbar>
            </Stack>
        </Paper>
    );
}

export default IngestionBox;
