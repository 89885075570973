import { Stack, Typography } from '@mui/material';
import React from 'react';
import { NumericFormat } from 'react-number-format';

function ItemDetails(props) {
    return (
        <Stack spacing={1.5} p={2}>
            <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>Item Details</strong></Typography>
            <Typography variant='normal' sx={{ fontSize: '14px' }}><strong>Description: </strong>{props.item.name}</Typography>
            <Typography variant='normal' sx={{ fontSize: '14px' }}><strong>Manufacturer: </strong>{props.item.vendor.name}</Typography>
            <Typography variant='normal' sx={{ fontSize: '14px' }}><strong>Reference Number: </strong>{props.item.part_number}</Typography>
            <Typography variant='normal' sx={{ fontSize: '14px' }}><strong>Unit Price: </strong>
                <NumericFormat 
                    value={props.item.cost / 100}
                    thousandSeparator={true}
                    displayType='text'
                    prefix='$'
                    decimalScale={2}
                    fixedDecimalScale={true}
                />
            </Typography>
        </Stack>
    )
}

export default ItemDetails;