import React from 'react';
import { Select, FormControl,InputLabel,MenuItem } from '@mui/material';
import { useGetOrganizationsQuery } from '../api/apiSlice';
import { Box } from '@mui/system';

function OrganizationDropdown(props){
    

    const { 
        data: organizations = [], } = useGetOrganizationsQuery();

    const sortedOrganizations = organizations.slice().sort((a, b) => a.name.localeCompare(b.name));

    function handleDropdownChange(selectedOrganizationId) {
        props.setData({
            ...props.currentData,
            organization_id: selectedOrganizationId
        })
    }

    return ( 
        <Box>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel>Select An Organization</InputLabel>
                <Select
                    value={props.currentData?.organization_id}
                    disabled={!props.isCreating}
                    label="Select An Organization"
                    onChange={(e) => handleDropdownChange(e.target.value)}
                    fullWidth
                >
                    { sortedOrganizations.map((organization) => {
                        return <MenuItem key={organization.id} value={organization.id}>{organization.name}</MenuItem>
                    })}
                </Select>
            </FormControl>    
        </Box>
        
    );
}

export default OrganizationDropdown;