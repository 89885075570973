import { Alert, Button, InputLabel, Paper, Snackbar, Stack, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteUserMutation, useForceUserPasswordResetMutation } from '../../api/userSlice';
import DisableConfirmation from './DisableConfirmation';
import PasswordResetConfirmation from './PasswordResetConfirmation';

function UserDetails(props) {
    const [firstName, setFirstName] = useState(props.user?.first_name);
    const [lastName, setLastName] = useState(props.user?.last_name);
    const [email, setEmail] = useState(props.user?.email);
    const [externalId, setExternalId] = useState();
    const [confirmDisableUser] = useDeleteUserMutation();
    const [forcePasswordReset] = useForceUserPasswordResetMutation();
    const navigate = useNavigate();
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isPasswordResetConfirmationOpen, setIsPasswordResetConfirmationOpen] = useState(false);
    const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false);
    const showCancelButton = firstName !== props.user?.first_name || lastName !== props.user?.last_name || email !== props.user?.email;

    const disableUser = async () => {
        await confirmDisableUser({ user: props.user });
        navigate('/users');
    }

    const forceUserPasswordReset = async () =>  {
        await forcePasswordReset({ user: props.user }).unwrap().then(() => {
            setIsPasswordResetSuccess(true);
        });
    }

    return (
        <Paper elevation={2} sx={{ width: { xs: '100%', md: '50%', lg: '40%' } }}>
            <Stack spacing={4} p={4}> 
                <Box>
                    <InputLabel htmlFor="user-first-name">
                        First Name
                    </InputLabel>
                    <TextField 
                        id="user-first-name"
                        variant='outlined' 
                        fullWidth={true}
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                    />
                </Box>
                <Box>
                    <InputLabel htmlFor="user-last-name">
                        Last Name
                    </InputLabel>
                    <TextField 
                        id="user-last-name"
                        variant='outlined' 
                        fullWidth={true}
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                    />
                </Box>
                <Box>
                    { props.isError && 
                        <InputLabel error = {true}  htmlFor='email' sx={{ pb: 1 }}>Email Already In Use</InputLabel>
                    }
                    <InputLabel htmlFor="user-email">
                        Email
                    </InputLabel>
                    <TextField 
                        id="user-email"
                        variant='outlined' 
                        fullWidth={true}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </Box>


                { props.isSuperUser &&
                    <Box>
                        <InputLabel htmlFor="externalid">
                            External ID
                        </InputLabel>
                        <TextField 
                            id="externalid"
                            variant='outlined' 
                            label='Optional External ID' 
                            fullWidth={true}
                            value={externalId}
                            onChange={(event) => setExternalId(event.target.value)}
                        />
                    </Box> 
                }
            </Stack>
            { !props.newUser && 
                <Stack spacing={1} pt={2} px={4} alignItems='flex-start' pb={4}>
                    <PasswordResetConfirmation 
                        open={isPasswordResetConfirmationOpen} 
                        setOpen={setIsPasswordResetConfirmationOpen} 
                        confirmReset={forceUserPasswordReset} 
                    />
                    <Snackbar open={isPasswordResetSuccess} onClose={() => setIsPasswordResetSuccess(false)} autoHideDuration={5000}>
                        <Alert onClose={() => setIsPasswordResetSuccess(false)} severity='success' sx={{ width: '100%' }}>
                            Password Reset Successful
                        </Alert>
                    </Snackbar>
                    <Button variant='text' color='primary' onClick={() => setIsPasswordResetConfirmationOpen(true)}>Force Password Reset</Button>
                    <Button 
                        variant='text' 
                        color='error'
                        onClick={() => setIsConfirmationOpen(true)}
                    >Disable Account</Button>
                    <DisableConfirmation
                        open={isConfirmationOpen}
                        setOpen={setIsConfirmationOpen}
                        confirmDisable={disableUser}
                    />
                </Stack>
            }

            {showCancelButton && 
                <Stack direction='row' pl = {4} pr={2} pb={1} justifyContent= 'space-between'>
                    <Button  color = 'error' onClick = {() => {setFirstName(props.user?.first_name); setLastName(props.user?.last_name); setEmail(props.user?.email) }}>Cancel</Button>
                    <Button 
                        variant='text' 
                        color='primary' 
                        onClick={() => props.saveUser({
                            first_name: firstName,
                            last_name: lastName,
                            email: email,
                            external_id : externalId
                        })}>Save</Button>
                </Stack>
            }
        </Paper>
    );
}

export default UserDetails;