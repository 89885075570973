import { Stack, Typography } from '@mui/material';
import { ChevronRightRounded } from '@mui/icons-material';
import { Box, useTheme } from '@mui/system';
import React from 'react';
import ItemSwapProgress from './ItemSwapProgress';
import TaskHeader from './TaskHeader';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

function ReviewItemSwapsTask(props) {
    const theme = useTheme();
    const navigate = useNavigate();

    const reviewedRecs = useMemo(() => {
        return props.totalRecs - props.activeRecs;
    }, [props.totalRecs, props.activeRecs]);

    function navigateToItemSwaps() {
        navigate(`/physician/${props.cardOwner.id}/swaps/${props.campaign.id}`, { state: {
            physician: props.cardOwner
        }});
    }

    if (props.totalRecs > 0) {
        return (
            <Stack spacing={2}>
                <TaskHeader title='Review Swap Recommendations' taskCount={props.activeRecs} shouldShowCount={props.activeRecs > 0} />
                <Box p={2} onClick={navigateToItemSwaps} sx={{ boxShadow: 2, borderRadius: '4px', background: `${theme.palette.background.white}` }}>
                    <Stack direction='row' alignItems='center' spacing={2}>
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <ItemSwapProgress value={props.totalRecs > 0 ? Math.round((reviewedRecs / props.totalRecs) * 100) : 0} />
                            <Typography variant='light' sx={{ fontSize: '12px' }}>
                                {reviewedRecs} of {props.totalRecs} swaps reviewed
                            </Typography>
                        </Stack>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ width: '100%' }}>
                            <Typography variant='normal' sx={{ fontSize: '12px' }}>
                                You have <strong>{props.activeRecs} supply items </strong>that can be swapped for less expensive or contract compliant alternatives.
                            </Typography>
                            <ChevronRightRounded sx={{ opacity: '0.6' }}/>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        );
    }

    return <></>;
}

export default ReviewItemSwapsTask;