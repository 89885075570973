import React from 'react';
import { Stack, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { theme } from '../../../theme';
import { useMemo } from 'react';
import { NumericFormat } from 'react-number-format';

function PreviousCampaignBox(props) {
    const showCompleted = props.campaign.completion_status === 'completed' || props.campaign.completion_status === 'partial';
    const completionText =  useMemo(() => {
        if (props.campaign.completion_status === 'completed') {
            return 'Review Completed';
        } else if (props.campaign.completion_status === 'partial') {
            return 'Partially Reviewed';
        }
        else {
            return 'Not Reviewed';
        }
    }, [props.campaign.completion_status]);


    const formatStartDate = useMemo(() => {
        let startDate = new Date(props.campaign.start_date);
        return `${startDate.toLocaleString('default', { month: 'long' }) } ${startDate.getFullYear()}`;
    }, [props.campaign.start_date]);

    return (
        <Stack p={2} spacing={1} border={1} sx={{ borderRadius: '4px', borderColor:'#E0E0E0', backgroundColor: 'white' }}  className={`pendo-previous-${props.campaign.campaign_type}-opportunity-summary`}>
            <Stack direction='row' spacing={0.5} alignItems='center'>
                { showCompleted ? <CheckCircleIcon sx={{ color: `${theme.palette.subtext.main}`, fontSize: '1.25rem' }} /> : <CancelIcon sx={{ color: `${theme.palette.subtext.main}`, fontSize: '1.25rem' }} />}
                <Typography variant='heavy' sx={{ fontSize: '18px' }}>{props.campaign.campaign_type === 'compliance' ? props.campaign.campaign_name : props.campaign.hospital.name}</Typography>
            </Stack>
            <Typography variant='normal' sx={{ fontSize: '12px' }}>{props.campaign.campaign_type === 'compliance' ? 'Contract Compliance' : 'Preference Card'} Review - {formatStartDate} </Typography>
            <Typography variant='normal' sx={{ fontSize: '12px' }}> {completionText + ' - '}
                { props.campaign.campaign_type === 'compliance' ? 
                    <strong> {props.campaign.reviewed_swaps} of {props.campaign.total_swaps} Swaps Reviewed</strong> :
                    <strong> 
                        <NumericFormat value={props.campaign.reviewed_savings / 100} prefix={'$'} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={true} />
                        {' Savings / Yr'}
                    </strong>
                }
            </Typography>
        </Stack>
    );
}

export default PreviousCampaignBox;
