import React from 'react';
import { Grid, Stack, Button, Fab } from '@mui/material';
import { ArrowBackRounded, ArrowForwardRounded, Check, Undo } from '@mui/icons-material';
import SegmentedProgressBar from '../../CustomComponents/SegmentedProgressBar';
import { useTheme } from '@mui/system';
import { useMemo } from 'react';

function ItemSwapNavigation(props) {
    const theme = useTheme();

    const lastSwap = useMemo(() => {
        return props.currentIndex === props.swaps.length - 1;
    }, [props.currentIndex, props.swaps]);
    const isComplete = useMemo(() => {
        return lastSwap
            && props.swaps.every((swap) => swap.recommendations.some((rec) => rec.status === 'accepted' || rec.status === 'rejected'));
    }, [props.swaps, props.currentIndex]);
    const maxIndex = useMemo(() => {
        return props.swaps.findLastIndex((swap) => swap.recommendations.some((rec) => rec.status === 'accepted' || rec.status === 'rejected')) + 1;
    }, [props.swaps]);
    const canAdvanceForward = useMemo(() => {
        return (!lastSwap && props.currentIndex < maxIndex) || isComplete;
    }, [props.currentIndex, maxIndex]);
    const canGoBack = useMemo(() => {
        return props.currentIndex > 0;
    }, [props.currentIndex]);

    const declineSwap = (context) => {
        props.declineSwap(context);
    }
    
    return (
        <Grid item xs={12} md={6} spacing={3} p={2} sx={{ zIndex: 2, position: 'fixed', bottom: 0 }}>
            <Stack direction='row' justifyContent='center' alignItems='center' paddingBottom='24px'>
                <Fab onClick={props.navigateBack} size='small' width='2.5em' height='1em' disabled={!canGoBack || props.isAnimating} style={{ backgroundColor: canGoBack ? `${theme.palette.background.white}` : '' }}>
                    <ArrowBackRounded sx={{ color: canGoBack ? `${theme.palette.blue.heavy}` : '' }}/>
                </Fab>
                <Stack direction='row' width='80%' justifyContent='space-around' px={1}>
                    { props.currentStatus === 'open' && 
                        <>
                            <Button onClick={() => declineSwap([])} variant='contained' style={{ color: `${theme.palette.blue.heavy}` }}>
                                Decline
                            </Button>
                            <Button onClick={props.swapItems} disabled={props.cannotSwap} variant='blueContained' style={{ width: 'fit-content' }}>
                                Swap
                            </Button>
                        </>
                    }
                    { props.currentStatus === 'accepted' && 
                        <Button 
                            startIcon={<Undo />} 
                            onClick={props.undoSwap} 
                            variant='contained' 
                            style={{ color: `${theme.palette.blue.heavy}`, boxShadow: 0, width: '90%' }}>
                            Undo Swap
                        </Button>
                    }
                    { props.currentStatus === 'rejected' && 
                        <Button 
                            startIcon={<Undo />} 
                            onClick={props.undoSwap} 
                            variant='contained' 
                            style={{ color: `${theme.palette.blue.heavy}`, boxShadow: 0, width: '90%' }}>
                            Undo Decline
                        </Button>
                    }
                </Stack>
                <Fab onClick={isComplete ? props.handleCompletion : props.navigateForward} size="small" width='2.5em' height='1em' disabled={!canAdvanceForward || props.isAnimating} style={{ backgroundColor: canAdvanceForward ? `${theme.palette.blue.heavy}` : '' }}>
                    { lastSwap
                        ? <Check sx={{ color: `${theme.palette.background.white}` }} />
                        : <ArrowForwardRounded sx={{ color: canAdvanceForward ? `${theme.palette.background.white}` : '' }}/>
                    }
                </Fab>
            </Stack>
            <SegmentedProgressBar selectedIndex={props.currentIndex} data={props.swaps} />
        </Grid>
    );
}

export default ItemSwapNavigation;