import { Add, AddPhotoAlternateRounded } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import React, { useContext } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useAddAuxiliaryItemMutation, useUpdateAuxiliaryItemMutation, useDeleteAuxiliaryItemMutation, useAddAuxiliaryItemImageMutation } from '../../api/cardSlice';
import AddSectionDialog from './AddSectionDialog';
import FreeformCardEntry from './FreeformCardEntry';
import { useGetAuxiliaryItemCategoriesQuery } from '../../api/categorySlice';
import { UserContext } from '../Hooks/useUser';
import ImageCarousel from '../CustomComponents/ImageCarousel';
import UploadImage from '../CustomComponents/UploadImage';

function Instruments(props) {
    const [updateAuxiliaryItem] = useUpdateAuxiliaryItemMutation();
    const [addAuxiliaryItem] = useAddAuxiliaryItemMutation();
    const [deleteAuxiliaryItem] = useDeleteAuxiliaryItemMutation();
    const [isAddSectionOpen, setIsAddSectionOpen] = useState(false);
    const [isAddingImage, setIsAddingImage] = useState(false);
    const [addImage, { isLoading: isUploadingImage }] = useAddAuxiliaryItemImageMutation();
    const { hasCardManagement } = useContext(UserContext);
    
    const { data: auxItemCategories = [] } = useGetAuxiliaryItemCategoriesQuery(props.card.owner.hospital.id);
    
    const saveData = async(auxiliaryItem, newText) => {
        await updateAuxiliaryItem({
            auxiliaryItem: auxiliaryItem,
            newText: newText
        });
    }

    const deleteData = async(auxiliaryItemId) =>{
        await deleteAuxiliaryItem({
            cardId : props.card.id,
            auxiliaryItemId : auxiliaryItemId
        })
    }

    const saveNewSection = async(category, body) => {
        await addAuxiliaryItem({
            card: props.card,
            auxiliaryItemData: {
                category_id: category.id,
                body: body
            }
        })
    }

    const addNewImage = async(formData) => {
        await addImage({
            card: props.card,
            formData: formData
        })
    }

    const sortedAuxItems = useMemo(() => {
        var auxItems = props.card.auxiliary_items.slice();
        return auxItems.sort(function (a, b) {
            return a.category.order - b.category.order;
        });
    }, [props.card.auxiliary_items]);

    const unusedCategories = useMemo(() => {
        const result = auxItemCategories.slice();
        props.card.auxiliary_items.forEach((item) => {
            result.forEach((element) =>
            {
                if(element.id === item.category.id)
                { result.splice(result.indexOf(element), 1) }
            })
        })
        return result;
    }, [auxItemCategories]);

    return (
        <Stack spacing={2}>
            { hasCardManagement() &&
                <Stack direction='row' spacing={2} sx={{ alignSelf: 'end' }}>
                    <Button 
                        variant='contained' 
                        startIcon={<AddPhotoAlternateRounded />} 
                        size='small'
                        onClick={() => setIsAddingImage(true)}
                    >
                        Add Image
                    </Button> 
                    <Button 
                        variant='contained' 
                        startIcon={<Add />} 
                        size='small'
                        onClick={() => setIsAddSectionOpen(true)}
                    >
                        Add Section
                    </Button> 
                </Stack>
            }
            <Stack display='flex' alignItems='center' spacing={4}>
                { sortedAuxItems.map((section) => (
                    <FreeformCardEntry key={section.id} id = {section.id} entry={section} dataType = {'Auxiliary Item'} saveData={saveData} deleteData = {deleteData} canEdit={hasCardManagement()} />
                ))}
                { props.card.auxiliary_item_images.length > 0 && 
                    <ImageCarousel images={props.card.auxiliary_item_images} card={props.card} type='auxiliary_items' />
                }
            </Stack>
            <AddSectionDialog 
                open={isAddSectionOpen} 
                setOpen={setIsAddSectionOpen} 
                saveNewSection={saveNewSection} 
                titleOptions={unusedCategories}
            />
            <UploadImage 
                open={isAddingImage} 
                setOpen={setIsAddingImage} 
                uploadImage={addNewImage}
                isUploading={isUploadingImage}
            />
        </Stack>
    );
}

export default Instruments;