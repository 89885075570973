import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import AssessmentRating from './AssessmentRating';
import AssessmentSummary from './AssessmentSummary';
import AssessmentLink from './AssessmentLink';
import { useParams } from 'react-router-dom';
import LogOnEnter from '../../../CustomComponents/LogOnEnter';

function Assessment(props) {
    const { physicianId } = useParams();

    return (
        <LogOnEnter action='viewed_ecri_clinical_evidence' data={{ item: props.item }}>
            <Stack className={props.className} spacing={2} p={2}>
                <Stack spacing={0.5}>
                    <Typography variant='heavy' fontSize='16px'><strong>Clinical Evidence Assessment</strong></Typography>
                    <Typography variant='normal' fontSize='14px'>Provided by the independent evidence reporting of ECRI</Typography>
                </Stack>
                <AssessmentRating assessment={props.item.ecri_assessment} />
                <AssessmentSummary assessment={props.item.ecri_assessment} />
                { props.item.ecri_assessment.link && 
                    <AssessmentLink 
                        item={props.item} 
                        tab={props.tab} 
                        currentIndex={props.currentIndex} 
                        physicianId={physicianId}
                    />
                }
            </Stack>
        </LogOnEnter>
    );
}

export default Assessment;