import { apiSlice } from './apiSlice';
import { NotificationActions } from './notificationSlice';

export const campaignSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCampaigns: builder.query({
            query: (hospitalId) => ({
                url: `/hospitals/${hospitalId}/campaigns`,
                method: 'GET'
            }),
            providesTags: (result = [], error, arg) => [
                'Campaign',
                ...result.map(({ id }) => ({ type: 'Campaign', id }))
            ]
        }),

        createCampaign : builder.mutation({
            query:({hospitalId, requestBody}) => ({
                url: `/hospitals/${hospitalId}/campaigns`,
                method: 'POST',
                body: requestBody
            }),
            invalidatesTags: ['Campaign']
        }),

        updateCampaign : builder.mutation({
            query : ({campaignId, requestBody}) =>({
                url : `/campaigns/${campaignId}`,
                method : 'PATCH',
                body: requestBody
            }),

            async onQueryStarted({campaignId, requestBody}, {dispatch, queryFulfilled}){
                const result = dispatch(
                    campaignSlice.util.updateQueryData('getCampaign', campaignId.toString(), draft => {
                        if (requestBody.status) {
                            draft.status = requestBody.status;
                        }
                        
                        if (requestBody.settings) {
                            draft.settings = requestBody.settings;
                        }
                    }))
                try{
                    await queryFulfilled;
                    dispatch(NotificationActions.addSuccessNotification({ message: 'Campaign updated successfully.' }));
                } catch{
                    result.undo();
                    dispatch(NotificationActions.addErrorNotification({ message: 'Campaign update failed. Please try again later.' }));
                }
            },
            invalidatesTags: ['Campaign']
        }),

        editCampaignInfo : builder.mutation({
            query: ({campaignId, requestBody }) =>({
                url : `/campaigns/${campaignId}`,
                method : 'PATCH',
                body : requestBody
            }),
            async onQueryStarted({campaignId, requestBody}, {dispatch, queryFulfilled}){
                const result = dispatch(
                    campaignSlice.util.updateQueryData('getCampaign', campaignId.toString(), draft => {
                        const requestKey  = Object.keys(requestBody);
                        const requestValue = Object.values(requestBody);
                        requestKey.map((key, index)=>{draft[key] = requestValue[index]})
                    }))
                try{
                    await queryFulfilled;
                } catch{
                    result.undo();
                }
            },
            invalidatesTags: ['Campaign']
        }),

        getCampaign: builder.query({
            query: ({campaignId, include}) => `/campaigns/${campaignId}?include=${include}`,
            providesTags: (result, error, arg) => [{ type: 'Campaign', id: arg }]
        }),
        
        getDraftsforCampaign : builder.query({
            query: (campaignId) => ({
                url: `/campaigns/${campaignId}/drafts`,
                method: 'GET'
            }),
            providesTags: (result = [], error, arg) => [
                'CampaignDrafts',
                ...result.map(({ id }) => ({ type: 'CampaignDrafts', id }))
            ]
        }),

        getCampaignPreview: builder.query({
            query: (campaignId) => ({
                url: `/campaigns/${campaignId}/preview`,
                method: 'GET',
            }),
            providesTags: ['CampaignPreview']
        }),

        createCampaignPreview: builder.mutation({
            query: ({campaignId, requestBody}) => ({
                url: `/campaigns/${campaignId}/preview`,
                method: 'POST',
            }),
            async onQueryStarted({campaignId, requestBody}, {dispatch, queryFulfilled}){
                try {
                    const { data: updatedPreview } = await queryFulfilled;
                    dispatch(
                        campaignSlice.util.updateQueryData('getCampaign', campaignId.toString(), draft => {
                            Object.assign(draft, updatedPreview);
                        })
                    );
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Campaign preview creation failed. Please try again later.' }));
                }
            },
            invalidatesTags: ['CampaignDrafts']
        }),

        saveCampaignTemplate: builder.mutation({
            query: ({ hospitalId, requestBody }) => ({
                url: `/hospitals/${hospitalId}/campaignTemplates`,
                method: 'POST',
                body: requestBody
            }),
        }),

        updateCampaignTemplate: builder.mutation({
            query: ({ templateId, requestBody }) => ({
                url: `/campaignTemplates/${templateId}`,
                method: 'PATCH',
                body: requestBody
            }),
        }),

        getCampaignTemplates: builder.query({
            query: (hospitalId) => ({
                url: `/hospitals/${hospitalId}/campaignTemplates`,
                method: 'GET'
            }),
            providesTags: (result = [], error, arg) => [
                'CampaignTemplates',
                ...result.map(({ id }) => ({ type: 'CampaignTemplates', id }))
            ]
        }),

        getCampaignPreviewDraft: builder.query({
            query: (draftId) => ({
                url: `/drafts/${draftId}`,
                method: 'GET',
            }),
            providesTags: ['CampaignDraft']
        }),

        updatePreviewDraft: builder.mutation({
            query: ({ draft, isExcluded }) => ({
                url: `/drafts/${draft.id}`,
                method: 'PATCH',
                body: { 
                    is_excluded: isExcluded 
                }
            }),
            async onQueryStarted({ draft, isExcluded }, { dispatch, queryFulfilled}) {
                const result = dispatch (
                    campaignSlice.util.updateQueryData('getCampaignPreviewDraft', draft.id.toString(), previewDraft => {
                        previewDraft.is_excluded = isExcluded;
                    })
                )
                const result2 = dispatch (
                    campaignSlice.util.updateQueryData('getDraftsforCampaign', draft.campaign_id.toString(), campaignDrafts => {
                        campaignDrafts.find(existingDraft => existingDraft.id === draft.id).is_excluded = isExcluded;
                    })
                )
                try {
                    await queryFulfilled;
                } catch {
                    result.undo();
                    result2.undo();
                }
            },
            invalidatesTags: ['Draft']
        }),

        updatePreviewItemRecommendation: builder.mutation({
            query: ({ draft, recommendation, isExcluded }) => ({
                url: `/itemRecommendations/${recommendation.id}`,
                method: 'PATCH',
                body: { 
                    is_excluded: isExcluded,
                }
            }),
            async onQueryStarted({ draft, recommendation, isExcluded }, { dispatch, queryFulfilled}) {
                const result = dispatch(
                    campaignSlice.util.updateQueryData('getCampaignPreviewDraft', draft.id.toString(), draft => {
                        const index = draft.recommendations[recommendation.item.category].findIndex(rec => rec.id === recommendation.id);
                        draft.recommendations[recommendation.item.category][index].is_excluded = isExcluded;
                    })
                )
                const result2 = dispatch (
                    campaignSlice.util.updateQueryData('getDraftsforCampaign', draft.campaign_id.toString(), campaignDrafts => {
                        let exisitingDraft = campaignDrafts.find(existingDraft => existingDraft.id === draft.id);
                        const index = exisitingDraft.recommendations[recommendation.item.category].findIndex(rec => rec.id === recommendation.id);
                        exisitingDraft.recommendations[recommendation.item.category][index].is_excluded = isExcluded;
                    })
                )
                try {
                    await queryFulfilled;
                } catch {
                    result.undo();
                    result2.undo();
                }
            }
        }),
        getComplianceCategories: builder.query({
            query: (hospitalId) => ({
                url: `/hospitals/${hospitalId}/complianceCategories`,
                method: 'GET'
            }),
            providesTags: (result = [], error, arg) => [
                'ComplianceCategories',
                ...result.map(({ id }) => ({ type: 'ComplianceCategories', id }))
            ]
        }),
        startReviewTimer: builder.mutation({
            query: ({ campaignId }) => ({
                url: `/campaigns/${campaignId}/startReviewTimer`,
                method: 'POST'
            }),
            async onQueryStarted({ campaignId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(NotificationActions.addSuccessNotification({ message: 'Review timer started successfully.' }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not start review timer at this time. Please try again later.' }));
                }
            },
            invalidatesTags: ['CampaignDrafts']     
        }),
        createBulkMessageJob: builder.mutation({
            query: ({ campaignId, requestBody }) => ({
                url: `/campaigns/${campaignId}/bulkText`,
                method: 'POST',
                body: requestBody
            }),
            async onQueryStarted({ campaignId , requestBody}, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(NotificationActions.addSuccessNotification({ message: 'Bulk text queued successfully.' }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not queue texts at this time. Please try again later.' }));
                }
            },
        }),
    })
});

export const {
    useGetCampaignsQuery,
    useCreateCampaignMutation,
    useUpdateCampaignMutation,
    useEditCampaignInfoMutation,
    useGetDraftsforCampaignQuery,
    useGetCampaignQuery,
    useGetCampaignPreviewQuery,
    useCreateCampaignPreviewMutation,
    useSaveCampaignTemplateMutation,
    useUpdateCampaignTemplateMutation,
    useGetCampaignTemplatesQuery,
    useGetCampaignPreviewDraftQuery,
    useUpdatePreviewDraftMutation,
    useUpdatePreviewItemRecommendationMutation,
    useGetComplianceCategoriesQuery,
    useStartReviewTimerMutation,
    useCreateBulkMessageJobMutation,
} = campaignSlice;
