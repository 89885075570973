import { Close } from '@mui/icons-material';
import { IconButton, Stack, Tab, Tabs, Typography, Drawer } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import ItemDetailPanel from './ItemDetailPanel';

function ItemDetails(props) {
    const theme = useTheme();
    const [value, setValue] = useState(props.startingValue);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <Drawer anchor='bottom' open={props.isOpen} onClose={props.handleClose} PaperProps={{ sx: { height: '100%'} }}>
            <Stack sx={{ boxShadow: 2 }}>
                <Stack p={1} direction='row' spacing={3} alignItems='center' sx={{ backgroundColor: `${theme.palette.background.itemSwap}` }}>
                    <IconButton onClick={props.handleClose} >
                        <Close />
                    </IconButton>
                    <Typography variant='heavy' sx={{ fontSize: '20px' }}><strong>Item Details</strong></Typography>
                </Stack>
                { props.variant === 'tabs' &&
                    <Tabs value={value} onChange={handleChange} variant='fullWidth' centered>
                        <Tab label='Current Item' />
                        <Tab label='Recommended Item' />
                    </Tabs>}
            </Stack>
            <ItemDetailPanel item={props.item} isActive={value === 0} canEdit={false} tab={'current'} currentIndex={props.currentIndex} />
            { props.variant === 'tabs' && 
                <ItemDetailPanel item={props.recommended_item} isActive={value === 1} canEdit={false} tab={'recommended'} currentIndex={props.currentIndex} />
            }
        </Drawer>
    );
}

export default ItemDetails;