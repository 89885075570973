import React, { useEffect, useMemo, useState } from 'react';
import { Drawer, Stack, Typography, List, ListItem, FormControlLabel, Checkbox, TextField, Button } from '@mui/material';

function FeedbackDrawer(props) {
    const [checked, setChecked] = useState({
        'not-equivalent': props.currentSwap.recommendations[0].rejection_context['not-equivalent'] ?? false,
        'more-info': props.currentSwap.recommendations[0].rejection_context['more-info'] ?? false,
        'other': props.currentSwap.recommendations[0].rejection_context['other'] ?? false,
        'other-text': props.currentSwap.recommendations[0].rejection_context['other-text'] ?? '',
    });
    const [otherText, setOtherText] = useState(checked['other-text']);
    const [showingTextField, setShowingTextField] = useState(false);

    useEffect(() => {
        setChecked({
            'not-equivalent': props.currentSwap.recommendations[0].rejection_context['not-equivalent'] ?? false,
            'more-info': props.currentSwap.recommendations[0].rejection_context['more-info'] ?? false,
            'other': props.currentSwap.recommendations[0].rejection_context['other'] ?? false,
            'other-text': props.currentSwap.recommendations[0].rejection_context['other-text'] ?? '',
        });
        setOtherText(checked['other_text']);
    }, [props.currentSwap]);

    const handleClose = () => {
        props.setOpen(false);
    }

    const handleSubmit = () => {
        props.onSubmit({
            'not-equivalent': checked['not-equivalent'],
            'more-info': checked['more-info'],
            'other': checked['other'],
            'other-text': otherText,
        });
        handleClose();
    }

    const handleToggle = (value) => () => {
        if (value === 'other') {
            setShowingTextField(!showingTextField);
        } 
        checked[value] = !checked[value];
        const newChecked = {...checked};
        setChecked(newChecked);
    };

    const canSubmitContext = useMemo(() => {
        return checked['not-equivalent'] || checked['more-info'] || checked['other'];
    }, [checked]);

    return (
        <Drawer open={props.open} onClose={handleClose} anchor='bottom'>
            <Stack spacing={2} p={2}>
                <Typography variant='heavy' fontSize='16px'>
                    {'Tell us why you\'re declining this swap.'}
                </Typography>
                <List>
                    <ListItem>
                        <FormControlLabel control={<Checkbox checked={checked['not-equivalent']} onChange={handleToggle('not-equivalent')} name='not-equivalent' />} label='These items are not functionally equivalent' />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel control={<Checkbox checked={checked['more-info']} onChange={handleToggle('more-info')} name='more-info' />} label='I need more information' />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel control={<Checkbox checked={checked['other']} onChange={handleToggle('other')} name='other' />} label='Other' />
                    </ListItem>
                    { showingTextField && 
                        <TextField
                            id='outlined-multiline-static'
                            multiline
                            rows={2}
                            fullWidth
                            variant='outlined'
                            value={otherText}
                            onChange={(e) => setOtherText(e.target.value)}
                            autoFocus
                        />
                    }
                </List>
                <Stack direction='row' spacing={2} justifyContent='end'>
                    <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                    <Button variant='contained' onClick={handleSubmit} disabled={!canSubmitContext}>Submit</Button>
                </Stack>
            </Stack>
        </Drawer>
    )
}

export default FeedbackDrawer;