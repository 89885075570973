import { InfoOutlined } from '@mui/icons-material';
import { Paper, Slider, Stack, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

function UsageThreshold(props) {
    const [highImpactThreshold, setHighImpactThreshold] = useState(props.settings.high_impact_threshold);

    const handleHighImpactThresholdChange = (value) => {
        setHighImpactThreshold(value);
        props.setSettings({
            ...props.settings,
            high_impact_threshold: value
        });
    };

    return (
        <Paper elevation={2}>
            <Stack p={3} spacing={2}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='light' sx={{ fontSize: '18px' }}>Item Removal Threshold</Typography>
                    <Tooltip title='Sets the usage threshold below which an item should be recommended for removal from the preference card.'>
                        <InfoOutlined style={{ opacity: 0.5 }} />
                    </Tooltip>
                </Stack>
                <Stack spacing={2} direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>Removal Threshold: </Typography>
                    <Slider disabled={props.disabled} value={Math.round((highImpactThreshold) * 100)} onChange={(e) => handleHighImpactThresholdChange(e.target.value / 100)}/>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}>{Math.round((highImpactThreshold) * 100)}%</Typography>
                </Stack>
            </Stack>
        </Paper>
    );
}

export default UsageThreshold;
