import React from 'react';
import { parseISO, format } from 'date-fns';
import { TableCell, TableRow, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import OverdueChip from '../OverdueChip';
import MultipleProgressBar from '../../CustomComponents/MultipleProgressBar';
import { theme } from '../../../theme';


function CampaignRow(props) {
    const physicianEngagement = Math.round((props.campaign.num_engaged_physicians / Math.max(props.campaign.num_included_physicians, 1)) * 100);
    const reviewProgress = Math.round((props.campaign.staff_reviewed_value / Math.max(props.campaign.staff_opportunity_value, 1)) * 100);
    const status = props.campaign.status === 'Active' || props.campaign.status === 'Staff Review' ? 'Active' : 'Complete';
    const capturedValue = <NumericFormat value={props.campaign.captured_value / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale />;
    const opportunityValue = <NumericFormat value={props.campaign.opportunity_value / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale />;

    return (
        <TableRow key={props.campaign.id} style={{ 
            height: 'auto', 
            '&:last-child': { 
                '& > td': {
                    borderBottom: 'none'
                }
            } 
        }} onClick={props.handleSelect} selected={props.isSelected} hover sx={{ cursor: 'pointer' }}>
            <CampaignContentCell width='10%' textAlign='left'>
                <Typography variant='normal' fontSize='12px' title={props.campaign.location}>{props.campaign.location}</Typography>
            </CampaignContentCell>
            <CampaignContentCell width='10%' textAlign='left'>
                <Typography variant='normal' fontSize='12px' title={props.campaign.specialties.join(', ')}>{props.campaign.specialties.join(', ')}</Typography>
            </CampaignContentCell>
            <CampaignContentCell width='8%' textAlign='left'>
                <Typography variant='normal' fontSize='12px' title={format(parseISO(props.campaign.start_date), 'MM/dd/yyyy')}>{format(parseISO(props.campaign.start_date), 'MM/dd/yyyy')}</Typography>
            </CampaignContentCell>
            <CampaignContentCell width='10%' textAlign='left'>
                <Typography variant='normal' fontSize='12px' title={status}>{status}</Typography>
            </CampaignContentCell>
            <CampaignContentCell width='14%' textAlign='right'>
                <Typography variant='normal' fontSize='12px'><strong>{capturedValue}</strong> / {opportunityValue}</Typography>
            </CampaignContentCell>
            <CampaignContentCell width='19%' textAlign='left'>
                <MultipleProgressBar segments={[
                    {
                        value: physicianEngagement,
                        color: `${theme.palette.blue.main}`
                    },
                    {
                        value: (100 - physicianEngagement),
                        color: `${theme.palette.background.unreviewedProgress}`
                    }
                ]} label={`${physicianEngagement}%`} />
            </CampaignContentCell>
            <CampaignContentCell width='19%' textAlign='left'>
                <MultipleProgressBar segments={[
                    {
                        value: reviewProgress,
                        color: `${theme.palette.blue.main}`
                    },
                    {
                        value: (100 - reviewProgress),
                        color: `${theme.palette.background.unreviewedProgress}`
                    }
                ]} label={`${reviewProgress}%`} />
            </CampaignContentCell>
            <CampaignContentCell width='10%' textAlign='right'>
                <OverdueChip numOverdue={props.campaign.num_overdue_reviews} overdueValue={props.campaign.value_of_overdue_reviews} format='no_percentage' />
            </CampaignContentCell>
        </TableRow>
    )
}

function CampaignContentCell(props) {
    return (
        <TableCell sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: props.width, maxWidth: 0, textAlign: props.textAlign }}>
            {props.children}
        </TableCell>
    );
}

export default CampaignRow;
