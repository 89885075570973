import React, { useState } from 'react';
import { Stack, Typography, Autocomplete, TextField, Button } from '@mui/material';
import { useTheme } from '@mui/system';
import { useCreateCardMutation, useGetCardsForHospitalQuery } from '../../../api/cardSlice';
import { useNavigate } from 'react-router-dom';

function CopyExistingCard(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [submitCreateCard] = useCreateCardMutation();
    const [selectedCopyTemplate, setSelectedCopyTemplate] = useState();
    const {
        data: cards = []
    } = useGetCardsForHospitalQuery({ hospitalId: props.user.hospital.id });

    const handleCreate = async () => {
        try{
            await submitCreateCard({
                cardName: props.cardName,
                cardOwner: props.selectedPhysician,
                hospitalId: props.user.hospital.id,
                copyTemplateId: selectedCopyTemplate.id,
            }).unwrap().then((payload) => navigate(`/cards/${payload.id}`));
        }
        catch(error){
            console.log(error);
        }
    }
    
    function handleBack() {
        props.setIsCopyingExistingCard(false);
        props.setIsChoosingDataMethod(true);
    }

    return (
        <Stack spacing={4} p={4} sx={{ backgroundColor: `${theme.palette.background.white}`, boxShadow: 1, borderRadius: '8px' }}>
            <Stack spacing={1}>
                <Typography variant='bold' sx={{ fontSize: '20px' }}>Copy existing card</Typography>
                <Typography variant='normal' sx={{ fontSize: '12px' }}>Select a base card template and make edits as needed.</Typography>
            </Stack>
            <Autocomplete 
                options={cards}
                getOptionLabel={(option) => `${option.name} - ${option.owner.name}`}
                value={selectedCopyTemplate}
                onChange={(event, newValue) => setSelectedCopyTemplate(newValue)}
                renderInput={(params) => (
                    <TextField 
                        {...params}
                        placeholder='Search by card or physician name'
                        InputProps={{
                            ...params.InputProps,
                        }}
                    />
                )}
            />
            <Stack direction='row' spacing={2} justifyContent='end'>
                <Button variant='outlined' onClick={handleBack}>Back</Button>
                <Button variant='contained' onClick={handleCreate} disabled={!selectedCopyTemplate}>
                    Copy selected card
                </Button>
            </Stack>
        </Stack>
    );
}

export default CopyExistingCard;
