import { Button, TableCell, TableRow, Typography, Table, TableHead, TableContainer } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useMemo } from 'react';
import { useGetOrganizationUsersQuery } from '../../../../api/organizationSlice';
import { format, parseISO } from 'date-fns';

function UserTable(props) {
    const { data: users = [], isLoading, isSuccess } = useGetOrganizationUsersQuery(props.organizationId);
    const usersToDisplay = useMemo(() => {
        return users.filter((user) => {
            return user.first_name.toLowerCase().includes(props.searchTerm.toLowerCase()) || user.last_name?.toLowerCase().includes(props.searchTerm.toLowerCase());
        })
    }, [users, props.searchTerm]);

    return (
        <Stack boxShadow={2} borderRadius='8px' border='1px solid #DDD' backgroundColor='white' maxHeight='35%'>
            <TableContainer sx={{ borderRadius: '8px' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow sx={{ '&:hover': { cursor: 'default' } }}>
                            <TableCell>
                                <Typography variant='heavy' fontSize='14px'><strong>User</strong></Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='heavy' fontSize='14px'><strong>Roles</strong></Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='heavy' fontSize='14px'><strong>Hospitals</strong></Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='heavy' fontSize='14px'><strong>Account Status</strong></Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='heavy' fontSize='14px'><strong>Last Login</strong></Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='heavy' fontSize='14px'><strong>Last Active</strong></Typography>
                            </TableCell>
                            <TableCell>
                                <></>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    { isLoading && <Typography>Loading...</Typography> }
                    { isSuccess && usersToDisplay.map((user) => (
                        <TableRow key={user.id} direction='row' selected={props.selectedUser?.id === user.id} alignItems='center' justifyContent='space-between' p={2} style={{ height: 'auto' }} onClick={() => props.openUserSettings(user)} hover sx={{ cursor: 'pointer' }}>
                            <TableCell>
                                <Typography variant='normal' fontSize='14px'>{user.first_name} {user.last_name}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='normal' fontSize='14px'>{user.roles.map((role) => role.name).join(', ')}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='normal' fontSize='14px'>{user.hospitals.map((hospital) => hospital.name).join(', ')}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='normal' fontSize='14px'>Active</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='normal' fontSize='14px'>{user.last_login ? format(parseISO(user.last_login), 'M/d/yy') : 'Never'}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant='normal' fontSize='14px'>{user.last_active ? format(parseISO(user.last_active), 'M/d/yy') : 'Not active in past two weeks'}</Typography>
                            </TableCell>
                            <TableCell>
                                <Button variant='contained' color='primary' onClick={() => props.openUserSettings(user)}>Settings</Button> 
                            </TableCell>
                        </TableRow>
                    ))}
                </Table>
            </TableContainer>
        </Stack>
    )
}

export default UserTable;
