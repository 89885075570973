import { ArrowForwardRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';

function CategoryDrawer(props) {
    const theme = useTheme();

    const item = props.recommendation.item ?? props.recommendation.recommended_item;

    return (
        <Stack
            direction='row' 
            mx={1} mt={-1} px={1.5} py={1} 
            justifyContent='space-between' 
            alignItems='center'
            sx={{ backgroundColor: `${theme.palette.background.categoryDrawer}`, borderRadius: '4px', cursor: props.categoryCount > 1 ? 'pointer' : '' }}
            onClick={() => props.openCategory(item.category, props.recommendation)}
        >
            { props.categoryCount > 1 ? 
                <>
                    <Typography variant='normal' sx={{ fontSize: '12px' }}>
                        View the {props.categoryCount - 1} other '{item.category}' item{props.categoryCount > 2 ? 's' : ''} on this card.
                    </Typography>
                    <ArrowForwardRounded fontSize='small' style={{ color: `${theme.palette.subtext.main}` }} />
                </> :
                <Typography variant='normal' sx={{ fontSize: '12px' }}>
                    No other items in this category on this card.
                </Typography>
            }
        </Stack>
    )
}

export default CategoryDrawer;
