import { Stack, Typography } from '@mui/material';
import React from 'react';
import { NavigationRounded } from '@mui/icons-material';
import { NumericFormat } from 'react-number-format';

function ItemSwapContextBullet(props) {
    if (props.variant === 'savings') {
        return (
            <Stack direction='row' spacing={1.5}>
                <NavigationRounded sx={{ transform: 'rotate(90deg)', fontSize: '12px' }} />
                <Typography variant='normal' sx={{ fontSize: '12px' }}>
                    <strong>Saves <NumericFormat value={Math.round(props.title / 100)} thousandSeparator={true} displayType={'text'} prefix={'$'} /></strong> {props.content}
                </Typography>
            </Stack>
        );
    }

    return (
        <Stack direction='row' spacing={1.5}>
            <NavigationRounded sx={{ transform: 'rotate(90deg)', fontSize: '12px' }} />
            <Typography variant='normal' sx={{ fontSize: '12px' }}><strong>{props.title}</strong> {props.content}</Typography>
        </Stack>
    );
}

export default ItemSwapContextBullet;