import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import React from 'react';

const filter = createFilterOptions();

function CreateAutocomplete(props) {
    const {
        data: options = []
    } = props.loadOptions();

    const sortedOptions = [...options].sort((a, b) => {
        const aName = typeof a === 'string' ? a : a.name;
        const bName = typeof b === 'string' ? b : b.name;
        return aName.localeCompare(bName);
    });

    return (
        <>
            <Autocomplete
                value={props.value}
                onChange={(event, newValue) => {
                    if (newValue && newValue.inputValue) {
                        props.setValue(newValue.inputValue)
                    } else {
                        props.setValue(newValue);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`
                        });
                    }

                    return filtered;
                }}
                options={sortedOptions}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.name;
                    
                }}
                selectOnFocus
                clearOnBlur
                blurOnSelect
                handleHomeEndKeys
                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                freeSolo
                renderInput={(params) => <TextField {...params} placeholder={props.label} />}
            />
        </>
    );
}

export default CreateAutocomplete;
