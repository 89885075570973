import React, { useState, useMemo } from 'react';
import { Table, TableContainer, TableBody } from '@mui/material';
import PhysicianRow from './PhysicianRow';
import PhysicianHeader from './PhysicianHeader';
import PhysicianFooter from './PhysicianFooter';


function PhysiciansTable(props) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('last_name');

    function descendingComparator(a, b, orderBy) {
        if (orderBy.includes('.')) {
            const keys = orderBy.split('.');
            const aValue = a[keys[0]][keys[1]];
            const bValue = b[keys[0]][keys[1]];
            if (bValue < aValue) {
                return -1;
            }
            if (bValue > aValue) {
                return 1;
            }
            return 0;
        }

        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = useMemo(() => {
        return [...props.physicians]
            .sort(getComparator(order, orderBy));
    }, [order, orderBy, props.physicians, props.appliedFilters]);

    return (
        <TableContainer sx={{ borderRadius: '8px', border: '2px solid #DDD', backgroundColor: 'white', maxHeight: '100%' }}>
            <Table stickyHeader>
                <PhysicianHeader
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    { visibleRows.map((physician) => (
                        <PhysicianRow key={physician.id} physician={physician} numericFormat={props.numericFormat} />
                    ))}
                </TableBody>
                <PhysicianFooter visiblePhysicians={visibleRows} totalPhysicians={props.physicians.length} numericFormat={props.numericFormat} />
            </Table>
        </TableContainer>
    );
}

export default PhysiciansTable;
