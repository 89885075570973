import React from 'react';
import { Stack } from '@mui/material';
import { Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

function Message(props) {
    function isFromPhysician() {
        return props.message.author === 'Physician';
    }

    function determineDeliveryStatus() {
        if (!props.message.delivery_status) return (
            <Stack direction='row' justifyContent='flex-end' >
                <Typography sx={{ fontSize: '10px', color: '#9e9e9e'}}> Sending </Typography>
            </Stack>
        )
        else if (props.message.delivery_status.failed !== 'none' || props.message.delivery_status.undelivered !== 'none') {
            <Stack direction='row' justifyContent='flex-end' >
                <Typography sx={{ fontSize: '10px', color: '#9e9e9e'}}> Failed </Typography>
                <ClearIcon sx={{ fontSize: '10px', color: '#9e9e9e', alignSelf:'center'}}/>
            </Stack>
        }
        else if (props.message.delivery_status.delivered === 'all') {
            return(
                <Stack direction='row' justifyContent='flex-end' >
                    <Typography sx={{ fontSize: '10px', color: '#9e9e9e'}}> Delivered </Typography>
                    <CheckIcon sx={{ fontSize: '10px', color: '#9e9e9e', alignSelf:'center'}}/>
                </Stack>
            )
        }
        else if (props.message.delivery_status.sent === 'all') {
            return (
                <Stack direction='row' justifyContent='flex-end' >
                    <Typography sx={{ fontSize: '10px', color: '#9e9e9e'}}> Sent </Typography>
                    <CheckIcon sx={{ fontSize: '10px', color: '#9e9e9e', alignSelf:'center'}}/>
                </Stack>
            )
        }
        return <></>;
    }

    function formatDate(inputDate)  {
        const date = new Date(inputDate + 'Z');
        const options = {month: '2-digit', day:'2-digit', year: 'numeric', hour: '2-digit', minute:'2-digit'};
        return date.toLocaleString('en', options);

    }

    return (
        <Stack direction='row' sx={{ '&:last-child': { overflowAnchor: 'auto' } }} justifyContent={isFromPhysician() ? 'flex-start' : 'flex-end'} alignItems='flex-start'>
            <Stack maxWidth='60%'>
                <Stack sx={{ borderRadius: '10px', padding: '10px', backgroundColor: isFromPhysician() ? '#e0e0e0' : '#e1f5fe' }}>
                    {props.message.body}
                </Stack>
                {props.message.media && 
                     <Stack sx={{ borderRadius: '10px', padding: '10px', marginTop: '2px', backgroundColor: isFromPhysician() ? '#e0e0e0' : '#e1f5fe' }}>
                         {props.message.author} sent an image or video on {formatDate(props.message.media.date_created)}
                     </Stack>
                }
                <Stack sx={{ fontSize: '10px', color: '#9e9e9e', display: 'grid' }} justifyContent={isFromPhysician() ? 'flex-start' : 'flex-end'}>

                    {props.message.author} - {formatDate(props.message.created_at)}
                    { (!isFromPhysician()) &&
                        determineDeliveryStatus()
                    }
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Message;
