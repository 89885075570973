import React, { useState, useEffect } from 'react';
import { Box, Button, LinearProgress, Stack, Typography, linearProgressClasses, styled } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { useTheme } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../../Hooks/useAnalytics';
import CountUp from 'react-countup';

function ComplianceHeader(props) {
    const theme = useTheme();
    const analytics = useAnalytics();
    const navigate = useNavigate();
    const [currentDeficiency, setCurrentDeficiency] = useState(0);
    const [oldDeficiency, setOldDeficiency] = useState(0);

    function navigateToDashboard() {
        analytics.track('Button Clicked', {
            label: 'Dashboard',
            sector: 'Physician Dashboard',
            location: 'Item Swap Recommendations'
        });
        navigate(`/physicianDashboard/headline/${props.campaignId}`);
    }

    useEffect(() => {
        let deficiency = props.swaps ? Object.keys(props.swaps).reduce((acc, itemId) => {
            return acc + props.swaps[itemId].recommendations.reduce((acc, rec) => {
                if (rec.status !== 'accepted') {
                    return acc + rec.recommendation_value;
                } else {
                    return acc;
                }
            }, 0);
        }, 0) : 0;
        setOldDeficiency(currentDeficiency);
        setCurrentDeficiency(deficiency);
    }, [props.swaps]);

    return (
        <Stack p={2} spacing={1} sx={{ backgroundColor: '#FFFFFF', boxShadow: 5 }}>
            <Stack direction='row' justifyContent='space-between'>
                <Stack spacing={1.5}>
                    <Button
                        variant='text'
                        startIcon={<ArrowBackIos />} 
                        onClick={navigateToDashboard}
                        style={{ color: `${theme.palette.hold.main}`, paddingTop: '0px', paddingBottom: '0px', width: 'fit-content'}}
                    >
                        Dashboard
                    </Button>
                    <Typography variant='heavy' sx={{ fontSize: '16px' }}>Compliance Recommendations</Typography>
                </Stack>
                <Stack spacing={0.5} justifyContent='center'>
                    <Box alignSelf='center' textAlign='center' sx={{ fontSize: '10px', color: '#00000099', maxWidth: '80px' }}>
                        <Typography variant='normal'>Out of Contract Spend /yr</Typography>
                    </Box>
                    <Box py={0.5} px={1} alignSelf='center' sx={{ color: `${theme.palette.red.compliance}`, font: 'normal normal 18px Roboto', border: '1px solid #0000001F', borderRadius: '4px', maxWidth: 'fit-content' }}>
                        <CountUp 
                            start={Math.round(oldDeficiency / 100)}
                            end={Math.round(currentDeficiency / 100)}
                            duration={0.4}
                            separator=','
                            decimals={0} 
                            prefix='$'
                        />
                    </Box>
                </Stack>
            </Stack>
            <Stack spacing={0.5}>
                <Typography variant='normal' sx={{ fontSize: '12px', color: '#00000099' }}>
                    Compliance Percentage: <Typography variant='normal' sx={{ fontSize: '12px', color: 'black' }}>{props.compliancePercentage}%</Typography>
                </Typography>
                <ComplianceBar variant='determinate' value={props.compliancePercentage} />
            </Stack>
        </Stack>
    )
}

const ComplianceBar = styled(LinearProgress)(({ value, theme }) => ({
    height: '8px',
    borderRadius: '4px',
    backgroundColor: theme.palette.red.compliance,
    [`& .${linearProgressClasses.bar1Determinate}`]: {
        display: 'grid',
        '&:after': value <= 97 ? {
            content: '""',
            position: 'absolute',
            width: '2px',
            height: '8px',
            backgroundColor: 'white',
            justifySelf: 'end',
            zIndex: 3
        } : {}
    },
}));

export default ComplianceHeader;
