import { Paper, Stack, Typography, Chip, Select, Tooltip, MenuItem } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import React, { useMemo, useState } from 'react';
import { useGetItemCategoriesQuery } from '../../../api/inventorySlice';

function ItemCategories(props) {
    const { data: categories = [] } = useGetItemCategoriesQuery(props.campaign.hospital.id);

    const [excludedCategories, setExcludedCategories] = useState(props.settings.excluded_categories);

    const notExcludedCategories = useMemo(() => {
        var categoriesToReturn = [];
        categories.forEach(category => {
            if (!excludedCategories.find(excludedCategory => category.name === excludedCategory.name)) {
                categoriesToReturn.push(category);
            }
        });
        return categoriesToReturn.sort((a, b) => a.name.localeCompare(b.name));
    }, [categories, excludedCategories]);

    const handleRemoveCategory = (excludedCategory) => {
        setExcludedCategories(excludedCategories.filter(category => category !== excludedCategory));
        props.setSettings({ ...props.settings, excluded_categories: excludedCategories.filter(category => category !== excludedCategory) });
    };

    const handleAddCategory = (category) => {
        setExcludedCategories([...excludedCategories, category]);
        props.setSettings({ ...props.settings, excluded_categories: [...excludedCategories, category] });
    };

    return (
        <Paper elevation={2} sx={{ width: '23%' }}>
            <Stack p={3} spacing={2} height='100%' justifyContent='space-between'>
                <Stack spacing={2}>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <Typography variant='light' sx={{ fontSize: '18px' }}>Excluded Item Categories</Typography>
                        <Tooltip title='Any item categories selected here will not be considered when building optimizations for this campaign.'>
                            <InfoOutlined style={{ opacity: 0.5 }} />
                        </Tooltip>
                    </Stack>
                    <Stack direction='row' alignItems='center' spacing={1} rowGap={0.5} flexWrap='wrap'>
                        <Typography variant='normal' sx={{ fontSize: '16px' }}>Excluded Categories: </Typography>
                        { excludedCategories.length === 0 && <Typography variant='normal' sx={{ fontSize: '16px' }}>None</Typography> }
                        {props.settings.excluded_categories.map((category, index) => (
                            <Chip
                                key={index}
                                label={category.name}
                                onDelete={() => handleRemoveCategory(category)}
                                disabled={props.disabled}
                            />
                        ))}
                    </Stack>
                </Stack>
                <Select
                    value={''}
                    displayEmpty
                    disabled={props.disabled}
                    onChange={event => handleAddCategory(event.target.value)}
                    sx={{ width: '100%' }}
                >
                    <MenuItem value={''}>Select a category to exclude</MenuItem>
                    {notExcludedCategories.map((category, index) => (
                        <MenuItem key={index} value={category}>{category.name}</MenuItem>
                    ))}
                </Select>
            </Stack>
        </Paper>
    )
}

export default ItemCategories;