import { Add, FilterList, Search } from '@mui/icons-material';
import { Autocomplete, Button, InputAdornment, Paper, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchCardOwnersQuery } from '../../api/cardSlice';
import { useGetCardOwnerSpecialtiesQuery } from '../../api/userSlice';
import useTableSearch from '../Hooks/useTableSearch';
import { UserContext } from '../Hooks/useUser';
import GenericTable from '../Tables/GenericTable';

function Physician() {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    const {
        searchTerm,
        filter: selectedSpecialty,
        handleSearchTermChange,
        handleFilterChange,
        page,
        setPage,
        resetSearch
    } = useTableSearch('physicians');

    const {
        data: fetchedPhysicians = {},
        isSuccess,
    } = useSearchCardOwnersQuery({ 
        hospitalId: user.hospital.id, 
        page: page,
        searchTerm: encodeURIComponent(searchTerm ?? ''),
        filter: encodeURIComponent(selectedSpecialty ?? '')
    });
   
    const {
        data: specialties = []
    } = useGetCardOwnerSpecialtiesQuery(user.hospital.id);

    const sortedSpecialties = useMemo(() => {
        return specialties
            .map(specialty => specialty.name)
            .sort((a, b) => a.localeCompare(b));
    }, [specialties]);

    const tablePage = useMemo(() => {
        return page - 1;
    }, [page]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
        sessionStorage.setItem('physiciansPage', newPage);
    };

    useEffect(() => {
        resetSearch();
    }, [user.hospital.id]);

    const tableHeaders = [
        'Name',
        'Specialty',
        '# of Cards',
        'Status',
        '',
        ''
    ];
    
    return (
        <Stack spacing={2} px={{ xs: 2, md: 6 }} pb={2} sx={{ height: '100%' }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='heavy' sx={{ fontSize: '20px' }}>Physicians</Typography>
            </Stack>
            <Stack direction='row' justifyContent='space-between' alignItems='end'>
                <Stack direction='row' justifyContent={{ xs: 'space-between', md: 'start' }} spacing={2} width={{ xs: '100%', md: '70%', lg: '60%' }}>
                    <Paper elevation={2} sx={{ width: '50%' }}>
                        <TextField
                            placeholder="Search Physicians"
                            value={searchTerm}
                            onChangeCapture={handleSearchTermChange}
                            size='small'
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Paper>
                    <Paper elevation={2} sx={{ width: '50%' }}>
                        <Autocomplete
                            options={sortedSpecialties}
                            getOptionLabel={(option) => option}
                            value={selectedSpecialty}
                            onChange={handleFilterChange}
                            size='small'
                            blurOnSelect
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder='Filter by specialty'
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <FilterList />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            )}
                        />
                    </Paper>
                </Stack>
                <Paper elevation={2}>
                    <Button variant='contained' size='small' onClick={() => navigate('/physicians/create')} startIcon={<Add />}>Add New Physician</Button>
                </Paper>
            </Stack>
            <GenericTable 
                data={fetchedPhysicians.data}
                dataType='physician'
                headers={tableHeaders}
                isDataLoaded={isSuccess}
                page={tablePage}
                setPage={handlePageChange}
                total={fetchedPhysicians.meta?.total}
            />
        </Stack>
    )
}

export default Physician;