import { ArrowForwardIos } from '@mui/icons-material';
import { Stack, Typography, Button, Divider } from '@mui/material';
import { Box, styled, useTheme } from '@mui/system';
import React from 'react';
import { useMemo, useState } from 'react';
import ItemSwapChipSection from './ItemSwapChipSection';
import ItemSwapContext from './ItemSwapContext';
import { motion } from 'framer-motion';
import ItemDetails from './ItemDetails';

function ItemSwapContainer(props) {
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    function openItemDetails(e) {
        e.stopPropagation();
        setIsDetailsOpen(true)
    }

    function handleItemDetailsClose(e) {
        e.stopPropagation();
        setIsDetailsOpen(false);
        sessionStorage.removeItem('complianceIndex');
    }

    const theme = useTheme();
    const activeRecommendation = props.swap.recommendations[0]; 
    const potentialSavings = useMemo(() => {
        var value = 0;
        props.swap.recommendations.forEach((rec) => {
            value += rec.swap_value;
        });
        return value;
    }, [props.swap]);
    const item = props.variant === 'current' ? activeRecommendation.item : activeRecommendation.recommended_item;
    const title = props.variant === 'current' ? 'Current Item' : 'Recommended Item';

    return (
        <Container onClick={openItemDetails} component={motion.div} key={activeRecommendation.item.id} disabled={props.disabled} layout transition={{ duration: 0.9, type: 'spring' }}>
            <Stack m={2} spacing={1}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography variant='heavy' sx={{ fontSize: '14px' }}><strong>{title}</strong></Typography>
                    <Button
                        endIcon={<ArrowForwardIos />}
                        variant='text'
                        style={{ color: `${theme.palette.hold.main}`, paddingTop: '0px', paddingBottom: '0px', width: 'fit-content'}}
                    >
                        Item Details
                    </Button>
                    
                    <ItemDetails variant='tabs' item={activeRecommendation.item} recommended_item={activeRecommendation.recommended_item} isOpen={isDetailsOpen} handleClose={handleItemDetailsClose} startingValue={props.variant === 'current' ? 0 : 1} currentIndex={props.currentIndex} />
                    
                </Stack>
                <Stack spacing={0.5}>
                    <Typography variant='normal' sx={{ fontSize: '12px' }}>{item.name}</Typography>
                    <Stack direction='row' spacing={1} sx={{ fontSize: '12px', color: '#00000099' }}>
                        <Typography variant='normal'>{item.vendor.name}</Typography> 
                        { props.showItemCost && 
                            <>
                                <Typography variant='normal'>|</Typography> 
                                <Typography variant='normal'>${Math.round(item.cost / 100)} each</Typography>
                            </>
                        }
                    </Stack>
                </Stack>
                <ItemSwapChipSection variant={props.variant} recommendation={activeRecommendation} potentialSavings={potentialSavings} />
            </Stack>
            { props.variant === 'recommended' && 
                <>
                    <Divider />
                    <ItemSwapContext recommendation={activeRecommendation} potentialSavings={potentialSavings} />
                </>
            }
        </Container>
    );
}

const Container = styled(Box)(({ theme, disabled }) => ({
    boxShadow: !disabled && 2,
    borderRadius: '4px',
    border: disabled && '1px solid #0000004D',
    backgroundColor: !disabled && theme.palette.background.white
}));

export default ItemSwapContainer;