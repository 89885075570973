import { useState } from 'react';

export default function useTableSearch(endpoint) {
    const getSearchTerm = () => {
        return sessionStorage.getItem(`${endpoint}SearchTerm`) || '';
    };

    const saveSearchTerm = (newTerm) => {
        sessionStorage.setItem(`${endpoint}SearchTerm`, newTerm);
        setSearchTerm(newTerm);
    };

    const getFilter = () => {
        return sessionStorage.getItem(`${endpoint}Filter`) || '';
    };

    const saveFilter = (newFilter) => {
        sessionStorage.setItem(`${endpoint}Filter`, newFilter || '');
        setFilter(newFilter);
    };

    const savePage = (newPage) => {
        sessionStorage.setItem(`${endpoint}Page`, newPage);
        setPage(newPage);
    };

    const [searchTerm, setSearchTerm] = useState(getSearchTerm());
    const [filter, setFilter] = useState(getFilter());
    const [page, setPage] = useState(sessionStorage.getItem(`${endpoint}Page`) || 1);

    const handleSearchTermChange = (event) => {
        saveSearchTerm(event.target.value);
        savePage(1);
    };

    const handleFilterChange = (event, newValue) => {
        saveFilter(newValue);
        savePage(1);
    };

    const resetSearch = () => {
        saveFilter('');
        saveSearchTerm('');
        savePage(1);
    };

    return {
        searchTerm,
        filter,
        handleSearchTermChange,
        handleFilterChange,
        page,
        setPage,
        resetSearch,
    };
}
