import React from 'react';
import { Typography } from '@mui/material';

function NewOpenQuantity(props) {
    const openQuantity = props.recommendation?.review.length > 0 ? (props.recommendation.custom_open ?? props.recommendation.open_quantity) : props.item.open_quantity;

    if ((props.recommendation?.type === 'removal' && props.recommendation.status === 'accepted') || props.recommendation?.status === 'custom_removal') {
        return (
            <Typography variant='heavy' sx={{ fontSize: '20px' }}>-</Typography>
        )
    } else if (props.recommendation?.status === 'open' || props.recommendation?.status === 'rejected' || props.recommendation === undefined || props.recommendation.review.length === 0) {
        return (
            <></>
        )
    } else {
        return (
            <Typography variant='heavy' sx={{ fontSize: '20px' }}>{openQuantity}</Typography>
        )
    }
}

function NewHoldQuantity(props) {
    const holdQuantity = props.recommendation?.review.length > 0 ? (props.recommendation.custom_hold ?? props.recommendation.hold_quantity) : props.item.hold_quantity;

    if ((props.recommendation?.type === 'removal' && props.recommendation.status === 'accepted') || props.recommendation?.status === 'custom_removal') {
        return (
            <Typography variant='heavy' sx={{ fontSize: '20px' }}>-</Typography>
        )
    } else if (props.recommendation?.status === 'open' || props.recommendation?.status === 'rejected' || props.recommendation === undefined || props.recommendation.review.length === 0) {
        return (
            <></>
        )
    } else {
        return (
            <Typography variant='heavy' sx={{ fontSize: '20px' }}>{holdQuantity}</Typography>
        )
    }
}

export { NewOpenQuantity, NewHoldQuantity };