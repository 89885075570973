import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useUpdateItemDetailsMutation } from '../../api/itemDetailSlice';

function AddDetailDialog(props) {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [addDetail] = useUpdateItemDetailsMutation();

    function handleClose() {
        setTitle('');
        setContent('');
        props.setOpen(false);
    }

    const handleSubmit = async() => {
        var details = {};
        Object.assign(details, props.item.details);
        details[title] = content;
        await addDetail({
            item: props.item,
            details: details
        });
        handleClose();
    }

    return (
        <Dialog maxWidth='sm' fullWidth open={props.open} onClose={handleClose}>
            <DialogTitle>
                Add New Item Detail
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} p={2}>
                    <TextField 
                        fullWidth
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder='Set Detail Title'
                    />
                    <TextField 
                        fullWidth
                        multiline
                        sx={{ whiteSpace: 'pre-line' }}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        placeholder='Set Content'
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant='contained' onClick={handleSubmit}>
                    Add Detail
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddDetailDialog;