import { IconButton, Typography } from '@mui/material';
import { Stack, useTheme } from '@mui/system';
import React, { useState } from 'react';
import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material';
import { AnimatePresence, motion } from 'framer-motion';

function DeclineItemCards(props) {
    const [index, setIndex] = useState(0);

    const variants = {
        enter: (direction) => {
            return {
                x: direction > 0 ? 1000 : -1000,
                opacity: 0
            };
        },
        center: {
            zIndex: 1,
            x: 0,
            opacity: 1
        },
        exit: (direction) => {
            return {
                zIndex: 0,
                x: direction < 0 ? 1000 : -1000,
                opacity: 0
            };
        }
    };

    return (
        <Stack direction='row' justifyContent='space-evenly' alignItems='center'>
            <IconButton onClick={() => setIndex(0)} disabled={index === 0} size='medium'>
                <ArrowCircleLeft />
            </IconButton>
            <AnimatePresence initial={false} custom={index}>
                <motion.div
                    initial='enter'
                    animate='center'
                    exit='exit'
                    variants={variants}
                    transition={{
                        x: { type: 'spring', stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 }
                    }}
                    drag='x'
                    dragConstraints={{ left: 0, right: 0 }}
                >
                    { index === 0 && <DeclineItemCard item={props.item} variant='current' /> }
                    { index === 1 && <DeclineItemCard item={props.recommendedItem} variant='recommended' /> }
                </motion.div>
            </AnimatePresence>
            <IconButton onClick={() => setIndex(1)} disabled={index === 1} size='medium'>
                <ArrowCircleRight />
            </IconButton>
        </Stack>
    );
}


function DeclineItemCard(props) {
    const theme = useTheme();

    return (
        <Stack p={2} sx={{ borderRadius: '4px', backgroundColor: '#EDEDED' }} spacing={1}>
            <Typography variant='heavy' sx={{ fontSize: '14px' }}>{ props.variant == 'current' ? 'Current' : 'Recommended' } Item</Typography>
            <Typography variant='normal' sx={{ fontSize: '12px' }}>{props.item.name}</Typography>
            <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>{props.item.vendor.name}</Typography>
        </Stack>
    )
}


export default DeclineItemCards;
