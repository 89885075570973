import { Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useGetActiveConversationsQuery } from '../../../api/textingSlice';
import GenericTable from '../../Tables/GenericTable';

function PhysicianTexting() {
    const {
        data: fetchedConversations = [],
        isSuccess,
    } = useGetActiveConversationsQuery();

    const [page, setPage] = useState(0);

    let tableHeaders = [
        'Name',
        'Specialty',
    ]

    return (
        <Stack spacing={2} px={{ xs: 2, md: 6 }} pb={2} sx={{ height: '100%' }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='heavy' sx={{ fontSize: '20px' }}>Physician Texting Dashboard</Typography>
            </Stack>
            <GenericTable 
                data={fetchedConversations} 
                dataType='conversation'
                headers={tableHeaders}
                isDataLoaded={isSuccess}
                page={page}
                setPage={setPage}
                total={fetchedConversations.length}
            />
        </Stack>
    )
}

export default PhysicianTexting;
