import React from 'react';
import { Button, Typography, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useLogUserActionMutation } from '../../../../api/physicianDashboardSlice';
import LogOnEnter from '../../../CustomComponents/LogOnEnter';

function Confidentiality(props) {
    const [logPhysicianAction] = useLogUserActionMutation();

    async function handleAccept() {
        await logPhysicianAction({
            action: 'accepted_confidentiality_notice',
            data: {},
        });

        props.setHasAcceptedConfidentiality(true);
        sessionStorage.setItem('hasAcceptedConfidentiality', true);
    }

    return (
        <Box p={2} sx={{ backgroundColor: 'white', borderRadius: '4px', position: 'absolute', top: '8%', width: { xs: '80%', md: '40%' } }}>
            <LogOnEnter action='viewed_confidentiality_notice' data={{}}>
                <Stack spacing={3} sx={{ display: 'grid' }}>
                    <Typography variant='heavy' sx={{ fontSize: '16px', textAlign: 'center' }}><strong>Confidentiality Notice</strong></Typography>
                    <Typography variant='normal' sx={{ fontSize: '14px', textAlign: 'center' }}>All information contained in this dashboard is confidential and should not be shared with people outside the Scripps Health system.</Typography>
                    <Button variant='blueContained' sx={{ justifySelf: 'center', fontSize: '12px' }} onClick={handleAccept}>I Understand</Button>
                </Stack>
            </LogOnEnter>
        </Box>
    );
}


export default Confidentiality;
