import React from 'react';
import CardRow from './Rows/CardRow';
import InventoryRow from './Rows/InventoryRow';
import PhysicianRow from './Rows/PhysicianRow';
import UserRow from './Rows/UserRow';
import ConversationRow from './Rows/ConversationRow';

function GenericTableRow(props) {
    if (props.dataType === 'physician') {
        return <PhysicianRow physician={props.data} />;
    } else if (props.dataType === 'item') {
        return <InventoryRow item={props.data} />;
    } else if (props.dataType === 'card') {
        return <CardRow card={props.data} />;
    } else if (props.dataType === 'user') {
        return <UserRow user={props.data} />;
    } else if (props.dataType === 'conversation') {
        return <ConversationRow conversation={props.data} />;
    }
}

export default GenericTableRow;
