import { LinearProgress, Stack, Typography } from '@mui/material';
import { linearProgressClasses } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import React from 'react';
import { styled } from '@mui/material/styles';

function ProgressBar(props) {
    const theme = useTheme();

    return (
        <Stack spacing={0.5} sx={{ display: 'flex', alignItems: 'end' }}>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant='normal' sx ={{ fontSize: '10px', color: `${theme.palette.subtext.main}` }}>
                    {props.label}: <Typography variant='normal' sx={{ fontSize: '12px', color: 'black'}}>{Math.round(props.efficiency * 100)}%</Typography>
                </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
                <CardCostEfficiencyBar variant='determinate' value={Math.round(props.efficiency * 100)} sx={{ height: '8', borderRadius: '4px', backgroundColor: '#0000001F' }} />
            </Box>
        </Stack>
    );
}

const CardCostEfficiencyBar = styled(LinearProgress)(({ theme }) => ({
    [`& .${linearProgressClasses.bar1Determinate}`]: {
        backgroundColor: theme.palette.blue.main,
    }
}));

export default ProgressBar;