import React, { useMemo } from 'react';
import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { parseISO, format, differenceInMinutes } from 'date-fns';
import useTheme from '@mui/system/useTheme';
import { ChevronRightRounded } from '@mui/icons-material';


function ReviewEntry(props) {
    const navigate = useNavigate();
    const theme = useTheme();
    const numberOfRecsToReview = useMemo(() => {
        var numToReturn = 0;
        Object.keys(props.draft.recommendations).forEach((category) => {
            props.draft.recommendations[category].forEach((rec) => {
                numToReturn += rec.review.length;
            });
        });
        return numToReturn;
    }, [props.draft.recommendations]);
    const numberOfChangesMade = useMemo(() => {
        var numToReturn = 0;
        Object.keys(props.draft.recommendations).forEach((category) => {
            props.draft.recommendations[category].forEach((rec) => {
                if (rec.status === 'accepted') {
                    numToReturn += 1;
                }
            });
        });
        return numToReturn;
    }, [props.draft.recommendations]);

    function isDraftCheckedOut() {
        const checkoutStatus = props.draft.checkout;
        if (!checkoutStatus) {
            return false;
        }

        const currentTime = new Date();
        const timeSinceLastAction = differenceInMinutes(currentTime, new Date(props.draft.last_staff_review_action_time));
        const timeSinceCheckout = differenceInMinutes(currentTime, new Date(checkoutStatus.time_of_action));

        if (checkoutStatus.action === 'checked_out' && (timeSinceLastAction < 15 || timeSinceCheckout < 15)) {
            return true;
        }

        return false;
    }

    if (props.variant === 'completed') {
        return (
            <Stack onClick={() => navigate(`/staffReview/${props.draft.id}`)} p={2} alignItems='center' justifyContent='space-between' direction='row' backgroundColor='white' borderRadius='8px' style={{ cursor: 'pointer' }}>
                <Stack direction='row' spacing={3} width='50%' alignItems='center'>
                    <Stack width='30%'>
                        <Typography variant='heavy' fontSize='16px'>{props.draft.card.name}</Typography>
                        <Typography variant='normal' fontSize='12px'>Dr. {props.draft.card.doctor.name}</Typography>
                    </Stack>
                    <Stack>
                        <Typography variant='normal' fontSize='16px' color={theme.palette.hold.main}>{numberOfChangesMade} change{numberOfChangesMade === 1 ? '' : 's'} made to card</Typography>
                        <Typography variant='normal' fontSize='12px' color={theme.palette.hold.main}>Review Completed: {props.draft.staff_review_completion_time ? format(parseISO(props.draft.staff_review_completion_time), 'MM/dd/yyyy hh:mm a') : ''}</Typography>
                    </Stack>
                </Stack>
                <ChevronRightRounded />
            </Stack>
        );
    }


    return (
        <Stack onClick={() => navigate(`/staffReview/${props.draft.id}`)} boxShadow={2} p={2} alignItems='center' justifyContent='space-between' direction='row' backgroundColor='white' borderRadius='8px' style={{ cursor: 'pointer' }}>
            <Stack direction='row' spacing={3} width='70%' alignItems='center'>
                <Stack width='30%'>
                    <Typography variant='heavy' fontSize='16px'>{props.draft.card.name}</Typography>
                    <Typography variant='normal' fontSize='14px'>Dr. {props.draft.card.doctor.name}</Typography>
                    <Stack>
                        { isDraftCheckedOut() &&
                            <Typography variant='normal' fontSize='12px'><strong>Note:</strong> Card currently being reviewed by {props.draft.checkout.reviewer.name}</Typography>
                        }
                    </Stack>
                </Stack>
                <Stack>
                    <Typography variant='normal' fontSize='16px' color={theme.palette.hold.main}>{numberOfRecsToReview} recommendation{numberOfRecsToReview === 1 ? '' : 's'} to review</Typography>
                </Stack>
            </Stack>
            <ChevronRightRounded />
        </Stack>
    );
}


export default ReviewEntry;
