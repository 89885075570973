import React, { useContext } from 'react';
import { Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Cancel, Check, Edit } from '@mui/icons-material';
import { useState } from 'react';
import { useUpdateCardMutation } from '../../api/cardSlice';
import { UserContext } from '../Hooks/useUser';
import ProcedureList from '../PhysicianDashboard/CardReview/ProcedureList';

function CardTitle(props) {
    const [cardName, setCardName] = useState(props.card.name);
    const [isEditing, setIsEditing] = useState(false);
    const [isShowingProcedureList, setIsShowingProcedureList] = useState(false);
    const [updateCard] = useUpdateCardMutation();
    const { hasCardManagement } = useContext(UserContext);

    const handleSubmit = async() => {
        await updateCard({
            card: props.card,
            requestData: {
                name: cardName
            }
        });
        setIsEditing(false);
    }
    
    function handleCancel() {
        setCardName(props.card.name);
        setIsEditing(false);
    }

    if (!isEditing) {
        return (
            <Stack direction='row' spacing={1} alignItems='center'>
                <Typography variant='heavy' sx={{ fontSize: '16px', letterSpacing: '0.18px' , fontWeight:'700'}}>
                    {props.card.name}
                </Typography>
                { hasCardManagement() && 
                    <IconButton sx={{ padding: '12px' }} onClick={() => setIsEditing(true)}>
                        <Edit fontSize='small' />
                    </IconButton>
                }
                { props.card.procedures.length > 0 &&
                    <>
                        <Button variant='outlined' size='small' onClick={() => setIsShowingProcedureList(true)}>
                            View Procedures
                        </Button>
                        <ProcedureList open={isShowingProcedureList} handleClose={() => setIsShowingProcedureList(false)} procedures={props.card.procedures} cardName={props.card.name} />
                    </>
                }
            </Stack>
        );
    } else if (isEditing) {
        return (
            <Stack direction='row' spacing={1} alignItems='center'>
                <TextField variant='standard' fullWidth value={cardName} onChange={(e) => setCardName(e.target.value)} />
                <IconButton sx={{ padding: '12px' }} onClick={handleSubmit}>
                    <Check />
                </IconButton>
                <IconButton sx={{ padding: '12px' }} onClick={handleCancel}>
                    <Cancel />
                </IconButton>
                { props.card.procedures.length > 0 &&
                    <>
                        <Button variant='outlined' size='small' onClick={() => setIsShowingProcedureList(true)}>
                            View Procedures
                        </Button>
                        <ProcedureList open={isShowingProcedureList} handleClose={() => setIsShowingProcedureList(false)} procedures={props.card.procedures} cardName={props.card.name} />
                    </>
                }
            </Stack>
        );
    }
}

export default CardTitle;