import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

function FlaggedReviewInfo(props) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>
                Flagged Review
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This review has been flagged by {props.flag.user.name} for the following reason:
                </DialogContentText>
                <DialogContentText>
                    {props.flag.reason}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={props.handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FlaggedReviewInfo;
