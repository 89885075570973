import {useState} from 'react';

export default function useStaffReviewToken() {
    const getToken = () => {
        return localStorage.getItem('reviewToken');
    }

    const [token, setToken] = useState(getToken());

    const saveToken = reviewToken => {
        if (reviewToken === null) {
            localStorage.removeItem('reviewToken');
        } else {
            localStorage.setItem('reviewToken', reviewToken);
        }
        setToken(reviewToken);
    }

    return {
        setStaffReviewToken: saveToken,
        staffReviewToken: token
    }
}
