import { Add, DeleteRounded, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Button, Collapse, Dialog, DialogActions, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDeleteUserAssignmentMutation, useGetHospitalReviewersQuery } from '../../../../api/apiSlice';
import AddReviewerAssignmentDialog from './AddReviewerAssignmentDialog';

function ReviewerAssignment() {
    const params = useParams();
    const { data: reviewers = [] } = useGetHospitalReviewersQuery(params.hospitalId);

    return (
        <Stack px={4} pb={6} spacing={2}>
            <Typography variant='heavy' fontSize='20px'>Reviewer Assignment</Typography>
            { reviewers.map((reviewer) => (
                <ReviewerEntry hospitalId={params.hospitalId} reviewer={reviewer} key={reviewer.id} />
            ))}
        </Stack>
    );
}

function ReviewerEntry(props) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isShowingAddAssignmentDialog, setIsShowingAddAssignmentDialog] = useState(false);

    const assignedSpecialties = useMemo(() => {
        const uniqueSpecialties = new Set();
        props.reviewer.assigned_cards.forEach((card) => {
            uniqueSpecialties.add(card.owner.specialty);
        });
        return uniqueSpecialties;
    }, [props.reviewer]);

    const assignedPhysicians = useMemo(() => {
        const uniquePhysicianIds = new Set();
        const uniquePhysicians = new Set();
        props.reviewer.assigned_cards.forEach((card) => {
            if (!uniquePhysicianIds.has(card.owner.id)) {
                uniquePhysicianIds.add(card.owner.id);
                uniquePhysicians.add(card.owner);
            }
        });
        return uniquePhysicians;
    }, [props.reviewer]);

    const numberOfAssignedCards = useMemo(() => {
        return props.reviewer.assigned_cards.length;
    }, [props.reviewer]);

    function handleAssignMoreCardsClick(event) {
        event.stopPropagation();
        setIsShowingAddAssignmentDialog(true);
    }

    return (
        <>
            <Stack direction='row' spacing={2} p={2} backgroundColor='white' borderRadius='8px'>
                <Stack width='100%'>
                    <Stack direction='row' width='100%' justifyContent='space-between' alignItems='center' onClick={() => setIsExpanded(!isExpanded)} style={{ cursor: 'pointer' }}>
                        <Stack>
                            <Typography>{props.reviewer.name}</Typography>
                            <Typography>{assignedSpecialties.size} assigned specialties, {assignedPhysicians.size} assigned physicians, {numberOfAssignedCards} assigned cards</Typography>
                        </Stack>
                        <Stack direction='row' spacing={3} alignItems='center'>
                            <Button variant='contained' onClick={(e) => handleAssignMoreCardsClick(e)} startIcon={<Add />}>Assign More Cards</Button>
                            { isExpanded ? <ExpandLess /> : <ExpandMore /> }    
                        </Stack>
                        
                    </Stack>
                    <Collapse in={isExpanded} timeout='auto' unmountOnExit>
                        <AssigneeList 
                            assignedSpecialties={Array.from(assignedSpecialties)} 
                            assignedPhysicians={Array.from(assignedPhysicians)} 
                            cards={props.reviewer.assigned_cards} 
                            reviewer={props.reviewer}
                        /> 
                    </Collapse>
                </Stack>
            </Stack>
            <AddReviewerAssignmentDialog 
                reviewer={props.reviewer}
                assignedCards={props.reviewer.assigned_cards}
                hospitalId={props.hospitalId}
                open={isShowingAddAssignmentDialog}
                onClose={() => setIsShowingAddAssignmentDialog(false)}
            />
        </>
    )
}

function AssigneeList(props) {
    return (
        <List
            sx={{ width: '100%' }}
        >
            {props.assignedSpecialties.map((specialty) => (
                <SpecialtyEntry 
                    specialty={specialty} 
                    key={specialty} 
                    physicians={props.assignedPhysicians.filter((p) => p.specialty === specialty)} 
                    cards={props.cards.filter((c) => c.owner.specialty === specialty)} 
                    reviewer={props.reviewer}
                />    
            ))}
        </List>
    )
}

function SpecialtyEntry(props) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <ListItem
                disablePadding
            >
                <ListItemButton onClick={() => setIsOpen(!isOpen)}>
                    <ListItemText>
                        {props.specialty}
                    </ListItemText>
                    { isOpen ? <ExpandLess /> : <ExpandMore /> }        
                </ListItemButton>
            </ListItem>
            <Collapse in={isOpen} timeout='auto' unmountOnExit>
                <List>
                    { props.physicians.map((physician) => (
                        <PhysicianEntry 
                            physician={physician} 
                            key={physician.id} 
                            cards={props.cards.filter((c) => c.owner.id === physician.id)}
                            reviewer={props.reviewer}
                        />
                    ))}
                </List>
            </Collapse>
        </>
    );
}

function PhysicianEntry(props) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <ListItem sx={{ pl: 4 }}>
                <ListItemButton onClick={() => setIsOpen(!isOpen)}>
                    <ListItemText>
                        Dr. {props.physician.first_name + ' ' + props.physician.last_name}
                    </ListItemText>
                    { isOpen ? <ExpandLess /> : <ExpandMore /> }
                </ListItemButton>
            </ListItem>
            <Collapse in={isOpen} timeout='auto' unmountOnExit>
                <List>
                    { props.cards.map((card) => (
                        <CardEntry card={card} key={card.id} reviewer={props.reviewer} />
                    ))}
                </List>
            </Collapse>
        </>
    );
}

function CardEntry(props) {
    const [removeCardFromReviewer] = useDeleteUserAssignmentMutation();
    const [isShowingConfirmation, setIsShowingConfirmation] = useState(false);

    async function handleDeleteClick() {
        await removeCardFromReviewer({
            card: props.card,
            reviewer: props.reviewer
        });
        setIsShowingConfirmation(false);
    }

    return (
        <>
            <ListItem sx={{ pl: 10 }}>
                <ListItemIcon>
                    <IconButton onClick={() => setIsShowingConfirmation(true)}>
                        <DeleteRounded />
                    </IconButton>
                </ListItemIcon>
                <ListItemText>
                    {props.card.name}
                </ListItemText>
            </ListItem>
            <ConfirmDeleteAssignment
                open={isShowingConfirmation}
                handleClose={() => setIsShowingConfirmation(false)}
                handleDelete={handleDeleteClick}
            />
        </>
    );
}

function ConfirmDeleteAssignment(props) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>
                Are you sure you want to remove this card from this reviewer?
            </DialogTitle>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={props.handleDelete} color='error'>Delete</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ReviewerAssignment;
