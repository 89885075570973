import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

export const CustomLinearProgress = styled(LinearProgress)(({ value, average, theme, size }) => ({
    height: size === 'large' ? 18 : 14,
    borderRadius: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#EDEDED',
    },
    [`& .${linearProgressClasses.bar1Determinate}`]: {
        backgroundColor: theme.palette.blue.main,
        display: 'grid',
        zIndex: 2,
        [' &:after']: {
            content: `"${Math.round(value)}%"`,
            color: 'white',
            zIndex: 3,
            position: 'absolute',
            fontSize: 12,
            justifySelf: 'end',
            alignSelf: 'center',
            paddingRight: 4,
            fontFamily: 'Roboto',
            fontWeight: 500,
            letterSpacing: 0.18 
        }
    },
    '&:after': {
        content: '""',
        position: 'absolute',
        width: `${average}%`,
        height: size === 'large' ? 18 : 14,
        zIndex: 3,
        borderRight: (value - average >= 0 && value-average < 7) ? '' : '2px solid #000000',
    }
}));