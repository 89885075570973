import React from 'react';
import { Stack, Typography } from '@mui/material';

function TableHeader() {
    return (
        <Stack direction='row' borderBottom='1px solid rgba(0, 0, 0, 0.5)' backgroundColor='rgb(0, 0, 0, 0.15)'>
            <Stack width='22%' borderRight='1px solid rgba(0, 0, 0, 0.5)' py={1} px={2} display='grid' justifyItems='start'>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}>Current Item</Typography>
            </Stack>
            <Stack width='7%' borderRight='1px solid rgba(0, 0, 0, 0.5)' py={1} display='grid' justifyItems='center'>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}>Open</Typography>
            </Stack>
            <Stack width='7%' borderRight='1px solid rgba(0, 0, 0, 0.5)' py={1} display='grid' justifyItems='center'>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}>Hold</Typography>
            </Stack>
            <Stack width='13%' borderRight='1px solid rgba(0, 0, 0, 0.5)' py={1} display='grid' justifyItems='center'>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}>Type of Change</Typography>
            </Stack>
            <Stack width='7%' borderRight='1px solid rgba(0, 0, 0, 0.5)' py={1} display='grid' justifyItems='center'>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}>New Open</Typography>
            </Stack>
            <Stack width='7%' borderRight='1px solid rgba(0, 0, 0, 0.5)' py={1} display='grid' justifyItems='center'>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}>New Hold</Typography>
            </Stack>
            <Stack width='22%' borderRight='1px solid rgba(0, 0, 0, 0.5)' py={1} px={2} display='grid' justifyItems='start'>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}>New Item</Typography>
            </Stack>
            <Stack width='15%' py={1} px={2} display='grid' justifyItems='start'>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}>Status</Typography>
            </Stack>
        </Stack>
    )
}

export default TableHeader;
