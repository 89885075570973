import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/system';


function AverageCostChip(props) {
    const theme = useTheme();

    const diffToAverageCost = Math.round(((props.draft.total_card_spend - props.draft.average_procedure_card_spend) / props.draft.average_procedure_card_spend) * 100);

    if (diffToAverageCost > 0) {
        return (
            <Box py={0.5} px={1} sx={{ color: `${theme.palette.red.main}`, backgroundColor: `${theme.palette.background.negative}`, borderRadius: '4px', fontSize: '10px' }}>
                <Typography variant='heavy'>
                    {diffToAverageCost}% Above Average
                </Typography>
            </Box>
        );
    } else if (diffToAverageCost < 0) {
        return (
            <Box py={0.5} px={1} sx={{ color: `${theme.palette.blue.main}`, backgroundColor: `${theme.palette.background.positive}`, borderRadius: '4px', fontSize: '10px' }}>
                <Typography variant='heavy'>
                    {Math.abs(diffToAverageCost)}% Below Average
                </Typography>
            </Box>
        );
    }
    return <></>;
}

export default AverageCostChip;
