import React, { useMemo, useContext, useEffect } from 'react';
import { useGetItemCategoriesQuery, useSearchItemsQuery } from '../../api/inventorySlice';
import { Stack, InputAdornment, Paper, TextField, Autocomplete, Button, Typography } from '@mui/material';
import { Search, FilterList, Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../Hooks/useUser';
import GenericTable from '../Tables/GenericTable';
import useTableSearch from '../Hooks/useTableSearch';

function Inventory() {
    const navigate = useNavigate();
    const { user, hasInventoryManagement } = useContext(UserContext);

    const {
        searchTerm,
        filter: selectedCategory,
        handleSearchTermChange,
        handleFilterChange,
        page,
        setPage,
        resetSearch,
    } = useTableSearch('items');

    const {
        data: fetchedInventory = {},
    } = useSearchItemsQuery({
        hospitalId: user.hospital.id,
        queryParams: {
            page: page,
            search: encodeURIComponent(searchTerm ?? ''),
            category: encodeURIComponent(selectedCategory ?? '')
        }
    });

    const {
        data: itemCategories = []
    } = useGetItemCategoriesQuery(user.hospital.id);

    const sortedItemCategoryNames = useMemo(() => {
        return itemCategories
            .map(category => category.name)
            .sort((a, b) => a.localeCompare(b));
    }, [itemCategories]);


    const tablePage = useMemo(() => {
        return page - 1;
    }, [page]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
        sessionStorage.setItem('itemsPage', newPage);
    };

    const totalEntries = useMemo(() => {
        if (fetchedInventory.meta) {
            return fetchedInventory.meta.total;
        }

        return 0;
    }, [fetchedInventory]);

    useEffect(() => {
        resetSearch();
    }, [user.hospital.id]);

    const tableHeaders = [
        'Item Name',
        'Manufacturer',
        'Reference Number',
        'Category',
        'Price (Each)',
        'Status'
    ];

    return (
        <Stack spacing={2} px={{ xs: 2, md: 6 }} pb={2} sx={{ height: '100%' }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: '20px', letterSpacing: '0.15px' }}>
                    Inventory
                </Typography>
                {hasInventoryManagement() &&
                    <Button variant='contained' size='small' sx={{ display: { xs: 'inline-flex', md: 'none' } }} onClick={() => navigate('/inventory/create')} startIcon={<Add />}>
                        Add New Item
                    </Button>
                }
            </Stack>
            <Stack direction='row' justifyContent='space-between' alignItems='end'>
                <Stack direction='row' justifyContent={{ xs: 'space-between', md: 'start' }} spacing={2} width={{ xs: '100%', md: '70%', lg: '60%' }}>
                    <Paper elevation={2} sx={{ width: '50%' }}>
                        <TextField
                            placeholder="Search Inventory"
                            value={searchTerm}
                            onChangeCapture={handleSearchTermChange}
                            size='small'
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Paper>
                    <Paper elevation={2} sx={{ width: '50%' }}>
                        <Autocomplete
                            options={sortedItemCategoryNames}
                            getOptionLabel={(option) => option}
                            value={selectedCategory}
                            onChange={handleFilterChange}
                            size='small'
                            blurOnSelect
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder='Filter by category'
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <FilterList />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            )}
                        />
                    </Paper>
                </Stack>
                {hasInventoryManagement() &&
                    <Paper elevation={2} sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
                        <Button variant='contained' size='small' onClick={() => navigate('/inventory/create')} startIcon={<Add />}>
                            Add New Item
                        </Button>
                    </Paper>
                }
            </Stack>
            <GenericTable
                data={fetchedInventory.data}
                dataType='item'
                headers={tableHeaders}
                page={tablePage}
                setPage={handlePageChange}
                total={totalEntries}

            />
        </Stack>
    )
}

export default Inventory;
