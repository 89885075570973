import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, Box } from '@mui/system';
import { Stack, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { Circle, ChevronRightRounded } from '@mui/icons-material';
import AverageCostChip from './AverageCostChip';

function ActiveCardEntry(props) {
    const navigate = useNavigate();
    const theme = useTheme();

    function handleClick() {
        sessionStorage.setItem('cardListScrollPosition', window.scrollY);
        navigate(`/physician/drafts/${props.draft.id}`, { state: {
            physician: props.physician
        }});
    }

    return (
        <Stack direction='row' justifyContent='space-between' onClick={handleClick} p={2} sx={{ backgroundColor: '#FFFFFF', borderRadius: '4px', boxShadow: 2 }} alignItems='center'>
            <Stack spacing={0.5} width='70%'>
                <Box sx={{ fontSize: '14px', color: '#000000DE', overflowWrap: 'anywhere' }}>
                    <Typography variant='normal'>{props.draft.card.name}</Typography>
                </Box>
                <Stack direction='row' spacing={1} alignItems='center'>
                    <Box sx={{ maxHeight: '4px', maxWidth: '4px', color: props.draft.status === 'review' ? '#FF9500' : `${theme.palette.blue.main}` }}>
                        <Circle sx={{ maxHeight: '4px', maxWidth: '4px' }} />
                    </Box>
                    <Stack direction='row' spacing={1} sx={{ fontSize: '12px', color: '#00000099' }} alignItems='center'>
                        { props.draft.status === 'review' && 
                            <Typography variant='normal'>In Review</Typography>
                        }
                        { props.draft.status === 'open' && 
                            <Typography variant='normal'>
                                <NumericFormat 
                                    value={props.draft.total_card_carry / 100}
                                    thousandSeparator={true}
                                    displayType={'text'}
                                    prefix={'$'}
                                    decimalScale={0}
                                /> Card Cost
                            </Typography>
                        }
                        { props.draft.status === 'open' &&
                            <AverageCostChip draft={props.draft} />
                        }
                    </Stack>
                </Stack>
            </Stack>
            <Stack direction='row' alignItems='center' spacing={0.5}>
                { props.campaignHasOptimizations ? 
                    <Stack justifyContent='center' alignItems='center'>
                        <Typography variant='normal' sx={{ fontSize: '14px' }}>
                            <NumericFormat
                                value={Math.round(props.draft.cost_reduction_opportunity / 100)}
                                thousandSeparator={true}
                                displayType={'text'}
                                prefix={'$'}
                            />
                        </Typography>
                        <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}`, textAlign: 'center' }}> 
                            Cost Reduction Opportunity /yr
                        </Typography>
                    </Stack>
                    :
                    props.draft.card.yearly_stats && <Stack py={0.25} px={0.5} justifyContent='center' alignItems='center'>
                        <Typography variant='normal' sx={{ fontSize: '14px' }}>
                            {props.draft.card.yearly_stats.usage_count} Cases
                        </Typography>
                        <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>
                            Est. Per Year
                        </Typography>
                    </Stack>
                }
                <ChevronRightRounded sx={{ opacity : '0.6', alignSelf: 'end' }}/>
            </Stack>
        </Stack>
    );
}

export default ActiveCardEntry;
