import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React from 'react';

function DisableConfirmation(props) {
    function handleClose() {
        props.setOpen(false);
    }

    function handleConfirm() {
        props.confirmDisable();
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to disable this user?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleConfirm}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DisableConfirmation;
