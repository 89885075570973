import React, { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import ReviewCardsTask from './ReviewCardsTask';
import ReviewItemSwapsTask from './ReviewItemSwapsTask';
import ReviewComplianceSwapsTask from './ReviewComplianceSwapsTask';
import LogOnEnter from '../../CustomComponents/LogOnEnter';

function TaskList(props) {
    const shouldShowItemSwaps = () => {
        return props.headlineData?.tasks?.total_swaps > 0;
    }

    const shouldShowComplianceSwaps = () => {
        return props.headlineData?.tasks?.total_compliance_swaps > 0;
    }

    const shouldShowCardsTask = () => {
        return props.headlineData?.tasks?.active_drafts > 0;
    }

    const hasOptimizations = useMemo(() => {
        return props.headlineData.optimizations_enabled;
    }, [props.headlineData.optimizations_enabled]);

    return (
        <LogOnEnter action='viewed_task_list' data={{}}>
            <Stack pt={hasOptimizations ? 2 : 0}>
                <Typography variant='heavy' sx={{ 'fontSize': '16px' }}>Things To Do</Typography>
                <Stack pt={2} spacing={4}>
                    { shouldShowItemSwaps() && 
                        <ReviewItemSwapsTask 
                            cardOwner={props.cardOwner} 
                            totalRecs={props.headlineData?.tasks?.total_swaps}
                            activeRecs={props.headlineData?.tasks?.active_swaps}
                            campaign={props.campaign}
                        />
                    }
                    { shouldShowComplianceSwaps() &&
                        <ReviewComplianceSwapsTask 
                            cardOwner={props.cardOwner} 
                            totalRecs={props.headlineData?.tasks?.total_compliance_swaps} 
                            activeRecs={props.headlineData?.tasks?.active_compliance_swaps} 
                            complianceDebt={props.headlineData?.compliance_debt}
                            campaign={props.campaign}
                        />
                    }
                    { shouldShowCardsTask() && 
                        <ReviewCardsTask 
                            cardOwner={props.cardOwner} 
                            drafts={props.drafts} 
                            campaignHasOptimizations={hasOptimizations}
                        />
                    }
                </Stack>
            </Stack>
        </LogOnEnter>
    );
}

export default TaskList;