import { InfoOutlined } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React, { useMemo, useState } from 'react';
import useAnalytics from '../../../Hooks/useAnalytics';
import CardEfficiencyDialog from './CardEfficiencyDialog';
import LeaderboardRankings from './LeaderboardRankings';
import HeadlineTile from '../HeadlineTile';
import ContractComplianceDialog from './ContractComplianceDialog';
import LogOnEnter from '../../../CustomComponents/LogOnEnter';

function PeerLeaderboard(props) {
    const [isShowingInfo, setIsShowingInfo] = useState(false);
    const theme = useTheme();
    const analytics = useAnalytics();

    function handleOpenInfo() {
        setIsShowingInfo(true);
        analytics.track('Leaderboard Info Opened', {
            sector: 'Physician Dashboard'
        });
    }

    const sortedLeaderboard = useMemo(() => {
        if (!props.headlineData?.leaderboard) {
            return [];
        }
        return [...props.headlineData?.leaderboard].sort((a, b) => {
            return b.efficiency - a.efficiency;
        });
    }, [props.headlineData?.leaderboard]);

    if (props.headlineData?.compliance_leaderboard) {
        return (
            <HeadlineTile className='pendo-compliance-leaderboard'>
                <LogOnEnter action='viewed_compliance_leaderboard' data={{}}>
                    <Stack spacing={1.5}>
                        <Stack direction='row' alignItems='center' justifyContent='space-between'>
                            <Stack>
                                <Typography variant='heavy' sx={{ fontSize: '16px' }}>
                                    Contract Compliance Leaders
                                </Typography>
                                <Typography variant='normal' sx={{ fontSize: '12px' }}>{Object.keys(props.siteCompliance)[0]}</Typography>
                            </Stack>
                            <IconButton fontSize='16px' onClick={handleOpenInfo}>
                                <InfoOutlined sx={{ color: `${theme.palette.blue.main}` }} />
                            </IconButton>
                            <ContractComplianceDialog isShowingInfo={isShowingInfo} setIsShowingInfo={setIsShowingInfo} />
                        </Stack>
                        <Stack direction='row' justifyContent='space-between'>
                            <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>
                                Physician
                            </Typography>
                            <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>
                                Contract Compliance
                            </Typography>
                        </Stack>
                        <LeaderboardRankings 
                            variant='compliance' 
                            cardOwner={props.cardOwner} 
                            userPercentile={props.headlineData?.user_percentile} 
                            rankings={props.headlineData?.compliance_leaderboard}
                        /> 
                    </Stack>
                </LogOnEnter>
            </HeadlineTile>
        )
    }

    return (
        <HeadlineTile className='pendo-leaderboard'>
            <LogOnEnter action='viewed_efficiency_leaderboard' data={{}}>
                <Stack spacing={1.5}>
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <Typography variant='heavy' sx={{ fontSize: '16px' }}>
                            Peer Leaderboard
                        </Typography>
                        <IconButton fontSize='16px' onClick={handleOpenInfo}>
                            <InfoOutlined sx={{ color: `${theme.palette.blue.main}` }} />
                        </IconButton>
                        <CardEfficiencyDialog isShowingInfo={isShowingInfo} setIsShowingInfo={setIsShowingInfo} />
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>
                            Physician
                        </Typography>
                        <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>
                            Overall Cost Efficiency
                        </Typography>
                    </Stack>
                    <LeaderboardRankings 
                        variant='efficiency' 
                        cardOwner={props.cardOwner} 
                        userEfficiency={props.headlineData?.efficiency?.current}
                        userPercentile={props.headlineData?.user_percentile} 
                        rankings={sortedLeaderboard}
                    /> 
                </Stack>
            </LogOnEnter>
        </HeadlineTile>
    );
}

export default PeerLeaderboard;
