import axios from 'axios';

window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios').default;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
require('dotenv').config();


switch(process.env.ENVIRONMENT_SETTINGS) {
case 'local':
    axios.defaults.baseURL = 'http://localhost:8000';
    break;
case 'staging':
    axios.defaults.baseURL = 'https://staging.docsihealth.com';
    break;
case 'demo':
    axios.defaults.baseURL = 'https://demo.docsihealth.com';
    break;
case 'production':
    axios.defaults.baseURL = 'https://api.docsihealth.com';
    break;
}

axios.defaults.withCredentials = true;
axios.interceptors.request.use(
    config => {
        if (!config.headers.Authorization) {
            const token = localStorage.getItem('token');

            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }

        return config;
    },
    error => Promise.reject(error)
);



/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true,
//     auth: {
//         headers: {
//             'authorization': `Bearer ${localStorage.getItem('token')}`
//         }
//     },
//     namespace: 'App.Providers.App.Events'
// });
