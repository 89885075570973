import { Alert, Button, Checkbox, FormControlLabel, Grid, Input, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import format from 'date-fns/format/index.js';
import parse from 'date-fns/parse/index.js';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateCampaignMutation, useGetCampaignTemplatesQuery } from '../../api/campaignSlice';
import { UserContext } from '../Hooks/useUser';

function CreateCampaign(){
    const { user } = useContext(UserContext);
    const { data: campaignTemplates } = useGetCampaignTemplatesQuery(user.hospital.id);
    const [isTestCampaign, setIsTestCampaign] = useState(false);
    const [name, setName] = useState('');
    const [startDate, setStartDate] = useState(format(new Date(),'yyyy-MM-dd'));
    const [endDate, setEndDate] = useState('');
    const [submitCreateCampaign] = useCreateCampaignMutation();
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async()=>{
        setShowError(false);
        try{
            await submitCreateCampaign({
                hospitalId : user.hospital.id,
                requestBody: {
                    name: name,
                    start_date : parse(startDate, 'yyyy-MM-dd', new Date()).toISOString(),
                    end_date : endDate.length === 0 ? undefined : parse(endDate, 'yyyy-MM-dd', new Date()).toISOString(),
                    status: 'open',
                    is_test_campaign : isTestCampaign,
                    base_template_id : selectedTemplate ? selectedTemplate.id : undefined
                }
            }).unwrap();
            setShowSuccess(true);
            setTimeout(() => 
            { 
                navigate('/campaigns');
            }, 2000);
        }
        catch(error){
            console.log(error);
            setShowError(true);
        }
    }

    return (
        <Grid container ml={0} spacing={2}>
            <Grid item xs={4}>
                <Typography variant='heavy' sx={{ fontSize: '20px' }} pt={2} paddingBottom ={2}>
                    New Campaign
                </Typography>
                <Box paddingBottom={2}>
                    {
                        showError &&<Alert  severity="error">Campaign Creation Unsuccessful, Please Try Again</Alert>
                    }
                
                    {
                        showSuccess&&<Alert severity="success">Campaign Creation Successful</Alert>
                    }
                </Box>
                <Paper elevation={2}>
                    <Stack spacing={3} p={4}> 
                        <Typography variant='heavy' sx={{ fontSize: '16px' }}>Hospital: {user.hospital.name}</Typography>
                        <TextField
                            variant='outlined' 
                            label='Campaign Name' 
                            fullWidth
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Stack direction='row' justifyContent='space-between'>
                            <Stack spacing={1}>
                                <Box>
                                    Start Date (required) : 
                                </Box>

                                <Box>
                                    <Input value = {startDate} required = {true} onChange={(e) =>{setStartDate(e.target.value)}} type = 'date'/>
                                </Box>
                            </Stack>
                            
                            <Stack spacing={1}>
                                <Box>
                                    End Date (not required): 
                                </Box>

                                <Box>
                                    <Input onChange={(e) =>{setEndDate(e.target.value)}} type = 'date'/>
                                </Box>                                
                            </Stack>
                        </Stack>
                        
                        <Box alignSelf='end'>
                            <FormControlLabel control={
                                <Checkbox 
                                    checked={isTestCampaign}
                                    onChange={(e) => setIsTestCampaign(e.target.checked)}
                                />
                            } label="Is Test Campaign"/>
                        </Box>

                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                            <Select
                                value={selectedTemplate}
                                onChange={(e) => setSelectedTemplate(e.target.value)}
                                displayEmpty
                                fullWidth
                            >
                                <MenuItem value={''}>Select a Template</MenuItem>
                                { campaignTemplates && campaignTemplates.map((template) => (
                                    <MenuItem key={template.id} value={template}>{template.name}</MenuItem>
                                ))}
                            </Select>
                            <Button 
                                variant='text' 
                                color='primary' 
                                onClick={handleSubmit}
                            >
                                Create Campaign
                            </Button>
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default CreateCampaign;
