import React from 'react';
import { Stack, Typography } from '@mui/material';
import SortableTableHeader from '../../CustomComponents/SortableTableHeader';


function OverviewHeader(props) {
    const headers = [
        {
            id: 'location',
            label: 'Location',
            tooltip: null,
            ratio: 10,
            justify: 'start',
            sortable: true,
        },
        {
            id: 'specialties',
            label: 'Specialties',
            tooltip: null,
            ratio: 10,
            justify: 'start',
            sortable: true,
        },
        {
            id: 'start_date',
            label: 'Start Date',
            tooltip: null,
            ratio: 8,
            justify: 'start',
            sortable: true,
        },
        {
            id: 'status',
            label: 'Status',
            tooltip: null,
            ratio: 10,
            justify: 'start',
            sortable: true,
        },
        {
            id: 'captured_value',
            label: 'Captured / Opportunity',
            tooltip: <Stack spacing={1}>
                <Typography variant='normal' fontSize='12px'><strong>Captured Value</strong> is the annual projected savings value of all optimizations in this campaign that have been approved by the card management team.</Typography>
                <Typography variant='normal' fontSize='12px'><strong>Opportunity Value</strong> is the annual projected savings value of all optimizations in this campaign.</Typography>
            </Stack>,
            ratio: 14,
            justify: 'end',
            sortable: true,
        },
        {
            id: 'num_engaged_physicians',
            label: 'Physician Engagement',
            tooltip: <Typography variant='normal' fontSize='12px'>Physician Engagement is the percentage of physicians in this camapign who have accessed their campaign dashboard and reviewed an optimization.</Typography>,
            ratio: 19,
            justify: 'start',
            sortable: true,
        },
        {
            id: 'staff_reviewed_value',
            label: 'Card Manager Review Progress',
            tooltip: <Typography variant='normal' fontSize='12px'>Card Manager Review Progress is the percentage of physician requested optimizations in this campaign (weighted by dollar value) that have been reviewed by the Card Manager.</Typography>,
            ratio: 19,
            justify: 'start',
            sortable: true,
        },
        {
            id: 'value_of_overdue_reviews',
            label: 'Overdue',
            tooltip: <Typography variant='normal' fontSize='12px'>Overdue optimizations are awaiting review and have not been reviewed by the assigned card manager within the allotted time frame.</Typography>,
            ratio: 10,
            justify: 'end',
            sortable: true,
        },
    ];

    return (
        <SortableTableHeader headers={headers} {...props} />
    );
}

export default OverviewHeader;
