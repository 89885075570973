import React, { useState } from 'react';
import { Stack, IconButton, Button, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { EditableDetailBox, StyledDetailBox } from '../Card/DetailBox';
import { useTheme } from '@mui/system';
import { useDeleteItemImageMutation } from '../../api/itemDetailSlice';

function ItemImageBox(props) {
    const theme = useTheme();
    const [isEditing, setIsEditing] = useState(false);
    const [deleteItemImage] = useDeleteItemImageMutation();

    function handleCancelClick() {
        setIsEditing(false);
    }

    const handleDeleteClick = async() => {
        await deleteItemImage({
            image: props.image
        });
    }

    return (
        <Stack>
            { props.canEdit && !isEditing && 
                <EditableDetailBox p={2}>
                    <IconButton onClick={() => setIsEditing(true)} aria-label='edit' size='small' className='editIcon' sx={{ display: { xs: 'initial', lg: 'none' }  }}>
                        <Edit fontSize='small'/>
                    </IconButton>
                    <Stack spacing={1}>
                        <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>Image</Typography>
                        <img src={props.image.presigned_url} width='100%' style={{ objectFit: 'contain' }} alt='item'/>
                    </Stack>
                </EditableDetailBox>
            }
            { props.canEdit && isEditing && 
                <EditableDetailBox p={2}>
                    <Stack spacing={1}>
                        <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>Image</Typography>
                        <img src={props.image.presigned_url} width='100%' style={{ objectFit: 'contain' }} alt='item'/>
                        <Stack direction='row' spacing={2} justifyContent='end'>
                            <Button variant='text' onClick={handleCancelClick} style={{ color: `${theme.palette.subtext.main}` }}>Cancel</Button>
                            <Button variant='text' onClick={handleDeleteClick} color='error'>Delete</Button>
                        </Stack>
                    </Stack>
                </EditableDetailBox>
            }
            { !props.canEdit && 
                <StyledDetailBox p={2}>
                    <Stack spacing={1}>
                        <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>Image</Typography>
                        <img src={props.image.presigned_url} width='100%' style={{ objectFit: 'contain' }} alt='item'/>
                    </Stack>
                </StyledDetailBox>
            }
        </Stack>
    );
}

export default ItemImageBox;
