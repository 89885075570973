import { Download } from '@mui/icons-material';
import { Button, Divider, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import axios from 'axios';
import format from 'date-fns/format/index.js';
import parseISO from 'date-fns/parseISO/index.js';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetIngestionTokenQuery } from '../../../api/apiSlice';
import { ingestionSlice, useGetImportQuery } from '../../../api/ingestionSlice';

function IngestionImport() {
    const { importId } = useParams();
    const {
        data: ingestionToken
    } = useGetIngestionTokenQuery();
    const { 
        data: importData,
    } = useGetImportQuery(
        { id: importId, token: ingestionToken?.token }, 
        { skip: !ingestionToken, pollingInterval: 2000 }
    );

    const [getImportFilepath] = ingestionSlice.endpoints.getImportFilePath.useLazyQuery();
    
    const handleDownload = async () => {
        await getImportFilepath({
            importId: importId,
            token: ingestionToken.token
        }).unwrap().then((res) => {
            window.open(res.filepath, '_blank');
        });
    }

    const getFailedImports = async() => {
        return new Promise((resolve) => {
            axios({
                url: `/api/ingestion/${importId}/failed`,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
                window.open(url, '_blank');
                resolve();
            });
        });
    }

    return (
        <Grid container pb={10} spacing={5} sx={{ maxHeight: '100vh', overflow: 'auto' }}>
            <Grid item xs={12} sm={3}>
                { importData && 
                    <Stack ml={4} spacing={4}>
                        <Paper elevation={2}>
                            <Stack p={4} spacing={1} divider={<Divider />}>
                                <Stack>
                                    <Typography variant='normal' sx={{ fontSize: '12px' }}>Import ID</Typography>
                                    <Typography variant='heavy' sx={{ fontSize: '20px' }}>{importData.id}</Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant='normal' sx={{ fontSize: '12px' }}>Import Type</Typography>
                                    <Typography variant='heavy' sx={{ fontSize: '20px' }}>{importData.type}</Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant='normal' sx={{ fontSize: '12px' }}>Import Date</Typography>
                                    <Typography variant='heavy' sx={{ fontSize: '20px' }}>{format(parseISO(importData.date), 'Pp')}</Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant='normal' sx={{ fontSize: '12px' }}>Import Status</Typography>
                                    <Typography variant='heavy' sx={{ fontSize: '20px' }}>{importData.status}</Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant='normal' sx={{ fontSize: '12px' }}>Completed Entries</Typography>
                                    <Typography variant='heavy' sx={{ fontSize: '20px' }}>{importData.completed_entries}</Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant='normal' sx={{ fontSize: '12px' }}>Failed Entries</Typography>
                                    <Typography variant='heavy' sx={{ fontSize: '20px' }}>{importData.failed_entries}</Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant='normal' sx={{ fontSize: '12px' }}>Total Entries</Typography>
                                    <Typography variant='heavy' sx={{ fontSize: '20px' }}>{importData.total_entries}</Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                        <Stack spacing={2}>
                            <Button variant='contained' disabled={!importData.filename} onClick={handleDownload} startIcon={<Download />}>
                                Download Import File
                            </Button>
                            { !importData.filename && 
                                <Typography variant='light' sx={{ fontSize: '14px' }}>{'* Some files may not be available if they\'ve expired or been deleted.'}</Typography>
                            }
                            { importData.status === 'complete' && importData.failed_entries > 0 && 
                                <Button variant='contained' onClick={getFailedImports}>
                                    Download Failed Imports
                                </Button>
                            }
                        </Stack>
                    </Stack>
                }
            </Grid>
            <Grid item xs={12} sm={8}>
                <Stack spacing={3}>
                    <Typography variant='heavy' sx={{ fontSize: '22px' }}>Error Reporting</Typography>
                    <Stack spacing={2}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='left'>Status Code</TableCell>
                                        <TableCell align='left'>Error Message</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { importData?.errors.map((error) => (
                                        <TableRow key={error.id}>
                                            <TableCell>{error.status_code}</TableCell>
                                            <TableCell>{error.message}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>

    );
}

export default IngestionImport;
