import React from 'react';
import { useState } from 'react';
import { Divider, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Add, Search } from '@mui/icons-material';
import { apiSlice } from '../../../api/apiSlice';
import ItemSearchResult from '../AddItem/ItemSearchResult';
import CreateNewItemDialog from '../AddItem/CreateNewItemDialog';


function ItemSearchDialog(props) {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isShowingCreateDialog, setIsShowingCreateDialog] = useState(false);

    const [searchItems] = apiSlice.endpoints.searchItems.useLazyQuery();

    const refreshSearchResults = async () => {
        await searchItems({
            hospitalId: props.card.owner.hospital.id,
            queryParams: {
                page: 1,
                search: searchTerm
            }
        }).unwrap().then((payload) => setSearchResults(payload.data));
    }

    const handleChange = async (value) => {
        setSearchTerm(value);
        await searchItems({
            hospitalId: props.card.owner.hospital.id,
            queryParams: {
                page: 1,
                search: value
            }
        }).unwrap().then((payload) => setSearchResults(payload.data));
    }

    function handleCreateNewItem() {
        setIsShowingCreateDialog(true);
    }

    function appendToSearchResults(newValue) {
        setSearchResults([newValue, ...searchResults]);
    }

    const cardItems = Object.keys(props.card.pick_list).map((category) => props.card.pick_list[category]).flat();

    return (
        <>
            <TextField
                fullWidth
                value={searchTerm}
                onChange={(e) => handleChange(e.target.value)}
                placeholder='Search by item name or reference number'
                InputProps={{
                    startAdornment: <InputAdornment position='start'><Search /></InputAdornment>
                }}
            />
            {searchResults.length > 0 &&
                <Stack divider={<Divider flexItem />}>
                    {searchResults.map((result) => (
                        <ItemSearchResult key={result.id} item={result} cardItems={cardItems} handleSelect={() => props.handleSelect(result)} refreshSearchResults={refreshSearchResults} />
                    ))}
                </Stack>
            }
            {(searchTerm || searchResults.length > 0) &&
                <>
                    <Divider />
                    <Stack direction='row' p={3} spacing={1} onClick={handleCreateNewItem} alignItems='center' justifyContent='center' style={{ cursor: 'pointer' }}>
                        <Add style={{ opacity: 0.6 }} />
                        <Typography variant='light' sx={{ fontSize: '16px' }}>Create New Item</Typography>
                    </Stack>
                    <CreateNewItemDialog
                        open={isShowingCreateDialog}
                        setOpen={setIsShowingCreateDialog}
                        addToSearchResults={appendToSearchResults}
                        updateSearchTerm={setSearchTerm}
                    />
                </>
            }
        </>
    );
}

export default ItemSearchDialog;
