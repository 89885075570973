import React from 'react';
import usePhysicianDashboardUser from '../../Hooks/usePhysicianDashboardUser';
import InvalidToken from '../InvalidToken';
import { Divider, Grid, Stack } from '@mui/material';
import LandingPageHeader from './LandingPageHeader';
import { useGetCampaignSummariesQuery } from '../../../api/physicianDashboardSlice';
import { theme } from '../../../theme';
import { useState } from 'react';
import CampaignSummaryTabs from './CampaignSummaryTabs';
import CurrentCampaignTab from './CurrentCampaignTab';
import PastCampaignTab from './PastCampaignTab.jsx';


function CampaignLandingPage() {
    const { dashboardUser: physician } = usePhysicianDashboardUser();
    const [selectedTab, setSelectedTab] = useState(0);
    const { 
        data: currentCampaigns = [],
        isError: campaignsError,
    } = useGetCampaignSummariesQuery({physicianId: physician.id, status: 'active'}, {
        skip: !physician
    });

    const {
        data: previousCampaigns = [], 
        isError: previousCampaignsError,
    } = useGetCampaignSummariesQuery({physicianId: physician.id, status: 'complete', maxDaysInPast: 90}, {
        skip: !physician
    });
    

    if (!physician || campaignsError || previousCampaignsError) {
        return(
            <InvalidToken resendButton={true} />  
        )
    }

    return (
        <Grid container sx={{ backgroundColor: `${theme.palette.background.white}` }}>
            <Grid item md={3} />
            <Grid item xs={12} md={6}>
                <Stack spacing={2} sx={{ backgroundColor: `${theme.palette.background.white}` }}>
                    <LandingPageHeader physician={physician} />
                    <Grid item pb={1} xs={12} mt={0} px={2} >
                        <CampaignSummaryTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                        <Divider />
                    </Grid>
                    { selectedTab === 0 && 
                        <CurrentCampaignTab campaigns={currentCampaigns} />
                    }
                    { selectedTab === 1 &&
                        <PastCampaignTab campaigns={previousCampaigns} />
                    }
                </Stack>
            </Grid>
        </Grid>
    )
}


export default CampaignLandingPage;
