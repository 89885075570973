import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import ItemSwapContextBullet from './ItemSwapContextBullet';

function ItemSwapContext(props) {
    const physicianNames = useMemo(() => {
        const physicians = props.recommendation.context.peer_preference?.map((doctor) => 'Dr. ' + doctor);
        if (physicians?.length === 1) {
            return physicians[0];
        }

        if (physicians?.length > 1) {
            physicians?.splice(-1, 0, 'and');
        }

        if (physicians?.length === 3) {
            return physicians?.join(' ');
        }

        var finalString = physicians?.join(', ');
        if (finalString) {
            return finalString.slice(0, finalString.lastIndexOf(',')) + finalString.slice(finalString.lastIndexOf(',') + 1);
        }
        return '';
    }, [props.recommendation]);

    return (
        <Stack m={2} spacing={1}>
            <>
                { props.recommendation.context.peer_preference && 
                    <ItemSwapContextBullet title='Preferred by your peers,' content={'including ' + physicianNames + '.'}/> 
                }
                { props.recommendation.recommended_item.ecri_assessment && 
                    <ItemSwapContextBullet title='ECRI Assessment Available' content='for this item. Select "Item Details" to view.' />
                }
                { props.potentialSavings > 0 && 
                    <ItemSwapContextBullet variant='savings' title={props.potentialSavings} content='per year compared to alternative item.' /> 
                }
            </>          
        </Stack>
    );
}

export default ItemSwapContext;