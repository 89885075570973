import { Stack } from '@mui/system';
import React, { useState } from 'react';
import { TextField, Typography, Button, Divider, Checkbox, FormControlLabel } from '@mui/material';
import { useDeleteUserMutation, useForceUserPasswordResetMutation } from '../../../../api/userSlice';
import DisableConfirmation from '../../../User/DisableConfirmation';
import PasswordResetConfirmation from '../../../User/PasswordResetConfirmation';
import UserRoles from './UserRoles';
import UserHospitals from './UserHospitals';

function UserSettings(props) {
    const [isDisableConfirmationOpen, setIsDisableConfirmationOpen] = useState(false);
    const [isPasswordResetConfirmationOpen, setIsPasswordResetConfirmationOpen] = useState(false);
    const [confirmDisableUser] = useDeleteUserMutation();
    const [forcePasswordReset] = useForceUserPasswordResetMutation();
    
    const permissions = [
        { 
            id: 5, 
            name: 'User Management', 
            description: 'Allows the user to create and edit user accounts',
        },
        { 
            id: 6, 
            name: 'Inventory Management', 
            description: 'Allows the user to edit inventory items',
        },
        { 
            id: 7, 
            name: 'Card Management', 
            description: 'Allows the user to make and approve preference card edits',
        },
        {
            id: 9,
            name: 'Recommendation Reviewer',
            description: 'Allows the user access to the staff review portal for reviewing physician approved recommendations',
        },
        {
            id: 10,
            name: 'Campaign Monitor',
            description: 'Allows the user access to our campaign monitoring tools',
        }
    ];

    function handleDetailChange(value, key) {
        props.setSelectedUser({ ...props.user, [key]: value });
    }

    async function disableUser() {
        await confirmDisableUser({ user: props.user });
    }

    async function confirmPasswordReset() {
        await forcePasswordReset({ user: props.user });
    }

    function handleRoleChange(event) {
        var roles = props.user.roles.slice();
        if (event.target.checked) {
            roles.push(permissions.find((permission) => permission.id === parseInt(event.target.name)));
            props.setSelectedUser({...props.user, roles: roles});
        } else {
            roles = props.user.roles.filter((role) => role.id !== parseInt(event.target.name));
            props.setSelectedUser({...props.user, roles: roles});
        }
    }

    function handleHospitalChange(event) {
        var hospitals = props.user.hospitals.slice();
        if (event.target.checked) {
            hospitals.push(props.orgHospitals.find((hospital) => hospital.id === parseInt(event.target.name)));
            props.setSelectedUser({...props.user, hospitals: hospitals});
        } else {
            hospitals = props.user.hospitals.filter((hospital) => hospital.id !== parseInt(event.target.name));
            props.setSelectedUser({...props.user, hospitals: hospitals});
        }
    }

    function handleAllHospitalsChange(event) {
        if (event.target.checked) {
            props.setSelectedUser({...props.user, hospitals: props.orgHospitals});
        } else {
            props.setSelectedUser({...props.user, hospitals: []});
        }
    }

    function toggleEmailUnsubscribed() {
        props.setSelectedUser({...props.user, email_unsubscribed: !props.user.email_unsubscribed});
    }

    return (
        <Stack boxShadow={2} backgroundColor='white' width='75%' borderRadius='8px' p={4} spacing={2}>
            <Stack direction='row' spacing={2}>
                <Stack spacing={4} width='33%'>
                    <Typography variant='heavy' fontSize='20px'>User Details</Typography>
                    <Stack spacing={3}>
                        <Stack spacing={2}>
                            <TextField fullWidth variant='outlined' label='First Name' value={props.user.first_name} onChange={(e) => handleDetailChange(e.target.value, 'first_name')} />
                            <TextField fullWidth variant='outlined' label='Last Name' value={props.user.last_name} onChange={(e) => handleDetailChange(e.target.value, 'last_name')}/>
                            <TextField fullWidth variant='outlined' label='Email' value={props.user.email} onChange={(e) => handleDetailChange(e.target.value, 'email')} />
                        </Stack>
                        <Stack alignItems='start' spacing={1}>
                            <FormControlLabel 
                                control={<Checkbox checked={props.user.email_unsubscribed} onChange={toggleEmailUnsubscribed} />}
                                label='Unsubscribed from emails'
                            />
                            <Button variant='text' color='primary' onClick={() => setIsPasswordResetConfirmationOpen(true)}>Force Password Reset</Button>
                            <Button variant='text' color='error' onClick={() => setIsDisableConfirmationOpen(true)}>Disable Account</Button>
                            <DisableConfirmation
                                open={isDisableConfirmationOpen}
                                setOpen={setIsDisableConfirmationOpen}
                                confirmDisable={disableUser}
                            />
                            <PasswordResetConfirmation 
                                open={isPasswordResetConfirmationOpen} 
                                setOpen={setIsPasswordResetConfirmationOpen} 
                                confirmReset={confirmPasswordReset} 
                            />
                        </Stack>
                    </Stack>
                </Stack>
                <Divider orientation='vertical' />
                <UserRoles userDetails={props.user} permissions={permissions} handleRoleChange={handleRoleChange} />
                <Divider orientation='vertical' />
                <UserHospitals userDetails={props.user} hospitals={props.orgHospitals} handleHospitalChange={handleHospitalChange} handleAllHospitalsChange={handleAllHospitalsChange} />
            </Stack>
            <Stack direction='row' alignSelf='end' spacing={2}>
                <Button variant='text' color='error' onClick={props.closeUserSettings}>Cancel</Button>
                <Button variant='contained' color='primary' onClick={props.updateUser}>Save</Button>
            </Stack>
        </Stack>
    )
}

export default UserSettings;
