import styled from '@emotion/styled';
import { Delete, Edit } from '@mui/icons-material';
import { Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import React, { useState } from 'react';
import DeleteDataConfirmation from './DeleteDataConfirmation';

function FreeformCardEntry(props) {
    const theme = useTheme();
    const [isEditing, setIsEditing] = useState(false);
    const [entryText, setEntryText] = useState(props.entry.body);
    const [isShowingDeleteConfirmation, setIsShowingDeleteConfirmation] = useState(false);

    function handleCancel() {
        setIsEditing(false);
        setEntryText(props.entry.body);
    }

    const handleSave = async(event) => {
        event.stopPropagation();
        await props.saveData(props.entry, entryText);
        setIsEditing(false);
    }

    const handleDeleteClick = (event) =>{
        setIsShowingDeleteConfirmation(true);
        event.stopPropagation;
    }

    return (
        <Stack spacing={1} width='100%'>
            <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>{props.entry.category.name}</strong></Typography>
            <StyledTextBox canEdit={props.canEdit}>
                { isEditing ? 
                    <Stack spacing={1} p={2}>
                        <TextField 
                            multiline 
                            sx={{ width: '100%', whiteSpace: 'pre-line', backgroundColor: `${theme.palette.background.white}` }} 
                            value={entryText} 
                            onChange={(event) => setEntryText(event.target.value)} 
                        />
                        <Stack direction='row' spacing={2} justifyContent='end'>
                            <DeleteDataConfirmation open = {isShowingDeleteConfirmation} setOpen = {setIsShowingDeleteConfirmation} deleteData = {props.deleteData} dataType = {props.dataType} id = {props.id} />
                            <IconButton onClick={(e) => handleDeleteClick(e)}>
                                <Delete />
                            </IconButton>                        
                            <Button variant='text' onClick={handleCancel} style={{ color: `${theme.palette.subtext.main}` }}>Cancel</Button>
                            <Button variant='text' onClick={handleSave} style={{ color: `${theme.palette.blue.heavy}` }}>Save</Button>
                        </Stack>
                    </Stack>
                    :   
                    <>
                        <IconButton onClick={() => setIsEditing(true)} aria-label='edit' size='small' className='editIcon' sx={{ display: 'none' }}>
                            <Edit fontSize='small'/>
                        </IconButton>
                        <Typography variant='normal' lineHeight='1.4375em' m={2} sx={{ width: '95%' }}>
                            <pre style={{ fontFamily: 'inherit', whiteSpace: 'pre-line' }}>
                                {props.entry.body}
                            </pre>
                        </Typography>
                    </>
                }
            </StyledTextBox>
        </Stack>
    );
}

const StyledTextBox = styled(Box)(({theme, canEdit}) => ({
    boxShadow: 1, 
    borderRadius: '8px', 
    backgroundColor: theme.palette.background.white,
    display: 'grid',
    position: 'relative',
    '&:hover': canEdit ? {
        '& .editIcon': {
            display: 'initial',
            justifySelf: 'end',
            position: 'absolute',
            margin: '4px'
        },
    } : {}
}));

export default FreeformCardEntry;