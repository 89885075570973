import { Button, InputAdornment, InputLabel, Paper, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiSlice } from '../../api/apiSlice';
import { useCreateItemMutation, useCreateVendorMutation, useGetHospitalVendorsQuery, useGetItemCategoriesQuery } from '../../api/inventorySlice';
import CreateAutocomplete from '../CustomComponents/CreateAutocomplete';
import VendorSuppliedTooltip from '../CustomComponents/Tooltips/VendorSuppliedTooltip';
import { UserContext } from '../Hooks/useUser';
import ItemCollisionAlert from './ItemCollisionAlert';

function CreateItem() {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [manufacturer, setManufacturer] = useState('');
    const [partNumber, setPartNumber] = useState('');
    const [category, setCategory] = useState('');
    const [price, setPrice] = useState('');
    const [isVendorSupplied, toggleIsVendorSupplied] = useState(false);
    const [isActive, toggleIsActive] = useState(true);
    const [collidingItems, setCollidingItems] = useState([]);
    const [createVendor] = useCreateVendorMutation();
    const [createItem] = useCreateItemMutation();
    const [searchItems] = apiSlice.endpoints.searchItems.useLazyQuery();

    function handleVendorSupplied(e, newValue) {
        if(newValue !== null) {
            toggleIsVendorSupplied(newValue);
        }
    }

    function handleActive(e, newValue) {
        if (newValue !== null) {
            toggleIsActive(newValue);
        }
    }

    const canSubmit = useMemo(() => {
        return name && manufacturer && partNumber && category;
    }, [name, partNumber, manufacturer, category]);

    const handleSubmit = async() => {
        var requestCategory = category;
        if (typeof(requestCategory) !== 'string') {
            requestCategory = requestCategory.name;
        }

        if (typeof(manufacturer) === 'string') {
            await createVendor({
                name: manufacturer
            }).unwrap().then((payload) => {
                createItem({
                    hospitalId: user.hospital.id,
                    data: {
                        name: name, 
                        part_number: partNumber,
                        vendor_id: payload.id,
                        category: requestCategory,
                        cost: (price ?? 0) * 100,
                        is_vendor_supplied: isVendorSupplied,
                        is_active: isActive,
                    }
                }).unwrap().then((payload) => {
                    navigate(`/inventory/${payload.id}/details`);
                })
            })
        } else {
            await createItem({
                hospitalId: user.hospital.id,
                data: {
                    name: name, 
                    part_number: partNumber,
                    vendor_id: manufacturer.id,
                    category: requestCategory,
                    cost: (price ?? 0) * 100,
                    is_vendor_supplied: isVendorSupplied,
                    is_active: isActive,
                }
            }).unwrap().then((payload) => {
                navigate(`/inventory/${payload.id}/details`);
            })
        }
    }

    const checkForPartNumberCollision = async (partNumber) => {
        await searchItems({
            hospitalId: user.hospital.id,
            queryParams: {
                search: partNumber
            }
        }).unwrap().then((payload) => {
            var collidingItems = [];
            payload.data.forEach((item) => {
                if (item.part_number === partNumber) {
                    collidingItems.push(item);
                }
            });
            setCollidingItems(collidingItems);
        })
    }

    return (
        <Stack spacing={2} pb={5} px={{ xs: 2, md: 4 }} sx={{ maxWidth: { xs: '100%', md: '80%', lg: '50%' } }}>
            <Stack spacing={2}>
                <Typography variant='bold' sx={{ fontSize: '20px' }}>New Item Creation</Typography>
                <Paper elevation={2} >
                    <Stack p={2} spacing={3}>
                        <Stack>
                            <InputLabel htmlFor='item-name'>
                                Enter Item Name
                            </InputLabel>
                            <TextField
                                id='item-name'
                                variant='outlined'
                                placeholder='Item Name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Stack>
                        <Stack>
                            <InputLabel htmlFor='manufacturer'>
                                Select Manufacturer
                            </InputLabel>
                            <CreateAutocomplete
                                label='Vendor'
                                value={manufacturer}
                                setValue={setManufacturer}
                                loadOptions={() => useGetHospitalVendorsQuery(user.hospital.id)}
                            />
                        </Stack>
                        <Stack>
                            <InputLabel htmlFor='part-number'>
                                Enter Reference Number
                            </InputLabel>
                            <TextField
                                id='part-number'
                                variant='outlined'
                                placeholder='Reference Number'
                                value={partNumber}
                                onChange={(e) => setPartNumber(e.target.value)}
                                onBlur={(e) => checkForPartNumberCollision(e.target.value)}
                            />
                            <ItemCollisionAlert collidingItems={collidingItems} />
                        </Stack>
                        <Stack>
                            <InputLabel htmlFor='category'>
                                Select Category
                            </InputLabel>
                            <CreateAutocomplete
                                label='Category'
                                value={category}
                                setValue={setCategory}
                                loadOptions={() => useGetItemCategoriesQuery(user.hospital.id)}
                            />
                        </Stack>
                        <Stack>
                            <InputLabel htmlFor='price'>
                                Enter Price
                            </InputLabel>
                            <TextField
                                id='price'
                                variant='outlined'
                                placeholder='Price'
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position='start'>$</InputAdornment>
                                }}
                            />
                        </Stack>
                        <Stack direction={{ xs: 'column', lg: 'row' }} spacing={{ xs: 2, lg: 0 }} alignItems={{ xs: 'start', lg: 'center' }} justifyContent='space-between'>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <Typography variant='normal' sx={{ fontSize: '16px'}}>Vendor Supplied?</Typography>
                                <ToggleButtonGroup
                                    value={isVendorSupplied}
                                    exclusive
                                    onChange={handleVendorSupplied}
                                    color='primary'
                                    size='small'
                                >
                                    <ToggleButton value={true}>
                                        Yes
                                    </ToggleButton>
                                    <ToggleButton value={false}>
                                        No
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <VendorSuppliedTooltip />
                            </Stack>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <Typography variant='normal' sx={{ fontSize: '16px' }}>Status:</Typography>
                                <ToggleButtonGroup
                                    id='active-status'
                                    value={isActive}
                                    exclusive
                                    onChange={handleActive}
                                    color='primary'
                                    size='small'
                                >
                                    <ToggleButton value={true}>
                                        Active
                                    </ToggleButton>
                                    <ToggleButton value={false}>
                                        Inactive
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                        </Stack>
                    </Stack>
                </Paper>
            </Stack>
            <Stack direction='row' justifyContent='flex-end' alignItems='center'>
                <Button variant='contained' disabled={!canSubmit} onClick={handleSubmit}>
                    Create Item
                </Button>
            </Stack>
        </Stack>
    );
}

export default CreateItem;
