import { LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from '@mui/material';
import format from 'date-fns/format/index.js';
import parseISO from 'date-fns/parseISO/index.js';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetImportsQuery } from '../../../api/ingestionSlice';

function PastUploads(props) {
    const navigate = useNavigate();
    const {
        data: pastImports = []
    } = useGetImportsQuery({ hospitalId: props.hospitalId, token: props.token }, { skip: !props.token, pollingInterval: 5000 });

    function navigateToImport(importData) {
        navigate(`/superSettings/hospitals/${props.hospitalId}/imports/${importData.id}`)
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>Date</TableCell>
                        <TableCell align='center'>File Type</TableCell>
                        <TableCell align='center'>Status</TableCell>
                        <TableCell align='center'>Progress</TableCell>
                        <TableCell align='center'>Success #</TableCell>
                        <TableCell align='center'>Failure #</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { pastImports.map((importData) => (
                        <StyledTableRow key={importData.id} onClick={() => navigateToImport(importData)}>
                            <TableCell align='center'>{format(parseISO(importData.date), 'Pp')}</TableCell>
                            <TableCell align='center'>{importData.type}</TableCell>
                            <TableCell align='center'>{importData.status}</TableCell>
                            <TableCell>
                                <UploadProgressBar importData={importData} />
                            </TableCell>
                            <TableCell align='center'>{importData.completed_entries}</TableCell>
                            <TableCell align='center'>{importData.failed_entries}</TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function UploadProgressBar(props) {
    const color = useMemo(() => {
        if (props.importData.status === 'Complete' && props.importData.failed_entries > 0) {
            return 'error';
        } 

        if (props.importData.status === 'Complete' && props.importData.failed_entries === 0) {
            return 'success';
        }

        return 'primary';
    }, [props.importData]);

    return (
        <LinearProgress variant='determinate' color={color} value={
            Math.round(((props.importData.completed_entries + props.importData.failed_entries) / props.importData.total_entries) * 100)
        } />
    )
}

const StyledTableRow = styled(TableRow)(() => ({    
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#F5F5F5'
    }
}));

export default PastUploads;
