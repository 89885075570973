import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import ItemSearchResultDropdown from './ItemSearchResultDropdown';
import ItemNameDisplay from '../../CustomComponents/ItemNameDisplay';

function ItemSearchResult(props) {
    const theme = useTheme();
    const isAlreadyOnCard = props.cardItems.some((cardItem) => cardItem.id === props.item.id);

    return (
        <Stack p={2} direction='row' justifyContent='space-between' alignItems='center'>
            <Stack sx={{ maxWidth: '75%' }}>
                <ItemNameDisplay item={props.item} />
                <Typography variant='normal' sx={{ fontSize: '14px', color: `${theme.palette.subtext.main}` }}>
                    {props.item.vendor.name} - {props.item.part_number} | <NumericFormat value={props.item.cost / 100}
                        thousandSeparator={true}
                        displayType={'text'}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true} />
                </Typography>
            </Stack>
            { isAlreadyOnCard ? 
                <Typography variant='normal' sx={{ fontSize: '14px', color: `${theme.palette.subtext.main}` }}>
                    Already on card
                </Typography>
                :
                <ItemSearchResultDropdown
                    item={props.item}
                    handleSelect={props.handleSelect}
                    refreshSearchResults={props.refreshSearchResults}
                />
            }
        </Stack>
    );
}

export default ItemSearchResult;


