import styled from '@emotion/styled';
import { Stack, Typography, IconButton, TextField, Button } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Box, useTheme } from '@mui/system';
import React, { useState } from 'react';

function DetailBox(props) {
    const theme = useTheme();
    const [isEditing, setIsEditing] = useState(false);
    const [content, setContent]  = useState(props.content);

    function handleCancel() {
        setIsEditing(false);
        setContent(props.content);
    }

    function handleSaveClick() {
        props.handleSave(content);
        setIsEditing(false);
    }

    if (props.editable && !isEditing) {
        return (
            <EditableDetailBox p={2}>
                <IconButton onClick={() => setIsEditing(true)} aria-label='edit' size='small' className='editIcon' sx={{ display: { xs: 'initial', lg: 'none' } }}>
                    <Edit fontSize='small'/>
                </IconButton>
                <Stack spacing={1}>
                    <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>{props.title}</Typography>
                    <Typography variant='heavy' sx={{ fontSize: '14px' }}>{content}</Typography>
                </Stack>
            </EditableDetailBox>
        );
    } else if (props.editable && isEditing) {
        return (
            <EditableDetailBox p={2}>
                <IconButton onClick={() => setIsEditing(true)} aria-label='edit' size='small' zIndex = '20' className='editIcon' sx={{ display: 'none' }}>
                    <Edit fontSize='small'/>
                </IconButton>
                <Stack spacing={1}>
                    <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>{props.title}</Typography>
                    <TextField 
                        multiline 
                        sx={{ width: '100%', whiteSpace: 'pre-line', backgroundColor: `${theme.palette.background.white}` }} 
                        value={content} 
                        onChange={(event) => setContent(event.target.value)} 
                    />
                    <Stack direction='row' spacing={2} justifyContent='end'>
                        <Button variant='text' onClick={handleCancel} style={{ color: `${theme.palette.subtext.main}` }}>Cancel</Button>
                        <Button variant='text' onClick={handleSaveClick} style={{ color: `${theme.palette.blue.heavy}` }}>Save</Button>
                    </Stack>
                </Stack>
            </EditableDetailBox>
        )
    } else {
        return (
            <StyledDetailBox p={2}>
                <Stack spacing={1}>
                    <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>{props.title}</Typography>
                    <Typography variant='heavy' sx={{ fontSize: '14px' }}>{props.content}</Typography>
                </Stack>
            </StyledDetailBox>
        );
    }
    
}

export const StyledDetailBox = styled(Box)(({theme}) => ({
    boxShadow: 1, 
    borderRadius: '8px', 
    backgroundColor: theme.palette.background.white
}));

export const EditableDetailBox = styled(Box)(({theme}) => ({
    boxShadow: 1, 
    borderRadius: '8px', 
    backgroundColor: theme.palette.background.white,
    display: 'grid',
    position: 'relative',
    '& .editIcon': {
        justifySelf: 'end',
        position: 'absolute',
        margin: '4px',
        zIndex: '10'
    },
    '&:hover': {
        '& .editIcon': {
            display: 'initial',
        },
    }
}));

export default DetailBox;