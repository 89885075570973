import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import QuantityInput from '../../PickList/QuantityInput';
import ItemNameDisplay from '../../CustomComponents/ItemNameDisplay';

function SelectedItem(props) {
    const theme = useTheme();
    

    return (
        <Stack direction='row' p={1} justifyContent='space-between' alignItems='center' sx={{ border: '1px solid #0000001F', borderRadius: '8px' }}>
            <Stack>
                <ItemNameDisplay item={props.item} />
                <Typography variant='normal' sx={{ fontSize: '14px', color: `${theme.palette.subtext.main}` }}>
                    {props.item.vendor.name} - {props.item.part_number} | <NumericFormat value={props.item.cost / 100}
                        thousandSeparator={true}
                        displayType={'text'}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true} />
                </Typography>
            </Stack>
            <Stack direction='row' spacing={3} mr={2}>
                <QuantityInput variant='open' quantity={props.openQuantity} setEditingQuantity={props.setOpenQuantity} isValid={props.isOpenValid} canEdit={true} />
                <QuantityInput variant='hold' quantity={props.holdQuantity} setEditingQuantity={props.setHoldQuantity} isValid={props.isHoldValid} canEdit={true} />
            </Stack>
        </Stack>
    );
}

export default SelectedItem;
