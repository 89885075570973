import { Stack } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import React from 'react';

function SegmentedProgressBar(props) {
    const stepWidth = screen.width / props.data.length;

    return (
        <Stack direction='row' spacing={1} justifyContent='center'>
            { props.data.map((datum, index) => (
                index === props.selectedIndex 
                    ? <SegmentedProgressBarStep key={index} status='active' width={stepWidth} /> 
                    : <SegmentedProgressBarStep key={index} status={datum.recommendations.some((rec) => rec.status === 'accepted' || rec.status === 'rejected') ? 'unlocked' : 'locked'} width={stepWidth} />
            ))}
        </Stack>
    );
}

function SegmentedProgressBarStep(props) {
    const theme = useTheme();

    const backgroundColor = () => {
        if (props.status === 'unlocked') {
            return theme.palette.blue.heavy;
        }
        if (props.status === 'active') {
            return '#5E5E5E';
        }
        if (props.status === 'locked') {
            return '#A6A6A6';
        }
    }

    return (
        <Box sx={{ width: props.width, height: '4px', borderRadius: '2px', backgroundColor: backgroundColor() }}>
            <></>
        </Box>
    )
}

export default SegmentedProgressBar;