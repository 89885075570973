import React from 'react';
import { Stack } from '@mui/material';
import CampaignBox from './CampaignBox';

function CurrentCampaignTab(props) {

    return (
        <Stack px={2} spacing={2} pb={5}>
            { props.campaigns?.map((campaign) => (
                <CampaignBox key={campaign.id} campaign={campaign} />
            ))}
        </Stack>
    );
}

export default CurrentCampaignTab;
