import React from 'react';
import { AppBar, Stack, Skeleton } from '@mui/material';
import { Box, Typography } from '@mui/material';
import logo from '../../../../assets/docsi_logo.svg';

function HeadlineHeader(props) {
    return (
        <AppBar position='sticky' sx={{ color: 'black', backgroundColor: '#FFFFFF', boxShadow: 'none' }}>
            <Stack>
                <Stack direction="row" justifyContent="space-between" px={2} pt={4} pb={0} sx={{ backgroundColor: '#FFFFFF' }}>
                    <Stack>
                        <Box sx={{ fontSize: '22px'}} alignItems='center'>
                            { props.physician ? <Stack>
                                <Typography variant='normal'> Welcome </Typography>
                                <Typography variant='normal'> Dr. {props.physician.name}</Typography>
                            </Stack> : <Skeleton variant='text' width={175} /> }
                        </Box>
                        <Box pt={1} sx={{ fontSize: '16px' }}>
                            { props.physician.organization ? <Typography variant='normal'>{props.physician.organization.name}</Typography> : <Skeleton variant='text' width={175} /> }
                        </Box>
                    </Stack>
                    <Box display='flex' alignItems='flex-start' maxWidth='40%'>
                        { props.physician.organization ? <img src={props.physician.organization.logo ? props.physician.organization.logo : logo} alt='Site Logo' style={{ objectFit: 'contain' }} width='100%' height={25}/> : <Skeleton variant='rectangle' width={150} height={20} /> }
                    </Box>
                </Stack>
            </Stack>
        </AppBar>
    );
}

export default HeadlineHeader;
