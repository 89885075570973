import { Checkbox, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useMemo } from 'react';

function ItemSwapDrawer(props) {

    useEffect(() => {
        if (props.currentSwapStatus === 'accepted') {
            let checkedDrafts = Object.values(props.swaps)[props.activeSwapIndex].recommendations.filter((rec) => rec.status === 'accepted').map((rec) => rec.draft);
            props.setChecked(checkedDrafts.map((draft) => draft.id));
        } else if (props.currentSwapStatus === 'open') {
            props.setChecked(props.drafts.map((draft) => draft.id));
        }
    }, [props.activeSwapIndex]);

    const allChecked = useMemo(() => {
        return props.checked.length === props.drafts.length;
    }, [props.checked, props.drafts]);

    function toggleCheckAll() {
        if (allChecked) {    
            props.setChecked(new Array());
        } else {
            props.setChecked(props.drafts.map((draft) => draft.id));
        }
    }

    function closeDrawer() {
        props.setIsDrawerOpen(false);
    }

    return ( 
        <Drawer anchor='bottom' open={props.isDrawerOpen} onClose={closeDrawer}>
            <Stack p={2} spacing={3}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography variant='heavy' sx={{ fontSize: '20px' }}>Swap item on these procedures:</Typography>
                    <IconButton onClick={closeDrawer}>
                        <Close />
                    </IconButton>
                </Stack>
                <List>
                    <ListItem key='select-all' disablePadding>
                        <ListItemButton role={undefined} onClick={toggleCheckAll} dense>
                            <ListItemIcon>
                                <Checkbox
                                    edge='start'
                                    checked={false}
                                    indeterminate={allChecked}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': 'checkbox-list-label-all' }}
                                />
                            </ListItemIcon>
                            <ListItemText id='checkbox-list-label-all' primary='Select All' />
                        </ListItemButton>
                    </ListItem>
                    { props.drafts.map((draft) => {
                        const labelId = `checkbox-list-label-${draft.id}`

                        return (
                            <ListItem key={draft.id} disablePadding>
                                <ListItemButton role={undefined} onClick={props.handleToggle(draft.id)} dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge='start'
                                            checked={props.checked.indexOf(draft.id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={draft.card.name} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </Stack>
        </Drawer>
    );
}

export default ItemSwapDrawer;