import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';


function ContractComplianceDialog(props) {
    function handleClose() {
        props.setIsShowingInfo(false);
    }

    return (
        <Dialog onClose={handleClose} open={props.isShowingInfo} >
            <DialogTitle>
                What is Overall Compliance?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Overall compliance for your facility is the combined compliance percentage across all physicians in this contract category.
                    Your facility must meet the minimum target compliance percentage to receive advantageous pricing and rebates.
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}   

export default ContractComplianceDialog;
