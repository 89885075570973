import React from 'react';
import { Stack, Typography, List, ListItem, FormControlLabel, Checkbox, Button } from '@mui/material';
import { useTheme } from '@mui/system';

function Halfway(props) {
    const theme = useTheme();

    const handleToggle = (value) => () => {
        props.checked[value] = !props.checked[value];
        const newChecked = {...props.checked};
        props.setChecked(newChecked);
    }

    function submitFeedback() {
        props.declineSwap(props.checked)
        props.closeView();
        window.scrollTo(0, 0);
    }

    function canSubmit() {
        if (props.checked['trial'] || props.checked['wet-lab'] || props.checked['peer-discussion'] || props.checked['vendor-discussion']) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <Stack spacing={2}>
            <Stack spacing={2}>
                <Typography variant='heavy' sx={{ fontSize: '20px' }}>Learning Opportunities</Typography>
                <Typography variant='normal' sx={{ fontSize: '14px', color: '#0000008A', letterSpacing: '0.25px' }}>
                    Would you be open to a trial, wet lab session, or discussion with a peer regarding the on-contract item?
                </Typography>
                <Typography variant='normal' sx={{ fontSize: '14px', color: '#0000008A', letterSpacing: '0.25px' }}>
                    Select 1 or more options below and someone will reach out to you shortly to make arragements:
                </Typography>
                <List>
                    <ListItem>
                        <FormControlLabel 
                            control={<Checkbox checked={props.checked['trial']} onChange={handleToggle('trial')} />} 
                            label={<Typography variant='normal' fontSize='14px'>Trial item for 5 cases</Typography>}
                            name='trial'
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel 
                            control={<Checkbox checked={props.checked['wet-lab']} onChange={handleToggle('wet-lab')} />} 
                            label={<Typography variant='normal' fontSize='14px'>Wet lab session</Typography>}
                            name='wet-lab' 
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel 
                            control={<Checkbox checked={props.checked['peer-discussion']} onChange={handleToggle('peer-discussion')} />} 
                            label={<Typography variant='normal' fontSize='14px'>Discuss with peer</Typography>}
                            name='peer-discussion'
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel 
                            control={<Checkbox checked={props.checked['vendor-discussion']} onChange={handleToggle('vendor-discussion')} />} 
                            label={<Typography variant='normal' fontSize='14px'>Discuss with vendor representative</Typography>}
                            name='vendor-discussion'
                        />
                    </ListItem>
                </List>
            </Stack>
            <Stack spacing={2} alignItems='center'>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Button variant="text" onClick={submitFeedback} disabled={canSubmit()} style={{ color: canSubmit() ? '' : `${theme.palette.red.main}` }}>No thanks</Button>
                    <Button variant='blueContained' disabled={!canSubmit()} onClick={submitFeedback}>Submit Response</Button>
                </Stack>
                <Button variant='text' onClick={() => {props.setStep(0)}}>Back</Button>
            </Stack>
        </Stack>
    );
}

export default Halfway;
