import { Add, ExitToApp, Public } from '@mui/icons-material';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import logo from '../../../assets/docsi_logo.svg';
import { useGetOrganizationsQuery } from '../../api/apiSlice';
import OrganizationEntry from './SuperSettingsSidebar/OrganizationEntry';

function SuperSettingsSidebarContents() {
    const params = useParams();
    const pathnames = useLocation().pathname.split('/');
    const navigate = useNavigate();
    const { data: organizations = [], isLoading } = useGetOrganizationsQuery();

    const selectedIndex = useMemo(() => {
        if (params.organizationId) {
            return 'org' + params.organizationId;
        } else if (params.hospitalId) {
            return 'hos' + params.hospitalId;
        } else if (pathnames.includes('create') && pathnames.includes('organizations')) {
            return 'createOrg';
        } else if (pathnames.includes('organizations')) {
            return 'organizations';
        }
    }, [params]);

    return (
        !isLoading &&
        <>
            <Toolbar sx={{ padding: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <img src={logo} alt='Site Logo' style={{ objectFit: 'contain' }} width={200}/>
            </Toolbar>
            <Divider />
            <List>
                <ListItemButton key={0} onClick={() => navigate('/cards')}>
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary='Exit to Admin Portal' />
                </ListItemButton>
                <ListItemButton key={1} selected={selectedIndex === 'organizations'} onClick={() => navigate('/superSettings/organizations')}>
                    <ListItemIcon>
                        <Public />
                    </ListItemIcon>
                    <ListItemText primary='Organizations' />
                </ListItemButton>
                <ListItemButton key={2} selected={selectedIndex === 'createOrg'} onClick={() => navigate('/superSettings/organizations/create')}>
                    <ListItemIcon>
                        <Add />
                    </ListItemIcon>
                    <ListItemText primary='Create New Organization' />
                </ListItemButton>
            </List>
            <Divider />
            <List style={{ overflowY: 'scroll' }}>
                {organizations.map((organization) => (
                    <OrganizationEntry 
                        organization={organization} 
                        selectedIndex={selectedIndex}
                    />
                ))}
            </List>
        </>
    );
}

export default SuperSettingsSidebarContents;