import {createApi} from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';

var urlPrefix = '';

switch(process.env.ENVIRONMENT_SETTINGS) {
case 'local':
    urlPrefix = 'http://localhost:8081';
    break;
case 'staging':
    urlPrefix = 'https://ingestion.staging.docsihealth.com';
    break;
case 'demo':
    urlPrefix = 'https://ingestion.demo.docsihealth.com';
    break;
case 'production':
    urlPrefix = 'https://ingestion.docsihealth.com';
    break;
}

export const ingestionSlice = createApi({
    reducerPath: 'ingestionApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${urlPrefix}/api`,
        prepareHeaders: (headers) => {
            headers.set('accept', 'application/json');
            return headers;
        },
        credentials: 'include'
    }),
    tagTypes: ['Import'],
    endpoints: (builder) => ({
        postIngestionUpload: builder.mutation({
            query: ({ data, token, url }) => ({
                url: `/ingestion/${url}`,
                method: 'POST',
                body: data,
                headers: {
                    authorization: `Bearer ${token}`
                }
            }),
            async onQueryStarted({ data, token, url }, { dispatch, queryFulfilled }) {
                try {
                    const { data: newImport } = await queryFulfilled;
                    const result = dispatch(
                        ingestionSlice.util.updateQueryData('getImports', {'hospitalId': data.hospital_id.toString(), 'token': token}, existingImports => {
                            existingImports.push(newImport);
                        })
                    )
                } catch {}
            }
        }),
        getImports: builder.query({
            query: ({ hospitalId, token }) => ({
                url: `/hospitals/${hospitalId}/imports`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`
                }
            }),
            providesTags: (result = [], error, arg) => [
                'Import',
                ...result.map(({ id }) => ({ type: 'Import', id }))
            ]
        }),
        getImport: builder.query({
            query: ({ id, token }) => ({
                url: `/imports/${id}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`
                }
            }),
            providesTags: (result, error, id) => [{ type: 'Import', id }]
        }),
        getImportFilePath: builder.query({
            query: ({ importId, token }) => ({
                url: `/imports/${importId}/files`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
        }),
    }),
});

export const {
    usePostIngestionUploadMutation,
    useGetImportsQuery,
    useGetImportQuery,
    useGetImportFilePathQuery,
} = ingestionSlice;