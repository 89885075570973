import { Dialog, DialogContent, DialogContentText, DialogTitle, Button, Stack, IconButton, Typography, Drawer, useMediaQuery } from '@mui/material';
import { Remove, Add } from '@mui/icons-material';
import { useTheme } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import { useDeleteCardItemMutation, useUpdateCardItemMutation } from '../../api/itemSlice';
import LaunchIcon from '@mui/icons-material/Launch';
import { useNavigate } from 'react-router-dom';

function EditPickListItem(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [updateCardItem] = useUpdateCardItemMutation();
    const [removeCardItem] = useDeleteCardItemMutation();
    const [openQuantity, setOpenQuantity] = useState(props.item.open_quantity);
    const [holdQuantity, setHoldQuantity] = useState(props.item.hold_quantity);
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const handleRemoveClick = async () => {
        await removeCardItem({
            cardId: props.card.id,
            item: props.item
        });
        props.handleClose();
    }

    const handleSave = async () => {
        await updateCardItem({
            cardId: props.card.id,
            item: props.item,
            openQuantity: openQuantity,
            holdQuantity: holdQuantity
        });
        props.handleClose();
    }

    function increaseHoldQuantity() {
        setHoldQuantity(holdQuantity + 1);
    }

    function decreaseHoldQuantity() {
        setHoldQuantity(holdQuantity - 1);
    }

    function increaseOpenQuantity() {
        setOpenQuantity(openQuantity + 1);
    }

    function decreaseOpenQuantity() {
        setOpenQuantity(openQuantity - 1);
    }

    if (isLargeScreen) {
        return (
            <Dialog open={props.open} onClose={props.handleClose} maxWidth='sm' fullWidth>
                <Stack justifyContent='space-between' alignItems='center' direction='row'>
                    <DialogTitle>Edit Item</DialogTitle>
                    <Button
                        variant='heavy'
                        endIcon={<LaunchIcon />}
                        onClick={() => navigate(`/inventory/${props.item.id}/details`)}
                        size='small'
                        style={{ borderColor: '#0000001F', color: `${theme.palette.subtext.main}` }}
                    >
                        Go to item in inventory
                    </Button>
                </Stack>
                <DialogContent>
                    <Stack spacing={1}>
                        <Stack>
                            <DialogContentText>{props.item.name}</DialogContentText>
                            <DialogContentText>{props.item.vendor.name} - {props.item.part_number}</DialogContentText>
                        </Stack>
                        <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
                            <IconButton disabled={openQuantity === 0} sx={{ border: `1px solid ${theme.palette.border.main}` }} onClick={decreaseOpenQuantity}>
                                <Remove />
                            </IconButton>
                            <Typography variant='bold' sx={{ fontSize: '16px', color: `${theme.palette.green.main}` }}>Open: {openQuantity}</Typography>
                            <IconButton sx={{ border: `1px solid ${theme.palette.border.main}` }} onClick={increaseOpenQuantity}>
                                <Add />
                            </IconButton>
                        </Stack>
                        <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
                            <IconButton disabled={holdQuantity === 0} sx={{ border: `1px solid ${theme.palette.border.main}` }} onClick={decreaseHoldQuantity}>
                                <Remove />
                            </IconButton>
                            <Typography variant='bold' sx={{ fontSize: '16px', color: `${theme.palette.hold.main}` }}>Hold: {holdQuantity}</Typography>
                            <IconButton sx={{ border: `1px solid ${theme.palette.border.main}` }} onClick={increaseHoldQuantity}>
                                <Add />
                            </IconButton>
                        </Stack>
                    </Stack>
                </DialogContent>
                <Stack p={2} direction='row' justifyContent='space-between'>
                    <Button onClick={handleRemoveClick} style={{ color: `${theme.palette.red.main}` }}>
                        Remove from Card
                    </Button>
                    <Stack direction='row'>
                        <Button onClick={props.handleClose} style={{ color: `${theme.palette.subtext.main}` }}>Cancel</Button>
                        <Button
                            onClick={handleSave}
                            style={{ color: `${theme.palette.blue.heavy}` }}
                        >
                            Save
                        </Button>
                    </Stack>
                </Stack>
            </Dialog>
        );
    } else if (!isLargeScreen) {
        return (
            <Drawer open={props.open} onClose={props.handleClose} anchor='bottom'>
                <Stack spacing={2} p={2}>
                    <Stack justifyContent='space-between' alignItems='center' direction='row' spacing={2}>
                        <Stack>
                            <DialogContentText>{props.item.name}</DialogContentText>
                            <DialogContentText>{props.item.vendor.name} - {props.item.part_number}</DialogContentText>
                        </Stack>
                        <Button
                            variant='heavy'
                            endIcon={<LaunchIcon />}
                            onClick={() => navigate(`/inventory/${props.item.id}/details`)}
                            size='small'
                            style={{ borderColor: '#0000001F', color: `${theme.palette.subtext.main}` }}
                        >
                            Go to item in inventory
                        </Button>
                    </Stack>
                    <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
                        <IconButton disabled={openQuantity === 0} sx={{ border: `1px solid ${theme.palette.border.main}` }} onClick={decreaseOpenQuantity}>
                            <Remove />
                        </IconButton>
                        <Typography variant='heavy' sx={{ fontSize: '16px', color: `${theme.palette.green.main}` }}>Open: {openQuantity}</Typography>
                        <IconButton sx={{ border: `1px solid ${theme.palette.border.main}` }} onClick={increaseOpenQuantity}>
                            <Add />
                        </IconButton>
                    </Stack>
                    <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
                        <IconButton disabled={holdQuantity === 0} sx={{ border: `1px solid ${theme.palette.border.main}` }} onClick={decreaseHoldQuantity}>
                            <Remove />
                        </IconButton>
                        <Typography variant='heavy' sx={{ fontSize: '16px', color: `${theme.palette.hold.main}` }}>Hold: {holdQuantity}</Typography>
                        <IconButton sx={{ border: `1px solid ${theme.palette.border.main}` }} onClick={increaseHoldQuantity}>
                            <Add />
                        </IconButton>
                    </Stack>
                </Stack>
                <Stack p={2} direction='row' justifyContent='space-between'>
                    <Button onClick={handleRemoveClick} style={{ color: `${theme.palette.red.main}` }}>
                        Remove from Card
                    </Button>
                    <Stack direction='row'>
                        <Button onClick={props.handleClose} style={{ color: `${theme.palette.subtext.main}` }}>Cancel</Button>
                        <Button
                            onClick={handleSave}
                            style={{ color: `${theme.palette.blue.heavy}` }}
                        >
                            Save
                        </Button>
                    </Stack>
                </Stack>
            </Drawer>
        );
    }
}

export default EditPickListItem;