import { Dialog, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import React from 'react';

function CSVGenerationDialog(props) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogContent>
                <DialogContentText>
                    Generating a CSV will create a new dashboard link for every physician in this campaign. Are you sure you want to do this?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={props.handleGenerateCSV}>Generate CSV</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CSVGenerationDialog;
