import React, { useMemo, useState } from 'react';
import {Box} from '@mui/system';
import {Button, Link, Stack, TextField, Grid, IconButton, List} from '@mui/material';
import whiteLogo from '../../../assets/white_logo.svg';
import smallLogo from '../../../assets/single_letter_logo.svg'
import { useConfirmPasswordResetMutation, useValidateResetTokenQuery, useResetPasswordMutation } from './passwordSlice';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ValidationListItem from '../CustomComponents/ValidationListItem';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

function ChangePassword() {
    const { token } = useParams();
    const [searchParams, ] = useSearchParams();
    const [email, setEmail] = useState(searchParams.get('email'));
    const [password, setPassword] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmedPassword, setConfirmedPassword] = useState();
    const [serverError, setServerError] = useState();
    const [resetPassword] = useConfirmPasswordResetMutation();
    const [isLinkSent, setIsLinkSent] = useState(false);
    const [sendNewLink, { isLoading: isSendingLink }] = useResetPasswordMutation();
    const navigate = useNavigate();

    const isValidPasswordLength = useMemo(() => {
        return password && password.length > 7;
    }, [password]);

    const hasLetters = useMemo(() => {
        return password && String(password).match(/[a-zA-Z]/gm);
    }, [password]);

    const hasNumbers = useMemo(() => {
        return password && String(password).match(/\d/gm);
    }, [password]);

    const hasUppercaseLetters = useMemo(() => {
        return password && String(password).match(/[A-Z]/gm);
    }, [password]);

    const hasLowercaseLetters = useMemo(() => {
        return password && String(password).match(/[a-z]/gm);
    }, [password]);

    const passwordsMatch = useMemo(() => {
        return password && confirmedPassword && password === confirmedPassword;
    }, [password, confirmedPassword]);

    const isValidPassword = useMemo(() => {
        return isValidPasswordLength && hasLetters && hasNumbers && hasUppercaseLetters && hasLowercaseLetters && passwordsMatch;
    }, [isValidPasswordLength, hasLetters, hasNumbers, hasUppercaseLetters, hasLowercaseLetters, passwordsMatch]);

    const changePassword = async () => {
        setServerError('');

        await resetPassword({
            email: email,
            token: token,
            password: password,
            confirmedPassword: confirmedPassword
        }).unwrap().then(() => {
            navigate(`/login?email=${encodeURIComponent(email)}`);
        }).catch(() => {
            setServerError('Your password reset attempt was unsucessful. You may be using an expired link. Please check your email and ensure you are using the most recent password reset link.')
        });   
    }

    const sendNewResetLink = async () => {
        await sendNewLink({
            email: email
        }).unwrap().then(() => {
            setIsLinkSent(true);
        });
    }

    const { error: invalidToken } = useValidateResetTokenQuery({
        email: encodeURIComponent(searchParams.get('email')),
        token: token
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const isButtonDisabled = useMemo(() => {
        return !isValidPassword;
    }, [isValidPassword]);

    if (invalidToken) {
        return (
            <Grid container sx={{ display: 'flex', height: '100%', width: '100%', backgroundColor: '#F7F9FC' }}>
                <Grid item xs={0} sm={2} md={3} />
                <Grid item xs={12} sm={8} md={6} height='100%' display='grid'>
                    <Stack direction='row' sx={{ alignSelf: 'center', boxShadow: 4, borderRadius: '13px' }}>
                        <Stack px={4} py={6} spacing={6} sx={{ minWidth: '60%', borderTopLeftRadius: '13px', borderBottomLeftRadius: '13px' }}>
                            <Box>
                                <img src={smallLogo} alt="DOCSI Mini Logo" width='40' height='30' />
                            </Box>
                            <Stack sx={{ justifySelf: 'center', alignSelf: 'center', width: '400'}} spacing={3}>
                                <Box sx={{font: 'normal normal normal 24px/22px Roboto' }}>Invalid Password Reset Link</Box>
                                { isLinkSent ? 
                                    <Box sx={{font: 'normal normal normal 16px/24px Roboto', color: '#00000099'}}>
                                        <Box>A new password reset link has been sent to your email. You may now close this page.</Box>
                                    </Box> :
                                    <>
                                        <Box sx={{font: 'normal normal normal 16px/24px Roboto', color: '#00000099'}}>
                                            <Box>The password reset link you are using is invalid and may be expired. Enter your email below to send a new link.</Box>
                                        </Box>
                                        <Stack direction='row' spacing={1} justifyContent='space-between'>
                                            <TextField
                                                id="email"
                                                label="Email"
                                                variant="outlined"
                                                value={email}
                                                onChange={(event) => setEmail(event.target.value)}
                                                fullWidth
                                            />
                                            <LoadingButton loading={isSendingLink} variant='contained' sx={{ width: '53%' }} onClick={sendNewResetLink}>
                                                Send New Link
                                            </LoadingButton>
                                        </Stack>
                                    </>
                                }
                            </Stack>
                            <Box sx={{font: 'normal normal normal 16px/24px Roboto'}}>Need Help? <Link  color = '#2C2CFF'  target="_blank"  href = "https://docs.google.com/forms/d/e/1FAIpQLSdZk2yXi-G7yU_dhUurqcd_id2ffgXYXh46m0tNwW2EJJmD-Q/viewform">Reach Out</Link></Box>
                        </Stack>
                        <Box sx={{ display: { xs: 'none', lg: 'flex' }, width: '100%', backgroundColor: '#2C2CFF', alignItems: 'center', justifyContent: 'center', borderTopRightRadius: '13px', borderBottomRightRadius: '13px' }}>
                            <img src={whiteLogo} alt="DOCSI Logo" width='75%' style={{ objectFit: 'contain' }} />
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={0} sm={2} md={3} />
            </Grid>
        );
    }

    return (
        <Grid container sx={{ display: 'flex', height: '100%', width: '100%', backgroundColor: '#F7F9FC' }}>
            <Grid item xs={0} sm={2} md={3} />
            <Grid item xs={12} sm={8} md={6} height='100%' display='grid'>
                <Stack direction='row' sx={{ alignSelf: 'center', boxShadow: 4, borderRadius: '13px' }}>
                    <Stack px={4} py={6} spacing={6} sx={{ minWidth: '60%', borderTopLeftRadius: '13px', borderBottomLeftRadius: '13px' }}>
                        <Box>
                            <img src={smallLogo} alt="DOCSI Mini Logo" width='40' height='30' />
                        </Box>
                        <Stack sx={{ justifySelf: 'center', alignSelf: 'center', width: '343'}}>
                            <Box sx={{font: 'normal normal normal 24px/22px Roboto' }} mb={1}>Set Your Password</Box>
                            <Box sx={{font: 'normal normal normal 16px/24px Roboto', color: '#00000099'}} mb={2}>
                                <Box>In order to protect your account, make sure your password:</Box>
                                <List dense>
                                    <ValidationListItem 
                                        passesValidation={isValidPasswordLength}
                                        text='Is at least 8 characters long'
                                    />
                                    <ValidationListItem
                                        passesValidation={hasLetters && hasNumbers}
                                        text='Contains both letters and numbers'
                                    />
                                    <ValidationListItem
                                        passesValidation={hasUppercaseLetters && hasLowercaseLetters}
                                        text='Contains uppercase and lowercase letters'
                                    />
                                    <ValidationListItem
                                        passesValidation={passwordsMatch}
                                        text='Matches the password confirmation field'
                                    />
                                </List>
                            </Box>
                            <Box mb={4}>
                                <TextField 
                                    fullWidth 
                                    variant='outlined' 
                                    label='Password' 
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                edge='end'
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Box>
                            <Box mb={4}>
                                <TextField 
                                    fullWidth 
                                    variant='outlined' 
                                    label='Confirm Password' 
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    value={confirmedPassword}
                                    onChange={(e) => setConfirmedPassword(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={handleClickShowConfirmPassword}
                                                edge='end'
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Box>
                            <Box mb={2}>
                                <Button variant='contained' disabled={isButtonDisabled} onClick={changePassword}>Change Password</Button>
                            </Box>
                            <Box sx={{font: 'normal normal normal 16px/24px Roboto', color: '#FF0000'}}>{serverError}</Box>
                        </Stack>
                        <Box sx={{font: 'normal normal normal 16px/24px Roboto'}}>Need Help? <Link  color = '#2C2CFF'  target="_blank"  href = "https://docs.google.com/forms/d/e/1FAIpQLSdZk2yXi-G7yU_dhUurqcd_id2ffgXYXh46m0tNwW2EJJmD-Q/viewform">Reach Out</Link></Box>
                    </Stack>
                    <Box sx={{ display: { xs: 'none', lg: 'flex' }, width: '100%', backgroundColor: '#2C2CFF', alignItems: 'center', justifyContent: 'center', borderTopRightRadius: '13px', borderBottomRightRadius: '13px' }}>
                        <img src={whiteLogo} alt="DOCSI Logo" width='75%' style={{ objectFit: 'contain' }} />
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default ChangePassword;