import React from 'react';
import SortableTableHeader from '../../../CustomComponents/SortableTableHeader';

function PhysicianHeader(props) {
    const headers = [
        {
            id: 'last_name',
            label: 'Card Manager',
            tooltip: null,
            ratio: 14,
            justify: 'start',
            sortable: true,
        },
        {
            id: 'progress',
            label: 'Card Manager Review Progress',
            tooltip: null,
            ratio: 35,
            justify: 'start',
            sortable: false,
        },
        {
            id: 'accepted_review_value',
            label: 'Approved',
            tooltip: null,
            ratio: 12,
            justify: 'start',
            sortable: true,
        },
        {
            id: 'rejected_review_value',
            label: 'Denied',
            tooltip: null,
            ratio: 12,
            justify: 'start',
            sortable: true,
        },
        {
            id: 'open_review_value',
            label: 'Unreviewed',
            tooltip: null,
            ratio: 12,
            justify: 'start',
            sortable: true,
        },
        {
            id: 'overdue_review_value',
            label: 'Overdue',
            tooltip: null,
            ratio: 15,
            justify: 'end',
            sortable: true,
        },
    ];

    return (
        <SortableTableHeader headers={headers} {...props} />
    );
}

export default PhysicianHeader;
