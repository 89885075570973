import React from 'react';
import { Stack, Typography, Collapse } from '@mui/material';
import ChangeType from './ChangeType';
import { NewOpenQuantity, NewHoldQuantity } from './NewQuantities';

import NewItem from './NewItem';
import ReviewActionDropdown from './ReviewActionDropdown';
import RejectionNotes from './RejectionNotes';

function ReviewRow(props) {
    const backgroundColor = props.index % 2 === 1 ? 'rgb(0, 0, 0, 0.05)' : 'white';

    return (
        <Stack backgroundColor={backgroundColor}>
            <Stack direction='row' borderBottom='1px solid rgba(0, 0, 0, 0.5)' sx={{ minHeight: '60px' }}>
                <Stack direction='row' width='22%' justifyContent='space-between' alignItems='center' borderRight='1px solid rgba(0, 0, 0, 0.5)' px={2}>
                    { props.recommendation?.type !== 'addition' && 
                        <Stack>
                            <Typography variant='heavy' sx={{ fontSize: '16px' }}>{props.item?.system_name ?? props.item?.display_name}</Typography>
                            <Typography variant='normal' sx={{ fontSize: '12px' }}>{props.item?.vendor.name} - {props.item?.part_number}</Typography>
                        </Stack>
                    }
                    { props.recommendation?.type === 'addition' && 
                        <></>
                    }
                </Stack>
                <Stack direction='row' width='7%' justifyContent='center' alignItems='center' borderRight='1px solid rgba(0, 0, 0, 0.5)'>
                    { props.recommendation?.type !== 'addition' &&
                        <Typography variant='heavy' sx={{ fontSize: '20px' }}>{props.item?.open_quantity}</Typography>
                    }
                </Stack>
                <Stack direction='row' width='7%' justifyContent='center' alignItems='center' borderRight='1px solid rgba(0, 0, 0, 0.5)'>
                    { props.recommendation?.type !== 'addition' &&
                        <Typography variant='heavy' sx={{ fontSize: '20px' }}>{props.item?.hold_quantity}</Typography>
                    }
                </Stack>
                <Stack width='13%' direction='row' justifyContent='center' alignItems='center' borderRight='1px solid rgba(0, 0, 0, 0.5)' px={2}>
                    <ChangeType recommendation={props.recommendation} />            
                </Stack>
                <Stack width='7%' direction='row' justifyContent='center' alignItems='center' borderRight='1px solid rgba(0, 0, 0, 0.5)'>
                    <NewOpenQuantity recommendation={props.recommendation} item={props.item} />
                </Stack>
                <Stack width='7%' direction='row' justifyContent='center' alignItems='center' borderRight='1px solid rgba(0, 0, 0, 0.5)'>
                    <NewHoldQuantity recommendation={props.recommendation} item={props.item} />
                </Stack>
                <Stack width='22%' direction='row' justifyContent='space-between' alignItems='center' borderRight='1px solid rgba(0, 0, 0, 0.5)' px={2}>
                    <NewItem recommendation={props.recommendation} item={props.item} />
                </Stack>
                <Stack width='15%'>
                    { props.recommendation?.review.length > 0 && 
                        <ReviewActionDropdown 
                            review={props.recommendation?.review[0]} 
                            recommendation={props.recommendation} 
                            handleUpdate={props.handleUpdate} 
                            disabled={props.disabled} 
                            index={props.index}
                        />
                    }
                </Stack>
            </Stack>
            <Collapse in={props.recommendation?.review[0]?.status === 'rejected'} timeout='auto' unmountOnExit>
                <Stack direction='row'>
                    <RejectionNotes review={props.recommendation?.review[0]} recommendation={props.recommendation} handleUpdate={props.handleUpdate} disabled={props.disabled} />
                </Stack>
            </Collapse>
        </Stack>
    );
}


export default ReviewRow;
