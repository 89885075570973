import React, {useState} from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useTheme as UseTheme } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useNavigate } from 'react-router-dom';



function HeadlineBanner(props) {

    const navigate = useNavigate();
    const theme = UseTheme();

    const reviewsCompleted = () => {
        return props.campaignProgress.every((campaign) => campaign.isReviewed === true);
    }

    const [closed, setClosed] = useState(true);

    const handleClose = () => {
        setClosed(false);
    };

    function CustomIcon(props) {
        return (
            props.completed ? <CheckCircleIcon fontSize='small' sx={{paddingLeft: '4px'}}/> : <CircleOutlinedIcon fontSize='small' sx={{paddingLeft:'4px'}}/>
        );
    }
    return (
        <Collapse in={closed} >
            <Stack p={2} spacing={2} textAlign='center' sx={{ borderRadius: '4px', backgroundColor: `${theme.palette.blue.main}`, color: 'white', justifyContent: 'center'}}>
                { reviewsCompleted() && 
                <Stack  direction='row' sx={{marginTop:'0px', justifyContent: 'center'}}>
                    <Typography variant='normal' sx={{ fontSize: '12px', alignSelf:'center', marginLeft:'auto'}}>You've reviewed all your top opportunities!</Typography> 
                    <IconButton onClick={handleClose} sx={{ display:'inline-block', color:`${theme.palette.background.white}`, marginLeft:'auto', padding:'0px'}}> <CloseIcon fontSize='small'/> </IconButton>
                </Stack>
                }
                {!reviewsCompleted() &&
                    <Typography variant='normal' sx={{ fontSize: '12px' }}>
                        { props.currentCampaign.campaign_type === 'compliance' ? <>You've reviewed all your swaps here! </> : 
                            <>You've reviewed your top cards here!</>
                        }      
                    </Typography>
                }
                <Stack direction='column' sx={{alignSelf: 'center'}}>
                    <Stepper orientation='vertical' >
                        { props.campaignProgress.map((campaign) => {
                            return (
                                <Step key={campaign.campaign_id} completed={campaign.isReviewed ? true : false} sx={{padding: 0}}>
                                    <StepLabel StepIconComponent={CustomIcon} sx={{padding:'0px'}}>
                                        <Typography variant='bold' sx={{ fontSize: '12px', color:'white'}}>{campaign.campaign_type === 'compliance' ? campaign.campaign_name : campaign.hospital_name}</Typography> 
                                    </StepLabel>
                                </Step>
                            );
                        })
                        }
                    </Stepper>
                </Stack>
                <Typography variant='normal' sx={{ fontSize: '12px' }}>
                    { reviewsCompleted() ? <> Feel free to continue reviewing your cards and making changes as you see fit! </> :
                        props.currentCampaign.campaign_type === 'compliance' ? <>You have additional opportunities ready for you at <Typography variant='bold'>other locations</Typography></> : 
                            <>Keep optimizing at <Typography variant='bold'>{props.currentCampaign.hospital_name}</Typography> or review your next opportunities at <Typography variant='bold'>other locations</Typography></>
                    }
                </Typography>
                { !reviewsCompleted() &&
                    <Button variant='contained' color='primary' onClick={() => navigate('/physician/dashboard/landing')}>Review next Opportunities</Button>
                }
            </Stack>
        </Collapse>
    );
}

export default HeadlineBanner;