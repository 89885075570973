import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material';
import { Backdrop, IconButton, Stack, Button, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from 'popmotion';
import React, { useState } from 'react';
import { useDeleteAuxiliaryItemImageMutation, useDeleteCommentImageMutation } from '../../api/cardSlice';
import DeleteDataConfirmation from '../Card/DeleteDataConfirmation';

const variants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        };
    }
};


function ImageCarousel(props) {
    const [[page, direction], setPage] = useState([0, 0]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isShowingDeleteImageConfirmation, setIsShowingDeleteImageConfirmation] = useState(false);
    const [deleteAuxItemImage] = useDeleteAuxiliaryItemImageMutation();
    const [deleteCommentImage] = useDeleteCommentImageMutation();
    const theme = useTheme();

    const imageIndex = wrap(0, props.images.length, page);

    const paginate = (newDirection) => {
        setPage([page + newDirection, newDirection]);
    };

    const handleDeleteImage = async() => {
        if (props.type === 'comments') {
            await deleteCommentImage({
                card: props.card,
                imageId: props.images[imageIndex]['id']
            });
        } else if (props.type === 'auxiliary_items') {
            await deleteAuxItemImage({
                card: props.card,
                imageId: props.images[imageIndex]['id']
            });
        }
    }

    return (
        <Stack padding={2} spacing={2} width='100%' height='500px' sx={{ 
            boxShadow: 1, 
            borderRadius: '8px', 
            backgroundColor: `${theme.palette.background.white}`,
            justifySelf: 'center'
        }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='bold' fontSize='16px'>Images</Typography>
                <Typography variant='normal' fontSize='12px'>{imageIndex + 1} of {props.images.length} Total Images</Typography>
            </Stack>
            <Box sx={{ 
                position: 'relative', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                overflow: 'hidden',
                width: '100%',
                height: '100%'
            }}>
                <AnimatePresence initial={false} custom={direction}>
                    <motion.img 
                        key={page}
                        src={props.images[imageIndex]['image_url']}
                        custom={direction}
                        variants={variants}
                        initial='enter'
                        animate='center'
                        exit='exit'
                        transition={{
                            x: { type: 'spring', stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 }
                        }}
                        drag='x'
                        dragConstraints={{ left: 0, right: 0 }}
                        style={{ zIndex: 0, width: '100%', height: '100%', position: 'absolute', objectFit: 'contain' }}
                        onClick={() => setIsExpanded(true)}
                    />
                    { props.images.length > 1 && 
                        <IconButton onClick={() => paginate(1)} size='large' sx={{
                            top: 'calc(50% - 20px)',
                            right: '10px',
                            position: 'absolute',
                            display: 'flex',
                            zIndex: 2,
                            padding: 0,
                            color: 'black'
                        }}>
                            <ArrowCircleRight />
                        </IconButton>
                    }
                    { props.images.length > 1 && 
                        <IconButton onClick={() => paginate(-1)} size='large' sx={{
                            top: 'calc(50% - 20px)',
                            left: '10px',
                            position: 'absolute',
                            display: 'flex',
                            zIndex: 2,
                            padding: 0,
                            color: 'black'
                        }}>
                            <ArrowCircleLeft />
                        </IconButton>
                    }
                </AnimatePresence>
                <Backdrop
                    sx={{ 
                        width: '100%', 
                        height: '100%', 
                        zIndex: 10,
                    }}
                    open={isExpanded}
                    onClick={() => setIsExpanded(false)}
                >
                    <img style={{ width: '90%', height: '90%', objectFit: 'contain' }} src={props.images[imageIndex]['image_url']} alt={props.type} />
                </Backdrop>
            </Box>
            <Button variant='text' color='error' onClick={() => setIsShowingDeleteImageConfirmation(true)} style={{ alignSelf: 'end' }}>Delete Image</Button>
            <DeleteDataConfirmation
                open={isShowingDeleteImageConfirmation}
                setOpen={setIsShowingDeleteImageConfirmation}
                dataType='Image'
                deleteData={handleDeleteImage}
            />
        </Stack>
    );
}

export default ImageCarousel;