import { Check, Clear } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Stack, useTheme } from '@mui/system';
import React from 'react';

function ValidationListItem(props) {
    const theme = useTheme();

    return (
        <Stack direction='row' spacing={1} alignItems='center'>
            { props.passesValidation ? 
                <Check fontSize='small' style={{ color: `${theme.palette.green.main}` }} /> :
                <Clear fontSize='small' style={{ color: `${theme.palette.red.main}` }} />
            }
            <Typography variant='normal' sx={{ fontSize: '14px' }}>{props.text}</Typography>
        </Stack>
    );
}

export default ValidationListItem;
