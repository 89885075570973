import { Stack, useTheme } from '@mui/system';
import { Button, Tooltip } from '@mui/material';
import React from 'react';
import { CheckRounded, CloseRounded, InfoOutlined, LockRounded, UndoRounded } from '@mui/icons-material';

function OptimizationActions(props) {
    const theme = useTheme();

    if (props.locked) {
        return (
            <Stack direction='row-reverse' alignItems='center' justifyContent='space-between'>
                <Tooltip enterTouchDelay={0} title='Any card edits you accept in this dashboard are automatically submitted to your card management team for review, approval, and implementation within 24 hours. This card edit is currently under review by your card management team.'>
                    <InfoOutlined style={{ color: `${theme.palette.blue.main}`, marginRight: '8px', position: 'absolute' }} />
                </Tooltip>
                <Button variant='text' startIcon={<LockRounded />} disabled fullWidth >
                    Pending Card Manager Review
                </Button>
            </Stack>
        )
    }

    else if (props.status === 'open') {
        return (
            <Stack px={2} py={1.5} direction='row' justifyContent='space-between'>
                <Stack>
                    { !props.hideDetailsButton && 
                        <Button variant='outlined' onClick={props.handleEdit} sx={{
                            paddingLeft: {
                                xs: '5px',
                                sm: '15px'
                            },
                            paddingRight: {
                                xs: '5px',
                                sm: '15px'
                            },
                        }}>
                            More Details
                        </Button>
                    }
                </Stack>
                <Stack direction='row' spacing={{
                    xs: 1,
                    sm: 2
                }}>
                    <Button variant='blueContained' startIcon={<CloseRounded />} onClick={props.handleDecline} style={{ backgroundColor: '#929292', boxShadow: 0 }}>
                        Decline
                    </Button>
                    <Button variant='blueContained' startIcon={<CheckRounded />} onClick={props.handleAccept} style={{ boxShadow: 0, }}>
                        Accept
                    </Button>
                </Stack>
            </Stack>
        )
    }

    else if (props.status === 'accepted' && props.type === 'removal') {
        return (
            <Button variant='text' startIcon={<UndoRounded />} onClick={props.handleUndo} fullWidth style={{
                color: `${theme.palette.subtext.main}`
            }}>
                Undo Removal
            </Button>
        )
    }

    else if (props.status === 'accepted' && props.type === 'update') {
        return (
            <Button variant='text' startIcon={<UndoRounded />} onClick={props.handleUndo} fullWidth style={{
                color: `${theme.palette.subtext.main}`
            }}>
                Undo Update
            </Button>
        )
    }

    else if (props.status === 'custom_removal' || props.status === 'custom_update') {
        return (
            <Button variant='text' startIcon={<UndoRounded />} onClick={props.handleUndo} fullWidth style={{
                color: `${theme.palette.subtext.main}`
            }}>
                Undo Custom Edit
            </Button>    
        )
    }

    else if (props.status === 'custom_swap') {
        return (
            <Button variant='text' startIcon={<UndoRounded />} onClick={props.handleUndo} fullWidth style={{
                color: `${theme.palette.subtext.main}`
            }}>
                Undo Custom Swap
            </Button>
        )
    }

    return (
        <Button variant='text' startIcon={<UndoRounded />} onClick={props.handleUndo} fullWidth style={{
            color: `${theme.palette.subtext.main}`
        }}>
            Undo Decline
        </Button>
    );
}

export default OptimizationActions;
