import React from 'react';
import { Stack, Typography, TextField } from '@mui/material';
import { useTheme } from '@mui/system';

function QuantityInput(props) {
    const theme = useTheme();
    if (props.variant === 'open') {
        return (
            <Stack direction='row'>
                <Typography sx={{ fontSize: '16px', color: `${theme.palette.green.main}`}} mr={1}>
                    Open:
                </Typography>
                <TextField
                    variant='standard'
                    size='small'
                    sx={{ width: '2ch' }}
                    value={props.quantity ?? props.item.open_quantity}
                    error={!props.isValid}
                    disabled={!props.canEdit}
                    onChange={(e) => {
                        props.setEditingQuantity(e.target.value)
                    }}
                    onFocus={event => {event.target.select()}}
                    inputProps={{ style: { textAlign: 'center'} }}
                />
            </Stack>
        );
    } else if (props.variant === 'hold') {
        return (
            <Stack direction='row'>
                <Typography sx={{ fontSize: '16px', color: '#8E8E93'}} mr={1}>
                    Hold:
                </Typography>
                <TextField
                    variant='standard'
                    size='small'
                    sx={{ width: '2ch' }}
                    value={props.quantity ?? props.item.hold_quantity}
                    error={!props.isValid}
                    disabled={!props.canEdit}
                    onChange={(e) => {
                        props.setEditingQuantity(e.target.value)
                    }}
                    onFocus={event => {event.target.select()}}
                    inputProps={{ style: { textAlign: 'center'} }}
                />
            </Stack>
        );
    }

    return <></>;
}

export default QuantityInput;