import { apiSlice } from './apiSlice';
import { NotificationActions } from './notificationSlice';

export const inventorySlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInventory: builder.query({
            query: ({hospitalId, page}) => `/hospitals/${hospitalId}/items?page=${page}`,
            providesTags: (result = [], error, arg) => 
                result.data ? 
                    [
                        ...result.data.map(({ id }) => ({ type: 'Inventory', id })),
                        { type: 'Inventory', id: 'PARTIAL-LIST' }
                    ] : 
                    [{ type: 'Inventory', id: 'PARTIAL-LIST' }],
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch {
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Could not load inventory at this time. Please try again later.'
                    }));
                }
            },
        }),
        searchItems: builder.query({
            query: ({ hospitalId, queryParams }) => ({
                url: `/hospitals/${hospitalId}/itemSearch?${Object.entries(queryParams).map(([key, value]) => `${key}=${value}`).join('&')}`,
            }),
            providesTags: (result = [], error, arg) => 
                result.data ? 
                    [
                        ...result.data.map(({ id }) => ({ type: 'Inventory', id })),
                        { type: 'Inventory', id: 'PARTIAL-LIST' }
                    ] : 
                    [{ type: 'Inventory', id: 'PARTIAL-LIST' }],
        }),
        getItemCategories: builder.query({
            query: (hospitalId) => `/hospitals/${hospitalId}/itemCategories`,
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch {
                    dispatch(NotificationActions.addWarningNotification({
                        message: 'Something went wrong. Filtering by category may not be available. We apologize for the inconvenience.'
                    }));
                }
            }
        }),
        getHospitalVendors: builder.query({
            query: (hospitalId) => `/hospitals/${hospitalId}/vendors`,
            providesTags: ['Vendor']
        }),
        createVendor: builder.mutation({
            query: ({name}) => ({
                url: '/vendors',
                method: 'POST',
                body: {
                    name: name
                }
            }),
            invalidatesTags: ['Vendor']
        }),
        createItem: builder.mutation({
            query: ({ hospitalId, data }) => ({
                url: `/hospitals/${hospitalId}/items`,
                method: 'POST',
                body: data
            }),
            async onQueryStarted({ hospitalId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: newItem } = await queryFulfilled;
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'New item successfully created.'
                    }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Could not create item at this time. Please try again later.'
                    }));
                }
            },
            invalidatesTags: ['Inventory']
        }),
        getItemCards: builder.query({
            query: (itemId) => `/items/${itemId}/cards`
        }),
    })
});

export const {
    useGetInventoryQuery,
    useSearchItemsQuery,
    useGetItemCategoriesQuery,
    useGetHospitalVendorsQuery,
    useCreateVendorMutation,
    useCreateItemMutation,
    useGetItemCardsQuery,
} = inventorySlice;