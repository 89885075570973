import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

function AssessmentSummary(props) {
    return (
        <Stack>
            <Typography variant='heavy' fontSize='14px'><strong>Conclusions</strong></Typography>
            <Typography variant='normal' fontSize='12px'>
                {props.assessment.summary}
            </Typography>
        </Stack>
    );
}

export default AssessmentSummary;
