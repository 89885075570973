import React, {useState, useMemo} from 'react';
import { Stack, Typography, IconButton, Button } from '@mui/material';
import { CheckCircle, Cancel, InfoOutlined, SwapVertRounded, ArrowForwardIos } from '@mui/icons-material';
import ItemSwapDialog from './ItemSwapDialog';
import ItemSwapContainer from './ItemSwapContainer';
import { useTheme } from '@mui/system';
import useAnalytics from '../../Hooks/useAnalytics';

function ItemSwapCard(props) {
    const theme = useTheme();
    const analytics = useAnalytics();

    const [isShowingDialog, setIsShowingDialog] = useState(false);

    function handleOpenInfo() {
        analytics.track('Item Swap Info Opened', {
            sector: 'Physician Dashboard'
        });
        setIsShowingDialog(true);
    }

    function handleOpenDrawer() {
        props.setIsDrawerOpen(true);
    }

    const title = useMemo(() => {
        if (props.isAccepted) {
            return 'Swap Accepted';
        } else if (props.isRejected) {
            return 'Swap Declined';
        } else {
            return 'Can we swap this item?';
        }
    }, [props.isAccepted, props.isRejected]);

    return (
        <Stack spacing={1}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Stack direction='row' alignItems='center' spacing={1}>
                    { props.isAccepted && <CheckCircle sx={{ color: `${theme.palette.green.main}`}} />}
                    { props.isRejected && <Cancel sx={{ color: `${theme.palette.subtext.main}` }} />}
                    <Typography variant='heavy' sx={{ fontSize: '16px', alignItems: 'center' }}><strong>{title}</strong></Typography>
                </Stack>
                <IconButton fontSize='16px' onClick={handleOpenInfo}>
                    <InfoOutlined sx={{ color: `${theme.palette.blue.main}` }} />
                </IconButton>
                <ItemSwapDialog isShowingDialog={isShowingDialog} setIsShowingDialog={setIsShowingDialog} />
            </Stack>
            <Stack direction={props.isAccepted ? 'column-reverse' : 'column'} spacing={1}>
                <ItemSwapContainer variant='current' disabled={props.isAccepted} swap={props.currentSwap} showItemCost={props.showItemCost} currentIndex={props.currentIndex} />
                <SwapVertRounded sx={{ color: '#0000001F', alignSelf: 'center' }}/>
                <ItemSwapContainer variant='recommended' disabled={props.isRejected} swap={props.currentSwap} showItemCost={props.showItemCost} currentIndex={props.currentIndex} />
            </Stack>
            <Button 
                onClick={handleOpenDrawer} 
                disabled={!props.isOpen} 
                variant='text' 
                endIcon={props.isOpen ? <ArrowForwardIos /> : <></>} 
                style={{ alignSelf: 'end', color: props.isOpen ? `${theme.palette.blue.heavy}` : `${theme.palette.hold.main}`, paddingTop: '0px', paddingBottom: '0px', width: 'fit-content'}}
            >
                Item swap applied to {props.checked.length} of {props.drafts.length} {props.drafts.length === 1 ? 'card' : 'cards'}
            </Button>
        </Stack>
    );
}

export default ItemSwapCard;