import { Divider, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { NumericFormat } from 'react-number-format';

function VendorDetails(props) {
    const theme = useTheme();

    const item = props.item ?? props.recommendation.item;
    const openQuantity = item.open_quantity ?? props.recommendation.open_quantity;
    const holdQuantity = item.hold_quantity ?? props.recommendation.hold_quantity;
    const isOpen = props.recommendation.status === 'open';

    return (
        <Stack color={`${theme.palette.subtext.main}`} direction='row' spacing={1} divider={<Divider orientation='vertical' flexItem />}>
            <Typography variant='normal' sx={{ fontSize: '12px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '100%' }}>
                {item.vendor.name}
            </Typography>
            { isOpen && 
                <Stack direction='row' spacing={1} divider={<Divider orientation='vertical' flexItem />}>
                    <Typography variant='normal' sx={{ fontSize: '12px' }}>
                        <NumericFormat
                            value={item.cost / 100}
                            thousandSeparator={true}
                            displayType='text'
                            prefix='$'
                            decimalScale={2}
                            fixedDecimalScale={true}
                        />
                    </Typography>
                    <>
                        <Typography variant='normal' sx={{ fontSize: '12px' }}>
                            Open: {openQuantity}
                        </Typography>
                        <Typography variant='normal' sx={{ fontSize: '12px' }}>
                            Hold: {holdQuantity}
                        </Typography>
                    </>
                </Stack>
            }
        </Stack>
    );
}

export default VendorDetails;