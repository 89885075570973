import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import React from 'react';

function DeleteDataConfirmation(props) {

    function handleClose(event) {
        event.stopPropagation();
        props.setOpen(false);
    }

    const handleDeletion = async(event) => {
        event.stopPropagation();
        props.deleteData(props.id);
        props.setOpen(false);
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>
                {`Confirm ${props.dataType} Deletetion`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this {props.dataType.toLowerCase()}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => handleClose(e)}>
                    Cancel
                </Button>
                <Button onClick={(e) => handleDeletion(e)} style={{ color: 'red' }}>
                    Yes, delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteDataConfirmation;