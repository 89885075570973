import React from 'react';
import { Autocomplete, Checkbox, TextField, InputAdornment } from '@mui/material';
import { CheckBoxOutlineBlankOutlined, CheckBox, FilterList } from '@mui/icons-material';

function Filter(props) {
    return (
        <Autocomplete 
            options={props.options}
            disableCloseOnSelect 
            getOptionLabel={(option) => option}
            value={props.value}
            onChange={(event, newValue) => props.setSelectedValue(newValue)}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankOutlined fontSize='small' />}
                        checkedIcon={<CheckBox fontSize='small' />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option}
                </li>
            )}
            style={{ width: 268, height: 56 }}
            renderInput={(params) => (
                <TextField 
                    {...params}
                    label={props.label} 
                    placeholder={props.placeholder}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position='start'>
                                <FilterList />
                            </InputAdornment>
                        )
                    }}
                />
            )}
        />
    )
}

export default Filter;