import React, {useState, useMemo, useContext} from 'react';
import { useAddCardCommentMutation, useUpdateCardCommentMutation, useDeleteCardCommentMutation, useAddCommentImageMutation} from '../../api/cardSlice';
import { Stack, Button } from '@mui/material';
import FreeformCardEntry from './FreeformCardEntry';
import { Add, AddPhotoAlternateRounded } from '@mui/icons-material';
import AddSectionDialog from './AddSectionDialog';
import { useGetCommentCategoriesQuery } from '../../api/categorySlice';
import { UserContext } from '../Hooks/useUser';
import ImageCarousel from '../CustomComponents/ImageCarousel';
import UploadImage from '../CustomComponents/UploadImage';

function Comments(props) {
    const [updateCardComment] = useUpdateCardCommentMutation();
    const [addCardComment] = useAddCardCommentMutation();
    const [deleteCardComment] = useDeleteCardCommentMutation();
    const [isShowingAddSection, setIsShowingAddSection] = useState(false);
    
    const [isAddingImage, setIsAddingImage] = useState(false);
    const { hasCardManagement } = useContext(UserContext);

    const { data: commentCategories = [] } = useGetCommentCategoriesQuery(props.card.owner.hospital.id);
    const [addImage, { isLoading: isUploadingImage }] = useAddCommentImageMutation();

    const saveData = async(comment, newText) => {
        await updateCardComment({
            comment: comment,
            newText: newText,
            card: props.card
        });
    }

    const deleteData = async(commentId) =>{
        await deleteCardComment({
            cardId : props.card.id,
            commentId : commentId
        })
    }

    const saveNewComment = async(category, body) => {
        await addCardComment({
            card: props.card,
            commentData: {
                category_id: category.id,
                body: body
            }
        });
    }

    const addNewImage = async(formData) => {
        await addImage({
            card: props.card,
            formData: formData
        })
    }

    const sortedComments = useMemo(() => {
        var comments = props.card.comments.slice();
        return comments.sort(function (a, b) {
            return a.category.order - b.category.order;
        });
    }, [props.card.comments]);

    const unusedCategories = useMemo(() => {
        const result = commentCategories.slice();
        props.card.comments.forEach((comment) => {
            result.forEach((element) =>
            {
                if(element.id === comment.category.id)
                { result.splice(result.indexOf(element), 1) }
            })
        })
        return result;
    }, [commentCategories]);

    return (
        <Stack spacing={2}>
            { hasCardManagement() &&
                <Stack direction='row' spacing={2} sx={{ alignSelf: 'end' }}>
                    <Button 
                        variant='contained' 
                        startIcon={<AddPhotoAlternateRounded />} 
                        size='small'
                        onClick={() => setIsAddingImage(true)}
                    >
                        Add Image
                    </Button> 
                    <Button 
                        variant='contained' 
                        startIcon={<Add />} 
                        size='small'
                        onClick={() => setIsShowingAddSection(true)}
                    >
                        Add Section
                    </Button> 
                </Stack>
            }
            <Stack display='flex' alignItems='center' spacing={4}>
                { sortedComments.map((section) => (
                    <FreeformCardEntry key={section.id} id = {section.id} entry={section} deleteData = {deleteData} saveData={saveData} dataType = {'Comment'} canEdit={hasCardManagement()} />
                ))}
                { props.card.comment_images.length > 0 && 
                    <ImageCarousel images={props.card.comment_images} card={props.card} type='comments' />
                }
            </Stack>
            <AddSectionDialog 
                open={isShowingAddSection} 
                setOpen={setIsShowingAddSection} 
                saveNewSection={saveNewComment}
                titleOptions={unusedCategories}
            />
            <UploadImage
                open={isAddingImage}
                setOpen={setIsAddingImage}
                uploadImage={addNewImage}
                isUploading={isUploadingImage}
            />
        </Stack>
    );
}

export default Comments;