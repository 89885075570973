import { apiSlice } from './apiSlice';
import { NotificationActions } from './notificationSlice';

export const campaignMonitoringSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOrganizationCampaigns: builder.query({
            query: (organizationId) => `/organizations/${organizationId}/campaigns`,
            providesTags: (result, error, arg) => [{ type: 'Campaign', id: arg }],
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ 
                        message: 'Could not load campaigns at this time. Please try again later.' 
                    }));
                }  
            },
        }),
        getPhysiciansInCampaign: builder.query({
            query: (campaignId) => `/campaigns/${campaignId}/physicians`,
            providesTags: (result, error, arg) => [{ type: 'Physician', id: arg }],
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try { await queryFulfilled; } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not load campaign\'s physicians. Please try again later.' }));
                }
            },
        }),
        getCardManagersInCampaign: builder.query({
            query: (campaignId) => `/campaigns/${campaignId}/cardManagers`,
            providesTags: (result, error, arg) => [{ type: 'CardManager', id: arg }],
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try { await queryFulfilled; } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Could not load campaign\'s card managers. Please try again later.' }));
                }
            },
        }),
    }),
});

export const {
    useGetOrganizationCampaignsQuery,
    useGetPhysiciansInCampaignQuery,
    useGetCardManagersInCampaignQuery,
} = campaignMonitoringSlice;
