import { useState } from 'react';

export default function useStaffReviewUser() {
    const getUser = () => {
        return JSON.parse(localStorage.getItem('staffReviewUser'));
    };

    const [user, setUser] = useState(getUser());

    const saveUser = (newUser) => {
        if (newUser === null) {
            localStorage.removeItem('staffReviewUser');
        } else {
            localStorage.setItem('staffReviewUser', JSON.stringify(newUser));
        }
        setUser(newUser);
    };

    return {
        setStaffReviewUser: saveUser,
        staffReviewUser: user,
    };
}
