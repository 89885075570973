import { WarningRounded } from '@mui/icons-material';
import { Dialog, Link, Typography } from '@mui/material';
import { Stack, useTheme } from '@mui/system';
import React, { useState } from 'react';

function ItemCollisionAlert(props) {
    const theme = useTheme();
    const [isShowingCollisionDialog, setIsShowingCollisionDialog] = useState(false);

    function onClickHandle() {
        setIsShowingCollisionDialog(true);
    }

    if (props.collidingItems.length == 0) {
        return null;
    }

    return (
        <Stack direction='row' alignItems='center' spacing={1} ml={1}>
            <WarningRounded fontSize='14px' style={{ color: `${theme.palette.warning.main}` }} />
            <Typography variant='normal' fontSize='14px' color={`${theme.palette.warning.main}`}>
                <Link href='#' onClick={onClickHandle}>{props.collidingItems.length} item(s)</Link> with this part number already exist in your inventory.
            </Typography>
            <CollisionDialog open={isShowingCollisionDialog} onClose={() => setIsShowingCollisionDialog(false)} collidingItems={props.collidingItems} />
        </Stack>
    );
}

function CollisionDialog(props) {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <Stack p={3} spacing={2}>
                <Typography variant='normal' fontSize='16px' fontWeight='bold'>Item Collision</Typography>
                <Typography variant='normal' fontSize='14px'>The following items already exist in your inventory with the same part number:</Typography>
                <Stack spacing={1}>
                    {props.collidingItems.map((item) => (
                        <Typography key={item.id} variant='normal' fontSize='14px'>{item.vendor.name} - {item.name}</Typography>
                    ))}
                </Stack>
            </Stack>
        </Dialog>
    )
}


export default ItemCollisionAlert;