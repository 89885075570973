import React, { useState } from 'react';
import HeadlineTile from '../HeadlineTile';
import { Stack, useTheme } from '@mui/system';
import { Typography, IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { CustomLinearProgress } from '../../../CustomComponents/CustomLinearProgress';
import OverallComplianceDialog from './OverallComplianceDialog';
import LogOnEnter from '../../../CustomComponents/LogOnEnter';


function SiteComplianceTile(props) {
    const theme = useTheme();
    const [isShowingInfo, setIsShowingInfo] = useState(false);

    // This accessor is temporary until we decide how we want to handle multiple active compliance campaigns.
    const complianceToDisplay = props.siteCompliance[Object.keys(props.siteCompliance)[0]];

    return (
        <HeadlineTile className='pendo-overall-compliance'>
            <LogOnEnter action='viewed_overall_compliance_tile' data={{ compliance: complianceToDisplay?.current, target: complianceToDisplay?.target }}>
                <Stack spacing={2}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Stack>
                            <Typography variant='heavy' sx={{ fontSize: '16px' }}>{props.hospital.name} Overall Compliance</Typography>
                            <Typography variant='normal' sx={{ fontSize: '12px' }}>{Object.keys(props.siteCompliance)[0]}</Typography>
                        </Stack>
                        <IconButton fontSize='16px' onClick={() => setIsShowingInfo(true)}>
                            <InfoOutlined sx={{ color: `${theme.palette.blue.main}` }} />
                        </IconButton>
                        <OverallComplianceDialog isShowingInfo={isShowingInfo} setIsShowingInfo={setIsShowingInfo} />
                    </Stack>
                    <Stack spacing={1}>
                        <CustomLinearProgress variant='determinate' value={complianceToDisplay?.current * 100} average={complianceToDisplay?.target * 100} size='large'/>
                        <Typography variant='normal' sx={{ fontSize: '12px', alignSelf: 'end' }}>Minimum Required Compliance: <strong>{complianceToDisplay?.target * 100}%</strong></Typography>
                    </Stack>
                </Stack>
            </LogOnEnter>
        </HeadlineTile>
    )
}


export default SiteComplianceTile;
