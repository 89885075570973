import { Grid, Stack } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ItemSwapsHeader from './ItemSwapsHeader';
import { useTheme } from '@mui/system';
import ItemSwapDrawer from './ItemSwapDrawer';
import { useGetPhysicianItemRecommendationsQuery, useGetTotalSavingsSummaryQuery, useUpdateItemSwapRecommendationMutation } from '../../../api/physicianDashboardSlice';
import CompleteModal from '../CompleteModal';
import FeedbackDrawer from './FeedbackDrawer';
import { Navigate } from 'react-router-dom';
import ItemSwapsFeed from './ItemSwapsFeed';
import ComplianceDeclineFlow from './Compliance/DeclineFlow/ComplianceDeclineFlow';
import LoadingIndicator from '../../LoadingIndicator';

function ItemSwaps() {
    const { physicianId, campaignId } = useParams();
    const theme = useTheme();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [activeSwapIndex, setActiveSwapIndex] = useState(0);
    const [oldSavings, setOldSavings] = useState(0);
    const [currentSavings, setCurrentSavings] = useState(0);
    const [isShowingComplete, setIsShowingComplete] = useState(false);
    const [isDeclining, setIsDeclining] = useState(false);
    
    const [isShowingFeedbackDrawer, setIsShowingFeedbackDrawer] = useState(false);

    if (!physicianId) {
        return <Navigate to="/physicianDashboard/auth" />;
    }

    useEffect(() => {
        scrollTo(0, 0);
    }, []);

    const {
        data: recommendations = [], 
        isSuccess: recsLoaded,
        isLoading: recsLoading,
    } = useGetPhysicianItemRecommendationsQuery({ physicianId: physicianId, campaignId: campaignId });
    const {
        data: savings,
    } = useGetTotalSavingsSummaryQuery({
        physicianId: physicianId,
        campaignId: campaignId
    }, {
        skip: !recsLoaded,
        refetchOnMountOrArgChange: true
    });
    const [ updateItemRec ] = useUpdateItemSwapRecommendationMutation();

    useEffect(() => {
        if (savings) {
            setOldSavings(currentSavings);
            setCurrentSavings(savings.swap_savings);
        }
    }, [savings]);

    const swaps = useMemo(() => {
        const groups = [];

        recommendations.forEach((rec) => {
            if (groups.find((group) => group.item_id === rec.item.id)) {
                groups.find((group) => group.item_id === rec.item.id).recommendations.push(rec);
                groups.find((group) => group.item_id === rec.item.id).value += rec.recommendation_value;
            } else {
                groups.push({
                    item_id: rec.item.id,
                    recommendations: [rec],
                    value: rec.recommendation_value
                });
            }
        });

        return groups.sort((a, b) => b.value - a.value);
    }, [recommendations]);

    const [checked, setChecked] = useState([]);

    const drafts = useMemo(() => {
        if (recsLoaded) {
            return Object.values(swaps)[activeSwapIndex].recommendations.map((rec) => rec.draft);
        }
        return [];
    }, [swaps, activeSwapIndex]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    function handleCompletion() {
        setIsShowingComplete(true);
    }

    function swapItems() {
        const swapsToApprove = currentSwap.recommendations.filter((rec) => checked.includes(rec.draft.id));
        const swapsToSkip = currentSwap.recommendations.filter((rec) => !checked.includes(rec.draft.id));
        var increasedSavings = 0;
        swapsToApprove.forEach((swap) => {
            updateItemRec({
                recId: swap.id,
                status: 'accepted',
                physicianId: physicianId,
                campaignId: campaignId,
                rejectionContext: []
            });
            increasedSavings += swap.swap_value;
        });
        swapsToSkip.forEach((swap) => updateItemRec({
            recId: swap.id,
            status: 'skipped',
            physicianId: physicianId,
            campaignId: campaignId,
            rejectionContext: []
        }));

        setTimeout(function() {
            updateSavings(currentSavings + increasedSavings);
        }, 600);
    }

    function undoSwap() {
        var changeInSavings = 0;

        if (isAccepted) {
            currentSwap.recommendations.filter((rec) => rec.status === 'accepted').forEach((swap) => {
                updateItemRec({
                    recId: swap.id,
                    status: 'open',
                    physicianId: physicianId,
                    campaignId: campaignId,
                    rejectionContext: []
                });
                changeInSavings -= swap.swap_value;
            });
        } else if (isRejected) {
            currentSwap.recommendations.forEach((swap) => {
                updateItemRec({
                    recId: swap.id,
                    status: 'open',
                    physicianId: physicianId,
                    campaignId: campaignId,
                    rejectionContext: []
                });
            });
            setChecked(drafts.map((draft) => draft.id));
        }

        setTimeout(function() {
            updateSavings(currentSavings + changeInSavings);
        }, 600);
    }

    const currentSwap = useMemo(() => {
        return Object.values(swaps)[activeSwapIndex]
    }, [activeSwapIndex, swaps]);

    const currentSwapStatus = useMemo(() => {
        if (currentSwap?.recommendations?.some((rec) => rec.status === 'accepted')) {
            return 'accepted';
        } else if (currentSwap?.recommendations?.every((rec) => rec.status === 'rejected')) {
            return 'rejected';
        } else {
            return 'open';
        }
    }, [currentSwap]);

    const isAccepted = currentSwapStatus === 'accepted';
    const isRejected = currentSwapStatus === 'rejected';

    function enterDeclineView() {
        setIsDeclining(true);
        window.scrollTo(0, 0);
    }

    function declineSwap(context) {
        currentSwap.recommendations.forEach((rec) => updateItemRec({
            recId: rec.id,
            status: 'rejected',
            physicianId: physicianId,
            campaignId: campaignId,
            rejectionContext: context
        }));
        setChecked([]);
    }

    function updateSavings(newSavings) {
        setOldSavings(currentSavings);
        setCurrentSavings(newSavings);
    }

    return (
        <Grid container sx={{ backgroundColor: '#EDEDED'}}>
            <Grid item md={3} />
            <Grid item xs={12} md={6}>
                <Stack sx={{ minHeight: window.innerHeight + 150, backgroundColor: `${theme.palette.background.itemSwap}`, overflowX: 'hidden' }}>
                    { isDeclining ? 
                        <ComplianceDeclineFlow
                            currentSwap={currentSwap}
                            declineSwap={declineSwap}
                            setIsDeclining={setIsDeclining}
                        /> :
                        <>
                            {recsLoaded && <ItemSwapsHeader oldSavings={oldSavings} currentSavings={currentSavings} campaignId={campaignId}/>}
                            { recsLoading && <LoadingIndicator variant='swaps'/>}        
                            { recsLoaded && 
                                <>
                                    <ItemSwapsFeed
                                        handleCompletion={handleCompletion}
                                        checked={checked}
                                        setChecked={setChecked}
                                        swaps={swaps}
                                        activeSwapIndex={activeSwapIndex}
                                        setActiveSwapIndex={setActiveSwapIndex}
                                        currentSwap={currentSwap}
                                        currentSwapStatus={currentSwapStatus}
                                        setIsShowingFeedbackDrawer={setIsShowingFeedbackDrawer}
                                        drafts={drafts}
                                        setIsDrawerOpen={setIsDrawerOpen}
                                        declineSwap={enterDeclineView}
                                        swapItems={swapItems}
                                        undoSwap={undoSwap}
                                        showItemCost={true}
                                    />
                                    <ItemSwapDrawer 
                                        isDrawerOpen={isDrawerOpen} 
                                        setIsDrawerOpen={setIsDrawerOpen} 
                                        handleToggle={handleToggle} 
                                        checked={checked}
                                        setChecked={setChecked}
                                        drafts={drafts} 
                                        swaps={swaps}
                                        currentSwapStatus={currentSwapStatus}
                                        activeSwapIndex={activeSwapIndex}
                                    />
                                    <FeedbackDrawer 
                                        open={isShowingFeedbackDrawer} 
                                        setOpen={setIsShowingFeedbackDrawer} 
                                        onSubmit={declineSwap} 
                                        currentSwap={currentSwap}
                                    />
                                    { isShowingComplete &&
                                        <CompleteModal 
                                            open={isShowingComplete} 
                                            setOpen={setIsShowingComplete} 
                                            physician={drafts[0].card.owner}
                                            campaignId={campaignId}
                                            variant='swaps'
                                        />
                                    }
                                    
                                </>
                            }
                        </>
                    }
                </Stack>
            </Grid>
            <Grid item md={3} />
        </Grid>
        
    );
}

export default ItemSwaps;