import React from 'react';
import { Box } from '@mui/system';

function HeadlineTile(props) {
    return (
        <Box className={props.className} p={2} sx={{ backgroundColor: 'white', borderRadius: '4px' }}>
            {props.children}
        </Box>
    );
}

export default HeadlineTile;