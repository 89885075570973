import React, { useMemo, useState, useEffect } from 'react';
import ComplianceHeader from './ComplianceHeader';
import { useGetComplianceRecommendationsQuery, useGetPhysicianComplianceQuery, useUpdateComplianceRecommendationMutation, useLogUserActionMutation } from '../../../../api/physicianDashboardSlice';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/system';
import ItemSwapsFeed from '../ItemSwapsFeed';
import { Stack } from '@mui/material';
import ItemSwapDrawer from '../ItemSwapDrawer';
import CompleteModal from '../../CompleteModal';
import ComplianceDeclineFlow from './DeclineFlow/ComplianceDeclineFlow';
import LoadingIndicator from '../../../LoadingIndicator';

function ComplianceSwaps() {
    const { physicianId, campaignId } = useParams();
    const theme = useTheme();
    const { data: complianceData } = useGetPhysicianComplianceQuery(physicianId);
    const [isShowingComplete, setIsShowingComplete] = useState(false);
    const [activeSwapIndex, setActiveSwapIndex] = useState(Number(sessionStorage.getItem('complianceIndex')) || 0);
    const [checked, setChecked] = useState([]);
    const [isShowingFeedbackDrawer, setIsShowingFeedbackDrawer] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [logPhysicianAction] = useLogUserActionMutation();

    useEffect(() => {
        if (sessionStorage.getItem('exitedViaAssessmentPDF')) {
            logPhysicianAction({
                action: 'returned_from_assessment_pdf',
                data: {},
            });
            sessionStorage.removeItem('exitedViaAssessmentPDF');
        }
    }, []);

    const {
        data: recommendations = [], 
        isSuccess: recsLoaded,
        isLoading: recsLoading,
    } = useGetComplianceRecommendationsQuery( {physicianId: physicianId, campaignId: campaignId });
    const [updateItemRec] = useUpdateComplianceRecommendationMutation();

    const swaps = useMemo(() => {
        const groups = [];

        recommendations.forEach((rec) => {
            if (groups.find((group) => group.item_id === rec.item.id)) {
                groups.find((group) => group.item_id === rec.item.id).recommendations.push(rec);
                groups.find((group) => group.item_id === rec.item.id).value += rec.recommendation_value;
            } else {
                groups.push({
                    item_id: rec.item.id,
                    recommendations: [rec],
                    value: rec.recommendation_value
                });
            }
        });

        return groups.sort((a, b) => b.value - a.value);
    }, [recommendations]);

    const drafts = useMemo(() => {
        if (recsLoaded) {
            return swaps[activeSwapIndex].recommendations.map((rec) => rec.draft);
        }
        return [];
    }, [swaps, activeSwapIndex]);

    function handleCompletion() {
        setIsShowingComplete(true);
    }

    function swapItems() {
        const swapsToApprove = currentSwap.recommendations.filter((rec) => checked.includes(rec.draft.id));
        const swapsToSkip = currentSwap.recommendations.filter((rec) => !checked.includes(rec.draft.id));
        swapsToApprove.forEach((swap) => {
            updateItemRec({
                recommendation: swap,
                status: 'accepted',
                rejectionContext: []
            });
        });
        swapsToSkip.forEach((swap) => updateItemRec({
            recommendation: swap,
            status: 'skipped',
            rejectionContext: []
        }));
    }

    function undoSwap() {
        if (currentSwapStatus === 'accepted') {
            currentSwap.recommendations.filter((rec) => rec.status === 'accepted').forEach((swap) => {
                updateItemRec({
                    recommendation: swap,
                    status: 'open',
                    rejectionContext: []
                });
            });
        } else if (currentSwapStatus === 'rejected') {
            currentSwap.recommendations.forEach((swap) => {
                updateItemRec({
                    recommendation: swap,
                    status: 'open',
                    rejectionContext: []
                });
            });
            setChecked(drafts.map((draft) => draft.id));
        }
    }

    function enterDeclineView() {
        setIsDeclining(true);
        window.scrollTo(0, 0);
    }

    function declineSwap(context) {
        currentSwap.recommendations.forEach((rec) => updateItemRec({
            recommendation: rec,
            status: 'rejected',
            rejectionContext: context
        }));
        setChecked([]);
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const currentSwap = useMemo(() => {
        return swaps[activeSwapIndex]
    }, [activeSwapIndex, swaps]);

    const currentSwapStatus = useMemo(() => {
        if (currentSwap?.recommendations?.some((rec) => rec.status === 'accepted')) {
            return 'accepted';
        } else if (currentSwap?.recommendations?.every((rec) => rec.status === 'rejected')) {
            return 'rejected';
        } else {
            return 'open';
        }
    }, [currentSwap]);

    const [isDeclining, setIsDeclining] = useState(false);

    return (
        <Grid container sx={{ backgroundColor: '#EDEDED', height: window.innerHeight + 150 }}>
            <Grid item md={3} />
            <Grid item xs={12} md={6}>
                <Stack sx={{ backgroundColor: `${theme.palette.background.itemSwap}`, overflowX: 'hidden' }}>
                    { isDeclining ? 
                        <ComplianceDeclineFlow 
                            currentSwap={currentSwap} 
                            setIsDeclining={setIsDeclining} 
                            declineSwap={declineSwap}
                        />
                        :
                        <>
                            <ComplianceHeader 
                                swaps={swaps}
                                compliancePercentage={Math.round((complianceData?.compliance ?? 0) * 100)}
                                campaignId={drafts[0]?.campaign_id}
                            />
                            { recsLoading && <LoadingIndicator variant='swaps'/>}
                            { recsLoaded && 
                            <>
                                <ItemSwapsFeed 
                                    handleCompletion={handleCompletion}
                                    checked={checked}
                                    setChecked={setChecked}
                                    swaps={swaps}
                                    activeSwapIndex={activeSwapIndex}
                                    setActiveSwapIndex={setActiveSwapIndex}
                                    currentSwap={currentSwap}
                                    currentSwapStatus={currentSwapStatus}
                                    setIsShowingFeedbackDrawer={setIsShowingFeedbackDrawer}
                                    drafts={drafts}
                                    setIsDrawerOpen={setIsDrawerOpen}
                                    declineSwap={enterDeclineView}
                                    swapItems={swapItems}
                                    undoSwap={undoSwap}
                                    showItemCost={false}
                                />
                                <ItemSwapDrawer 
                                    isDrawerOpen={isDrawerOpen}
                                    setIsDrawerOpen={setIsDrawerOpen}
                                    handleToggle={handleToggle}
                                    checked={checked}
                                    setChecked={setChecked}
                                    drafts={drafts}
                                    swaps={swaps}
                                    currentSwapStatus={currentSwapStatus}
                                    activeSwapIndex={activeSwapIndex}
                                />
                                { isShowingComplete &&
                                    <CompleteModal
                                        open={isShowingComplete}
                                        setOpen={setIsShowingComplete}
                                        physician={drafts[0].card.owner}
                                        campaignId={drafts[0].campaign_id}
                                        variant='compliance'
                                    />
                                }
                                
                            </>
                            }
                        </>
                    }   
                </Stack>
            </Grid>
            <Grid item md={3} />
        </Grid>
    );
}


export default ComplianceSwaps;
