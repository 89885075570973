
import { Dialog, DialogContent, DialogTitle, Stack, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { useState, useMemo } from 'react';
import { useAddItemToCardMutation } from '../../../api/cardSlice';
import SelectedItem from './SelectedItem';
import ItemSearchDialog from '../ItemSearch/ItemSearchDialog';

function AddItemDialog(props) {
    const [selectedResult, setSelectedResult] = useState();
    const [openQuantity, setOpenQuantity] = useState(0);
    const [holdQuantity, setHoldQuantity] = useState(0);
    const [addItemToCard] = useAddItemToCardMutation();
    const theme = useTheme();


    function handleClose() {
        props.setOpen(false);
    }

    function handleSelect(item) {
        setSelectedResult(item);
    }

    function handleBack() {
        setSelectedResult(null);
        setOpenQuantity(0);
        setHoldQuantity(0);
    }

    const handleAddItem = async () => {
        await addItemToCard({
            card: props.card,
            item: selectedResult,
            requestData: {
                open_quantity: openQuantity,
                hold_quantity: holdQuantity
            }
        })
        handleClose();
        handleBack();
    }

    const isOpenQuantityValid = useMemo(() => {
        return openQuantity !== '' && Number.isInteger(Number(openQuantity)) && Number(openQuantity) >= 0;
    }, [openQuantity]);
    const isHoldQuantityValid = useMemo(() => {
        return holdQuantity !== '' && Number.isInteger(Number(holdQuantity)) && Number(holdQuantity) >= 0;
    }, [holdQuantity]);

    return (
        <Dialog open={props.open} onClose={handleClose} maxWidth='md' fullWidth sx={{
            '& .MuiDialog-container': {
                alignItems: 'flex-start',
            },
            mt: 2,
        }}
        >
            <DialogTitle>
                <Stack>
                    <Typography variant='heavy'>Add item to card</Typography>
                    <Typography variant='normal' sx={{ fontSize: '14px', color: `${theme.palette.subtext.main}` }}>
                        Add a new item from inventory to this card.
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                {selectedResult ?
                    <Stack spacing={4} >
                        <SelectedItem
                            item={selectedResult}
                            openQuantity={openQuantity}
                            setOpenQuantity={setOpenQuantity}
                            isOpenValid={isOpenQuantityValid}
                            holdQuantity={holdQuantity}
                            setHoldQuantity={setHoldQuantity}
                            isHoldValid={isHoldQuantityValid}

                        />

                        <Stack direction='row' spacing={2} justifyContent='end'>
                            <Button
                                variant='outlined'
                                onClick={handleBack}
                                style={{ borderColor: '#0000001F', color: `${theme.palette.subtext.main}` }}
                            >
                                Back
                            </Button>
                            <Button
                                disabled={!isOpenQuantityValid || !isHoldQuantityValid}
                                variant='contained'
                                onClick={handleAddItem}
                                style={{ color: `${theme.palette.blue.main}` }}
                            >
                                Add Item
                            </Button>
                        </Stack>
                    </Stack>
                    :
                    <ItemSearchDialog
                        card={props.card}
                        handleSelect={handleSelect}
                    />
                }
            </DialogContent>
        </Dialog>
    );
}

export default AddItemDialog;
