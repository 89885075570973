import { Drawer, Stack, Typography, IconButton, Button } from '@mui/material';
import { useTheme } from '@mui/system';
import React, {useState} from 'react';
import { CloseRounded, Remove, Add, DeleteOutlineRounded } from '@mui/icons-material';
import { useAcceptRecommendationMutation, useAcceptRecommendationWithChangesMutation, useCreateCustomRecommendationMutation } from '../../../../api/itemRecommendationSlice';

function EditItemDrawer(props) {
    const theme = useTheme();
    const [openQuantity, setOpenQuantity] = useState(props.item.open_quantity);
    const [holdQuantity, setHoldQuantity] = useState(props.item.hold_quantity);
    const [acceptRecommendation] = useAcceptRecommendationMutation();
    const [acceptRecommendationWithChanges] = useAcceptRecommendationWithChangesMutation();
    const [createCustomRecommendation] = useCreateCustomRecommendationMutation();

    function handleClose() {
        props.setOpen(false);
    }

    function increaseHoldQuantity() {
        setHoldQuantity(holdQuantity + 1);
    }

    function decreaseHoldQuantity() {
        setHoldQuantity(holdQuantity - 1);
    }

    function increaseOpenQuantity() {
        setOpenQuantity(openQuantity + 1);
    }

    function decreaseOpenQuantity() {
        setOpenQuantity(openQuantity - 1);
    }

    const handleRemoveClick = async() => {
        if (!props.recommendation) {
            createCustomRemoval();
        } else if (props.recommendation.type === 'removal') {
            acceptRemoval();
        } else {
            acceptCustomRemoval();
        }
        handleClose();
        props.closeItemInfo();
    }

    const createCustomRemoval = async() => {
        await createCustomRecommendation({
            draft: props.draft,
            item: props.item,
            status: 'custom_removal',
            customValue: Math.round((props.item.open_quantity + (props.item.hold_quantity * 0.3)) * props.item.cost * (props.draft.card.yearly_stats?.usage_count ?? 1)),
            customOpen: null,
            customHold: null
        });
    }

    const acceptRemoval = async() => {
        await acceptRecommendation({
            draft: props.draft,
            recommendation: props.recommendation,
        });
    }

    const acceptCustomRemoval = async() => {
        await acceptRecommendationWithChanges({
            draft: props.draft,
            recommendation: props.recommendation,
            status: 'custom_removal',
            customValue: Math.round((props.item.open_quantity + (props.item.hold_quantity * 0.3)) * props.item.cost * (props.draft.card.yearly_stats?.usage_count ?? 1)),
            customOpen: null,
            customHold: null
        });
    }

    const handleSave = async() => {
        if (props.recommendation) {
            handleRecommendationEdit();
        } else {
            handleCustomItemEdit();
        }
        
        handleClose();
        props.closeItemInfo();
    }

    const handleRecommendationEdit = async() => {
        if (openQuantity !== props.recommendation.open_quantity || holdQuantity !== props.recommendation.hold_quantity) {
            await acceptRecommendationWithChanges({
                draft: props.draft,
                recommendation: props.recommendation,
                status: 'custom_update',
                customValue: Math.round((props.item.open_quantity - openQuantity + ((props.item.hold_quantity - holdQuantity) * 0.3)) * props.item.cost * (props.draft.card.yearly_stats?.usage_count ?? 1)),
                customOpen: openQuantity,
                customHold: holdQuantity
            });
        } else {
            await acceptRecommendation({
                draft: props.draft,
                recommendation: props.recommendation
            });
        }
    }

    const handleCustomItemEdit = async() => {
        await createCustomRecommendation({
            draft: props.draft,
            item: props.item,
            status: 'custom_update',
            customValue: Math.round((props.item.open_quantity - openQuantity + ((props.item.hold_quantity - holdQuantity) * 0.3)) * props.item.cost * (props.draft.card.yearly_stats?.usage_count ?? 1)),
            customOpen: openQuantity,
            customHold: holdQuantity
        });
    }

    const valuesHaveChanged = openQuantity !== props.item.open_quantity || holdQuantity !== props.item.hold_quantity;

    return (
        <Drawer open={props.open} onClose={handleClose} anchor='bottom'>
            <Stack spacing={2} p={2}>
                <Stack spacing={1}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography variant='normal' sx={{ fontSize: '20px' }}>Edit Item</Typography>
                        <IconButton onClick={handleClose}>
                            <CloseRounded />
                        </IconButton>
                    </Stack>
                    { props.recommendation &&
                        <Stack spacing={1.5} direction='row' alignItems='center'>
                            <Typography variant='bold' sx={{ fontSize: '12px' }}>Recommendation:</Typography>
                            { props.recommendation.type === 'removal' && 
                                <Typography variant='normal' sx={{ fontSize: '12px' }}>Remove this item from card</Typography>
                            }
                            { props.recommendation.type === 'addition' &&
                                <Typography variant='normal' sx={{ fontSize: '12px' }}>Add this item to card</Typography>
                            }
                            { props.recommendation.type === 'update' &&
                                <Typography variant='normal' sx={{ fontSize: '12px' }}>Set open to {props.recommendation.open_quantity}, set hold to {props.recommendation.hold_quantity}</Typography>
                            }
                        </Stack>
                    }
                </Stack>
                <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
                    <IconButton disabled={openQuantity === 0} sx={{ border: `1px solid ${theme.palette.border.main}` }} onClick={decreaseOpenQuantity}>
                        <Remove />
                    </IconButton>
                    <Typography variant='heavy' sx={{ fontSize: '16px', color: `${theme.palette.green.main}` }}>Open: {openQuantity}</Typography>
                    <IconButton sx={{ border: `1px solid ${theme.palette.border.main}` }} onClick={increaseOpenQuantity}>
                        <Add />
                    </IconButton>
                </Stack>
                <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
                    <IconButton disabled={holdQuantity === 0} sx={{ border: `1px solid ${theme.palette.border.main}` }} onClick={decreaseHoldQuantity}>
                        <Remove />
                    </IconButton>
                    <Typography variant='heavy' sx={{ fontSize: '16px', color: `${theme.palette.hold.main}` }}>Hold: {holdQuantity}</Typography>
                    <IconButton sx={{ border: `1px solid ${theme.palette.border.main}` }} onClick={increaseHoldQuantity}>
                        <Add />
                    </IconButton>
                </Stack>
                <Stack direction='row' justifyContent='space-between'>
                    <Button 
                        onClick={handleRemoveClick} 
                        startIcon={<DeleteOutlineRounded />}
                        style={{ color: `${theme.palette.red.main}` }}
                    >
                        Remove Item
                    </Button>
                    <Button 
                        onClick={handleSave} 
                        disabled={!valuesHaveChanged}
                        style={{ color: valuesHaveChanged ? `${theme.palette.blue.heavy}` : `${theme.palette.background.disabled}` }}
                    >
                        Save
                    </Button>
                </Stack>
            </Stack>
        </Drawer>
    );
}

export default EditItemDrawer;
