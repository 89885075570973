import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNotification } from '../Hooks/useNotification';

function Notification() {
    const notification = useSelector((state) => state.notification);
    const { clearNotification } = useNotification();

    const handleClose = (_, reason) => {
        if (reason !== 'clickaway') {
            clearNotification();
        }
    }

    return (
        <Snackbar
            open={notification.open}
            autoHideDuration={notification.timeout}
            onClose={handleClose}
        >
            <Alert
                onClose={handleClose}
                severity={notification.type}
            >
                {notification.message}
            </Alert>
        </Snackbar>
    )
}

export default Notification;