import { TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react';
import UserTable from './UserTable';
import { useParams } from 'react-router-dom';
import { useGetHospitalsInOrganizationQuery } from '../../../../api/organizationSlice';
import UserSettings from './UserSettings';
import { useUpdateUserMutation } from '../../../../api/userSlice';

function UserManagement() {
    const [isShowingUserSettings, setIsShowingUserSettings] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const organizationId = useParams().organizationId;
    const { data: hospitals = [] } = useGetHospitalsInOrganizationQuery(organizationId);
    const [submitUpdateUser] = useUpdateUserMutation();

    function openUserSettings(user) {        
        setSelectedUser(user);
        setIsShowingUserSettings(true);
    }

    async function updateUser() {
        await submitUpdateUser({ 
            user: selectedUser,
            firstName: selectedUser.first_name,
            lastName: selectedUser.last_name,
            email: selectedUser.email,
            emailUnsubscribed: selectedUser.email_unsubscribed,
            password: selectedUser.password,
            roles: selectedUser.roles,
            hospitals: selectedUser.hospitals,
        });
    }

    return (
        <Stack p={2} spacing={2} height='100%'>
            <Stack direction='row' spacing={2} alignItems='center'>
                <Typography variant='heavy' fontSize='20px'>User Management</Typography>
                <TextField id='search-users' label='Search Users' size='small' variant='outlined' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </Stack>
            <Stack spacing={4} height='100%'>
                <UserTable organizationId={organizationId} openUserSettings={openUserSettings} searchTerm={searchTerm} selectedUser={selectedUser} />
                { isShowingUserSettings && 
                    <UserSettings 
                        user={selectedUser} 
                        setSelectedUser={setSelectedUser} 
                        closeUserSettings={() => setIsShowingUserSettings(false)} 
                        orgHospitals={hospitals} 
                        updateUser={updateUser}
                    />
                }
            </Stack>
        </Stack>
    )
}

export default UserManagement;
