import { Stack } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCardQuery } from '../../api/cardSlice';
import CardTabPicker from '../CardTabPicker';
import CardContent from './CardContent';
import CardDetails from './CardDetails';

function CardTemplate(){
    let params = useParams();
    const theme = useTheme();
    const {data:cardTemplateData, isSuccess} = useGetCardQuery(params.cardID);
    const [selectedIndex, setSelectedIndex] = useState(0);

    return(
        <Stack spacing={2} alignItems='center' display='flex' height='100%' overflow='auto'>
            { isSuccess && 
                <Box px={{ xs: 3, md: 5 }} sx={{ width: { xs: '100%', lg: '85%' }, justifySelf: 'center'  }}>
                    <CardDetails cardTemplateData={cardTemplateData} />
                </Box>
            }
            { isSuccess &&
                <Stack px={{ xs: 2, md: 4 }} sx={{ width: { xs: '100%', lg: '85%' }, justifySelf: 'center' }}>
                    <Box sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: `${theme.palette.background.main}` }}>
                        <CardTabPicker selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
                    </Box>
                    <Box pb={10} px={1} sx={{ width: '100%' }}>
                        <CardContent card={cardTemplateData} selectedIndex={selectedIndex} />
                    </Box>
                </Stack>
            }
        </Stack>
    );
}

export default CardTemplate;