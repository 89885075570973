import { CloseRounded } from '@mui/icons-material';
import { IconButton, Slide, Stack, Typography, Divider } from '@mui/material';
import { useTheme } from '@mui/system';
import React, { useEffect } from 'react';
import { useMemo } from 'react';
import ItemEntry from './ItemEntry';
import Optimization from './Optimizations/Optimization';
import LogOnEnter from '../../CustomComponents/LogOnEnter';

function CategoryView(props) {
    const theme = useTheme();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.open]);

    const highlightedRec = useMemo(() => {
        if (props.highlightedItem) {
            return props.draft.recommendations[props.category].find((rec) => rec.id === props.highlightedItem?.id);
        }
        return null;
    }, [props.draft, props.category, props.highlightedItem]);

    const categoryRecommendations = useMemo(() => {
        return props.draft.recommendations[props.category] ?? [];
    }, [props.draft, props.category]);

    const categoryItems = useMemo(() => {
        return props.draft.items[props.category] ?? [];
    }, [props.draft, props.category]);

    const visibleRecommendations = useMemo(() => {
        return categoryRecommendations.filter((rec) => rec.id !== props.highlightedItem?.id && rec.type !== 'swap' && rec.type !== 'compliance');
    }, [categoryRecommendations, props.highlightedItem]);

    const visibleItems = useMemo(() => {
        return categoryItems.filter((item) => item.id !== props.highlightedItem?.item.id && !visibleRecommendations.find((rec) => rec.item.id === item.id));
    }, [categoryItems, props.highlightedItem]);

    const shouldHideDetailsButton = useMemo(() => {
        return props.selectedTab === 0;
    });

    return (
        <Slide direction='up' in={props.open} mountOnEnter unmountOnExit>
            <Stack spacing={2} py={2} px={1.5} sx={{ backgroundColor: `${theme.palette.background.categoryDrawer}`, minHeight: '100vh' }}>
                <LogOnEnter action='viewed_category' data={{ category: props.category, card: props.draft.card }}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography variant='normal' sx={{ fontSize: '14px' }}>{props.category}</Typography>
                        <IconButton onClick={props.closeCategoryView}>
                            <CloseRounded />
                        </IconButton>                
                    </Stack>
                    { props.highlightedItem && 
                        <Stack spacing={2} py={2}>
                            <Optimization
                                recommendation={highlightedRec}
                                draft={props.draft}
                                item={props.draft.items[highlightedRec.item.category].filter((item) => item.id === highlightedRec.item.id)[0]}
                                openItemView={props.openItemView}
                                closeItemView={props.closeItemView}
                                hideContext
                                hideDetailsButton={shouldHideDetailsButton}
                            />
                            <Divider>
                                <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>Other '{props.category}' items on this card</Typography>
                            </Divider>
                        </Stack>
                    }
                    <Stack spacing={1.5}>
                        { visibleRecommendations.map((rec) => (
                            <Optimization
                                key={rec.id}
                                recommendation={rec}
                                draft={props.draft}
                                item={
                                    props.draft.items[rec.item.category] !== undefined ?
                                        props.draft.items[rec.item.category].filter((item) => item.id === rec.item.id)[0] :
                                        null
                                }
                                openItemView={props.openItemView}
                                closeItemView={props.closeItemView}
                                setCurrentCategory={props.setCurrentCategory}
                                hideContext
                                hideDetailsButton={shouldHideDetailsButton}
                            />
                        ))}
                        { visibleItems.map((item) => (
                            <ItemEntry 
                                key={item.id}
                                item={item} 
                                draft={props.draft} 
                                openItemView={props.openItemView}
                                closeItemView={props.closeItemView}
                                setCurrentCategory={props.setCurrentCategory}
                                setIsShowingCategoryView={props.setOpen}
                            />
                        ))}
                    </Stack>
                </LogOnEnter>
            </Stack>
        </Slide>
    );
    
}

export default CategoryView;
