import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import CampaignRow from './CampaignRow';
import OverviewFooter from './OverviewFooter';
import OverviewHeader from './OverviewHeader';
import { useState, useMemo } from 'react';
import { useLogUserActionMutation } from '../../../api/apiSlice';


function CampaignOverviewTable(props) {
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('start_date');
    const [logUserAction] = useLogUserActionMutation();

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = useMemo(() => {
        return [...props.campaigns].filter((campaign) => {
            return Object.entries(props.appliedFilters).every(([filterName, filterInfo]) => {
                if (!filterInfo.selected) {
                    return true;
                }
                if (filterName === 'Specialties') {
                    return filterInfo.selectedOptions.some((option) => campaign.specialties.includes(option));
                }
                return filterInfo.selectedOptions.includes(campaign[filterName.toLowerCase()]);
            });
        }).sort(getComparator(order, orderBy));
    }, [order, orderBy, props.campaigns, props.appliedFilters]);

    function handleSelectCampaign(campaign) {
        props.setSelectedCampaign(campaign);
        logUserAction({ 
            action: 'viewed_campaign_details',
            data: {
                campaign: campaign 
            }
        });
    }

    return (
        <TableContainer sx={{ borderRadius: '8px', border: '2px solid #DDD', backgroundColor: 'white' }}>
            <Table stickyHeader>
                <OverviewHeader
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    { visibleRows.map((campaign) => (
                        <CampaignRow key={campaign.id} campaign={campaign} isSelected={props.selectedCampaign?.id === campaign.id} handleSelect={() => handleSelectCampaign(campaign)}/>
                    ))}
                </TableBody>
                <OverviewFooter visibleCampaigns={visibleRows} totalCampaigns={props.campaigns.length} />
            </Table>
        </TableContainer>
    );
}

export default CampaignOverviewTable;
