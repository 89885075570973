import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import React from 'react';


function EfficiencyExplainer(props) {
    return (
        <Dialog onClose={props.handleClose} open={props.open}>
            <DialogTitle>
                I accepted all my recommendations. Why is my efficiency not 100%?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Your efficiency calculation includes all recommendations on this screen as well as any proposed item swaps or compliance opportunities that contain items on this card. Your missing efficiency percentage is likely due to unaccepted swaps or compliance opportunities. Return to the main dashboard page to review these additional recommendations.
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default EfficiencyExplainer;
