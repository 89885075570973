import React from 'react';
import { Tabs, Tab, Typography } from '@mui/material';

function CampaignSummaryTabs(props) {

    function handleTabChange(e, value) {
        props.setSelectedTab(value);
    }

    return (
        <Tabs variant='fullWidth' value={props.selectedTab} onChange={(e, value) => handleTabChange(e, value)} centered>
            <Tab sx={{textTransform :'none', transformStyle:'none', paddingX: '10px'}} label={<Typography variant='normal'> Current Opportunities </Typography>} value={0} />
            <Tab sx={{textTransform :'none', transformStyle:'none', paddingX: '10px'}} label={<Typography variant='normal'> Previous Opportunities </Typography>} value={1} />
        </Tabs>
    );
}

export default CampaignSummaryTabs;
