import { Stack } from '@mui/material';
import React from 'react';
import StatisticTile from './StatisticTile';

function LiveCampaignStats(props) {
    const maximumPossibleSavings = props.drafts?.reduce((total, draft) => {
        return total + draft.cost_reduction_opportunity + draft.swap_opportunity + draft.compliance_opportunity;
    }, 0);

    const capturedSavings = props.drafts?.reduce((total, draft) => {
        return total + draft.optimization_savings + draft.swap_savings + draft.compliance_savings;
    }, 0);

    return (
        <Stack direction='row' spacing={2} alignItems='center'>
            <StatisticTile
                title='Maximum Capturable Savings'
                value={maximumPossibleSavings / 100}
                isLoading={props.isLoadingDrafts}
                formatAsDollar
            />
            <StatisticTile
                title='Captured Savings'
                value={capturedSavings / 100}
                isLoading={props.isLoadingDrafts}
                formatAsDollar
                {...capturedSavings > 0 ? { includeTrendUp: true } : { includeTrendDown: true }}
            />
            <StatisticTile
                title='Number of Physicians'
                value={Object.keys(props.physicians).length}
                isLoading={props.isLoadingDrafts}
            />
            <StatisticTile
                title='Number of Cards'
                value={props.drafts.length}
                isLoading={props.isLoadingDrafts}
            />
            <StatisticTile
                title='Number of Cards Reviewed'
                value={props.drafts.filter(draft => draft.status === 'submitted').length}
                isLoading={props.isLoadingDrafts}
                {...props.drafts.filter(draft => draft.status === 'submitted').length > 0 ? { includeTrendUp: true } : {}}
            />
        </Stack>
    );
}

export default LiveCampaignStats;
