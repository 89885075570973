import {useState, useEffect} from 'react';

export default function usePartnerConfigs(user) {
    const [configs, setConfigs] = useState([]);

    useEffect(() => {
        if (user) {
            setConfigs(user.hospital.configurations);
        }
    }, [user])

    const isConfigActive = configName => {
        return configs?.find(config => config.name === configName) !== undefined;
    }

    const isCardComparisonActive = () => {
        return configs?.find(config => config.name === 'Card Comparison') !== undefined;
    }

    return {
        isConfigActive,
        isCardComparisonActive
    }
}
