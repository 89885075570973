import { LocalHospital } from '@mui/icons-material';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function HospitalsCollapse(props) {
    const navigate = useNavigate();

    const sortedHospitals = props.organization.hospitals.slice().sort((a, b) => a.name.localeCompare(b.name));

    return (
        <List component="div" disablePadding>
            {sortedHospitals.map((hospital) => (
                <ListItemButton sx={{ pl: 4 }} 
                    key={'hos' + hospital.id} 
                    selected={props.selectedIndex === 'hos' + hospital.id} 
                    onClick={() => navigate('/superSettings/hospitals/' + hospital.id)}
                >
                    <ListItemIcon>
                        <LocalHospital />
                    </ListItemIcon>
                    <ListItemText primary={hospital.name} />
                </ListItemButton>
            ))}
        </List>
    );
}

export default HospitalsCollapse;