import { Divider, Skeleton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import format from 'date-fns/format/index.js';
import parseISO from 'date-fns/parseISO/index.js';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCampaignQuery, useGetDraftsforCampaignQuery } from '../../api/campaignSlice';
import CampaignPreview from './CampaignPreview/CampaignPreview';
import CampaignSettings from './CampaignSettings/CampaignSettings';
import CampaignStatusTracker from './CampaignStatusTracker';
import DraftTable from './DraftTable';
import PhysicianManagement from './PhysicianManagement/PhysicianManagement';
import DraftReviewTable from './StaffReviewManagement/DraftReviewTable';
import LiveCampaignStats from './StatisticTiles/LiveCampaignStats';
import EditCampaignName from './EditCampaignName';

function EditCampaign() {
    const theme = useTheme();
    const {campaignId} = useParams();
    const {currentData: campaignInfo,  isLoading : isLoadingCampaign} = useGetCampaignQuery({ campaignId: campaignId });
    const { data: campaignDrafts = [], isLoading: isLoadingDrafts} = useGetDraftsforCampaignQuery(campaignId);
    
    const [isShowingSettings, setIsShowingSettings] = useState(false);
    const [settings, setSettings] = useState(campaignInfo?.settings);
    const [isManagingPhyicians, setIsManagingPhysicians] = useState(false);
    const [isManagingStaffReview, setIsManagingStaffReview] = useState(false);
    const status = campaignInfo?.status;

    useEffect(() => {
        if (campaignInfo) {
            setSettings(campaignInfo.settings);
        }
    }, [campaignInfo]);

    const startDate = useMemo(() => {
        return(campaignInfo?.start_date ? format(parseISO(campaignInfo.start_date), 'MM/dd/yyyy') : 'None');
    }, [campaignInfo?.start_date]);

    const endDate = useMemo(() => {
        return(campaignInfo?.end_date ? format(parseISO(campaignInfo.end_date), 'MM/dd/yyyy') : 'None');
    }, [campaignInfo?.end_date]);

    const physicians = useMemo(() => {
        const physicians = {};
        campaignDrafts.forEach((draft) => {
            if (!physicians[draft.card.doctor.id]) {
                physicians[draft.card.doctor.id] = draft.card.doctor;
            }
        });
        return physicians;
    }, [campaignDrafts]);
    
    const tableData = useMemo(() => {
        const draftsGroupedByPhysician = {};
        campaignDrafts.forEach((draft) => {
            if (!draftsGroupedByPhysician[draft.card.doctor.id]) {
                draftsGroupedByPhysician[draft.card.doctor.id] = [];
            }
            draftsGroupedByPhysician[draft.card.doctor.id].push(draft);
        });
        return draftsGroupedByPhysician;
    }, [campaignDrafts]);

    const shouldShowPreview = useMemo(() => {
        return (status === 'open' && !isShowingSettings);
    }, [status, isShowingSettings]);

    const shouldShowSettings = useMemo(() => {
        return ((status === 'open' || status === 'staff_review' || status === 'complete') && settings && isShowingSettings);
    }, [status, settings, isShowingSettings]);

    const shouldShowLiveStats = useMemo(() => {
        return ((status === 'active' || status === 'staff_review' || status === 'complete') && !isManagingPhyicians && !isShowingSettings && !isManagingStaffReview);
    }, [status, isManagingPhyicians, isShowingSettings, isManagingStaffReview]);

    const shouldShowPhysicianManagement = useMemo(() => {
        return (status === 'active' && isManagingPhyicians && !isManagingStaffReview);
    }, [status, isManagingPhyicians, isManagingStaffReview]);

    const shouldShowStaffReviewManagement = useMemo(() => {
        return ((status === 'active' || status === 'staff_review' || status === 'complete') && isManagingStaffReview && !isManagingPhyicians);
    }, [status, isManagingStaffReview, isManagingPhyicians]);

    return (
        <Stack spacing={2} px={3} pb={3} height='100%' overflow='auto'>
            { isLoadingCampaign ? <Skeleton variant='rectangular' width={800} height={100} /> :
                <Stack spacing={1}>
                    <Stack>
                        { campaignInfo.name && 
                            <EditCampaignName campaignInfo={campaignInfo} campaignId={campaignId} />
                        }
                        <Typography variant='normal' sx={{ fontSize: '16px', color: `${theme.palette.subtext.main}` }}>Card Optimization Campaign for {campaignInfo.hospital.name}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={1} divider={<Divider />}>
                        <Typography variant='normal' sx={{ fontSize: '14px' }}>Campaign ID: {campaignInfo.id}</Typography>
                        <Typography variant='normal' sx={{ fontSize: '14px' }}>Start Date: {startDate}</Typography>
                        <Typography variant='normal' sx={{ fontSize: '14px' }}>End Date: {endDate}</Typography>
                    </Stack>
                    <CampaignStatusTracker 
                        campaign={campaignInfo} 
                        hasPreview={campaignDrafts.length > 0}
                        settings={settings} 
                        isShowingSettings={isShowingSettings} 
                        isManagingPhysicians={isManagingPhyicians}
                        isManagingStaffReview={isManagingStaffReview}
                        setIsShowingSettings={setIsShowingSettings} 
                        setIsManagingPhysicians={setIsManagingPhysicians}
                        setIsManagingStaffReview={setIsManagingStaffReview}
                    />
                </Stack>
            }
            { shouldShowLiveStats &&
                <>
                    <LiveCampaignStats physicians={physicians} drafts={campaignDrafts} isLoadingDrafts={isLoadingDrafts} />
                    <DraftTable physicians={physicians} drafts={tableData} campaign={campaignInfo} isLoadingDrafts={isLoadingDrafts} />
                </>
            }
            { shouldShowPhysicianManagement &&
                <PhysicianManagement physicians={physicians} campaignId={campaignInfo.id} />
            }
            { shouldShowStaffReviewManagement && 
                <DraftReviewTable physicians={physicians} drafts={tableData} campaignId={campaignInfo.id} />
            }
            { shouldShowPreview &&
                <CampaignPreview campaign={campaignInfo} drafts={campaignDrafts} tableData={tableData} physicians={physicians} isLoadingDrafts={isLoadingDrafts} />
            }
            { shouldShowSettings && 
                <CampaignSettings campaign={campaignInfo} settings={settings} setSettings={setSettings} />
            }
        </Stack>
    )
}

export default EditCampaign;






