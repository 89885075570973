import { Add } from '@mui/icons-material';
import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetAuxiliaryItemCategoriesQuery, useGetCommentCategoriesQuery } from '../../../api/categorySlice';
import AddCategoryDialog from './AddCategoryDialog';
import Category from './Category';

function ManageCategories() {
    const params = useParams();

    const [isShowingAddDialog, setIsShowingAddDialog] = useState(false);

    const { data: commentCategories = [] } = useGetCommentCategoriesQuery(params.hospitalId);
    const { data: auxItemCategories = [] } = useGetAuxiliaryItemCategoriesQuery(params.hospitalId);

    const sortedCommentCats = useMemo(() => {
        return commentCategories.slice().sort(function (a, b) {
            return a.order - b.order;
        });
    }, [commentCategories]);

    const sortedAuxItemCats = useMemo(() => {
        return auxItemCategories.slice().sort(function (a, b) {
            return a.order - b.order;
        })
    }, [auxItemCategories]);

    function handleAddCategory() {
        setIsShowingAddDialog(true);
    }

    return (
        <Grid container px={3} pb={10} sx={{ overflow: 'auto', height: '100vh' }}>
            <Grid item xs={12} md={6} p={2}>
                <Stack spacing={2}>
                    <Stack direction='row' justifyContent='end' alignItems='center' spacing={10}>
                        <Typography variant='heavy' sx={{ fontSize: '22px', textAlign: 'center' }}>Comment Categories</Typography>
                        <Button variant='contained' onClick={handleAddCategory} startIcon={<Add />} style={{ alignSelf: 'end' }}>
                            Add Category
                        </Button>
                        <AddCategoryDialog open={isShowingAddDialog} setOpen={setIsShowingAddDialog} hospitalId={params.hospitalId} />
                    </Stack>
                    <Stack px={2} direction='row' justifyContent='space-between'>
                        <Typography variant='heavy' sx={{ fontSize: '16px', textAlign: 'center' }}>Category Name</Typography>
                        <Stack spacing={2} direction='row'>
                            <Typography variant='heavy' sx={{ fontSize: '16px', textAlign: 'center' }}>Order</Typography>
                            <Typography variant='heavy' sx={{ fontSize: '16px', textAlign: 'center' }}>Edit</Typography>
                        </Stack>
                    </Stack>
                    { sortedCommentCats.map((category) => (
                        <Category key={category.id} category={category} />
                    ))}
                </Stack>
            </Grid>
            <Divider orientation='vertical' flexItem sx={{ marginRight: -2, alignSelf: 'center' }}/>
            <Grid item xs={12} md={6} p={2}>
                <Stack spacing={2}>
                    <Stack direction='row' justifyContent='end' alignItems='center' spacing={8}>
                        <Typography variant='heavy' sx={{ fontSize: '22px', textAlign: 'center' }}>Auxiliary Item Categories</Typography>
                        <Button variant='contained' onClick={handleAddCategory} startIcon={<Add />} style={{ alignSelf: 'end' }}>
                            Add Category
                        </Button>
                    </Stack>
                    <Stack px={2} direction='row' justifyContent='space-between'>
                        <Typography variant='heavy' sx={{ fontSize: '16px', textAlign: 'center' }}>Category Name</Typography>
                        <Stack spacing={2} direction='row'>
                            <Typography variant='heavy' sx={{ fontSize: '16px', textAlign: 'center' }}>Order</Typography>
                            <Typography variant='heavy' sx={{ fontSize: '16px', textAlign: 'center' }}>Edit</Typography>
                        </Stack>
                    </Stack>
                    { sortedAuxItemCats.map((category) => (
                        <Category key={category.id} category={category} />
                    ))}
                </Stack>
            </Grid>
        </Grid>
    );
}

export default ManageCategories;
