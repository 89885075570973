import { ContentCopy } from '@mui/icons-material';
import { Button, ClickAwayListener, IconButton, MenuItem, Paper, Select, Stack, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGeneratePasswordResetLinkMutation, useGetUsersQuery } from '../../../api/userSlice';

function PasswordReset() {
    const params = useParams();
    const [selectedUser, setSelectedUser] = useState();
    const [generatedLink, setGeneratedLink] = useState();
    const [linkCopied, setLinkCopied] = useState(false);

    const { 
        data: fetchedUsers = [],
    } = useGetUsersQuery({ hospitalId: params.hospitalId });

    const [generateLink] = useGeneratePasswordResetLinkMutation();

    async function handleGenerateLink() {
        await generateLink({
            user: selectedUser
        }).unwrap().then((payload) => {
            setGeneratedLink(payload.reset_link);
        });
    }

    function handleCopyLink() {
        navigator.clipboard.writeText(generatedLink);
        setLinkCopied(true);
    }

    return (
        <Paper elevation={2} sx={{ margin: '32px', width: '50%' }}>
            <Stack spacing={2} p={2}>
                <Typography variant='bold' sx={{ fontSize: '16px' }}>Create Password Reset for User</Typography>
                <Typography variant='normal' sx={{ fontSize: '14px' }}>Choose a User</Typography>
                <Select 
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.target.value)}
                    sx={{ width: '100%' }}
                >
                    { fetchedUsers.map((user) => (
                        <MenuItem key={user.id} value={user}>{user.name}</MenuItem>
                    ))}
                </Select>
                <Button variant='text' onClick={handleGenerateLink} disabled={!selectedUser} sx={{ alignSelf: 'end' }}>
                    Generate Link
                </Button>
                { generatedLink &&
                    <Stack spacing={2} direction='row' alignItems='center'>
                        <ClickAwayListener onClickAway={() => setLinkCopied(false)}>
                            <Tooltip open={linkCopied} title='Copied!' placement='top'>
                                <IconButton onClick={handleCopyLink}>
                                    <ContentCopy />
                                </IconButton>
                            </Tooltip>
                        </ClickAwayListener>
                        <Typography noWrap variant='normal' sx={{ fontSize: '14px' }}>{generatedLink}</Typography>
                    </Stack>
                }
            </Stack>
        </Paper>
    );
}

export default PasswordReset;
