import { apiSlice } from './apiSlice';
import { NotificationActions } from './notificationSlice';

export const itemSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        updateCaseItem: builder.mutation({
            query: ({ caseId, item, openQuantity, holdQuantity }) => ({
                url: `/cases/${caseId}/items/${item.id}`,
                method: 'PATCH',
                body: {
                    open_quantity: openQuantity,
                    hold_quantity: holdQuantity
                } 
            }),
            async onQueryStarted({ caseId, item, openQuantity, holdQuantity }, { dispatch, queryFulfilled }) {
                const result = dispatch(
                    apiSlice.util.updateQueryData('getCase', caseId.toString(), draft => {
                        let existingItem = draft.pick_list[item.category].find(itemData => itemData.id === item.id)
                        if (existingItem) {
                            existingItem.open_quantity = openQuantity
                            existingItem.hold_quantity = holdQuantity
                        }
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    result.undo()
                }
            }
        }),
        deleteCaseItem: builder.mutation({
            query: ({ caseId, item }) => ({
                url: `/cases/${caseId}/items/${item.id}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ caseId, item }, { dispatch, queryFulfilled }) {
                const result = dispatch(
                    apiSlice.util.updateQueryData('getCase', caseId.toString(), draft => {
                        let existingItem = draft.pick_list[item.category].find(itemData => itemData.id === item.id)
                        if (existingItem) {
                            draft.pick_list[item.category].splice(draft.pick_list[item.category].indexOf(existingItem), 1)
                            if (draft.pick_list[item.category].length === 0) {
                                delete draft.pick_list[item.category];
                            }
                        }
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    result.undo()
                }
            }
        }),
        updateCardItem: builder.mutation({
            query: ({ cardId, item, openQuantity, holdQuantity }) => ({
                url: `/cards/${cardId}/items/${item.id}`,
                method: 'PATCH',
                body: {
                    open_quantity: openQuantity,
                    hold_quantity: holdQuantity
                }
            }),
            async onQueryStarted({ cardId, item, openQuantity, holdQuantity }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedCardItem } = await queryFulfilled;
                    dispatch(
                        apiSlice.util.updateQueryData('getCard', cardId.toString(), card => {
                            let existingItem = card.pick_list[item.category].find(itemData => itemData.id === item.id);
                            if (existingItem) {
                                existingItem.open_quantity = openQuantity;
                                existingItem.hold_quantity = holdQuantity;
                            }
                        })
                    )
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'Item successfully updated on card.'
                    }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Item could not be updated at this time. Please try again later.'
                    }));
                }
            }
        }),
        deleteCardItem: builder.mutation({
            query: ({ cardId, item }) => ({
                url: `/cards/${cardId}/items/${item.id}`,
                method: 'DELETE'
            }),
            async onQueryStarted({ cardId, item }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedCard } = await queryFulfilled; 
                    dispatch(
                        apiSlice.util.updateQueryData('getCard', cardId.toString(), card => {
                            Object.assign(card, updatedCard);
                        })
                    );
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'Item successfully removed from card.'
                    }));
                } catch {
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Item could not be removed from card at this time. Please try again later.'
                    }));
                }
            }
        }),
    })    
});

export const { 
    useUpdateCaseItemMutation,
    useDeleteCaseItemMutation,
    useUpdateCardItemMutation,
    useDeleteCardItemMutation,
} = itemSlice;
