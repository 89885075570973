import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import React from 'react';
import { useGetItemCardsQuery } from '../../api/inventorySlice';

function DeactivateItemDialog(props) {
    const { data: cards = [] } = useGetItemCardsQuery(props.item.id, { skip: !props.open });

    function handleClose() {
        props.setOpen(false);
    }

    const handleDeactivate = async() => {
        await props.deactivateItem();
        handleClose();
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>Deactivate this item?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {'Deactivated items cannot be added to cards and are designated as "Inactive" when they appear in the inventory or on cards.'}
                </DialogContentText>
            </DialogContent>
            { cards.length > 0 &&
                <>
                    <Divider />
                    <DialogContent>
                        <DialogContentText>
                            This item is currently present on the following cards:
                        </DialogContentText>
                        <ul>
                            { cards.slice(0, 3).map((card) => (
                                <li key={card.id}>{card.name} - {card.owner.name}</li>
                            ))}
                        </ul>
                        { cards.length > 3 && 
                            <DialogContentText>
                                ...and {cards.length - 3} more.
                            </DialogContentText>
                        }
                    </DialogContent>
                </>
            }
            <DialogActions>
                <Button variant='text' onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant='text' color='error' onClick={handleDeactivate}>
                    Deactivate Item
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeactivateItemDialog;