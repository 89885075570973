import React from 'react';
import { Stack, Typography } from '@mui/material';

function NewItem(props) {
    const item = props.recommendation?.item ?? props.item;

    if (props.recommendation?.status === 'accepted' && props.recommendation?.type === 'addition') {
        return (
            <Stack>    
                <Typography variant='heavy' sx={{ fontSize: '16px' }}>{item.system_name ?? item.display_name}</Typography>
                <Typography variant='normal' sx={{ fontSize: '12px' }}>{item.vendor.name} - {item.part_number}</Typography>
            </Stack>
        )
    }

    if (props.recommendation?.status === 'accepted' && (props.recommendation?.type === 'swap' || props.recommendation?.type === 'compliance')) {
        return (
            <Stack>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}>{props.recommendation.recommended_item.system_name ?? props.recommendation.recommended_item.display_name}</Typography>
                <Typography variant='normal' sx={{ fontSize: '12px' }}>{props.recommendation.recommended_item.vendor.name} - {props.recommendation.recommended_item.part_number}</Typography>
            </Stack>
        )
    }

    return (
        <></>
    )
}

export default NewItem;
