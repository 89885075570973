import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import { useNavigate } from 'react-router-dom';


function ConversationRow(props) {
    const navigate = useNavigate();

    const goToPhysicianText = (physicianData) => {
        navigate(`/physicians/${physicianData.id}/physicianTexting`);
    }

    return (
        <TableRow onClick={() => goToPhysicianText(props.conversation.physician)} hover sx={{ cursor: 'pointer' }}>
            <TableCell>{'Dr. ' + props.conversation.physician.last_name + ', ' + props.conversation.physician.first_name}</TableCell>
            <TableCell>{ props.conversation.physician.specialty }</TableCell>
        </TableRow>
    )
}

export default ConversationRow;
