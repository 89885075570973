import React, { useState } from 'react';
import { Select, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function ReviewActionDropdown(props) {
    const theme = useTheme();
    const [value, setValue] = useState(props.review.status);
    const defaultBackgroundColor = props.index % 2 === 1 ? 'rgb(0, 0, 0, 0)' : 'white';
    const colors = {
        open: defaultBackgroundColor,
        accepted: '#2ad56880',
        rejected: '#FF000B80',
        staff_implemented: '#007AFF80',
    }

    function handleChange(event) {
        setValue(event.target.value);
        props.handleUpdate(props.review, props.recommendation, event.target.value);
    }

    return (
        <Select
            value={value}
            onChange={(e) => handleChange(e)}
            sx={{ width: '100%', height: '100%', 
                borderRadius: '0px',
                '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    borderRadius: '0px'
                }, 
                backgroundColor: colors[value],
            }}
            disabled={props.disabled}
        >
            <MenuItem value='open'>
                <Typography sx={{ color: `${theme.palette.blue.main}` }}>
                    <strong>Action Required</strong>
                </Typography>
            </MenuItem>
            <MenuItem value='accepted'><strong>Approved</strong></MenuItem>
            <MenuItem value='rejected'><strong>Denied</strong></MenuItem>
            <MenuItem value='staff_implemented'><strong>Implemented by Me</strong></MenuItem>
        </Select>
    )
}

export default ReviewActionDropdown;
