import { apiSlice } from './apiSlice';
import { NotificationActions } from './notificationSlice';

export const textingSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getConversation: builder.query({
            query: (physicianId) => ({
                url: `/cardOwners/${physicianId}/conversation`,
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: 'Conversation', id: arg }]
        }),
        sendMessage: builder.mutation({
            query: ({ physicianId, requestBody }) => ({
                url: `/cardOwners/${physicianId}/messages`,
                method: 'POST',
                body: requestBody
            }),
            async onQueryStarted({ physicianId, requestBody }, { dispatch, queryFulfilled }) {
                try {
                    const { data: sentMessage } = await queryFulfilled;
                    dispatch(
                        textingSlice.util.updateQueryData('getConversation', physicianId, draft => {
                            draft.messages.push(sentMessage);
                            draft.status = 'inactive';
                        })
                    );
                    dispatch(
                        textingSlice.util.updateQueryData('getActiveConversationCount', {'skip': false}, draft => {
                            if (draft.count > 0) {
                                draft.count -= 1;
                            }
                        })
                    );
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Message failed to send. Please try again later.' }));
                }
            }
        }),
        updateConversation: builder.mutation({
            query: ({ conversation, requestBody }) => ({
                url: `/conversations/${conversation.id}`,
                method: 'PATCH',
                body: requestBody
            }),
            async onQueryStarted({ conversation, requestBody }, { dispatch, queryFulfilled }) {
                const result = dispatch( 
                    textingSlice.util.updateQueryData('getConversation', conversation.physician.id, draft => {
                        draft.status = requestBody.status;
                    })
                )
                try {
                    await queryFulfilled;
                    dispatch(
                        textingSlice.util.updateQueryData('getActiveConversationCount', {'skip': false}, draft => {
                            if (draft.count > 0) {
                                draft.count -= 1;
                            }
                        })
                    )
                } catch {
                    dispatch(NotificationActions.addErrorNotification({ message: 'Conversation update failed. Please try again later.' }));
                    result.undo();
                }
            }
        }),
        getActiveConversationCount: builder.query({
            query: () => ({
                url: '/conversations/activeCount',
                method: 'GET'
            }),
            providesTag: 'ConversationCount'
        }),
        getActiveConversations: builder.query({
            query: () => ({
                url: '/conversations',
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: 'Conversation', id: arg }]
        }),
    })
});

export const {
    useGetConversationQuery,
    useSendMessageMutation,
    useUpdateConversationMutation,
    useGetActiveConversationCountQuery,
    useGetActiveConversationsQuery,
} = textingSlice;
