import React, { useMemo } from 'react';
import { Box } from '@mui/system';
import { Stack, TextField, InputLabel, Link, Grid, Typography, Button } from '@mui/material';
import { useState } from 'react';
import smallLogo from '../../../assets/single_letter_logo.svg'
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import TermsOfServiceDialog from './TermsOfServiceDialog';

function LandingPage() {
    const [searchParams, ] = useSearchParams();
    const [email, setEmail] = useState(searchParams.get('email') || '');
    const [firstName, setFirstName] = useState(searchParams.get('firstName') || '');
    const [lastName, setLastName] = useState(searchParams.get('lastName') || '');
    const [phoneNumber, setPhoneNumber] = useState(searchParams.get('phoneNumber') || '');
    const [isError, setIsError] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isShowingTerms, setIsShowingTerms] = useState(false);

    const canSubmit = useMemo(() => {
        return (email || phoneNumber) && firstName && lastName;
    }, [email, phoneNumber, firstName, lastName]);

    function handleRegister(event) {
        event.preventDefault();
        setIsError(false);
        setIsSubmitting(true);
        axios.post('/api/demo/register', {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone_number: phoneNumber,
            hospital_id: searchParams.get('hospitalId')
        }).then((response) => {
            setIsSubmitted(true);
            setIsSubmitting(false);
            setTimeout(() => {
                window.location.replace(response.data.link);
            }, 2500);
        }).catch(() => {
            setIsError(true);
            setIsSubmitting(false);
        });
    }

    return (
        <Grid container pb={10} sx={{ height: '100vh', backgroundColor: '#F7F9FC' }}>
            <Grid item xs={1} md={3} />
            <Grid item xs={10} md={6}>
                <Box mt={20} p={4} sx={{ boxShadow: 4, borderRadius: '13px', overflow: 'hidden' }}>
                    <Stack spacing={2}>
                        <Stack spacing={3} direction='row' alignItems='center'>
                            <Box mb={2}>
                                <img src={smallLogo} alt="DOCSI Mini Logo" width='40' height='30' />
                            </Box>
                            <Stack spacing={1}>
                                <Box sx={{font: 'normal normal normal 24px/22px Roboto' }}>Welcome to DOCSI</Box>
                                { !isSubmitted && 
                                    <Box sx={{font: 'normal normal normal 16px/24px Roboto', color: '#00000099'}} mb={4}>{'Enter your credentials and we\'ll send you a link to your personalized demo dashboard'}</Box>
                                }
                            </Stack>
                        </Stack>
                        { isSubmitted ? 
                            <Typography variant='normal' sx={{ fontSize: '20px' }}>Thank you for demoing! We will redirect you do your demo shortly and a copy of your link will be sent to you.</Typography> :
                            <Stack spacing={3}>
                                <Box>
                                    { isError && 
                                        <InputLabel error = {true}  htmlFor='email' sx={{ pb: 1 }}>Something went wrong. Please try again.</InputLabel>
                                    }
                                    <Stack direction-= 'row' spacing={2}>
                                        <TextField fullWidth variant='outlined' label='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                        <TextField fullWidth variant='outlined' label='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                    </Stack>
                                </Box>
                                <TextField fullWidth id='email' variant='outlined' label='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                <TextField fullWidth id='phone' variant='outlined' label='Phone Number' value={phoneNumber} inputProps={{ inputMode: 'numeric' }} onChange={(e) => setPhoneNumber(e.target.value)} />
                                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                    <LoadingButton loading={isSubmitting} variant='contained' disabled={!canSubmit} onClick={handleRegister} sx = {{backgroundColor : '#2C2CFF', '&:hover': {bgcolor:'#2C2CFF'}, 'color': 'white'}}>Get My Link</LoadingButton>
                                </Box>
                            </Stack>
                        }                        
                        <Box sx={{font: 'normal normal normal 16px/24px Roboto'}}>Want To Learn More? <Link color = '#2C2CFF' target="_blank"  href = "https://calendly.com/adeleeuw/docsi-dashboard-overview">Schedule A Call</Link></Box>
                        <Stack sx={{font: 'normal normal normal 16px/24px Roboto', alignSelf: 'start'}}>
                            <Button variant='text' onClick={() => setIsShowingTerms(true)}>View Terms of Service</Button>
                            { isShowingTerms && 
                                <TermsOfServiceDialog open={isShowingTerms} handleClose={() => setIsShowingTerms(false)} />
                            }
                        </Stack>
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    );
}

export default LandingPage;