import { Dialog, DialogContent, DialogTitle, Stack, TextField, Typography, ToggleButton, ToggleButtonGroup, DialogActions, Button, InputAdornment } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { UserContext } from '../../Hooks/useUser';
import { useCreateItemMutation, useCreateVendorMutation, useGetHospitalVendorsQuery, useGetItemCategoriesQuery } from '../../../api/inventorySlice';
import CreateAutocomplete from '../../CustomComponents/CreateAutocomplete';
import { useMemo } from 'react';
import VendorSuppliedTooltip from '../../CustomComponents/Tooltips/VendorSuppliedTooltip';

function CreateNewItemDialog(props) {
    const { user } = useContext(UserContext);
    const [name, setName] = useState('');
    const [manufacturer, setManufacturer] = useState('');
    const [partNumber, setPartNumber] = useState('');
    const [category, setCategory] = useState('');
    const [price, setPrice] = useState('');
    const [isVendorSupplied, toggleIsVendorSupplied] = useState(false);
    const [isActive, toggleIsActive] = useState(true);
    const [createVendor] = useCreateVendorMutation();
    const [createItem] = useCreateItemMutation();

    const canSubmit = useMemo(() => {
        return name && manufacturer && partNumber && category;
    }, [name, manufacturer, partNumber, category]);

    function handleClose() {
        props.setOpen(false);
    }

    const handleSubmit = async() => {
        var requestCategory = category;
        if (typeof(requestCategory) !== 'string') {
            requestCategory = requestCategory.name;
        }

        if (typeof(manufacturer) === 'string') {
            await createVendor({
                name: manufacturer
            }).unwrap().then((payload) => {
                createItem({
                    hospitalId: user.hospital.id,
                    data: {
                        name: name, 
                        part_number: partNumber,
                        vendor_id: payload.id,
                        category: requestCategory,
                        cost: (price ?? 0) * 100,
                        is_vendor_supplied: isVendorSupplied,
                        is_active: isActive,
                    }
                }).unwrap().then((payload) => {
                    props.addToSearchResults(payload);
                });
            });
        } else {
            await createItem({
                hospitalId: user.hospital.id,
                data: {
                    name: name, 
                    part_number: partNumber,
                    vendor_id: manufacturer.id,
                    category: requestCategory,
                    cost: (price ?? 0) * 100,
                    is_vendor_supplied: isVendorSupplied,
                    is_active: isActive,
                }
            }).unwrap().then((payload) => {
                props.addToSearchResults(payload);
            });
        }
        props.updateSearchTerm(name);
        handleClose();
    }

    return (
        <Dialog open={props.open} onClose={handleClose} fullWidth sx={{
            '& .MuiDialog-container': {
                alignItems: 'flex-start',
                paddingTop : '100px'
            }
        }}>
            <DialogTitle>
                Create New Item
            </DialogTitle>
            <DialogContent>
                <Stack spacing={1}>                
                    <TextField 
                        variant='outlined'
                        placeholder='Item Name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <CreateAutocomplete
                        label='Manufacturer'
                        value={manufacturer}
                        setValue={setManufacturer}
                        loadOptions={() => useGetHospitalVendorsQuery(user.hospital.id)}
                    />
                    <TextField
                        variant='outlined'
                        placeholder='Reference Number'
                        value={partNumber}
                        onChange={(e) => setPartNumber(e.target.value)}
                    />
                    <CreateAutocomplete
                        label='Category'
                        value={category}
                        setValue={setCategory}
                        loadOptions={() => useGetItemCategoriesQuery(user.hospital.id)}
                    />
                    <TextField
                        variant='outlined'
                        placeholder='Price (each)'
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>
                        }}
                    />
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Stack direction='row' spacing={1} alignItems='center'>
                            <Typography variant='normal' sx={{ fontSize: '16px'}}>Vendor Supplied?</Typography>
                            <ToggleButtonGroup
                                value={isVendorSupplied}
                                exclusive
                                onChange={(e, newValue) => toggleIsVendorSupplied(newValue)}
                                color='primary'
                                size='small'
                            >
                                <ToggleButton value={true}>
                                    Yes
                                </ToggleButton>
                                <ToggleButton value={false}>
                                    No
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <VendorSuppliedTooltip />
                        </Stack>
                        <Stack direction='row' spacing={1} alignItems='center'>
                            <Typography variant='normal' sx={{ fontSize: '16px'}}>Status:</Typography>
                            <ToggleButtonGroup
                                value={isActive}
                                exclusive
                                onChange={(e, newValue) => toggleIsActive(newValue)}
                                color='primary'
                                size='small'
                            >
                                <ToggleButton value={true}>
                                    Active
                                </ToggleButton>
                                <ToggleButton value={false}>
                                    Inactive
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant='contained' disabled={!canSubmit} onClick={handleSubmit}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateNewItemDialog;