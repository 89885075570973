import { LanOutlined } from '@mui/icons-material';
import { Divider, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import HospitalsCollapse from './HospitalsCollapse';

function OrganizationEntry(props) {
    const navigate = useNavigate();
    const { organization } = props;
    const index = 'org' + organization.id;    

    return (
        <>
            <ListItemButton key={index} selected={props.selectedIndex == index} onClick={() => navigate('/superSettings/organizations/' + organization.id)}>
                <ListItemIcon>
                    <LanOutlined />
                </ListItemIcon>
                <ListItemText primary={organization.name} />
            </ListItemButton>
            { organization.hospitals.length > 0 && 
                <HospitalsCollapse 
                    organization={organization} 
                    selectedIndex={props.selectedIndex}
                />
            }
            <Divider />
        </>
    );
}

export default OrganizationEntry;