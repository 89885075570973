import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useCreateReviewFlagMutation } from '../../api/staffReviewSlice';

function FlagReviewDialog(props) {
    const [reason, setReason] = useState('');
    const [createFlag] = useCreateReviewFlagMutation();

    async function handleFlag() {
        await createFlag({
            draftId: props.draftId,
            body: {
                reason: reason
            }
        });
        props.handleClose();
    }

    return (
        <Dialog onClose={props.handleClose} open={props.open} >
            <DialogTitle>
                Flag Review
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {'Something wrong with this review? Can\'t complete it for some reason? Flag it and we\'ll take a look.'}
                </DialogContentText>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant='outlined'
                    placeholder="Tell us what's wrong"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={props.handleClose}>
                    Close
                </Button>
                <Button variant='contained' onClick={handleFlag} disabled={reason === ''}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FlagReviewDialog;
