import React, { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TableRow, TableCell } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDeleteCardOwnerMutation } from '../../../api/cardSlice';
import { Delete, TextsmsRounded } from '@mui/icons-material';

function PhysicianRow(props) {
    const [isShowingDeleteConfirmation, setIsShowingDeleteConfirmation] = useState(false);
    const navigate = useNavigate();

    function handleDeleteClick(event) {
        event.stopPropagation();
        setIsShowingDeleteConfirmation(true);
    }

    function handleTextClick(event) {
        event.stopPropagation();
        navigate(`/physicians/${props.physician.id}/physicianTexting`);
    }

    const goToPhysician = (physicianData) => {
        navigate(`/physicians/${physicianData.id}`);
    }

    return (
        <TableRow onClick={() => goToPhysician(props.physician)} hover sx={{ cursor: 'pointer' }}>
            <TableCell>{'Dr. ' + props.physician.last_name + ', ' + props.physician.first_name}</TableCell>
            <TableCell>{ props.physician.specialty}</TableCell>
            <TableCell>{ props.physician.count}</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>
                <IconButton  onClick={(e) => handleTextClick(e)}>
                    <TextsmsRounded />
                </IconButton>
            </TableCell>
            <TableCell>
                <DeleteCardOwner open={isShowingDeleteConfirmation} setOpen={setIsShowingDeleteConfirmation} owner={props.physician} />
                <IconButton onClick={(e) => handleDeleteClick(e)}>
                    <Delete />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

function DeleteCardOwner(props) {
    const [deleteCardOwner] = useDeleteCardOwnerMutation();

    function handleClose(e) {
        e.stopPropagation();
        props.setOpen(false);
    }

    const handleDelete = async(e) => {
        e.stopPropagation();
        await deleteCardOwner({
            cardOwner: props.owner,
        });
        handleClose(e);
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>Confirm Card Owner Deletion</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you want to delete this card owner?</DialogContentText>
                <DialogContentText>
                    Deleting this physician will also delete their preference cards.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => handleClose(e)}>
                    Cancel
                </Button>
                <Button onClick={(e) => handleDelete(e)} style={{ color: 'red' }}>
                    Yes, delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PhysicianRow;
