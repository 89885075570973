import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, Paper, Button, Typography } from '@mui/material';
import { useGetUsersQuery } from '../../api/userSlice';
import { UserContext } from '../Hooks/useUser';
import GenericTable from '../Tables/GenericTable';
import { Add } from '@mui/icons-material';

function UsersView() {
    const { state } = useLocation();
    const hospitalId = state?.hospitalId;
    const { user, hasUserManagement } = useContext(UserContext);
    const [page, setPage] = useState(1);

    const {
        data: users = {},
        isSuccess,
    } = useGetUsersQuery({ hospitalId: hospitalId ? hospitalId : user.hospital.id, page: page });

    const navigate = useNavigate();

    useEffect(() => {
        setPage(1);
    }, [hospitalId, user.hospital.id]);

    const tableHeaders = [
        'Name',
        'Special User Permissions',
        'Last Login',
        'Last Active',
        'Account Status',
    ];

    return (
        <Stack spacing={2} px={{ xs: 2, md: 6 }} pb={2} sx={{ height: '100%' }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='heavy' sx={{ fontSize: '20px' }}>Users</Typography>
                {hasUserManagement() && (
                    <Paper elevation={2}>
                        <Button variant='contained' size='small' onClick={() => navigate('/users/create')} startIcon={<Add />}>Add New User</Button>
                    </Paper>
                )}
            </Stack>
            <GenericTable
                data={users.data}
                dataType='user'
                headers={tableHeaders}
                isDataLoaded={isSuccess}
                page={page - 1}
                setPage={setPage}
                total={users.meta?.total}
            />
        </Stack>
    )
}

export default UsersView;