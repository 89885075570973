import React from 'react';
import DashboardHeader from '../DashboardHeader/DashboardHeader';
import { AppBar, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/system';
import { ArrowBackIos } from '@mui/icons-material';


function HeadlineHeader(props) {
    const navigate = useNavigate();
    const theme = useTheme();
    

    function navigateToLanding() {
        navigate('/physician/dashboard/landing')
    }

    return (
        <AppBar position='sticky' sx={{ color: 'black', backgroundColor: '#FFFFFF' }}>
            <Stack >
                {props.showButton && 
                    <Stack pl={2} pt={2}>
                        <Button
                            className = 'pendo-headline-back-button'
                            variant='text'
                            startIcon={<ArrowBackIos />} 
                            onClick={navigateToLanding}
                            style={{ color: `${theme.palette.blue.heavy}`, paddingTop: '0px', paddingBottom: '0px', width: 'fit-content', fontSize: '14px'}}
                        >
                            <Typography variant='bold'> All Optimization Opportunities </Typography>
                        </Button>
                    </Stack>
                }
                <DashboardHeader 
                    physician={props.cardOwner}
                    hospital={props.campaign.hospital}  
                />
            </Stack>
        </AppBar>
    );
}

export default HeadlineHeader;