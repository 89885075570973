import React from 'react';
import { Tooltip, Typography } from '@mui/material';

function ItemNameDisplay(props) {
    return (
        <Typography 
            variant='normal' 
            sx={{ fontSize: '16px' }}
        >
            {props.item.is_active === false &&
                <>
                    <Typography variant='normal' color='error' sx={{ fontSize: '16px', opacity: 1.0 }}>Inactive</Typography>
                    <Typography variant='normal' sx={{ fontSize: '16px' }}> - </Typography>
                </>
            }
            { (props.item.system_name !== null && props.item.system_name !== props.item.name) ? 
                <Tooltip title={props.item.system_name} placement='top' arrow>
                    {props.item.name}
                </Tooltip>
                :
                <>{props.item.name}</>
            }    
        </Typography>
    );
}

export default ItemNameDisplay;
