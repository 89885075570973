import { ArrowForwardRounded } from '@mui/icons-material';
import { Divider, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';

function QuantityChangeDetails(props) {
    const theme = useTheme();

    return (
        <Stack direction='row' spacing={0.5} alignItems='center'>
            <Stack spacing={0.5} direction='row' divider={<Divider orientation='vertical' flexItem />}>
                <Typography variant='normal' sx={{ fontSize: '12px' }}>
                    Open: <Typography variant='normal' sx={{ color: `${theme.palette.red.main}` }}>{props.item.open_quantity}</Typography>
                </Typography>
                <Typography variant='normal' sx={{ fontSize: '12px' }}>
                    Hold: <Typography variant='normal' sx={{ color: `${theme.palette.red.main}` }}>{props.item.hold_quantity}</Typography>
                </Typography>
            </Stack>
            <ArrowForwardRounded fontSize='16px' style={{ color: '#0000004D' }} />
            <Stack spacing={0.5} direction='row' divider={<Divider orientation='vertical' flexItem />}>
                <Typography variant='normal' sx={{ fontSize: '12px' }}>
                    Open: <Typography variant='normal' sx={{ color: `${theme.palette.blue.main}` }}>{props.recommendation.custom_open ?? props.recommendation.open_quantity}</Typography>
                </Typography>
                <Typography variant='normal' sx={{ fontSize: '12px' }}>
                    Hold: <Typography variant='normal' sx={{ color: `${theme.palette.blue.main}` }}>{props.recommendation.custom_hold ?? props.recommendation.hold_quantity}</Typography>
                </Typography>
            </Stack>
        </Stack>
    )
}

export default QuantityChangeDetails;
