import { Button, Stack, Typography } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { Box, useTheme } from '@mui/system';
import React from 'react';
import useAnalytics from '../../Hooks/useAnalytics';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';

function ItemSwapsHeader(props) {
    const theme = useTheme();
    const analytics = useAnalytics();
    const navigate = useNavigate();


    function navigateToDashboard() {
        analytics.track('Button Clicked', {
            label: 'Dashboard',
            sector: 'Physician Dashboard',
            location: 'Item Swap Recommendations'
        });
        navigate(`/physicianDashboard/headline/${props.campaignId}`);
    }

    return (
        <Stack direction='row' p={2} justifyContent='space-between' sx={{ backgroundColor: '#FFFFFF', boxShadow: 5 }}>
            <Stack spacing={1.5}>
                <Button
                    variant='text'
                    startIcon={<ArrowBackIos />} 
                    onClick={navigateToDashboard}
                    style={{ color: `${theme.palette.hold.main}`, paddingTop: '0px', paddingBottom: '0px', width: 'fit-content'}}
                >
                    Dashboard
                </Button>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}>Item Swap Recommendations</Typography>
            </Stack>
            <Stack spacing={0.5} justifyContent='center'>
                <Box alignSelf='center' textAlign='center' sx={{ fontSize: '10px', color: '#00000099', maxWidth: '80px' }}>
                    <Typography variant='normal'>Estimated Savings /yr</Typography>
                </Box>
                <Box py={0.5} px={1} alignSelf='center' sx={{ color: `${theme.palette.blue.main}`, font: 'normal normal 18px Roboto', border: '1px solid #0000001F', borderRadius: '4px', maxWidth: 'fit-content' }}>
                    <CountUp 
                        start={Math.round(props.oldSavings / 100)}
                        end={Math.round(props.currentSavings / 100)}
                        duration={0.4}
                        separator=','
                        decimals={0} 
                        prefix='$'
                    />
                </Box>
            </Stack>
        </Stack>
    );
}

export default ItemSwapsHeader;