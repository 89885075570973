import React, {createContext, useContext, useState} from 'react';
import useToken from './useToken';
import axios from 'axios';
import useUser from './useUser';
import useStaffReviewToken from './useStaffReviewToken';
import useDashboardToken from './useDashboardToken';

export const authContext = createContext(null);
export const staffReviewAuthContext = createContext(null);

export function useAuth() {
    const { token, setToken } = useToken();
    const { physicianDashboardToken } = useDashboardToken();
    const { staffReviewToken } = useStaffReviewToken();
    const [authed, setAuthed] = useState(token !== null);
    const [physicianDashboardAuthed, setPhysicianDashboardAuthed] = useState(physicianDashboardToken !== null);
    const [staffReviewAuthed, setStaffReviewAuthed] = useState(staffReviewToken !== null);
    const { user, setUser } = useUser();
    function isSuperUser() {
        superUserRole = user.roles.filter((role) => 
            role.name === 'Super Admin'
        );
        
        return superUserRole.length > 0;
    }

    return {
        authed,
        staffReviewAuthed,
        setStaffReviewAuthed,
        physicianDashboardAuthed,
        setPhysicianDashboardAuthed,
        user,
        isSuperUser,
        login(email, password) {
            return new Promise((res, reject) => {
                axios.post('/api/passwordLogin', {
                    email: email,
                    password: password
                }).then((response) => {
                    setToken(response.data.access_token);
                    setAuthed(true);
                    res(response);
                }).catch((error) => {
                    if (error.response.status === 403) {
                        reject('Account Locked');
                    } else {
                        reject('Invalid Login Credentials');
                    }
                });
            });
        },
        logout() {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            setToken(null);
            return new Promise((res) => {
                axios.post('/api/logout', {}, config).then((response) => {
                    setUser(null);
                    setAuthed(false);
                    res();
                })
            });
        }
    };
}

export function AuthProvider({ children }) {
    const auth = useAuth();

    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function StaffReviewAuthProvider({ children }) {
    const auth = useAuth();

    return <staffReviewAuthContext.Provider value={auth}>{children}</staffReviewAuthContext.Provider>;
}

export default function AuthConsumer() {
    return useContext(authContext);
}

export function StaffReviewAuthConsumer() {
    return useContext(staffReviewAuthContext);
}