import React, { useState } from 'react';
import UserPermissions from './UserPermissions';

function EditUserPerssion(props){
    const existingIds = props.user.roles?.map((role) => {return role.id;});
    const [selectedIds,setSelectedIds] = useState(existingIds);
    const showCancelButton  =  !(selectedIds.every(id => existingIds.includes(id)) && existingIds.every(id => selectedIds.includes(id)));

    return(
        <UserPermissions 
            existingIds = {existingIds}
            user={props.user}
            showCancelButton = {showCancelButton}
            selectedIds = {selectedIds}
            setSelectedIds = {setSelectedIds}
        />
    )
}

export default EditUserPerssion