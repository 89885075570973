import { ArrowDropDown } from '@mui/icons-material';
import { Button, ButtonGroup, Popper, Paper, ClickAwayListener, Grow, MenuItem, MenuList } from '@mui/material';
import { useTheme } from '@mui/system';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdateItemMutation } from '../../../api/itemDetailSlice';
import ActivateItemDialog from '../../Inventory/ActivateItemDialog';
import DeactivateItemDialog from '../../Inventory/DeactivateItemDialog';

function ItemSearchResultDropdown(props) {
    const [open, setOpen] = useState(false);
    const [isShowingActivateDialog, toggleIsShowingActivateDialog] = useState(false);
    const [isShowingDeactivateDialog, toggleIsShowingDeactivateDialog] = useState(false);
    const [updateItem] = useUpdateItemMutation();
    const anchorRef = React.useRef(null);
    const theme = useTheme();
    const navigate = useNavigate();
    let options = [
        'Go to item',
    ];

    if (props.item.is_active === true) {
        options.push('Deactivate item');
    }

    function handleToggle() {
        setOpen((prevOpen) => !prevOpen);
    }

    function handleClose(event) {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    }

    function handleMenuItemClick(event, index) {
        if (index === 0) {
            navigate(`/inventory/${props.item.id}/details`);
        } else if (index === 1) {
            toggleIsShowingDeactivateDialog(true);
        }
    }

    const handleActivate = async() => {
        await updateItem({
            item: {
                id: props.item.id,
                is_active: true
            }
        }).unwrap().then(() => {
            props.refreshSearchResults();
        });
    }

    const handleDeactivate = async() => {
        await updateItem({
            item: {
                id: props.item.id,
                is_active: false
            }
        }).unwrap().then(() => {
            props.refreshSearchResults();
        });
    }

    return (
        <>
            <ButtonGroup variant='outlined' ref={anchorRef} >
                { props.item.is_active === true ? 
                    <ActiveItemButton handleSelect={props.handleSelect}/> : 
                    <InactiveItemButton handleActivate={() => toggleIsShowingActivateDialog(true)} />
                }
                <Button 
                    size='small'
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    style={{ borderColor: '#0000001F' }}
                >
                    <ArrowDropDown sx={{ color: `${theme.palette.subtext.main}` }}/>
                </Button>
                <Popper
                    sx={{
                        zIndex: 1,
                    }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </ButtonGroup>
            <ActivateItemDialog 
                open={isShowingActivateDialog} 
                setOpen={toggleIsShowingActivateDialog} 
                activateItem={handleActivate}
            />
            <DeactivateItemDialog 
                item={props.item} 
                open={isShowingDeactivateDialog} 
                setOpen={toggleIsShowingDeactivateDialog}
                deactivateItem={handleDeactivate}
            />
        </>
    );
}

function ActiveItemButton(props) {
    const theme = useTheme();
    return ( <Button onClick={props.handleSelect} style={{ borderColor: '#0000001F', color: `${theme.palette.subtext.main}` }}>Select</Button> );
}

function InactiveItemButton(props) {
    const theme = useTheme();
    return ( <Button onClick={props.handleActivate} style={{ borderColor: '#0000001F', color: `${theme.palette.subtext.main}` }}>Activate</Button> );
}

export default ItemSearchResultDropdown;