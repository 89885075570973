import { apiSlice } from '../../api/apiSlice';

export const passwordSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        resetPassword: builder.mutation({
            query: ({email}) => ({
                url: '/requestReset',
                method: 'POST',
                body: {
                    email: email
                }
            })
        }),
        confirmPasswordReset: builder.mutation({
            query: ({ email, token, password, confirmedPassword }) => ({
                url: '/resetPassword',
                method: 'POST',
                body: {
                    email: email,
                    token: token,
                    password: password,
                    password_confirmation: confirmedPassword
                }
            })
        }),
        validateResetToken: builder.query({
            query: ({ email, token }) => ({
                url: '/resetToken?email=' + email + '&token=' + token,
                method: 'GET'
            }),
        }),
    })
});

export const {
    useResetPasswordMutation,
    useConfirmPasswordResetMutation,
    useValidateResetTokenQuery,
} = passwordSlice;