import React from 'react';
import { useState } from 'react';
import { Stack, Typography, TextField, Button } from '@mui/material';


function RejectionNotes(props) {
    const [notes, setNotes] = useState(props.review.notes ?? '');
    const [isEditing, setIsEditing] = useState(props.review.notes ? false : true);

    function handleSave() {
        setIsEditing(false);
        props.handleUpdate(props.review, props.recommendation, 'rejected', notes);
    }

    return (
        <Stack direction='row' width='100%' borderBottom='1px solid rgba(0, 0, 0, 0.5)' py={1}>
            <Stack width='36%' />
            <Stack width='13%' display='grid' pl={2}>
                <Typography variant='heavy' sx={{ fontSize: '16px', alignSelf: 'center' }}>Reviewer Feedback:</Typography>
            </Stack>
            <TextField
                variant='standard'
                value={notes} 
                onChange={(e) => setNotes(e.target.value)} 
                multiline
                disabled={!isEditing}
                style={{ width: '36%', height: '100%', paddingLeft: '16px', paddingRight: '16px', paddingTop: '2px', paddingBottom: '2px' }}
            />
            <Stack width='15%' display='grid' justifyContent='center' alignContent='center'>
                { isEditing ?
                    <Button variant='contained' size='small' onClick={handleSave}>Save Feedback</Button>
                    :
                    <Button variant='outlined' size='small' onClick={() => setIsEditing(true)} disabled={props.disabled}>Edit Feedback</Button>
                }
            </Stack>
        </Stack>
    )
}

export default RejectionNotes;
