import { Box } from '@mui/system';
import React, { useState , useMemo, useContext} from 'react';
import { Stack, InputLabel, TextField, Paper, Button, Typography} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {useCreateCardOwnerMutation} from '../../api/cardSlice'
import { UserContext } from '../Hooks/useUser';
import CreateAutocomplete from '../CustomComponents/CreateAutocomplete';
import { useGetCardOwnerSpecialtiesQuery } from '../../api/userSlice';

function CreatePhysician(){
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const [submitCreateCardOwner] = useCreateCardOwnerMutation();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [externalId, setExternalId] = useState('');
    const [specialty, setSpecialty] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isError, setIsError] = useState(false);

    const canCreate = useMemo(() => {
        return firstName && lastName && specialty;
    }, [firstName, lastName, specialty]);
    const saveCardOwner = async () => {
        var requestSpecialty = specialty;
        if (typeof(requestSpecialty) === 'object') {
            requestSpecialty = requestSpecialty.name;
        }

        try{
            await submitCreateCardOwner({
                hospitalId: user.hospital.id,
                firstName: firstName,
                lastName: lastName,
                specialty : requestSpecialty,
                externalId : externalId,
                phoneNumber : phoneNumber
            }).unwrap()
            navigate('/physicians');
        }
        catch(error){
            if (error.data.message == 'validation.unique'){
                setIsError(true);
            }        
        }
       
    }

    return(
        <Stack spacing={2} px={{ xs: 2, md: 4 }}>
            <Typography variant='heavy' sx={{ fontSize: '20px' }}>Add Physician</Typography>
            <Paper elevation={2} sx={{ maxWidth: { xs: '100%', md: '80%', lg: '50%' } }}>
                <Stack spacing={4} px={4} pt={4}> 
                    <Box>
                        <InputLabel htmlFor="cardOwner-first-name">
                            First Name
                        </InputLabel>
                        <TextField 
                            id="cardOwner-first-name"
                            variant='outlined' 
                            fullWidth={true}
                            value={firstName}
                            onChange={(event) => setFirstName(event.target.value)}
                        />
                    </Box>
                    <Box>
                        <InputLabel htmlFor="cardOwner-last-name">
                            Last Name
                        </InputLabel>
                        <TextField 
                            id="cardOwner-last-name"
                            variant='outlined' 
                            fullWidth={true}
                            value={lastName}
                            onChange={(event) => setLastName(event.target.value)}
                        />
                    </Box>
                    <Box>
                        {isError && 
                            <InputLabel error = {true}  htmlFor='email' sx={{ pb: 1 }}>External ID Already Exists</InputLabel>
                        }
                        <InputLabel htmlFor="cardOwner-externalId ">
                            External ID(optional)
                        </InputLabel>
                        <TextField 
                            id="cardOwner-externalId"
                            variant='outlined' 
                            fullWidth={true}
                            value={externalId}
                            onChange={(event) => setExternalId(event.target.value)}
                        />
                    </Box>
                    <Box>
                        <InputLabel htmlFor="cardOwner-specialty">
                            Specialty
                        </InputLabel>
                        <CreateAutocomplete
                            label="Specialty"
                            value={specialty}
                            setValue={setSpecialty}
                            loadOptions={() => useGetCardOwnerSpecialtiesQuery(user.hospital.id)}
                            fullWidth={true}
                        />
                    </Box>
                    <Box>
                        <InputLabel htmlFor="cardOwner-phone-number">
                            Phone Number
                        </InputLabel>
                        <TextField 
                            id="cardOwner-phone-number"
                            variant='outlined' 
                            fullWidth={true}
                            value={phoneNumber}
                            onChange={(event) => setPhoneNumber(event.target.value)}
                        />
                    </Box>
                    <Stack direction='row' spacing={2} justifyContent='flex-end' alignItems='center' pb={2}>
                        <Button 
                            variant='contained' 
                            onClick={() => saveCardOwner()}
                            disabled = {!canCreate}>Create New Physican
                        </Button>
                    </Stack>
                </Stack>
            </Paper>
        </Stack>
    )
}

export default CreatePhysician;