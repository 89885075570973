import { Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import React from 'react';

function ItemSwapChip(props) {
    const theme = useTheme();
    const backgroundColor = props.variant === 'positive' ? theme.palette.itemSwapChip.background.positive : theme.palette.itemSwapChip.background.negative;
    const textColor = props.variant === 'positive' ? theme.palette.itemSwapChip.text.positive : theme.palette.itemSwapChip.text.negative;

    return (
        <Box px={0.5} py={0.25} sx={{ borderRadius: '4px', background: backgroundColor, color: textColor }}>
            <Typography variant='heavy' sx={{ fontSize: '12px' }}>{props.text}</Typography>
        </Box>
    );
}

export default ItemSwapChip;