import { Block, ContentCopyRounded, HeightRounded, InventoryRounded, PeopleRounded, StarRounded, TrendingDownRounded, TrendingUpRounded, KeyboardDoubleArrowUpRounded, KeyboardDoubleArrowDownRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React from 'react';

function OptimizationContextBullet(props) {
    if (props.type === 'low_utilization') {
        return (
            <OptimizationBullet
                icon={<TrendingDownRounded fontSize='small' />}
                title='Rarely Used'
                text={<span>You used this <strong>{Math.round(props.data*100)}%</strong> of the time</span>}
            />
        );
    } else if (props.type === 'high_utilization') {
        return (
            <OptimizationBullet
                icon={<TrendingUpRounded fontSize='small' />}
                title='Frequently Used'
                text={<span>You used this <strong>{Math.round(props.data*100)}%</strong> of the time</span>}
            />
        );
    } else if (props.type === 'outlier') {
        return (
            <OptimizationBullet
                icon={<PeopleRounded fontSize='small' />}
                title='Outlier'
                text={<span>This item appears on <strong>{Math.round(props.data*100)}%</strong> of cards of this procedure type</span>}
            />
        );
    } else if (props.type === 'update_quantity') {
        return (
            <OptimizationBullet
                icon={<HeightRounded fontSize='small' />}
                title='Adjust Quantity'
                text='Tune the open / hold qty to align with usage'
            />
        );
    } else if (props.type === 'out_of_compliance') {
        return (
            <OptimizationBullet
                icon={<Block fontSize='small' />}
                title='Not Compliant'
                text='Item is not compliant with your contract'
            />
        );
    } else if (props.type === 'duplicate') {
        return (
            <OptimizationBullet
                icon={<ContentCopyRounded fontSize='small' />}
                title='Duplicate Item'
                text='A similar item exists on this card'
            />
        );
    } else if (props.type === 'swap_already_on_card') {
        return (
            <OptimizationBullet
                icon={<ContentCopyRounded fontSize='small' />}
                title='Already on Card'
                text='The recommended contract compliant item is already on this card'
            />
        );
    } else if (props.type === 'low_average_usage') {
        return (
            <OptimizationBullet
                icon={<TrendingDownRounded fontSize='small' />}
                title='Average Usage'
                text={<span>On average, you used this item <strong>{props.data.toFixed(1)}</strong> times per case</span>}
            />
        );
    } else if (props.type === 'high_average_usage') {
        return (
            <OptimizationBullet
                icon={<TrendingUpRounded fontSize='small'/>}
                title='Average Usage'
                text={<span>On average, you used this item <strong>{props.data.toFixed(1)}</strong> times per case</span>}
            />
        );
    } else if (props.type === 'low_peer_usage') {
        return (
            <OptimizationBullet
                icon={<TrendingDownRounded fontSize='small'/>}
                title='Peer Usage'
                text={<span>Other physicians who perform this procedure use this item <strong>{props.data.toFixed(1)}</strong> times per case on average</span>}
            />
        );
    } else if (props.type === 'commonly_used') {
        return (
            <OptimizationBullet
                icon={<StarRounded fontSize='small'/>}
                title='Commonly Used'
                text={<span>This item appears on <strong>{Math.round(props.data * 100)}%</strong> of other cards of this procedure type</span>}
            />
        );
    } else if (props.type === 'down_trending_usage') {
        return (
            <OptimizationBullet
                icon={<TrendingDownRounded fontSize='small'/>}
                title='Usage Decreasing'
                text='Recent usage data suggests you use this item less than your current card quantity indicates'
            />
        );
    } else if (props.type === 'up_trending_usage') {
        return (
            <OptimizationBullet
                icon={<TrendingUpRounded fontSize='small'/>}
                title='Usage Increasing'
                text='Recent usage data suggests you use this item more than your current card quantity indicates'
            />
        );
    } else if (props.type === 'picked_percentage') {
        return (
            <OptimizationBullet
                icon={<InventoryRounded fontSize='small'/>}
                title='Picked Percentage'
                text={<span>This item was picked for <strong>{Math.round(props.data * 100)}%</strong> of your recent cases</span>}
            />
        );
    } else if (props.type === 'peer_usage') {
        return (
            <OptimizationBullet
                icon={<PeopleRounded fontSize='small'/>}
                title='Peer Usage'
                text={<span>Other physicians who perform this procedure use this item <strong>{props.data.toFixed(1)}</strong> times per case on average</span>}
            />
        );
    } else if (props.type === 'elevated_item_spend') {
        return (
            <OptimizationBullet
                icon={<KeyboardDoubleArrowUpRounded fontSize='small'/>}
                title='Elevated Item Spend'
                text={<span>You spend <strong>{Math.round(props.data * 100)}%</strong> more on this item than your peers</span>}
            />
        )
    } else if (props.type === 'elevated_category_spend') {
        return (
            <OptimizationBullet
                icon={<KeyboardDoubleArrowUpRounded fontSize='small'/>}
                title='Elevated Category Spend'
                text={<span>You spend <strong>{Math.round(props.data.value * 100)}%</strong> more on '{props.data.category}' items than your peers</span>}
            />
        )
    } else if (props.type === 'low_item_spend') {
        return (
            <OptimizationBullet
                icon={<KeyboardDoubleArrowDownRounded fontSize='small'/>}
                title='Low Item Spend'
                text={<span>Your peers bring more of this item into the room than you do on average</span>}
            />
        )
    } else if (props.type === 'low_category_spend') {
        return (
            <OptimizationBullet
                icon={<KeyboardDoubleArrowDownRounded fontSize='small'/>}
                title='Low Category Spend'
                text={<span>Your peers bring more '{props.data.category}' items into the room than you do on average</span>}
            />
        )
    }
    return <></>;
}

function OptimizationBullet(props) {
    return (
        <Stack direction='row' spacing={0.5} sx={{ fontSize: '12px' }} alignItems='center'>
            {props.icon}
            <Typography variant='normal'>
                <strong>{props.title}</strong> - {props.text}
            </Typography>
        </Stack>
    )
}

export default OptimizationContextBullet;
