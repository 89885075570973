import { createSlice } from '@reduxjs/toolkit';

export const notificationInitialSlice = {
    open: false,
    type: 'info',
    message: '',
    timeout: 5000
}

export const NotificationSlice = createSlice({
    name: 'notification',
    initialState: notificationInitialSlice,
    reducers: {
        addNotification: (_state, action) => ({
            ...notificationInitialSlice,
            ...action.payload,
            open: true
        }),
        addSuccessNotification: (_state, action) => ({
            message: 'Success!',
            timeout: 3000,
            ...notificationInitialSlice,
            ...action.payload,
            open: true,
            type: 'success'
        }),
        addWarningNotification: (_state, action) => ({
            ...notificationInitialSlice,
            ...action.payload,
            open: true,
            type: 'warning'
        }),
        addErrorNotification: (_state, action) => ({
            message: 'Something went wrong. Please try again later.',
            timeout: 7000,
            ...notificationInitialSlice,
            ...action.payload,
            open: true,
            type: 'error',
        }),
        clearNotification: (state) => ({ ...state, open: false })
    }
});

export const NotificationActions = NotificationSlice.actions;
export const NotificationReducer = NotificationSlice.reducer;
