import { apiSlice } from './apiSlice';

export const categorySlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCommentCategories: builder.query({
            query: (hospitalId) => `/hospitals/${hospitalId}/categories?type=comment`,
            providesTags: ['CommentCategory']
        }),

        getAuxiliaryItemCategories: builder.query({
            query: (hospitalId) => `/hospitals/${hospitalId}/categories?type=auxiliary_item`,
            providesTags: ['AuxiliaryItemCategory']
        }),

        createCategory: builder.mutation({
            query: ({ hospitalId, requestData }) => ({
                url: `/hospitals/${hospitalId}/categories`,
                method: 'POST',
                body: requestData
            }),
            invalidatesTags: ['AuxiliaryItemCategory', 'CommentCategory'],
            async onQueryStarted({ hospitalId, requestData }, { dispatch, queryFulfilled }) {
                try {
                    const {data : createdCategory} = await queryFulfilled;
                    if (createdCategory.type === 'App\Models\AuxiliaryItem') {
                        const result = dispatch(
                            apiSlice.util.updateQueryData('getAuxiliaryItemCategories', hospitalId.toString(), draft => {
                                draft.push(createdCategory);
                            })
                        )
                    } else if (createdCategory.type === 'App\Models\Comment') {
                        const result = dispatch(
                            apiSlice.util.updateQueryData('getCommentCategories', hospitalId.toString(), draft => {
                                draft.push(createdCategory);
                            })
                        )
                    }
                    
                    await queryFulfilled;
                } catch {}
            }
        }),

        updateCategory: builder.mutation({
            query: ({ category, requestData }) => ({
                url: `/categories/${category.id}`,
                method: 'PATCH',
                body: requestData
            }),
            invalidatesTags: ['AuxiliaryItemCategory', 'CommentCategory'],
            async onQueryStarted({ category, requestData }, { dispatch, queryFulfilled }) {
                if (category.type === 'App\Models\AuxiliaryItem') {
                    const result = dispatch(
                        apiSlice.util.updateQueryData('getAuxiliaryItemCategories', category.hospital.id.toString(), categories => {
                            let existingCategory = categories.find(data => data.id === category.id);
                            if (existingCategory) {
                                existingCategory.name = requestData.name;
                                existingCategory.order = requestData.order;
                            }
                        })
                    )
                } else if (category.type === 'App\Models\Comment') {
                    const result = dispatch(
                        apiSlice.util.updateQueryData('getCommentCategories', category.hospital.id.toString(), categories => {
                            let existingCategory = categories.find(data => data.id === category.id);
                            if (existingCategory) {
                                existingCategory.name = requestData.name;
                                existingCategory.order = requestData.order;
                            }
                        })
                    )
                }
                try {
                    await queryFulfilled;   
                } catch {
                    result.undo();
                }
            }
        })
    })
});

export const {
    useGetCommentCategoriesQuery,
    useGetAuxiliaryItemCategoriesQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
} = categorySlice;
