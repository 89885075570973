import React, { useState, useMemo } from 'react';
import SelectedItem from '../AddItem/SelectedItem';
import { Button, Stack } from '@mui/material';
import { useTheme } from '@mui/system';

function SwapItemReview(props) {
    const theme = useTheme();
    const [openQuantity, setOpenQuantity] = useState(props.currentItem.open_quantity);
    const [holdQuantity, setHoldQuantity] = useState(props.currentItem.hold_quantity);

    function handleBack() {
        props.setSelectedItem(null);
        setOpenQuantity(props.currentItem.open_quantity);
        setHoldQuantity(props.currentItem.hold_quantity);
    }

    function handleReplaceClick() {
        props.handleSwapItems(openQuantity, holdQuantity);
    }

    const isOpenQuantityValid = useMemo(() => {
        return openQuantity !== '' && Number.isInteger(Number(openQuantity)) && Number(openQuantity) >= 0;
    }, [openQuantity]);
    const isHoldQuantityValid = useMemo(() => {
        return holdQuantity !== '' && Number.isInteger(Number(holdQuantity)) && Number(holdQuantity) >= 0;
    }, [holdQuantity]);

    return (
        <Stack spacing={4}>
            <SelectedItem
                item={props.selectedItem}
                openQuantity={openQuantity}
                setOpenQuantity={setOpenQuantity}
                isOpenValid={isOpenQuantityValid}
                holdQuantity={holdQuantity}
                setHoldQuantity={setHoldQuantity}
                isHoldValid={isHoldQuantityValid}
            />
            <Stack direction='row' spacing={2} justifyContent='end'>
                <Button
                    variant='outlined'
                    onClick={handleBack}
                    style={{ borderColor: '#0000001F', color: `${theme.palette.subtext.main}` }}
                >
                    Back
                </Button>
                <Button
                    disabled={!isOpenQuantityValid || !isHoldQuantityValid}
                    variant='contained'
                    onClick={handleReplaceClick}
                    style={{ color: `${theme.palette.blue.main}` }}
                >
                    Replace Item
                </Button>
            </Stack>
        </Stack>
    )
}

export default SwapItemReview;
