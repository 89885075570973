import { EditNoteRounded, Person, Settings, Upload, WysiwygRounded } from '@mui/icons-material';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetHospitalQuery } from '../../api/apiSlice';
import LoadingIndicator from '../LoadingIndicator';

function SuperSettings() {
    const theme = useTheme();
    const params = useParams();
    const navigate = useNavigate();
    const { data: hospital, isLoading } = useGetHospitalQuery(params.hospitalId);

    return (
        isLoading && <LoadingIndicator /> ||
        !isLoading &&
        <Stack pl={8} spacing={4}>
            <Typography variant='heavy' sx={{ fontSize: '20px' }}>Super Settings</Typography>
            <Box p={4} sx={{ borderRadius: '8px', boxShadow: 1, maxWidth: '50%', backgroundColor: `${theme.palette.background.white}` }}>
                <Stack spacing={2}>
                    <Stack spacing={1}>
                        <Typography variant='heavy' sx={{ fontSize: '20px' }}>{hospital.name}</Typography>
                        <Typography variant='normal' sx={{ fontSize: '14px' }}>Hospital ID: {hospital.id}</Typography>
                    </Stack>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate(`/superSettings/hospitals/${hospital.id}/manageHospital`)}>
                                <ListItemIcon>
                                    <Settings />
                                </ListItemIcon>
                                <ListItemText>
                                    Site Configuration
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate(`/superSettings/hospitals/${hospital.id}/passwordResets`)}>
                                <ListItemIcon>
                                    <Person />
                                </ListItemIcon>
                                <ListItemText>
                                    Generate Password Reset Link
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate(`/superSettings/hospitals/${hospital.id}/manageCategories`)}>
                                <ListItemIcon>
                                    <WysiwygRounded />
                                </ListItemIcon>
                                <ListItemText>
                                    Card Setup
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate(`/superSettings/hospitals/${hospital.id}/imports`)}>
                                <ListItemIcon>
                                    <Upload />
                                </ListItemIcon>
                                <ListItemText>
                                    Data Ingestion
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate(`/superSettings/hospitals/${hospital.id}/reviewerManagement`)}>
                                <ListItemIcon>
                                    <EditNoteRounded />
                                </ListItemIcon>
                                <ListItemText>
                                    Staff Review Management
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Stack>
            </Box>
        </Stack>
    );
}

export default SuperSettings;