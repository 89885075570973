import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

function UploadImage(props) {
    const [files, setFiles] = useState([]);

    function handleClose() {
        props.setOpen(false);
    }

    function handleFileChange(files) {
        setFiles(files);
    }

    function handleCancel() {
        setFiles([]);
    }

    const handleUpload = async() => {
        let formData = new FormData();
        formData.append('image', files[0]);
        props.uploadImage(formData);
        setFiles([]);
        handleClose();
    }

    return (
        <Dialog open={props.open} onClose={handleClose} maxWidth='sm' fullWidth>
            <DialogTitle>
                Add Image
            </DialogTitle>
            <DialogContent>
                { files.length > 0 && 
                    <Stack direction='row' spacing={2}>
                        <Typography variant='bold'>Selected File: </Typography>
                        <Typography variant='normal'>{files[0].name}</Typography>
                    </Stack>
                }
                <Stack direction='row' spacing={2} justifyContent='center'>
                    { files.length > 0 ? 
                        <>
                            <Button variant='outlined' onClick={handleCancel}>Cancel</Button>
                            <LoadingButton loading={props.isUploading} variant='contained' onClick={handleUpload}>Upload</LoadingButton>
                        </> :
                        <Button variant='contained' component='label'>
                            Select File
                            <input hidden accept="image/*" type="file" onChange={(e) => handleFileChange(e.target.files)}/>
                        </Button>    
                    }
                </Stack>
            </DialogContent>
        </Dialog>
    );  
}

export default UploadImage;