import { Box, useTheme } from '@mui/system';
import { Stack, Typography, Button } from '@mui/material';
import React from 'react';
import { useCreateCardMutation } from '../../../api/cardSlice';
import { useNavigate } from 'react-router-dom';

function CardDataImport(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [submitCreateCard] = useCreateCardMutation();

    const handleCreate = async () => {
        try{
            await submitCreateCard({
                cardName: props.cardName,
                cardOwner: props.selectedPhysician,
                hospitalId: props.user.hospital.id,
            }).unwrap().then((payload) => navigate(`/cards/${payload.id}`));
        }
        catch(error){
            console.log(error);
        }
    }

    return (
        <Stack spacing={4} p={4} sx={{ backgroundColor: `${theme.palette.background.white}`, boxShadow: 1, borderRadius: '8px' }}>
            <Stack spacing={1}>
                <Typography variant='bold' sx={{ fontSize: '20px' }}>Quick Card Creation</Typography>
                <Typography variant='normal' sx={{ fontSize: '16px' }}>Select a method of adding items and comments to this new card</Typography>
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
                <Box py={2} px={3} sx={{ borderRadius: '8px', border: '1px solid #0000001F', width: { xs: '100%', md: '50%' }, height: '50%' }}>
                    <Stack spacing={2} justifyContent='space-between' alignItems='center' height='100%'>
                        <Stack spacing={1} alignItems='center' textAlign='center'>
                            <Typography variant='bold' sx={{ fontSize: '18px' }}>Copy existing card</Typography>
                            <Typography variant='normal' sx={{ fontSize: '16px' }}>Select an existing card as a template and make edits as needed.</Typography>
                        </Stack>
                        <Button variant='contained' onClick={props.handleCopyExistingCard}>Select a template</Button>
                    </Stack>
                </Box>
                <Box py={2} px={3} sx={{ borderRadius: '8px', border: '1px solid #0000001F', width: { xs: '100%', md: '50%' }, height: '50%' }}>
                    <Stack spacing={2} justifyContent='space-between' alignItems='center' height='100%'>
                        <Stack spacing={1} alignItems='center' textAlign='center'>
                            <Typography variant='bold' sx={{ fontSize: '18px' }}>Start from scratch</Typography>
                            <Typography variant='normal' sx={{ fontSize: '16px' }}>Begin with a blank card and add items and comments individually by hand.</Typography>
                        </Stack>
                        <Button variant='contained' onClick={handleCreate}>Manually add items</Button>
                    </Stack>
                </Box>
            </Stack>   
        </Stack>
    )
}

export default CardDataImport;
