import React from 'react';
import { Stack, Typography, CircularProgress } from '@mui/material';

function LoadingIndicator(props) {
    
    return (
        <Stack justifyContent="center" alignItems="center" textAlign="center" spacing={2} m={2} p={2}>
            <Typography variant='bold' fontSize={{xs:'20px', sm: '24px', md: '36px'}}>Loading{props.variant && ' your ' + props.variant}...</Typography>
            <CircularProgress />
        </Stack>
    );
}

export default LoadingIndicator;