import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import { Paper, Skeleton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { NumericFormat } from 'react-number-format';

function StatisticTile(props) {
    const theme = useTheme();

    return (
        <Paper elevation={2}>
            <Stack spacing={1} p={4} alignItems='center'>
                <Typography variant='heavy' alignItems='center' sx={{ fontSize: '24px', color: `${theme.palette.blue.main}`, display: 'flex' }}>
                    { props.isLoading ? <Skeleton variant='text' sx={{ fontSize: '24px' }} width='100px' /> :
                        <>
                            { props.includeTrendDown &&
                                <ArrowDownwardRounded />
                            }
                            { props.includeTrendUp &&
                                <ArrowUpwardRounded />
                            }
                            { props.formatAsDollar &&
                                <NumericFormat
                                    value={props.value}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    prefix={'$'}
                                    fixedDecimalScale={true}
                                />
                            }
                            { props.formatAsPercent &&
                                <NumericFormat
                                    value={props.value}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    suffix={'%'}
                                    fixedDecimalScale={true}
                                />
                            }
                            { !props.formatAsDollar && !props.formatAsPercent && 
                                <NumericFormat
                                    value={props.value}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                />
                            }
                        </>
                    }
                </Typography>
                <Typography variant='light' sx={{ fontSize: '14px' }}>{props.title}</Typography>
            </Stack>
        </Paper>
    );
}

export default StatisticTile;
