import { MenuItem, Paper, Select, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { useGetComplianceCategoriesQuery } from '../../../api/campaignSlice';

function ComplianceSettings(props) {
    const { data: categories } = useGetComplianceCategoriesQuery(props.campaign.hospital.id);

    return (
        <Paper elevation={2} sx={{ width: '23%' }}>
            <Stack p={3} spacing={2}>
                <Typography variant='light' sx={{ fontSize: '18px' }}>Compliance Settings</Typography>
                <Select 
                    value={props.settings.compliance_categories[0]} 
                    onChange={(e) => props.setSettings({ ...props.settings, compliance_categories: [e.target.value] })}
                    disabled={props.disabled}
                    placeholder="Select a compliance category"
                >
                    {categories?.map((category) => (
                        <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                    ))}
                </Select>
            </Stack>
        </Paper>
    );
}

export default ComplianceSettings;
