import React from 'react';
import { Drawer } from '@mui/material';
import StaffReviewSidebarContents from './StaffReviewSidebarContents';

function StaffReviewSidebar(props) {
    return (
        <>  
            <Drawer
                sx={{
                    width: props.drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: props.drawerWidth,
                        boxSizing: 'border-box',
                    },
                    display: { xs: 'none', md: 'block' },
                    zIndex: 5
                }}
                variant='permanent'
                anchor="left"
                open
            >
                <StaffReviewSidebarContents drawerWidth={props.drawerWidth} />
            </Drawer>
            <Drawer
                sx={{
                    width: props.drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: props.drawerWidth,
                        boxSizing: 'border-box',
                    },
                    display: { xs: 'block', md: 'none' },
                    zIndex: 5
                }}
                variant='temporary'
                anchor="left"
                ModalProps={{
                    keepMounted: true
                }}
                open={props.open}
                onClose={() => props.setOpen(false)}
            >
                <StaffReviewSidebarContents drawerWidth={props.drawerWidth} />
            </Drawer>
        </>
    );
}

export default StaffReviewSidebar;
