import { Cancel } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Select, MenuItem, DialogActions, Button, InputLabel, Checkbox, ListItemText, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react';

function FilterDialog(props) {
    const [filters, setFilters] = useState(props.filters);

    function handleToggleFilter(filter) {
        filters[filter].selected = !filters[filter].selected;
        setFilters({ ...filters });
    }

    function handleToggleFilterOption(filter, option) {
        const index = filters[filter].selectedOptions.indexOf(option);
        if (index > -1) {
            filters[filter].selectedOptions.splice(index, 1);
        } else {
            filters[filter].selectedOptions.push(option);
        }
        setFilters({ ...filters });
    }

    function handleClose() {
        props.setOpen(false);
        setFilters(props.filters);
    }

    return (
        <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth='md' PaperProps={{ sx: { height: '60%' } }}>
            <DialogTitle>
                {props.dialogTitle}
            </DialogTitle>
            <DialogContent style={{ paddingTop: '8px' }}>
                <Stack spacing={2}>
                    <Select
                        multiple
                        fullWidth
                        displayEmpty
                        value={Object.keys(filters).filter((filter) => filters[filter].selected)}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em>Select Filters</em>;
                            }
                            return selected.join(', ');
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    boxShadow: 2,
                                    borderBottom: '1px solid #d3d4d5',
                                    borderLeft: '1px solid #d3d4d5',
                                    borderRight: '1px solid #d3d4d5',    
                                    borderBottomLeftRadius: '8px',
                                    borderBottomRightRadius: '8px',
                                    borderTopRightRadius: '0px',
                                    borderTopLeftRadius: '0px',
                                }
                            }
                        }}   
                    >
                        {Object.keys(filters).map((filterName) => (
                            <MenuItem key={filterName} value={filterName} onClick={() => handleToggleFilter(filterName)}>
                                <Checkbox checked={filters[filterName].selected} />
                                <ListItemText primary={filterName} />
                            </MenuItem>
                        ))}
                    </Select>
                    <Stack spacing={2}>
                        { Object.values(filters).filter((f) => f.selected).map((filterInfo) => (
                            <Stack key={filterInfo.name} direction='row' justifyContent='space-between' alignItems='center'>
                                <Stack direction='row' alignItems='center'>
                                    <IconButton onClick={() => handleToggleFilter(filterInfo.name)}>
                                        <Cancel />
                                    </IconButton>
                                    <InputLabel>{filterInfo.name}: </InputLabel>
                                </Stack>
                                <Select
                                    multiple
                                    displayEmpty
                                    value={filterInfo.selectedOptions}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <em>Select {filterInfo.name}</em>;
                                        }
                                        return selected.join(', ')
                                    }}
                                    sx={{ width: '80%' }}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                boxShadow: 2,
                                                borderBottom: '1px solid #d3d4d5',
                                                borderLeft: '1px solid #d3d4d5',
                                                borderRight: '1px solid #d3d4d5',    
                                                borderBottomLeftRadius: '8px',
                                                borderBottomRightRadius: '8px',
                                                borderTopRightRadius: '0px',
                                                borderTopLeftRadius: '0px',
                                            }
                                        }
                                    }}   
                                >
                                    {filterInfo.options.map((option) => (
                                        <MenuItem key={option} value={option} onClick={() => handleToggleFilterOption(filterInfo.name, option)}>
                                            <Checkbox checked={filterInfo.selectedOptions.indexOf(option) > -1} />
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                        ))}
                        { Object.values(filters).filter((f) => f.selected).length === 0 && <em>Filter properties will appear below once selected above.</em> }
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant='text' onClick={handleClose}>Cancel</Button>
                <Button 
                    variant='contained' 
                    onClick={() => props.handleApplyFilters(filters)}
                    disabled={
                        Object.values(filters).filter((f) => f.selectedOptions.length > 0).length === 0 &&
                        Object.values(filters).filter((f) => f.selectedOptions.length > 0).length !== Object.values(filters).filter((f) => f.selected).length
                    }
                >
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default FilterDialog;
