import { Grid, Stack, Typography, Paper, ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetItemQuery, useUpdateItemMutation } from '../../api/itemDetailSlice';
import DetailBox from '../Card/DetailBox';
import ItemDetailBox from './ItemDetailBox';
import ItemImageBox from './ItemImageBox';
import { UserContext } from '../Hooks/useUser';
import DeactivateItemDialog from './DeactivateItemDialog';
import ActivateItemDialog from './ActivateItemDialog';
import CategoryDetail from './CategoryDetail';
import VendorSuppliedTooltip from '../CustomComponents/Tooltips/VendorSuppliedTooltip';
import AddItemDetailsActions from './AddItemDetailsActions';
import { InfoOutlined } from '@mui/icons-material';

function ItemDetails() {
    let params = useParams();
    const theme = useTheme();
    const navigate = useNavigate();
    
    const { hasInventoryManagement, isSuperUser } = useContext(UserContext);
    const { data: item, isError } = useGetItemQuery(params.itemId);

    const [isShowingActivateDialog, toggleIsShowingActivateDialog] = useState(false);
    const [isShowingDeactivateDialog, toggleIsShowingDeactivateDialog] = useState(false);
    const [updateItem] = useUpdateItemMutation();

    if (isError) {
        navigate('/inventory');
    }

    const handleNameSave = async(newName) => {
        await updateItem({
            item: {
                id: item.id,
                name: newName
            }
        });
    }

    const handleCostSave = async(newCost) => {
        await updateItem({
            item: {
                id: item.id,
                cost: newCost * 100
            }
        });
    }

    const handleVendorSuppliedChange = async(e, newValue) => {
        await updateItem({
            item: {
                id: item.id,
                is_vendor_supplied: newValue
            }
        });
    }

    function handleActiveStatusChange(e, newValue) {
        if (newValue === false) {
            toggleIsShowingDeactivateDialog(true);
        } else {
            toggleIsShowingActivateDialog(true);
        }
    }

    return (
        <Grid container columnSpacing={3} rowSpacing={{ xs: 3, md: 0 }} px={{ xs: 2, md: 3 }} pb={4} overflow='auto' height='100%'>
            { item && 
                <>
                    <Grid item xs={12} md={6} lg={3}>
                        <Stack spacing={2}>
                            <Typography variant='heavy' sx={{ fontSize: '20px' }}>Item Details</Typography>
                            <DetailBox
                                title='Display Name'
                                editable={hasInventoryManagement()}
                                handleSave={handleNameSave}
                                content={item.display_name}
                            />

                            <Box p={2} sx={{ boxShadow: 1, borderRadius: '8px', backgroundColor: theme.palette.background.white }}>
                                <Stack spacing={1}>
                                    <Stack spacing={1} direction='row' alingItems='center'>
                                        <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>System Name</Typography>
                                        <Tooltip disableFocusListener title='This name is designated by your inventory system and cannot be manually edited.'>
                                            <InfoOutlined style={{ opacity: 0.5 }} fontSize='small' />
                                        </Tooltip>
                                    </Stack>
                                    <Typography variant='heavy' sx={{ fontSize: '14px' }}>{item.system_name}</Typography>
                                </Stack>
                            </Box>

                            <DetailBox
                                title='Vendor'
                                content={item.vendor.name}
                            />

                            <DetailBox
                                title='Reference Number'
                                content={item.part_number}
                            />

                            <CategoryDetail
                                title='Category'
                                canEdit={hasInventoryManagement()}
                                item={item}
                            />

                            <DetailBox
                                title='Price (Each)'
                                content={parseFloat(item.cost / 100).toFixed(2)}
                                editable={hasInventoryManagement()}
                                handleSave={handleCostSave}
                            />

                            <Paper elevation={2}>
                                <Stack direction='row' justifyContent='space-between' alignItems='center' p={2}>
                                    <Stack direction='row' spacing={1} alignItems='center'>
                                        <Typography variant='normal' sx={{ fontSize: '16px'}}>Vendor Supplied?</Typography>
                                        <VendorSuppliedTooltip />
                                    </Stack>
                                    <ToggleButtonGroup
                                        value={item.is_vendor_supplied}
                                        disabled={!hasInventoryManagement}
                                        exclusive
                                        onChange={handleVendorSuppliedChange}
                                        color='primary'
                                        size='small'
                                    >
                                        <ToggleButton value={true}>
                                            Yes
                                        </ToggleButton>
                                        <ToggleButton value={false}>
                                            No
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>
                            </Paper>

                            <Paper elevation={2}>
                                <Stack direction='row' justifyContent='space-between' alignItems='center' p={2}>
                                    <Typography variant='normal' sx={{ fontSize: '16px' }}>Status:</Typography>
                                    <ToggleButtonGroup
                                        value={item.is_active}
                                        disabled={!hasInventoryManagement}
                                        exclusive
                                        onChange={handleActiveStatusChange}
                                        color='primary'
                                        size='small'
                                    >
                                        <ToggleButton value={true}>
                                            Active
                                        </ToggleButton>
                                        <ToggleButton value={false}>
                                            Inactive
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                    <DeactivateItemDialog 
                                        open={isShowingDeactivateDialog} 
                                        setOpen={toggleIsShowingDeactivateDialog}
                                        deactivateItem={() => updateItem({item: {id: item.id, is_active: false}})}
                                        item={item}
                                    />
                                    <ActivateItemDialog
                                        open={isShowingActivateDialog}
                                        setOpen={toggleIsShowingActivateDialog}
                                        activateItem={() => updateItem({item: {id: item.id, is_active: true}})}
                                    />
                                </Stack>
                            </Paper>

                            { isSuperUser() &&
                                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                    <AddItemDetailsActions item={item} />
                                </Box>
                            }
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        { (item.images.length > 0 || item.details !== null) &&
                            <Stack spacing={2}>
                                { item.images.map((image) => (
                                    <Stack key={image.id} spacing={0.5}>
                                        <ItemImageBox image={image} canEdit={isSuperUser()} />
                                    </Stack>
                                ))}
                                { item.details !== null && Object.entries(item.details).map(([key, value]) => (
                                    <Stack key={key}>
                                        <ItemDetailBox title={key} content={value} item={item} canEdit={isSuperUser()} />
                                    </Stack>
                                ))}
                            </Stack>
                        }
                        { isSuperUser() &&
                            <Box mt={2} sx={{ display: { xs: 'flex', md: 'none' } }}>
                                <AddItemDetailsActions item={item} />
                            </Box>
                        }
                    </Grid>
                </>
            }
        </Grid>
    );
}

export default ItemDetails;