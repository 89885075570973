import React from 'react';
import { useGetCompletedReviewsQuery } from '../../api/staffReviewSlice';
import { Stack } from '@mui/system';
import ReviewEntry from './ReviewEntry';
import { Typography } from '@mui/material';
import useStaffReviewUser from '../Hooks/useStaffReviewUser';


function PastReviewsCardList() {
    const { staffReviewUser: user } = useStaffReviewUser();
    const { data: pastReviews = [] } = useGetCompletedReviewsQuery(user.id, { skip: !user });

    return (
        <Stack spacing={2} px={{ xs: 2, md: 6 }} pb={2} sx={{ height: '100%' }}>
            <Stack>
                <Typography variant='heavy' sx={{ fontSize: '20px' }}>Previously Completed Reviews</Typography>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>Completed By: </strong>{user.name}</Typography>
            </Stack>
            {pastReviews.map((draft, index) => (
                <ReviewEntry variant='completed' key={index} draft={draft} reviewer={user} />
            ))}
        </Stack>
    );
}

export default PastReviewsCardList;
