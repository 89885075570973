import React from 'react';
import { Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

function VendorSuppliedTooltip() {
    return (
        <Tooltip disableFocusListener title='These items will be designated as "Vendor Supplied" when they appear on printed cards.'>
            <InfoOutlined style={{ opacity: 0.5 }}/>
        </Tooltip>
    )
}

export default VendorSuppliedTooltip;