import React, { useRef } from 'react';
import { styled } from '@mui/system';
import { Tab, TabsList, Tabs, buttonClasses, tabClasses } from '@mui/base';
import { useMediaQuery, useTheme } from '@mui/material';

function CardTabPicker(props) {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const CustomTab = styled(Tab)`
        font: normal normal normal 17px/25px Roboto;
        background-color: transparent;
        color: #000000;
        cursor: pointer;
        width: 100%;
        height: 50px;
        padding: 12px 16px;
        margin: 8px 8px;
        border: none;
        border-radius: 7px;
        display: inline-flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        white-space: nowrap;
        
        &:hover { 
            background-color: #CFD0D2;
        }
        
        &.${buttonClasses.focusVisible} {
            color: #fff;
            outline: none;
            margin: 8px 8px;
            background-color: #80BFFF;
        }

        &.${tabClasses.selected} {
            background-color: #ffffff;
            color: #007AFF;
        }

        &.${buttonClasses.disabled} {
            opacity: 0.5;
            cursor: not-allowed;
    `;

    const CustomTabsList = styled(TabsList)`
        min-width: 320px;
        background-color: #7676801F;
        border-radius: 9px;
        margin-bottom: 16px;
        display: flex;
        @media (max-width: 600px) {
            overflow-x: auto;
            flex-wrap: nowrap;
            padding: 8px;
    `;

    const handleChange = (event, newValue) => {
        props.setSelectedIndex(newValue);
    };
    const selectedTabRef = useRef(null);

    return (
        <Tabs
            value={props.selectedIndex}
            onChange={handleChange}
            variant={isLargeScreen ? 'standard' : 'scrollable'}
            scrollButtons={isLargeScreen ? 'auto' : 'on'}
        >
            <CustomTabsList>
                <CustomTab
                    value={0}
                    ref={props.selectedIndex === 0 ? selectedTabRef : null}
                >
                    Pick List
                </CustomTab>
                <CustomTab
                    value={1}
                    ref={props.selectedIndex === 1 ? selectedTabRef : null}
                >
                    Instruments & Equipment
                </CustomTab>
                <CustomTab
                    value={2}
                    ref={props.selectedIndex === 2 ? selectedTabRef : null}

                >
                    Comments
                </CustomTab>
            </CustomTabsList>
        </Tabs>
    );
}


export default CardTabPicker;

