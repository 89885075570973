import { Stack, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateUserMutation } from '../../api/userSlice';
import { UserContext } from '../Hooks/useUser';
import UserDetails from './UserDetails';
import UserPermissions from './UserPermissions';

function CreateUser() {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const [submitCreateUser] = useCreateUserMutation();
    const [selectedIds,setSelectedIds] = useState([]);
    const [isError, setIsError] = useState(false);

    const permissions = [
        { 
            id: 5, 
            name: 'User Management', 
            description: 'Allows the user to create and edit user accounts',
        },
        { 
            id: 6, 
            name: 'Inventory Management', 
            description: 'Allows the user to edit inventory items',
        },
        { 
            id: 7, 
            name: 'Card Management', 
            description: 'Allows the user to make and approve preference card edits',
        },
        {
            id: 9,
            name: 'Recommendation Reviewer',
            description: 'Allows the user access to the staff review portal for reviewing physician approved recommendations',
        },
        {
            id: 10,
            name: 'Campaign Monitor',
            description: 'Allows the user access to our campaign monitoring tools',
        }
    ];    

    const saveUser = async (data) => {
        try{
            await submitCreateUser({
                hospitalId: user.hospital.id,
                firstName: data.first_name,
                lastName: data.last_name,
                email: data.email,
                roles : selectedIds.map((roleId) => {
                    return permissions.find((permission) => {
                        return permission.id === roleId;
                    });
                })
            }).unwrap()
            navigate('/users');
        }
        catch(error){
            if (error.data.message == 'validation.unique'){
                setIsError(true);
            }
        }
       
    }

    return (
        <Stack spacing={2} px={{ xs: 2, md: 4 }}>
            <Typography variant='heavy' sx={{ fontSize: '20px' }}>Create New User</Typography>
            <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
                <UserDetails
                    saveUser={saveUser}
                    newUser={true}  
                    isSuperUser={false}
                    isError={isError}
                />
                <UserPermissions 
                    showCancelButton={false}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                />
            </Stack>
        </Stack>
    );
}

export default CreateUser;