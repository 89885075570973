import React, { useMemo } from 'react';
import { Stack, Divider, Grid, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { ChevronRightRounded } from '@mui/icons-material';
import { NumericFormat } from 'react-number-format';
import ItemDetail from './ItemDetail';
import LogOnEnter from '../../CustomComponents/LogOnEnter';


function ItemEntry(props) {
    const theme = useTheme();
    const itemHasAcceptedSwap = useMemo(() => {
        if (props.draft.recommendations[props.item.category]) {
            const swapRec = props.draft.recommendations[props.item.category].find((rec) => rec.type === 'swap' && rec.item.id === props.item.id);
            if (swapRec?.status === 'accepted') {
                return true;
            }
        }
        return false;
    }, [props]);

    const itemToDisplay = useMemo(() => {
        if (itemHasAcceptedSwap) {
            const swapRec = props.draft.recommendations[props.item.category].find((rec) => rec.type === 'swap' && rec.item.id === props.item.id);
            return swapRec.recommended_item;
        }
        return props.item;
    }, [props]);


    function handleEdit() {
        props.openItemView(itemToDisplay);
        props.setIsShowingCategoryView(false);
    }

    const yearlyCost = useMemo(() => {
        return (itemToDisplay.cost * props.item.hold_quantity + itemToDisplay.cost * props.item.open_quantity) * props.draft.card.yearly_stats?.usage_count / 100;
    }, [props]);

    return (
        <Grid container p= {1.5} sx={{ backgroundColor: '#FFFFFF', borderRadius: '4px' }} onClick={handleEdit}>
            <Grid item xs={9} sx={{ display: 'grid', alignContent: 'center' }}>
                <LogOnEnter action='viewed_item' data={{ item: itemToDisplay }}>
                    <Stack spacing={0.5} pr={2}>
                        <Box sx={{ fontSize: '12px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '250px' }}>
                            <Typography variant='normal'>
                                {itemToDisplay.name}
                            </Typography>
                        </Box>
                        <Grid container sx={{ fontSize: '12px', color: '#00000099' }}>
                            <Grid item pr={0.5} xs={5} sm='auto' sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                                <Typography variant='normal'>{itemToDisplay.vendor.name}</Typography>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item px={0.5} xs='auto'>
                                <Typography variant='normal'>
                                    <NumericFormat
                                        value={itemToDisplay.cost / 100}
                                        thousandSeparator={true}
                                        displayType={'text'}
                                        prefix={'$'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                </Typography>
                            </Grid>
                            <Divider orientation='vertical' flexItem />
                            <Grid item px={0.5} xs='auto'>
                                <Typography variant='normal'>Open: {props.item.open_quantity}</Typography>
                            </Grid>
                            <Divider orientation='vertical' flexItem />
                            <Grid item px={0.5} xs='auto'>
                                <Typography variant='normal'>Hold: {props.item.hold_quantity}</Typography>
                            </Grid>
                        </Grid>
                        <Stack direction='row' spacing={1}>
                            {
                                itemToDisplay.usage_percentage !== null &&
                                <ItemDetail type='utilization' data={itemToDisplay.usage_percentage} />
                            }
                            {  props.draft.card.yearly_stats && 
                                <ItemDetail type='cost' data={yearlyCost} />
                            }
                        </Stack>
                    </Stack>
                </LogOnEnter>
            </Grid>
            <Grid item xs={3} sx={{ display: 'grid' }}>
                <Stack spacing= {1.5} direction='row' alignItems='center' justifyContent='end'>
                    <Typography variant='normal' sx={{ fontSize: '10px', color: `${theme.palette.subtext.main}` }}>Item Details</Typography>
                    <Box sx={{ display: 'grid', color: 'black', opacity: '0.6' }}>
                        <ChevronRightRounded/>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default ItemEntry;