import { ArrowRightRounded } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, ListItem, Typography } from '@mui/material';
import React from 'react';


function ProcedureList(props) {
    return (
        <Dialog onClose={props.handleClose} open={props.open} >
            <DialogTitle>
                <>Preference Card Description:</><br/>
                <Typography variant='heavy' fontSize='16px' lineHeight='1.3'><strong>{props.cardName}</strong></Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This preference card is designated to be used for the following procedures:
                    {props.procedures.map((procedure) => {
                        return (
                            <ListItem mt={1}>
                                <ArrowRightRounded />
                                <strong>{procedure.name}</strong>
                            </ListItem>
                        );
                    })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={props.handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ProcedureList;
