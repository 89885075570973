import React from 'react';
import { ListItemIcon, ListItemText, Badge } from '@mui/material';
import { ListItemButton } from '@mui/material';
import { TextsmsRounded } from '@mui/icons-material';
import { useGetActiveConversationCountQuery } from '../../api/textingSlice';

function ConversationCount(props) {    
    const { data: conversationCount = { count: 0 } } = useGetActiveConversationCountQuery({ skip: !props.isSuperUser });

    return ( 
        <ListItemButton key={6} selected={props.selectedIndex === 'physicianTexting'} onClick = {props.navigate}>
            <ListItemIcon>
                <Badge badgeContent={conversationCount.count} color="error">
                    <TextsmsRounded />
                </Badge>
            </ListItemIcon>
            <ListItemText primary='Physician Texting'/>
        </ListItemButton>
    )
}

export default ConversationCount;
