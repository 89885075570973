import React from 'react';
import CardManagerRow from './CardManagerRow';
import CardManagerHeader from './CardManagerHeader';
import { useState, useMemo } from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import CardManagerFooter from './CardManagerFooter';


function CardManagersTable(props) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('last_name');

    function descendingComparator(a, b, orderBy) {
        if (orderBy.includes('.')) {
            const keys = orderBy.split('.');
            const aValue = a[keys[0]][keys[1]];
            const bValue = b[keys[0]][keys[1]];
            if (bValue < aValue) {
                return -1;
            }
            if (bValue > aValue) {
                return 1;
            }
            return 0;
        }

        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = useMemo(() => {
        return [...props.cardManagers]
            .sort(getComparator(order, orderBy));
    }, [order, orderBy, props.cardManagers, props.appliedFilters]);

    return (
        <TableContainer sx={{ borderRadius: '8px', border: '2px solid #DDD', backgroundColor: 'white', maxHeight: '100%' }}>
            <Table stickyHeader>
                <CardManagerHeader
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    { visibleRows.map((cardManager) => (
                        <CardManagerRow key={cardManager.id} cardManager={cardManager} numericFormat={props.numericFormat} />
                    ))}
                </TableBody>
                <CardManagerFooter visibleManagers={visibleRows} totalManagers={props.cardManagers.length} numericFormat={props.numericFormat} />
            </Table>
        </TableContainer>
    );
}

export default CardManagersTable;