import {createApi} from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { NotificationActions } from './notificationSlice';

var urlPrefix = '';

switch(process.env.ENVIRONMENT_SETTINGS) {
case 'local':
    urlPrefix = 'http://localhost:8000';
    break;
case 'staging':
    urlPrefix = 'https://staging.docsihealth.com';
    break;
case 'demo':
    urlPrefix = 'https://demo.docsihealth.com';
    break;
case 'production':
    urlPrefix = 'https://api.docsihealth.com';
    break;
}

const buildBaseQuery = () => {
    const query = fetchBaseQuery({
        baseUrl: `${urlPrefix}/api`,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('token');
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            headers.set('accept', 'application/json');
            return headers;
        },
        credentials: 'include'
    });
    return async (args, api, extraOptions) => {
        const { error, data } = await query(args, api, extraOptions);
        if (error) {
            if (error.status === 401) {
                localStorage.removeItem('token');
                window.location.href = '/login';
            }
            return { error: { status: error.status, data: error.data } };
        }
        return { data };
    }
} 

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: buildBaseQuery(),
    tagTypes: ['Case', 'Organization', 'Procedure', 'Card', 'Physician', 'Inventory', 'User', 'UserCards', 'Hospital', 'HospitalUsers', 'Campaign', 'CampaignPreview', 'CampaignDrafts', 'CardOwner', 'CommentCategory', 'AuxiliaryItemCategory', 'Vendor', 'Item', 'ProposedMatch', 'Conversation', 'OrgUsers'],
    endpoints: (builder) => ({
        getOrganization: builder.query({
            query : (organizationId) => `/organizations/${organizationId}`
        }),
        getOrganizations: builder.query({
            query : () => '/organizations',
            providesTags: (result = [], error, arg) => [
                'Organization',
                ...result.map(({ id }) => ({ type: 'Organization', id }))
            ]
        }),
        getHospital: builder.query({
            query : (hospitalId) => `/hospitals/${hospitalId}`
        }),
        getHospitalsInOrganization: builder.query({
            query : (organizationId) => `/organizations/${organizationId}/hospitals`,
            providesTags: (result = [], error, arg) => [
                'Hospital',
                ...result.map(({ id }) => ({ type: 'Hospital', id }))
            ]
        }),
        getHospitalPhysicians: builder.query({
            query : (hospitalId) => `/hospitals/${hospitalId}/cardOwners`
        }),

        getPartnerConfigs :  builder.query({
            query : () => '/partnerConfigs'
        }),

        generateLink : builder.mutation({
            query : ({campaign, cardOwner }) => ({
                url: `/campaigns/${campaign}/cardOwners/${cardOwner}/dashboardLink`,
                method: 'POST',
            })
        }),

        createHospital: builder.mutation({
            query: ({ organizationId, data }) => ({
                url: `/organizations/${organizationId}/hospitals`,
                method: 'POST',
                body: data
            }),
            async onQueryStarted({organizationId, data}, { dispatch, queryFulfilled }) {
                try {
                    const { data: newHospital } = await queryFulfilled
                    dispatch(
                        apiSlice.util.updateQueryData('getOrganizations', undefined, draft => {
                            const orgIndex = draft.findIndex((org) => org.id === organizationId);
                            draft[orgIndex].hospitals.push(newHospital);
                        })
                    )
                } catch {}
            }
        }),

        updateHospital: builder.mutation({
            query: ({ hospitalId, formData }) => ({
                url: `/hospitals/${hospitalId}`,
                method: 'PATCH',
                body: formData
            }),

            async onQueryStarted({ hospitalId, data }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedHospital } = await queryFulfilled;
                    const result = dispatch(
                        apiSlice.util.updateQueryData('getHospitals', hospitalId.toString(), draft => {
                            Object.assign(draft, updatedHospital);
                        })
                    )
                } catch {}
            }
        }),

        createOrganization: builder.mutation({
            query: ({ data }) => ({
                url: '/organizations',
                method: 'POST',
                body: data
            }),
            async onQueryStarted({ data }, { dispatch, queryFulfilled }) {
                try {
                    const { data: newOrg } = await queryFulfilled;
                    dispatch(
                        apiSlice.util.updateQueryData('getOrganizations', undefined, draft => {
                            draft.push(newOrg);
                        })
                    )
                } catch {}
            }
        }),

        updateOrganization: builder.mutation({
            query: ({ organizationId, data }) => ({
                url: `/organizations/${organizationId}`,
                method: 'PATCH',
                body: data
            }),
            async onQueryStarted({ organizationId, data }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedOrg } = await queryFulfilled;
                    dispatch(
                        apiSlice.util.updateQueryData('getOrganizations', undefined, draft => {
                            const orgIndex = draft.findIndex((org) => org.id === organizationId);
                            draft[orgIndex] = updatedOrg;
                        })
                    )
                } catch {}
            }
        }),

        getProcedures: builder.query({
            query: () => '/procedures',
            providesTags: (result, error, arg) => [
                'Procedure',
                ...result.map(({ id }) => [{ type: 'Procedure', id }])
            ]
        }),
        changeSuperUserHospital: builder.mutation({
            query: ({ user, hospital_id }) => ({
                url: `/users/${user.id}`,
                method: 'PATCH',
                body: {
                    current_hospital_id: hospital_id
                }
            })
        }),
        getIngestionToken: builder.query({
            query : () => '/ingestionToken'
        }),
        getItemServiceToken: builder.query({
            query: () => '/itemServiceToken'
        }),
        getHospitalReviewers: builder.query({
            query: (hospitalId) => `/hospitals/${hospitalId}/reviewers`
        }),
        deleteUserAssignment: builder.mutation({
            query: ({ card, reviewer }) => ({
                url: '/reviewAssignments',
                method: 'DELETE',
                body: {
                    card_id: card.id,
                    assigned_user_id: reviewer.id
                }
            }),
            async onQueryStarted({ card, reviewer }, { dispatch, queryFulfilled, queryRejected }) {
                const result = dispatch(
                    apiSlice.util.updateQueryData('getHospitalReviewers', reviewer.hospital.id, reviewerData => {
                        const reviewerIndex = reviewerData.findIndex((rev) => rev.id === reviewer.id);
                        reviewerData[reviewerIndex].assigned_cards = reviewerData[reviewerIndex].assigned_cards.filter((c) => c.id !== card.id);
                    })
                )
                try {
                    await queryFulfilled;
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'Reviewer removed successfully.'
                    }));
                } catch (error) {
                    result.undo();
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Could not remove reviewer at this time. Please try again later.'
                    }));
                }
            }
        }),
        createUserAssignment: builder.mutation({
            query: ({ card, reviewer }) => ({
                url: '/reviewAssignments',
                method: 'POST',
                body: {
                    card_id: card.id,
                    assigned_user_id: reviewer.id
                }
            }),
            async onQueryStarted({ card, reviewer }, { dispatch, queryFulfilled, queryRejected }) {
                const result = dispatch(
                    apiSlice.util.updateQueryData('getHospitalReviewers', reviewer.hospital.id, reviewerData => {
                        const reviewerIndex = reviewerData.findIndex((rev) => rev.id === reviewer.id);
                        reviewerData[reviewerIndex].assigned_cards.push(card);
                    })
                )
                try {
                    await queryFulfilled;
                    dispatch(NotificationActions.addSuccessNotification({
                        message: 'Reviewer assigned successfully.'
                    }));
                } catch (error) {
                    result.undo();
                    dispatch(NotificationActions.addErrorNotification({
                        message: 'Could not assign reviewer at this time. Please try again later.'
                    }));
                }
            }
        }),
        logUserAction: builder.mutation({
            query: ({ action, data }) => ({
                url: '/userActions',
                method: 'POST',
                body: {
                    action: action,
                    data: data
                }
            })
        }),
    }),
});

export const { 
    useGetOrganizationQuery,
    useGetHospitalQuery,
    useGetPartnerConfigsQuery,
    useGetOrganizationsQuery,
    useGetHospitalsInOrganizationQuery,
    useUpdateHospitalMutation,
    useGetHospitalPhysiciansQuery,
    useGenerateLinkMutation,
    useCreateHospitalMutation,
    useCreateOrganizationMutation,
    useUpdateOrganizationMutation,
    useGetProceduresQuery,
    useChangeSuperUserHospitalMutation,
    useGetIngestionTokenQuery,
    useGetItemServiceTokenQuery,
    useGetHospitalReviewersQuery,
    useDeleteUserAssignmentMutation,
    useCreateUserAssignmentMutation,
    useLogUserActionMutation,
} = apiSlice
