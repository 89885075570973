import React, { useState } from 'react';
import { Stack, Button } from '@mui/material';
import { Upload, Add } from '@mui/icons-material';
import AddDetailDialog from './AddDetailDialog';
import UploadImage from '../CustomComponents/UploadImage';
import { useAddItemImageMutation } from '../../api/itemDetailSlice';

function AddItemDetailsActions(props) {
    const [isAddingImage, setIsAddingImage] = useState(false);
    const [isAddingDetail, setIsAddingDetail] = useState(false);

    function handleAddImageClick() {
        setIsAddingImage(true);
    }

    function handleAddDetailClick() {
        setIsAddingDetail(true);
    }

    const [addItemImage, { isLoading: isUploadingImage }] = useAddItemImageMutation();

    const uploadImage = async(formData) => {
        await addItemImage({
            itemId: props.item.id,
            formData: formData
        });
    }

    return (
        <Stack spacing={2} width='100%'>
            <Button 
                variant='contained' 
                onClick={handleAddDetailClick}
                startIcon={<Add />}
            >
                Add Detail
            </Button>
            <AddDetailDialog open={isAddingDetail} setOpen={setIsAddingDetail} item={props.item} />
            <Button 
                variant='contained' 
                onClick={handleAddImageClick}
                startIcon={<Upload />}
            >
                Add Image
            </Button>
            <UploadImage open={isAddingImage} setOpen={setIsAddingImage} uploadImage={uploadImage} isUploading={isUploadingImage} />
        </Stack>
    )
}

export default AddItemDetailsActions;