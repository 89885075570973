import { Stack } from '@mui/material';
import React from 'react';
import OptimizationContextBullet from './OptimizationContextBullet';

function OptimizationContext(props) {
    const context = props.recommendation.context;
    const recType = props.recommendation.type;
    const isQuantityIncrease = recType === 'update' && (props.item.open_quantity + props.item.hold_quantity) < (props.recommendation.open_quantity + props.recommendation.hold_quantity);
    const isQuantityDecrease = recType === 'update' && (props.item.open_quantity + props.item.hold_quantity) > (props.recommendation.open_quantity + props.recommendation.hold_quantity);

    function shouldShowDownTrendingUsage() {
        if (context['predicted_usage'] === undefined) return false;

        return (recType === 'removal' && context['predicted_usage'] <= 0.3) ||
            (isQuantityDecrease && context['predicted_usage'] < props.item.open_quantity + props.item.hold_quantity);
    }

    function shouldShowUpTrendingUsage() {
        if (context['predicted_usage'] === undefined) return false;

        return recType === 'addition' || 
            (isQuantityIncrease && context['predicted_usage'] > props.item.open_quantity + props.item.hold_quantity);
    }

    function shouldShowHighUsagePercentage() {
        if (context['usage_percentage'] === undefined) return false;

        return (recType === 'addition' && context['usage_percentage'] >= 0.55) || 
            (isQuantityIncrease && context['usage_percentage'] >= 0.55);
    } 

    function shouldShowLowUsagePercentage() {
        if (context['usage_percentage'] === undefined) return false;

        return (recType === 'removal' && context['usage_percentage'] <= 0.45) ||
            (isQuantityDecrease && context['usage_percentage'] <= 0.45);
    }

    function shouldShowLowUtilization() {
        if (context['low_utilization'] === undefined) return false;

        return true;
    }

    function shouldShowPickedPercentage() {
        if (context['picked_percentage'] === undefined) return false;
        return true;
    }

    function shouldShowPeerUsage() {
        if (context['average_peer_usage'] === undefined) return false;
        if (recType === 'addition' && context['average_peer_usage'] < 0.5) return false;
        return true;
    }

    function shouldShowCommonItem() {
        if (context['procedure_prevalence'] === undefined) return false;
        return (recType === 'addition' && context['procedure_prevalence'] >= 0.66) ||
            (isQuantityIncrease && context['procedure_prevalence'] >= 0.66);
    }

    function shouldShowOutlier() {
        if (context['procedure_prevalence'] === undefined) return false;
        return (recType === 'removal' && context['procedure_prevalence'] <= 0.33) || 
            (isQuantityDecrease && context['procedure_prevalence'] <= 0.33);
    }

    function shouldShowAverageUsage() {
        if (context['average_usage'] === undefined) return false;
        return (isQuantityIncrease || recType === 'addition');
    }

    function shouldShowElevatedItemSpend() {
        if (context['percentage_above_average'] === undefined) return false;
        return true;
    }
    
    function shouldShowElevatedCategorySpend() {
        if (context['percentage_above_category_average'] === undefined) return false;
        return true;
    }

    function shouldShowLowItemSpend() {
        if (context['percentage_below_average'] === undefined) return false;
        return isQuantityIncrease ;
    }

    function shouldShowLowCategorySpend() {
        if (context['percentage_below_category_average'] === undefined) return false;
        return isQuantityIncrease;
    }

    return (
        <Stack spacing={1} py={1.5}>
            { shouldShowDownTrendingUsage() &&
                <OptimizationContextBullet type='down_trending_usage' />
            }
            { shouldShowUpTrendingUsage() &&
                <OptimizationContextBullet type='up_trending_usage' />
            }
            { shouldShowHighUsagePercentage() &&
                <OptimizationContextBullet type='high_utilization' data={context['usage_percentage']} />
            }
            { shouldShowLowUsagePercentage() &&
                <OptimizationContextBullet type='low_utilization' data={context['usage_percentage']} />
            }
            { shouldShowLowUtilization() &&
                <OptimizationContextBullet type='low_utilization' data={context['low_utilization']} />
            }
            { shouldShowPickedPercentage() &&
                <OptimizationContextBullet type='picked_percentage' data={context['picked_percentage']} />
            }
            { shouldShowPeerUsage() &&
                <OptimizationContextBullet type='peer_usage' data={context['average_peer_usage']} />
            }
            { shouldShowCommonItem() &&
                <OptimizationContextBullet type='commonly_used' data={context['procedure_prevalence']} />
            }
            { shouldShowOutlier() &&
                <OptimizationContextBullet type='outlier' data={context['procedure_prevalence']} />
            }
            { shouldShowAverageUsage() &&
                <OptimizationContextBullet type='high_average_usage' data={context['average_usage']} />
            }
            { shouldShowElevatedItemSpend() &&
                <OptimizationContextBullet type='elevated_item_spend' data={context['percentage_above_average']} />
            }
            { shouldShowElevatedCategorySpend() &&
                <OptimizationContextBullet type='elevated_category_spend' data={{ value: context['percentage_above_category_average'], category: props.item.category }} />
            }
            { shouldShowLowItemSpend() &&
                <OptimizationContextBullet type='low_item_spend' data={context['percentage_below_average']} />
            }
            { shouldShowLowCategorySpend() &&
                <OptimizationContextBullet type='low_category_spend' data={{ value: context['percentage_below_category_average'], category: props.item.category }} />
            }
        </Stack>
    )
}

export default OptimizationContext;
