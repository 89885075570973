import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Stack } from '@mui/material';

function ItemSwapDialog(props) {
    function handleClose() {
        props.setIsShowingDialog(false);
    }

    return (
        <Dialog onClose={handleClose} open={props.isShowingDialog} >
            <DialogTitle>
                Why are these items recommended?
            </DialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                    <Typography variant='heavy' sx={{ fontSize: '16px' }}>
                        <strong>Contract Compliance</strong>
                    </Typography>
                    <Typography variant='normal' sx={{ fontSize: '14px' }}>
                        {'Your facility receives advantageous pricing on pre-negotiated "in-contract" items and is penalized for "out-of-contract" items that are used without prior approval.'}
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogContent>
                <Stack spacing={1}>
                    <Typography variant='heavy' sx={{ fontSize: '16px' }}>
                        <strong>Cost Efficiency</strong>   
                    </Typography>
                    <Typography variant='normal' sx={{ fontSize: '14px' }}>
                        A lower cost alternative item may be recommended to replace a more expensive item if the two share similar features and functionality.
                    </Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default ItemSwapDialog;
