import { BoltRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Badge, Stack, styled, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import LogOnEnter from '../../CustomComponents/LogOnEnter';

function CategoryList(props) {

    const categoriesWithOptimizations = Object.keys(props.draft.recommendations).filter((category) => {
        return props.draft.recommendations[category].length > 0;
    });

    const sortedCategories = categoriesWithOptimizations.sort((a, b) => {
        if (props.draft.recommendations[a].length < props.draft.recommendations[b].length) {
            return 1;
        } else {
            return 0;
        }
    }).concat(Object.keys(props.draft.items).sort().filter((category) => {
        return !categoriesWithOptimizations.includes(category);
    }));

    return (
        <LogOnEnter action='viewed_category_list' data={{ card: props.draft.card }}>
            <Stack sx={{ marginTop: '60px' }}>
                { sortedCategories.map((category) => (
                    <CategoryHeader 
                        key={category}
                        name={category}
                        optimizations={props.draft.recommendations[category]}
                        setCurrentCategory={props.setCurrentCategory}
                        setIsShowingCategoryView={props.setIsShowingCategoryView}
                    />
                ))}
            </Stack>
        </LogOnEnter>
    )
}

function CategoryHeader(props) {
    const theme = useTheme();

    function handleClick() {
        props.setCurrentCategory(props.name);
        props.setIsShowingCategoryView(true);
    }

    const optimizationCount = props.optimizations?.filter((optimization) => {
        return optimization.status === 'open' && optimization.type !== 'swap' && optimization.types !== 'compliance';
    }).length ?? 0;

    return (
        <Stack 
            direction='row' 
            alignItems='start' 
            justifyContent='space-between'
            px={2}
            py={3}
            onClick={handleClick}
            sx={{ 
                borderRadius: '12px',
                marginTop: '-40px',
                minHeight: '60px',
                '&:nth-of-type(odd)': {
                    backgroundColor: `${theme.palette.background.main}`
                },
            
                '&:nth-of-type(even)': {
                    backgroundColor: `${theme.palette.background.itemSwap}`
                },
            }}
        >
            <Stack direction='row' alignItems='center' spacing={1}>
                { optimizationCount > 0 &&
                    <OptimizationBadge optimizationCount={optimizationCount} />
                }
                <Typography variant='normal' sx={{ fontSize: '14px' }}>{props.name}</Typography>
            </Stack>
            <Stack direction='row' spacing={1}>
                <ExpandMoreRounded style={{ color: `${theme.palette.subtext.main}` }}/>
            </Stack>
        </Stack>
    )
}

function OptimizationBadge(props) {

    return (
        <StyledBadge badgeContent={props.optimizationCount} color='primary' anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}>
            <BoltRounded />
        </StyledBadge>
    )
}

const StyledBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
        height: '15px',
        minWidth: '15px',
        borderRadius: '10px',
        left: 3,
        padding: 0,
    }
}));

export default CategoryList;
