import React from 'react';
import { useState } from 'react';
import { EditableDetailBox, StyledDetailBox } from '../Card/DetailBox';
import { Edit } from '@mui/icons-material';
import { IconButton, Stack, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/system';
import CreateAutocomplete from '../CustomComponents/CreateAutocomplete';
import { useGetItemCategoriesQuery } from '../../api/inventorySlice';
import { useUpdateItemMutation } from '../../api/itemDetailSlice';

function CategoryDetail(props) {
    const [isEditing, setIsEditing] = useState(false);
    const [category, setCategory] = useState(props.item.category);
    const theme = useTheme();
    const [updateItem] = useUpdateItemMutation();

    function handleCancel() {
        setCategory(props.item.category);
        setIsEditing(false);
    }

    const handleSaveClick = async() => {
        var requestCategory = category;
        if (typeof(category) !== 'string') {
            requestCategory = category.name;
        }

        await updateItem({
            item: {
                id: props.item.id,
                category: requestCategory
            }
        }).unwrap().then((payload) => {
            setCategory(payload.category);
        });
        setIsEditing(false);
    }

    if (props.canEdit && !isEditing) {
        return (
            <EditableDetailBox p={2}>
                <IconButton onClick={() => setIsEditing(true)} aria-label='edit' size='small' className='editIcon' sx={{ display: { xs: 'initial', lg: 'none' }  }}>
                    <Edit fontSize='small'/>
                </IconButton>
                <Stack spacing={1}>
                    <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>{props.title}</Typography>
                    <Typography variant='heavy' sx={{ fontSize: '14px' }}>{category}</Typography>
                </Stack>
            </EditableDetailBox>
        )
    } else if (props.canEdit && isEditing) {
        return (
            <EditableDetailBox p={2}>
                <IconButton onClick={() => setIsEditing(true)} aria-label='edit' size='small' zIndex = '20' className='editIcon' sx={{ display: 'none' }}>
                    <Edit fontSize='small'/>
                </IconButton>
                <Stack spacing={1}>
                    <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>{props.title}</Typography>
                    <CreateAutocomplete
                        label='Category'
                        value={category}
                        setValue={setCategory}
                        loadOptions={() => useGetItemCategoriesQuery(props.item.hospital.id)}
                    />
                    <Stack direction='row' spacing={2} justifyContent='end'>
                        <Button variant='text' onClick={handleCancel} style={{ color: `${theme.palette.subtext.main}` }}>Cancel</Button>
                        <Button variant='text' onClick={handleSaveClick} style={{ color: `${theme.palette.blue.heavy}` }}>Save</Button>
                    </Stack>
                </Stack>
            </EditableDetailBox>
        );
    } else {
        return (
            <StyledDetailBox p={2}>
                <Stack spacing={1}>
                    <Typography variant='normal' sx={{ fontSize: '12px', color: `${theme.palette.subtext.main}` }}>{props.title}</Typography>
                    <Typography variant='heavy' sx={{ fontSize: '14px' }}>{category}</Typography>
                </Stack>
            </StyledDetailBox>
        );
    }
}

export default CategoryDetail;