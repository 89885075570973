import { Dialog, DialogContent, DialogTitle, DialogContentText, TextField, DialogActions } from '@mui/material';
import React, { useState } from 'react';
import { Button } from '@mui/material';

function StartNewConversation(props) {
    const [messageText, setMessageText] = useState('');

    function handleSendClick() {
        props.sendMessage(messageText);
        props.setShowNewConversation(false);
    }
    
    return (
        <Dialog open={props.showNewConversation} onClose={() => props.setShowNewConversation(false)}>
            <DialogTitle>Start a Conversation</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To start a conversation with this physician, please enter a message below.
                </DialogContentText>
                <TextField value={messageText} multiline onChange={(e) => setMessageText(e.target.value)} autoFocus margin="dense" id="message" label="Message" type="text" fullWidth />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setShowNewConversation(false)}>Cancel</Button>
                <Button onClick={handleSendClick} disabled={!messageText}>Send</Button>
            </DialogActions>
        </Dialog>
    )
}

export default StartNewConversation;
