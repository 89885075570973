import { EastRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import ecriLogo from '../../../../../assets/ecri_logo_no_background.png'
import { useLogUserActionMutation } from '../../../../api/physicianDashboardSlice';


function AssessmentLink(props) {
    const [logPhysicianAction] = useLogUserActionMutation();

    async function handleClick(event) {
        await logPhysicianAction({
            action: 'opened_ecri_evidence_pdf',
            data: {
                item: props.item
            },
        });
        sessionStorage.setItem('complianceIndex', props.currentIndex);
        sessionStorage.setItem('exitedViaAssessmentPDF', 'true');
        self.location.href = props.item.ecri_assessment.link;
        event.stopPropagation();
    }

    return (
        <Stack onClick={handleClick} style={{ cursor: 'pointer' }}>
            <Stack direction='row' justifyContent='space-between' borderRadius='4px' backgroundColor='#00000033' maxHeight='30px'>
                <Stack p={0.5}>
                    <img src={ecriLogo} alt='ECRI' width='50px' height='22px' />
                </Stack>
                <Stack direction='row' alignItems='center' spacing={0.5} p={1}>
                    <Typography variant='heavy' fontSize='14px'>Full Clinical Evidence Assessment</Typography>
                    <EastRounded fontSize='small' />
                </Stack>
            </Stack>
        </Stack>
    );
}

export default AssessmentLink;
