import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import React from 'react';


function TextingConfirmationDialog(props) {
    return (
        <Dialog open={props.open} onClose={props.onCancel}>
            <DialogTitle>
                Confirm Bulk Text Message
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to send this text message to all selected physicians?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel}>
                    Cancel
                </Button>
                <Button onClick={props.onConfirm}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default TextingConfirmationDialog;
