import { Grid, Stack } from '@mui/material';
import React from 'react';
import { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAnalytics from '../Hooks/useAnalytics';
import Headline from './Headline/Headline';
import InvalidToken  from './InvalidToken';
import usePhysicianDashboardUser from '../Hooks/usePhysicianDashboardUser';
import { useGetCampaignQuery } from '../../api/physicianDashboardSlice';
import LoadingIndicator from '../LoadingIndicator';

function PhysicianDashboard() {
    const analytics = useAnalytics();
    const { dashboardUser: cardOwner } = usePhysicianDashboardUser();
    const {campaignId} = useParams();
    const {
        data: campaign = {},
        isLoading: campaignLoading,
        isSuccess: campaignSuccess,
    } = useGetCampaignQuery(campaignId);
    
    useEffect(() => {
        const cardListScrollPosition = sessionStorage.getItem('cardListScrollPosition');
        if (cardListScrollPosition) {
            window.scrollTo(0, parseInt(cardListScrollPosition, 10));
            sessionStorage.removeItem('cardListScrollPosition');
        }
    }, []);

    useMemo(() => {
        if (cardOwner) {
            analytics.identify('owner-' + cardOwner.id, {
                'name': cardOwner.name,
                'specialty': cardOwner.specialty,
            });
            analytics.group(cardOwner.hospital.id);
        }
    }, [cardOwner]);

    if(!cardOwner){
        return(
            <InvalidToken resendButton={true} /> 
        )
    }

    else{
        return (
            campaignLoading ? 
                <LoadingIndicator /> :
                <Grid container sx={{ backgroundColor: '#EDEDED' }}>
                    <Grid item md={3} />
                    <Grid item xs={12} md={6}>
                        <Stack sx={{ backgroundColor: '#EDEDED' }}>
                            { campaignSuccess && 
                                <Headline cardOwner={cardOwner} campaign={campaign}/>
                            }
                        </Stack>
                    </Grid>
                </Grid>
        );
    }
}


export default PhysicianDashboard;