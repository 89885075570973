import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';


function TermsOfServiceDialog(props) {
    return (
        <Dialog open={props.open} onClose={props.handleClose} maxWidth={'sm'} fullWidth sx={{
            '& .MuiDialog-container': {
                alignItems: 'flex-start',
                paddingTop : '100px'
            }
        }}>
            <DialogTitle>Terms of Service</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <ol>
                        <li>
                            DOCSI is a healthcare software company focused on optimizing surgical spend by reducing waste in operating room environments. This demo service is meant to provide a glimpse at the capabilities of DOCSI’s software. Users who opt into the demo process will receive a one-time, temporarily active link to access a demo version of the DOCSI Dashboard. The link’s access will expire after no more than 30 days at which point, you can request a new link by filling out the demo submission form again. 
                        </li>
                        <li>
                            {'You can cancel the SMS service at any time. Just text "STOP" to the short code. After you send the SMS message "STOP" to us, we will send you an SMS message to confirm that you have been unsubscribed. After this, you will no longer receive SMS messages from us. If you want to join again, just sign up as you did the first time and we will start sending SMS messages to you again.'}
                        </li>
                        <li>
                            If you are experiencing issues with the messaging program you can reply with the keyword HELP for more assistance, or you can get help directly at support@docsihealth.com.
                        </li>
                        <li>
                            Carriers are not liable for delayed or undelivered messages.
                        </li>
                        <li>
                            As always, message and data rates may apply for any messages sent to you from us and to us from you. You will receive one message per instance of the demo link requested. If you have any questions about your text plan or data plan, it is best to contact your wireless provider.
                        </li>
                        <li>
                            If you have any questions regarding privacy, please read our privacy policy: <a href='https://www.docsihealth.com/privacy-policy'>https://www.docsihealth.com/privacy-policy</a>
                        </li>
                    </ol>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TermsOfServiceDialog;