import React, { useState, useEffect } from 'react';
import { Stack, Button } from '@mui/material';
import { useTheme } from '@mui/system';
import HeadlineCardList from '../Headline/Headline Cards/HeadlineCardList';
import TaskHeader from './TaskHeader';
import useAnalytics from '../../Hooks/useAnalytics';
import { useMemo } from 'react';

function ReviewCardsTask(props) {
    const theme = useTheme();
    const analytics = useAnalytics();
    const [isShowingAllCards, setIsShowingAllCards] = useState(!props.campaignHasOptimizations);

    useEffect(() => {
        if (sessionStorage.getItem('headlineCardList') === 'expanded') {
            setIsShowingAllCards(true);
        }
    }, []);

    const topThreeDrafts = props.drafts.slice()
        .sort((a, b) => b.cost_reduction_opportunity - a.cost_reduction_opportunity)
        .sort((a, b) => b.impact_score - a.impact_score)
        .slice(0, 3);

    const visibleDrafts = useMemo(() => {
        if (!props.campaignHasOptimizations) {
            return props.drafts.slice().sort((a, b) => 
                (b.card.yearly_stats?.usage_count ?? 1) - (a.card.yearly_stats?.usage_count ?? 1)
            );
        }

        return isShowingAllCards 
            ? props.drafts.slice().sort((a, b) => b.cost_reduction_opportunity - a.cost_reduction_opportunity)
            : topThreeDrafts;
    }, [topThreeDrafts, isShowingAllCards, props.drafts, props.campaignHasOptimizations]);

    const taskCount = useMemo(() => {
        return visibleDrafts.filter((draft) => draft.status !== 'submitted').length
    }, [visibleDrafts])

    function handleButtonClick() {
        setIsShowingAllCards(!isShowingAllCards);
        sessionStorage.setItem('headlineCardList', isShowingAllCards ? 'collapsed' : 'expanded');
        analytics.track('All Cards Toggled', {
            sector: 'Physician Dashboard'
        })
    }

    return (
        <Stack spacing={2}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <TaskHeader title={isShowingAllCards ? 'Review All Preference Cards' : 'Review Least Efficient Cards'} taskCount={taskCount} shouldShowCount={!isShowingAllCards} />
                { props.campaignHasOptimizations &&
                    <Button onClick={handleButtonClick} variant='text' sx={{ color: `${theme.palette.subtext.main}` }}>
                        {isShowingAllCards ? 'View Top Cards' : 'All Cards'}
                    </Button>
                }
            </Stack>
            <HeadlineCardList currentUser={props.cardOwner} drafts={visibleDrafts} campaignHasOptimizations={props.campaignHasOptimizations}/>
        </Stack>
    );
}

export default ReviewCardsTask;