import { Button, Paper, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateHospitalMutation } from '../../../api/apiSlice';
import HospitalSiteDetails from './HospitalSiteDetails';
import HospitalSiteFeatures from './HospitalSiteFeatures';
import HospitalSiteSettings from './HospitalSiteSettings';

function CreateHospital() {
    let { state } = useLocation();
    const [files, setFiles] = useState([]);
    const [hospitalData, setHospitalData] = useState({
        name: '',
        organization_id: state.organizationId ? state.organizationId : '',
        high_impact_threshold: 0.5,
        configurations: []
    });
    const [submitCreateHospital] = useCreateHospitalMutation();
    const navigate = useNavigate();

    const handleSave = async() => {
        var requestData;
        if (files.length > 0) {   
            // Handle Form Data Request for Logo File
            requestData = new FormData();
            requestData.append('name', hospitalData.name);            
            requestData.append('high_impact_threshold', hospitalData.high_impact_threshold);
            requestData.append('configurations', JSON.stringify(hospitalData.configurations));
            requestData.append('logo', files[0]);
        } else {
            // Remove logo string from request since there is no change.
            delete hospitalData.logo;
            requestData = hospitalData;
        }

        var response = await submitCreateHospital({
            organizationId: hospitalData.organization_id,
            data: requestData
        });
        navigate('/superSettings/hospitals/' + response.data.id);
    }

    return (
        <Stack spacing={3} pl={4} pb={4} maxWidth='75%'>
            <Typography variant='heavy' sx={{ fontSize: '20px' }}>Create New Hospital</Typography>
            <Stack >
                <Paper elevation={2}>
                    <HospitalSiteDetails 
                        hospitalData={hospitalData}
                        setHospitalData={setHospitalData}
                        isCreating={true}
                        files={files}
                        setFiles={setFiles}
                    />
                    <HospitalSiteSettings
                        hospitalData={hospitalData}
                        setHospitalData={setHospitalData}
                    />
                    <HospitalSiteFeatures
                        hospitalData={hospitalData}
                        setHospitalData={setHospitalData}
                    />
                </Paper>
            </Stack>
            <Stack direction='row'>
                <Button variant='contained' disabled={!hospitalData.name || !hospitalData.organization_id} onClick={handleSave}>
                Save
                </Button>
            </Stack>
        </Stack>
    );
}

export default CreateHospital;