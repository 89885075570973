import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useDeleteItemImageMutation, useUpdateItemDetailsMutation } from '../../../api/itemDetailSlice';
import Linkify from 'linkify-react';
import Assessment from './ECRI/Assessment';
import LogOnEnter from '../../CustomComponents/LogOnEnter';

function ItemDetailPanel(props) {
    const [deleteItemImage] = useDeleteItemImageMutation();

    const handleDeleteImage = async(image) => {
        await deleteItemImage({
            image: image
        });
    }

    return (
        <Stack role='tabpanel' divider={<Divider />} sx={{ display: props.isActive ? '' : 'none', overflowX: 'hidden' }}>
            <Stack>
                <LogOnEnter action='viewed_item_details' data={{ item: props.item }}>
                    <DetailBox title='Name' content={props.item.name} item={props.item} canEdit={props.canEdit} />
                    <DetailBox title='Manufacturer' content={props.item.vendor.name} item={props.item} canEdit={props.canEdit} />
                    <DetailBox title='Part Number' content={props.item.part_number} item={props.item} canEdit={props.canEdit} />
                </LogOnEnter>
            </Stack>
            { props.item.images?.map((image) => (
                <Stack direction='row' justifyContent='space-between' alignItems='center' key={image.id}>
                    <Stack p={2} spacing={0.5} width={{xs:'100%', sm: '65%', md: '40%'}} >
                        <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>Image</strong></Typography>
                        <img src={image.presigned_url} width='100%' style={{ objectFit: 'contain' }} alt='Item in consideration'/>
                    </Stack>
                    { props.canEdit && 
                        <Button variant='outlined' color='error' onClick={() => handleDeleteImage(image)}>Delete</Button>
                    }
                </Stack>
            ))}
            { props.item.details && Object.entries(props.item.details).map(([key, value]) => (
                <Stack key={key}>
                    <DetailBox title={key} content={value} item={props.item} canEdit={props.canEdit} />
                </Stack>
            ))}
            { props.item.ecri_assessment && 
                <Assessment className='ecri-assessment' item={props.item} currentIndex={props.currentIndex} tab={props.tab} />
            }
        </Stack>
    );
}

function DetailBox(props) {
    const [isEditing, setIsEditing] = useState(false);
    const [title, setTitle] = useState(props.title);
    const [content, setContent] = useState(props.content);
    const [updateItemDetails] = useUpdateItemDetailsMutation();

    function handleEditClick() {
        setIsEditing(true);
    }

    function handleCancelClick() {
        setIsEditing(false);
    }

    const handleDeleteClick = async() => {
        var details = Object.assign({}, props.item.details);
        delete details[props.title];

        await updateItemDetails({
            item: props.item,
            details: details
        });
    }

    const handleSave = async() => {
        var details = Object.assign({}, props.item.details);
        if (!(title in details)) {
            delete details[props.title];
        }
        
        details[title] = content;
        
        await updateItemDetails({
            item: props.item,
            details: details
        });
        setIsEditing(false);
    }

    return (
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack p={2} spacing={0.5}>
                { isEditing ? 
                    <>
                        <TextField fullWidth value={title} onChange={(e) => setTitle(e.target.value)} />
                        <TextField multiline fullWidth value={content} sx={{ whiteSpace: 'pre-line' }} onChange={(e) => setContent(e.target.value)} />
                    </>
                    :
                    <>
                        <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>{props.title}</strong></Typography>
                        <Typography variant='normal' sx={{ fontSize: '12px' }}>
                            <pre style={{ fontFamily: 'inherit', whiteSpace: 'pre-line', marginTop: '4px', marginBottom: '4px' }}>
                                <Linkify options={{ target: 'blank' }}>
                                    {props.content}
                                </Linkify>
                            </pre>
                        </Typography>
                    </>   
                }
            </Stack>
            { props.canEdit && !isEditing && 
                <Stack direction='row' spacing={2}>
                    <Button variant='outlined' color='error' onClick={handleDeleteClick}>Delete</Button>
                    <Button variant='contained' onClick={handleEditClick}>Edit</Button>
                </Stack>
            }
            { props.canEdit && isEditing && 
                <Stack direction='row' spacing={2}>
                    <Button variant='outlined' onClick={handleCancelClick}>Cancel</Button>
                    <Button variant='contained' onClick={handleSave}>Save</Button>
                </Stack>
            }
        </Stack>
    );
}

export default ItemDetailPanel;