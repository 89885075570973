import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useLogUserActionMutation } from '../../api/staffReviewSlice';

function CheckoutTimeoutDialog(props) {
    const [logUserAction] = useLogUserActionMutation();

    function handleRefresh() {
        logUserAction({
            action: 'refreshed_checkout_timeout_dialog',
            data: {
                draft: props.draft,
            }
        });
        props.handleRefresh();
    }

    return (
        <Dialog
            open={props.open}
        >
            <DialogTitle>Review Session Timed Out</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Your review session for this card has been automatically ended due to inactivity. Please click the refresh button below to continue working.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRefresh} color='primary'>
                    Refresh
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CheckoutTimeoutDialog;
