import { Dialog, DialogContentText, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import React from 'react';
import { useLogUserActionMutation } from '../../api/staffReviewSlice';


function CheckedOutCardDialog(props) {
    const [logUserAction] = useLogUserActionMutation();

    function handleReturnToActiveReviews() {
        logUserAction({
            action: 'declined_to_view_checked_out_card',
            data: {
                draft: props.draft,
            }
        });
        props.returnToActiveReviews();
    }

    function handleContinueToReadOnly() {
        logUserAction({
            action: 'continued_to_view_checked_out_card',
            data: {
                draft: props.draft,
            }
        });
        props.handleClose();
    }
    
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Card Currently in Review</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This card is currently being reviewed by another staff member so you won't be able to make any changes right now. You can continue reviewing other cards in the meantime and feel free to check back again later.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleReturnToActiveReviews} color="primary">
                    Back to Other Cards
                </Button>
                <Button onClick={handleContinueToReadOnly} color="primary">
                    Continue Viewing (Read Only)
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CheckedOutCardDialog;
