import React from 'react';
import { Stack, Typography } from '@mui/material';

function ChangeType(props) {
    if (!props.recommendation || props.recommendation.review.length === 0) {
        return (
            <>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>No Change</strong></Typography>
            </>
        )
    }
    if ((props.recommendation.type === 'removal' && props.recommendation.status === 'accepted') || props.recommendation.status === 'custom_removal') {
        return (
            <Stack alignItems='center'>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>Remove Item</strong></Typography>
            </Stack>
        )
    }
    if ((props.recommendation.type === 'update' && props.recommendation.status === 'accepted') || props.recommendation.status === 'custom_update') {
        return (
            <>
                <Stack>
                    <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>Change Quantity</strong></Typography>
                </Stack>
            </>
        )
    }
    if (props.recommendation.type === 'addition' && props.recommendation.status === 'accepted') {
        return (
            <>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>Add Item</strong></Typography>
            </>
        )
    }
    if (((props.recommendation.type === 'swap' || props.recommendation.type === 'compliance') && props.recommendation.status === 'accepted') || props.recommendation.status === 'custom_swap'){
        return (
            <>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>Swap Item</strong></Typography>
            </>
        )
    }
    return (
        <>
            <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>No Change</strong></Typography>
        </>
    );
}

export default ChangeType;
