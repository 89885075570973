import React from 'react';
import {Select, FormControl,InputLabel, MenuItem, ListSubheader } from '@mui/material';
import { apiSlice, useChangeSuperUserHospitalMutation, useGetOrganizationsQuery } from '../api/apiSlice';
import { UserContext } from './Hooks/useUser';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/system';
import { useContext } from 'react';

function HospitalDropdown(){
    const { user, setUser } = useContext(UserContext);
    const dispatch = useDispatch();
    const [updateSuperUser] = useChangeSuperUserHospitalMutation();
    
    const { 
        data: organizations = [], 
    } = useGetOrganizationsQuery();

    const sortedOrganizations = organizations.slice().sort((a, b) => a.name.localeCompare(b.name));

    const hospitalsByOrganization = organization => {
        const sortedHospitals = organization.hospitals.slice().sort((a, b) => a.name.localeCompare(b.name));
        const hospitals = sortedHospitals.map(hospital => {
            return (
                <MenuItem sx={{ pl: 3, fontSize: 14 }} key={hospital.id} value={hospital.id}>
                    {hospital.name}
                </MenuItem>
            );
        });
        return [<ListSubheader sx={{fontSize: 14 }}> {organization.name} </ListSubheader>, hospitals];
    };

    const handleChangeDropdown = async(selectedHospitalId) => {
        await updateSuperUser({
            user: user,
            hospital_id: selectedHospitalId
        }).unwrap().then((payload) => {
            setUser({
                ...user,
                hospital: payload.hospital
            });
        });
        dispatch(apiSlice.util.invalidateTags(['Card', 'ProposedMatch']));
    };

    return ( 
        <Box p={2}>
            <FormControl sx={{ width: '100%', height: '100%'}}>
                <InputLabel>Select A Hospital</InputLabel>
                <Select
                    value={user.hospital?.id}
                    label="Select A Hospital"
                    onChange={(e) => handleChangeDropdown(e.target.value)}
                    fullWidth
                >
                    {sortedOrganizations.map(organization => hospitalsByOrganization(organization))}
    
                </Select>
            </FormControl>    
        </Box>
        
    );
}

export default HospitalDropdown;