import { Check, Close } from '@mui/icons-material';
import { Button, Dialog, Link, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useResetPhysicianDashboardTokenMutation } from '../../api/physicianDashboardSlice';
import { theme } from '../../theme';

function InvalidToken(props){
    const [ buttonDisabled, setButtonDisabled ] = useState(false);
    const [ isError, setIsError ] = useState(false);

    const [ resetToken ] = useResetPhysicianDashboardTokenMutation();

    const handleResendToken = async () => {
        await resetToken({
            token: localStorage.getItem('dashboardToken'),
        }).unwrap().then(() => {
            setIsError(false);
        }).catch(() => {
            setIsError(true);
        });
        setButtonDisabled(true);
    }

    return (
        <Dialog open>
            <Stack p={4} spacing={2} open>
                <Typography variant='bold' sx={{ fontSize: '1.25rem' }}>
                    Sorry, your access link is no longer valid
                </Typography>
                { props.resendButton &&
                <Typography sx={{ fontSize: '0.875rem' }}>
                    This link has expired. Please use the most recent link sent to you or click the button below to send a new one to your phone number on file.
                </Typography>
                }
                { props.resendButton &&
                <Button fullWidth variant='blueContained' onClick={handleResendToken} disabled={buttonDisabled}>
                    { !buttonDisabled && 'Resend Link' }
                    { buttonDisabled && isError && <><Close></Close>Error Sending Link</> }
                    { buttonDisabled && !isError && <><Check></Check>Link Sent</> }
                </Button>
                }
                { (props.resendButton && buttonDisabled && isError) || !props.resendButton && 
                    <Typography sx={{ fontSize: '0.75rem', color: `${theme.palette.red.main}` }}>{'We encountered a problem, please contact '}
                        <Link>support@docsihealth.com</Link> or fill out <Link underline="always" href='https://docs.google.com/forms/d/e/1FAIpQLSdZk2yXi-G7yU_dhUurqcd_id2ffgXYXh46m0tNwW2EJJmD-Q/viewform' target="_blank">
                        this form</Link> for help.
                    </Typography>
                }
                { props.resendButton && buttonDisabled && !isError &&
                    <Typography sx={{ fontSize: '0.75rem' }}>{'If you don\'t receive it within five minutes, please fill '} 
                        <Link underline="always" href='https://docs.google.com/forms/d/e/1FAIpQLSdZk2yXi-G7yU_dhUurqcd_id2ffgXYXh46m0tNwW2EJJmD-Q/viewform' target="_blank">
                        this form</Link> for help.
                    </Typography>
                }
            </Stack>
        </Dialog>
    )
}

export default InvalidToken;