import React from 'react';
import { Tabs, Tab, Stack, Typography } from '@mui/material';
import useAnalytics from '../../Hooks/useAnalytics';
import { BoltRounded } from '@mui/icons-material';

function CardReviewTabs(props) {
    const analytics = useAnalytics();

    function handleTabChange(e, value) {
        analytics.track('Toggled ' + e.target.textContent, {
            sector: 'Physician Dashboard',
            location: 'Single Card View'
        });
        props.setSelectedTab(value);
    }

    return (
        <Tabs variant='fullWidth' value={props.selectedTab} onChange={(e, value) => handleTabChange(e, value)} centered>
            <Tab 
                label={
                    <Stack direction='row' alignItems='center'>
                        <BoltRounded /> 
                        <Typography variant='normal'>Optimizations</Typography>
                    </Stack>
                }
                value={0} 
            />
            <Tab label='All Card Items' value={1} />
        </Tabs>
    );
}

export default CardReviewTabs;