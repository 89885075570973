import React from 'react';
import { Stack } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { useLogUserActionMutation } from '../../api/physicianDashboardSlice';

function LogOnEnter(props) {
    const [logAction] = useLogUserActionMutation();

    async function handleEnterView(inView) {
        if (inView) {
            await logAction({
                action: props.action,
                data: props.data,
            });
        }
    }

    const { ref } = useInView({
        triggerOnce: true,
        threshold: 0.5,
        onChange: handleEnterView,
    });

    return (
        <Stack ref={ref}>
            { props.children }
        </Stack>
    );
}

export default LogOnEnter;
