import { Stack } from '@mui/system';
import { useState } from 'react';
import FilterDialog from './FilterDialog';
import { FilterListRounded } from '@mui/icons-material';
import { IconButton, Typography, Chip } from '@mui/material';
import React from 'react';
import { useLogUserActionMutation } from '../../api/apiSlice';

function FilterManager(props) {
    const [isShowingFiltersDialog, setIsShowingFiltersDialog] = useState(false);
    const [logUserAction] = useLogUserActionMutation();
    
    const filterOptions = {
        'Location': {
            name: 'Location',
            options: [...new Set(props.campaigns.map((campaign) => campaign.location))],
            selectedOptions: [],
            selected: false,
        },
        'Specialties': {
            name: 'Specialties',
            options: [...new Set(props.campaigns.map((campaign) => campaign.specialties).flat())],
            selectedOptions: [],
            selected: false,
        },
        'Status': {
            name: 'Status',
            options: [...new Set(props.campaigns.map((campaign) => campaign.status))],
            selectedOptions: [],
            selected: false,
        },
    }

    function handleApplyFilters(filters) {
        props.setAppliedFilters(filters);
        logUserAction({ action: 'applied_campaign_monitoring_filters', data: { filterSet: filters, } });
        setIsShowingFiltersDialog(false);
    }

    return (
        <Stack direction='row' spacing={2}>
            <IconButton onClick={() => setIsShowingFiltersDialog(true)}>
                <FilterListRounded />
            </IconButton>
            { Object.values(props.appliedFilters).some((filter) => filter.selected) &&
                <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography variant='normal' fontSize='14px'>Active Filters:</Typography>
                    { Object.values(props.appliedFilters).filter((filter) => filter.selected).map((filter) => (
                        <Chip key={filter.name} label={`${filter.name}: ${filter.selectedOptions.join(', ')}`} />
                    ))}
                </Stack>
            }
            <FilterDialog 
                dialogTitle='Campaign Filters'
                open={isShowingFiltersDialog} 
                setOpen={setIsShowingFiltersDialog} 
                filters={Object.keys(props.appliedFilters).length > 0 ? props.appliedFilters : filterOptions}
                handleApplyFilters={handleApplyFilters}
            />
        </Stack>
    );
}

export default FilterManager;

