import React, { useState } from 'react';
import { Stack, Typography, TextField, Button, IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useUpdateCampaignMutation } from '../../api/campaignSlice';

function EditCampaignName(props) {
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState(props.campaignInfo.name);
    const [updateCampaign] = useUpdateCampaignMutation();

    const handleNameChange = async() => {
        await updateCampaign({
            campaignId: props.campaignId,
            requestBody: {
                name: name
            }
        });
        setIsEditing(false);
    }

    const showSaveButton = props.campaignInfo.name !== name && name;

    return (
        <Stack >
            { isEditing &&
                <Stack direction='row' alignItems='flex-end' sx={{width:'75%'}} >
                    <TextField 
                        margin='normal' 
                        id="filled-basic" 
                        defaultValue={props.campaignInfo.name} 
                        variant="filled" 
                        onChange={(event) => setName(event.target.value)}
                        onEnterKeyDown={handleNameChange}
                        sx={{margin:0, width:'50%', padding:'0' }} 
                    />
                    {showSaveButton ?
                        <Button variant='text' color='primary' pb={1} onClick={handleNameChange} > Save</Button> :
                        <Button variant='text' color='primary' pb={1} onClick={() => setIsEditing(false)} > Cancel</Button> 
                    }   
                </Stack>
            }
            { !isEditing &&
            <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='heavy' sx={{ fontSize: '20px' }}>{props.campaignInfo.name}</Typography>
                <IconButton onClick={() => setIsEditing(true)}>
                    <Edit fontSize='small' />
                </IconButton>
            </Stack>
            }
        </Stack>
    )
}

export default EditCampaignName;