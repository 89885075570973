import { Menu } from '@mui/icons-material';
import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material';
import React from 'react';
import logo from '../../assets/docsi_logo.png';
import HeaderBreadcrumbs from './HeaderBreadcrumbs';

function Header(props) {
    return (
        <AppBar position='fixed' sx={{ 
            backgroundColor: '#F7F9FC', 
            boxShadow: 0, 
            width: {
                xs: 'calc(100%)', 
                md: `calc(100% - ${props.drawerWidth}px)`
            },
            ml: {
                xs: 0,
                md: `${props.drawerWidth}px` 
            },
            zIndex: 5
        }}>
            <Stack direction='row'>
                <IconButton onClick={() => props.setOpenSidebar(true)} sx={{ display: { xs: 'inline', md: 'none' }, padding: '16px' }}>
                    <Menu />
                </IconButton>
                <Toolbar sx={{ justifyContent: 'space-between', width: '100%' }}>
                    <HeaderBreadcrumbs />
                    <Box display='flex' alignItems='center' mr={{ xs: 1,  md: 4 }}>
                        <img src={logo} alt="DOCSI Logo" style={{ objectFit: 'contain' }} height={25}/>
                    </Box>
                </Toolbar>
            </Stack>
        </AppBar>
    );
}

export default Header;