import React from 'react';
import { TableHead, TableRow, TableCell, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import MultipleProgressBar from '../../../CustomComponents/MultipleProgressBar';
import { theme } from '../../../../theme';
import { Box } from '@mui/system';
import ProgressTooltip from './ProgressTooltip';


function PhysicianFooter(props) {
    const totalOpportunityValue = props.visiblePhysicians.reduce((acc, physician) => acc + physician.recommendation_summary.total_value, 0);
    const totalNumRecs = props.visiblePhysicians.reduce((acc, physician) => acc + physician.recommendation_summary.total_recs, 0);
    const totalApprovedValue = props.visiblePhysicians.reduce((acc, physician) => acc + physician.recommendation_summary.accepted_value, 0);
    const totalApprovedPercentage = Math.round((totalApprovedValue / Math.max(totalOpportunityValue, 1)) * 100);
    const totalNumApproved = props.visiblePhysicians.reduce((acc, physician) => acc + physician.recommendation_summary.num_accepted, 0);
    const countApprovedPercentage = Math.round((totalNumApproved / Math.max(totalNumRecs, 1)) * 100);
    const totalDeniedValue = props.visiblePhysicians.reduce((acc, physician) => acc + physician.recommendation_summary.rejected_value, 0);
    const totalDeniedPercentage = Math.round((totalDeniedValue / Math.max(totalOpportunityValue, 1)) * 100);
    const totalNumDenied = props.visiblePhysicians.reduce((acc, physician) => acc + physician.recommendation_summary.num_rejected, 0);
    const countDeniedPercentage = Math.round((totalNumDenied / Math.max(totalNumRecs, 1)) * 100);
    const totalUnreviewedValue = props.visiblePhysicians.reduce((acc, physician) => acc + physician.recommendation_summary.open_value, 0);
    const totalUnreviewedPercentage = Math.round((totalUnreviewedValue / Math.max(totalOpportunityValue, 1)) * 100);
    const totalNumUnreviewed = props.visiblePhysicians.reduce((acc, physician) => acc + physician.recommendation_summary.num_open, 0);
    const countUnreviewedPercentage = Math.round((totalNumUnreviewed / Math.max(totalNumRecs, 1)) * 100);

    return (
        <TableHead sx={{ bottom: 0, left: 0, position: 'sticky', zIndex: 2 }}>
            <TableRow sx={{ textOverflow: 'ellipsis', overflow: 'hidden', color: 'black', height: '60px' }}>
                <TableCell style={{ borderBottom: 'none' }}>
                    <Typography variant='heavy' fontSize='12px'>
                        <strong>Total ({props.visiblePhysicians.length}/{props.totalPhysicians})</strong>
                    </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: 'none' }}>
                    <ProgressTooltip 
                        acceptedCount={totalNumApproved} 
                        acceptedValue={totalApprovedValue} 
                        deniedCount={totalNumDenied} 
                        deniedValue={totalDeniedValue} 
                        unreviewedCount={totalNumUnreviewed} 
                        unreviewedValue={totalUnreviewedValue}
                    >
                        <Box>
                            <MultipleProgressBar segments={[
                                {
                                    value: totalApprovedPercentage,
                                    color: `${theme.palette.blue.main}`
                                },
                                {
                                    value: totalDeniedPercentage,
                                    color: `${theme.palette.background.deniedProgress}`
                                },
                                {
                                    value: totalUnreviewedPercentage,
                                    color: `${theme.palette.background.unreviewedProgress}`
                                }
                            ]} label={props.numericFormat === 'currency' ? 
                                <strong>{`$${Math.round((totalOpportunityValue - totalUnreviewedValue) / 100 / 1000)}k / $${Math.round(totalOpportunityValue / 100 / 1000)}k (${100 - totalUnreviewedPercentage}%)`}</strong> : 
                                <strong>{`${totalNumRecs - totalNumUnreviewed} / ${totalNumRecs} Optimizations (${100 - countUnreviewedPercentage}%)`}</strong>
                            } />
                        </Box>
                    </ProgressTooltip>
                </TableCell>
                <TableCell style={{ borderBottom: 'none' }}>
                    <Typography variant='heavy' fontSize='12px'>
                        <strong>
                            { props.numericFormat === 'currency' &&
                                <NumericFormat value={totalOpportunityValue / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} fixedDecimalScale />
                            }
                            { props.numericFormat === 'count' &&
                                `${totalNumRecs} Optimizations`
                            }
                        </strong>
                    </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: 'none' }}>
                    <Typography variant='heavy' fontSize='12px'>
                        <strong>
                            { props.numericFormat === 'currency' &&
                                <NumericFormat value={totalApprovedValue / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={` (${totalApprovedPercentage}%)`} decimalScale={0} fixedDecimalScale />
                            }
                            { props.numericFormat === 'count' &&
                                `${totalNumApproved} Optimizations (${countApprovedPercentage}%)`
                            }
                        </strong>
                    </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: 'none' }}>
                    <Typography variant='heavy' fontSize='12px'>
                        <strong>
                            { props.numericFormat === 'currency' &&
                                <NumericFormat value={totalDeniedValue / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={` (${totalDeniedPercentage}%)`} decimalScale={0} fixedDecimalScale />
                            }
                            { props.numericFormat === 'count' &&
                                `${totalNumDenied} Optimizations (${countDeniedPercentage}%)`
                            }
                        </strong>
                    </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: 'none' }}>
                    <Typography variant='heavy' fontSize='12px'>
                        <strong>
                            { props.numericFormat === 'currency' &&
                                <NumericFormat value={totalUnreviewedValue / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={` (${totalUnreviewedPercentage}%)`} decimalScale={0} fixedDecimalScale />
                            }
                            { props.numericFormat === 'count' &&
                                `${totalNumUnreviewed} Optimizations (${countUnreviewedPercentage}%)`
                            }
                        </strong>
                    </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default PhysicianFooter;
