import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';


function ContractComplianceDialog(props) {
    function handleClose() {
        props.setIsShowingInfo(false);
    }

    return (
        <Dialog onClose={handleClose} open={props.isShowingInfo} >
            <DialogTitle>
                What is Contract Compliance?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {'Your facility gets discounted prices on agreed "in-contract" items but faces penalties for using unauthorized "out-of-contract" items.'}
                </DialogContentText>
            </DialogContent>
            <DialogTitle>
                How is my Contract Compliance % Calculated?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {'Your Contract Compliance percentage is calculated by dividing the cost of your "in-contract" supplies by your "out-of-contract" supplies.'}
                </DialogContentText>
                <DialogContentText>
                    {'Specially approved "out-of-contract" supply usage won\'t affect your Contract Compliance.'}
                </DialogContentText>
                <DialogContentText>
                    {'Unless you are in the top three of your leaderboard, your peers won\'t see your ranking.'}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}   

export default ContractComplianceDialog;
