import { Stack, Typography } from '@mui/material';
import React from 'react';
import OptimizationContext from '../Optimizations/OptimizationContext';
import CategoryDrawer from '../Optimizations/CategoryDrawer';

function OptimizationOpportunity(props) {
    var optimizationText = {
        'addition': 'Add This Item',
        'removal': 'Remove This Item',
        'update': 'Adjust This Item',
    }

    return (
        <Stack direction='row' alignItems='center' p={2}>
            <Stack spacing={0.5} width='100%'>
                <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>Optimization: {optimizationText[props.recommendation.type]}</strong></Typography>
                <OptimizationContext item={props.item} recommendation={props.recommendation} />
                <CategoryDrawer recommendation={props.recommendation} categoryCount={props.categoryCount} openCategory={props.openCategory} />
            </Stack>
        </Stack>
    )
}

export default OptimizationOpportunity;
