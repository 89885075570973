import { Button, Divider, Stack, Typography } from '@mui/material';
import React, {useState} from 'react';
import ItemDetails from './ItemDetails';
import OptimizationOpportunity from './OptimizationOpportunity';
import { ChevronLeftRounded } from '@mui/icons-material';
import { useTheme } from '@mui/system';
import EditItemDrawer from './EditItemDrawer';
import { useMemo } from 'react';
import EditItemPanel from './EditItemPanel';
import LogOnEnter from '../../../CustomComponents/LogOnEnter';

function ItemInfo(props) {
    const [isEditing, setIsEditing] = useState(false);

    // This is hot garbage but there's no clean way to handle addition recommendations right now.
    const recommendation = useMemo(() => {
        // In this first if case, the 'item' is actually an addition recommendation. 
        if (props.item.type === 'addition') {
            return props.draft.recommendations[props.item.item.category].filter((rec) => rec.id === props.item.id)[0];
        } else if (props.draft.recommendations[props.item.category]) {
            return props.draft.recommendations[props.item.category].filter((rec) => rec.item.id === props.item.id)[0];
        } else {
            return undefined;
        }
    });

    const item = useMemo(() => {
        if (props.item.type === 'addition') {
            return props.item.item;
        } else {
            return props.draft.items[props.item.category].filter((item) => item.id === props.item.id)[0];
        }
    }, [props.item, props.draft]);

    const countItemsInCategory = props.draft.items[item.category] !== undefined ? 
        props.draft.items[item.category].length : 
        0;

    return (
        <LogOnEnter action='viewed_item_details' data={{ item: props.item, recommendation: recommendation ?? {} }}>
            <Stack divider={<Divider />} sx={{ backgroundColor: 'white' }}>
                <ItemInfoHeader handleBack={props.closeItemView} />
                { props.item.images?.map((image) => (
                    <Stack direction='row' justifyContent='space-between' alignItems='center' key={image.id}>
                        <LogOnEnter action='viewed_item_image' data={{ item: props.item }}>
                            <Stack p={2} spacing={0.5}>
                                <Typography variant='heavy' sx={{ fontSize: '16px' }}><strong>Image</strong></Typography>
                                <img src={image.presigned_url} width='100%' style={{ objectFit: 'contain' }} alt='Item'/>
                            </Stack>
                        </LogOnEnter>
                    </Stack>
                ))}
                <ItemDetails item={item} recommendation={recommendation}/>
                { (recommendation === undefined || (recommendation && recommendation.type !== 'addition')) && 
                    <EditItemPanel 
                        item={item} 
                        recommendation={recommendation} 
                        draft={props.draft} 
                        setIsEditing={setIsEditing} 
                        closeItemInfo={props.closeItemView} 
                        physician={props.physician}
                    />
                }
                { recommendation && 
                    <OptimizationOpportunity recommendation={recommendation} item={props.item} categoryCount={countItemsInCategory} openCategory={props.openCategory} />
                }
                <EditItemDrawer 
                    open={isEditing} 
                    setOpen={setIsEditing} 
                    closeItemInfo={props.closeItemView}
                    item={item} 
                    draft={props.draft} 
                    recommendation={recommendation} 
                />
            </Stack>
        </LogOnEnter>
    )
}

function ItemInfoHeader(props) {
    const theme = useTheme();

    return (
        <Stack p={2} direction='row' justifyContent='space-between'>
            <Button onClick={props.handleBack} style={{ alignSelf: 'start', color: `${theme.palette.subtext.main}`, width: '62px' }} startIcon={<ChevronLeftRounded />}>Back</Button>
            <Typography variant='bold' sx={{ fontSize: '14px', alignSelf: 'center' }}>More Details</Typography>
            <Stack sx={{ width: '62px' }}></Stack>
        </Stack>
    );
}

export default ItemInfo;
