import React from 'react';
import { Box, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

function SortableTableHeader(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return(
        <TableHead>
            <TableRow sx={{ height: '60px' }}>
                {props.headers.map((header) => (
                    <TableCell key={header.id} style={{ borderTop: 'none' }} sx={{ width: `${header.ratio}%`, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} sortDirection={orderBy === header.id ? order : false}>
                        {header.sortable === false ? (
                            <Tooltip title={header.tooltip} placement='top'>
                                {header.label}
                            </Tooltip>
                        ) : (
                            <TableSortLabel
                                active={orderBy === header.id}
                                direction={orderBy === header.id ? order : 'asc'}
                                onClick={createSortHandler(header.id)}
                                sx={{ width: '100%', justifyContent: header.justify }}
                                style={{ flexDirection: header.justify === 'end' ? 'row-reverse' : 'row' }}
                            >
                                <Tooltip title={header.tooltip} placement='top'>
                                    {header.label}
                                </Tooltip>
                                {orderBy === header.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default SortableTableHeader;