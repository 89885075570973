import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserQuery, useUpdateUserMutation } from '../../api/userSlice';
import EditUserPerssion from './EditUserPermission';
import UserDetails from './UserDetails';

function EditUser() {
    let params = useParams();
    const { data: user, isSuccess } = useGetUserQuery(params.userId);
    const [submitUpdateUser] = useUpdateUserMutation();
    const navigate = useNavigate();
    const updateUser = async (data) => {
        await submitUpdateUser({
            user: user,
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
            password: data.password,
        });
        navigate('/users', { state: {
            hospitalId : user.hospital.id
        }});
    }


    return (
        <Stack spacing={2} px={{ xs: 2, md: 6 }} pb={2} height='100%' sx={{ overflowY: 'auto' }}>
            <Typography variant='heavy' sx={{ fontSize: '20px' }}>User Settings</Typography>
            <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} >
                { isSuccess && 
                    <UserDetails 
                        user={user} 
                        newUser={false}
                        saveUser={updateUser}
                        isSuperUser = {false}
                    />
                }
                { isSuccess && 
                    <EditUserPerssion
                        user = {user}
                    />
                }
            </Stack>
        </Stack>
    );
}

export default EditUser;