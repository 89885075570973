import { BoltRounded, CancelRounded, CheckCircleRounded, HowToRegRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';

function OptimizationHeader(props) {
    const theme = useTheme();

    var headerText = '';

    function buildAdjustmentString() {
        var adjustmentString = '';
        if (props.recommendation.open_quantity > props.item.open_quantity) {
            adjustmentString += 'Increase Open Quantity';
        } else if (props.recommendation.open_quantity < props.item.open_quantity) {
            adjustmentString += 'Decrease Open Quantity';
        }

        if (props.recommendation.hold_quantity < props.item.hold_quantity) {
            adjustmentString ? adjustmentString += ', Decrease Hold Quantity' : adjustmentString += 'Decrease Hold Quantity';
        } else if (props.recommendation.hold_quantity > props.item.hold_quantity) {
            adjustmentString ? adjustmentString += ', Increase Hold Quantity' : adjustmentString += 'Increase Hold Quantity';
        }
        return adjustmentString;
    }
    
    if (props.recommendation.status === 'rejected') {
        headerText = 'Optimization Declined';
    } else if (props.recommendation.status === 'custom_update') {
        headerText = 'Item Adjusted (Custom Edit)';
    } else if (props.recommendation.status === 'custom_removal') {
        headerText = 'Item Removed (Custom Edit)';
    } else if (props.recommendation.status === 'custom_swap') {
        headerText = 'Item Swapped (Custom Edit)';
    } else if (props.recommendation.type === 'removal') {
        headerText = props.recommendation.status === 'accepted' 
            ? 'Item Removed' 
            : 'Remove This Item';
    } else if (props.recommendation.type === 'addition') {
        headerText = props.recommendation.status === 'accepted'
            ? 'Item Added'
            : 'Add This Item';
    } else if (props.recommendation.type === 'update') {
        headerText = props.recommendation.status === 'accepted' 
            ? 'Item Adjusted'
            : buildAdjustmentString();
    }

    return (
        <Stack direction='row' spacing={0.5} alignItems='center'>
            { props.recommendation.status === 'accepted' && 
                <CheckCircleRounded 
                    fontSize='small' 
                    style={{ color: `${theme.palette.green.main}`, marginLeft: '-5px' }} 
                />
            }
            { props.recommendation.status === 'rejected' && 
                <CancelRounded
                    fontSize='small'
                    style={{ color: `${theme.palette.red.main}`, marginLeft: '-5px' }}
                />
            }
            { props.recommendation.status === 'open' && 
                <BoltRounded fontSize='small' style={{ marginLeft: '-5px' }} />
            }
            { (props.recommendation.status === 'custom_update' || props.recommendation.status === 'custom_removal' || props.recommendation.status === 'custom_swap') &&
                <HowToRegRounded fontSize='small' style={{ marginLeft: '-5px', color: `${theme.palette.green.main}` }} />
            }
            <Typography variant='bold' sx={{ fontSize: '14px' }}>{headerText}</Typography>
        </Stack>
    )
}

export default OptimizationHeader;