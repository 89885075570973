import { Stack, Typography } from '@mui/material';
import React from 'react';
import LeaderboardRow from './LeaderboardRow';
import { MoreHoriz } from '@mui/icons-material';
import { useTheme } from '@mui/system';

function LeaderboardRankings(props) {
    const theme = useTheme();
    const currentRanking = props.rankings?.findIndex((ranking) => ranking.id === props.cardOwner?.id);

    return (
        <Stack>
            { props.rankings?.slice(0, 3).map((physician, index) => (
                <LeaderboardRow 
                    key={index + 1} 
                    value={props.variant === 'compliance' ? physician.compliance_percentage : physician.efficiency}
                    physician={physician} 
                    ranking={index + 1}
                    currentUser={props.cardOwner}
                    userPercentile={props.userPercentile}
                    sx={{ paddingTop: '4px' }}
                />
            ))}
            { currentRanking > 2 && 
                <>
                    <MoreHoriz sx={{ color: '#0000004D', alignSelf: 'center' }}/>
                    <LeaderboardRow
                        key={currentRanking}
                        value={props.variant === 'compliance' ? props.rankings.find((ranking) => ranking.id === props.cardOwner.id).compliance_percentage : props.userEfficiency}
                        physician={props.rankings.find((ranking) => ranking.id === props.cardOwner.id)}
                        ranking={currentRanking + 1}
                        currentUser={props.cardOwner}
                        userPercentile={props.userPercentile}
                    />
                </>
            }
            <Typography variant='normal' sx={{ alignSelf: 'center', fontSize: '12px', color: `${theme.palette.subtext.main}`, paddingTop: '8px' }}>
                {props.rankings?.length} Total
            </Typography>
        </Stack>
    );
}

export default LeaderboardRankings;
